import React from "react";
import "react-tooltip/dist/react-tooltip.css";
import style from "./dashboardCard.module.scss";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const OrderListCard = ({ data, titleName, index, active }) => {
  const getSubtitle = (titleName) => {
    switch (titleName) {
      case "Yearly Orders":
        return "Last Year";
      case "Monthly Orders":
        return "Last Month";
      case "Weekly Orders":
        return "Last Week";
      case "Daily Orders":
        return "Yesterday";
      case "Payment History":
        return "Last Month";
      case "Total Orders":
        return "Last Year";
      case "Today Orders":
        return "Yesterday";
      default:
        return "Previous";
    }
  };
  return (
    <div className={`${style.root} ${active ? `active` : ""}`}>
      <div className={`${style.inner} ${style.old}`}>
        <div
          className={`${style.body} ${
            Object.keys(data)?.includes("price") && style.first
          }`}
        >
          <h3
            data-tooltip-id={`total-order-${index}`}
            className="pro-ttl h3 pro-mb-2 pro-fw-medium"
          >
            {data?.total_order !== null && data?.total_order !== undefined
              ? data?.total_order
              : data?.total_payment !== null &&
                data?.total_payment !== undefined
              ? data?.total_payment
              : null}
          </h3>
          <div className="pro-d-flex pro-items-center pro-gap-2">
            <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
              {data?.order_name}
            </h6>
            {data?.percentage_difference !== "" && data?.comparison_status && (
              <span
                className={`${
                  data?.comparison_status === "greater"
                    ? style.increment
                    : data?.comparison_status === "lesser"
                    ? style.decrement
                    : ""
                } ${
                  style.growth_percentage
                } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
              >
                <span className={`material-symbols-outlined ${style.arrow}`}>
                  arrow_outward
                </span>
                <span>{data?.percentage_difference}%</span>
              </span>
            )}
          </div>
          <div
            className={`pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3`}
          >
            {data?.percentage_difference !== "" && data?.comparison_status && (
              <span
                data-tooltip-id={`previous-order-${index}`}
                className={`pro-badge  ${style.last_month}`}
              >
                {getSubtitle(titleName)}
                &nbsp;:&nbsp;{data?.previous_order ?? ""}
              </span>
            )}
          </div>
        </div>
        {data?.price?.map((item, i) => {
          return (
            <div
              className={`${style.body} ${
                data?.price?.length - 1 == i ? "" : style.first
              }`}
            >
              <h3
                data-tooltip-id={`total-amount-${index}-${i}`}
                className="pro-ttl h3 pro-mb-2 pro-fw-medium"
              >
                {item?.formated_current_price !== null &&
                item?.formated_current_price !== undefined
                  ? item?.formated_current_price
                  : null}
              </h3>
              <div className="pro-d-flex pro-items-center pro-gap-2">
                <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                  {item?.name ?? ""}
                </h6>
                {item?.price_percentage_difference !== "" &&
                  item?.price_comparison_status && (
                    <span
                      className={`${
                        item?.price_comparison_status === "greater"
                          ? style.increment
                          : item?.price_comparison_status === "lesser"
                          ? style.decrement
                          : ""
                      } ${
                        style.growth_percentage
                      } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
                    >
                      <span
                        className={`material-symbols-outlined ${style.arrow}`}
                      >
                        arrow_outward
                      </span>
                      <span>{item?.price_percentage_difference}%</span>
                    </span>
                  )}
              </div>
              <div
                className={`pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3`}
              >
                {" "}
                {item?.price_percentage_difference !== "" &&
                  item?.price_comparison_status && (
                    <span
                      data-tooltip-id={`previous-amount-${index}-${i}`}
                      className={`pro-badge ${style.last_month}`}
                    >
                      {getSubtitle(titleName)}
                      &nbsp;:&nbsp;{item?.formated_previous_price ?? ""}
                    </span>
                  )}
              </div>
              <ReactTooltip
                id={`total-amount-${index}-${i}`}
                place="bottom"
                content={`${item?.name} : ${item?.current_price}`}
                className={"react-tooltip"}
              />
              <ReactTooltip
                id={`previous-amount-${index}-${i}`}
                place="bottom"
                content={`${item?.name} : ${item?.previous_price}`}
                className={"react-tooltip"}
              />
            </div>
          );
        })}
      </div>
      <ReactTooltip
        id={`total-order-${index}`}
        place="bottom"
        content={`${titleName} : ${data?.total_order}`}
        className={"react-tooltip"}
      />
      <ReactTooltip
        id={`previous-order-${index}`}
        place="bottom"
        content={`${
          titleName == "Payment History" ? "Payment History" : "Orders"
        } : ${data?.previous_order}`}
        className={"react-tooltip"}
      />
    </div>
  );
};

export default OrderListCard;
