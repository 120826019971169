import DashboardCardShimmer from "../../Global/Shimmers/DashboardCardShimmer";
import NewDashboardCardShimmer from "../../Global/Shimmers/NewDashboardCardShimmer";
import DashboardCard from "../DashboardCard";
import Style from "./dashboardHero.module.scss";

const DashboardHero = ({
  data,
  obj,
  transactions = false,
  loading,
  listingType,
  isDashboard,
  shimmerCount = 1,
}) => {
  return (
    <div className={`pro-w-100`}>
      <div className={`row g-4`}>
        {!loading ? (
          <>
            {Object.keys(data || {})?.map((key, index) => {
              return (
                <>
                  <div
                    className={`${
                      data?.[key]?.price?.length && !isDashboard
                        ? "col-12"
                        : "col-3"
                    } ${!transactions ? Style.dashboardCard_root : ""}`}
                    onClick={
                      !transactions
                        ? () => obj?.[key]?.handleClick(obj?.[key]?.label)
                        : () => {}
                    }
                  >
                    <DashboardCard
                      isDashboard={isDashboard}
                      index={index}
                      icon={obj?.[key]?.icon}
                      title={data?.[key]?.name}
                      data={data?.[key]}
                      active={obj?.[key]?.label === listingType ? true : false}
                      transactions={transactions}
                    />
                  </div>
                </>
              );
            })}
          </>
        ) : (
          [...Array(shimmerCount)].map((_, i) =>
            isDashboard ? (
              <div className="col-3" key={i}>
                <NewDashboardCardShimmer />
              </div>
            ) : (
              <div className="col-12" key={i}>
                <DashboardCardShimmer />
              </div>
            )
          )
        )}
      </div>
    </div>
  );
};

export default DashboardHero;
