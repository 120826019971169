import React from "react";
import ProductDetails from "./ProductDetails";
import useEditProduct from "./useEditProduct";
import ProductEditShimmer from "../../../Global/Shimmers/ProductEditShimmer";

const EditProduct = ({ refetch }) => {
  const {
    formik,
    isSuccess,
    getFieldError,
    handleCloseModal,
    setUpdateAllStore,
  } = useEditProduct(refetch);
  return (
    <>
      {isSuccess ? (
        <ProductDetails
          formik={formik}
          getFieldError={getFieldError}
          handleCloseModal={handleCloseModal}
          setUpdateAllStore={setUpdateAllStore}
        />
      ) : (
        <ProductEditShimmer />
      )}
    </>
  );
};

export default EditProduct;
