import { createApi } from "@reduxjs/toolkit/query/react";
import { getAdminAxiosInstance } from "../../../api/Admin";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAdminAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort")}sort_order=${
            params?.sort_order || "desc"
          }&per_page=${params?.page_size || "20"}&${getParams("search")}page=${
            params?.page || ""
          }`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const cms = createApi({
  reducerPath: "cmsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "CMS",
    "Privacy Policy",
    "Basic Data",
    "Edit Data",
    "CustomNotification",
    "CustomNotificationBasicData",
  ],
  endpoints: (builder) => ({
    getPrivacyPolicyListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/app-settings/index",
      }),
      providesTags: ["Privacy Policy"],
    }),
    updatePrivacyPolicyTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Privacy Policy"],
    }),
    getComponentBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/settings/basic-data",
      }),
      providesTags: ["Basic Data"],
      keepUnusedDataFor: 1,
    }),
    getComponentEditData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/settings/edit-home-component",
      }),
      providesTags: ["Edit Data"],
    }),
    //custom notification starts here

    getCustomNotification: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/notification/index",
      }),
      providesTags: ["CustomNotification"],
    }),
    getCustomNotificationBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/notification/basic-data",
      }),
      providesTags: ["CustomNotificationBasicData"],
    }),
    updateCustomNotificationHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["CustomNotification"],
    }),

    sendCustomNotification: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/notification/send-notification`,
      }),

      invalidatesTags: ["CustomNotification"],
    }),
    //custom notification ends her
  }),
});

export const {
  useGetComponentBasicDataQuery,
  useGetComponentEditDataQuery,
  useGetPrivacyPolicyListDataQuery,
  useUpdatePrivacyPolicyTableHeadDataMutation,
  useGetCustomNotificationQuery,
  useUpdateCustomNotificationHeadDataMutation,
  useGetCustomNotificationBasicDataQuery,
  useSendCustomNotificationMutation,
} = cms;
