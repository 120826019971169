import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useDeliveryAreasForm from "./useDeliveryAreasForm";
import Select from "react-select";

const DeliveryAreasForm = ({ isStickyFooter, refetch, handleClose }) => {
  const { formik, isEdit, staffBasicData, getFieldError } =
    useDeliveryAreasForm({ refetch, handleClose });

  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Name"}
          type="text"
          id="name"
          name="name"
          className={`pro-input lg ${getFieldError("name") && " error"}`}
          {...formik.getFieldProps("name")}
          // onChangedevent) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("name")}
          errorMessage={getFieldError("name")}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Store
        </label>
        <Select
          isMulti
          id="store"
          name="store"
          placeholder={"Select"}
          className={`pro-input lg  ${getFieldError("store") && " error"}`}
          classNamePrefix="pro-input"
          options={staffBasicData?.data?.stores ?? []}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?.customer_key}
          value={staffBasicData?.data?.stores?.filter((m) =>
            formik?.values?.store?.includes(m.customer_key?.toString?.())
          )}
          onBlur={formik.handleBlur("store")}
          onChange={(value) =>
            formik.setFieldValue(
              "store",
              value.map((v) => v.customer_key?.toString?.())
            )
          }
          menuPlacement="auto"
        />
        {getFieldError("store") && (
          <span className="error-text">{getFieldError("store")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <Input
          label={"Area"}
          type="text"
          id="area"
          name="area"
          className={`pro-input lg ${getFieldError("area") && " error"}`}
          {...formik.getFieldProps("area")}
          // onChangedevent) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("area")}
          errorMessage={getFieldError("area")}
        />
      </div>

      <div className="input-wrap pro-mb-4 pro-rtl">
        <Input
          label={"Alias Area"}
          type="text"
          id="alias_area"
          name="alias_area"
          className={`pro-input lg ${getFieldError("alias_area") && " error"}`}
          {...formik.getFieldProps("alias_area")}
          // onChangedevent) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("alias_area")}
          errorMessage={getFieldError("alias_area")}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <Input
          label={"Block"}
          type="text"
          id="block"
          name="block"
          className={`pro-input lg ${getFieldError("block") && " error"}`}
          {...formik.getFieldProps("block")}
          // onChangedevent) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("block")}
          errorMessage={getFieldError("block")}
        />
      </div>
      <div className="input-wrap pro-mb-4 pro-rtl">
        <Input
          label={"Alias Block"}
          type="text"
          id="alias_block"
          name="alias_block"
          className={`pro-input lg ${getFieldError("alias_block") && " error"}`}
          {...formik.getFieldProps("alias_block")}
          // onChangedevent) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("alias_block")}
          errorMessage={getFieldError("alias_block")}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <Input
          label={"Delivery Charge"}
          type="text"
          id="delivery_charge"
          name="delivery_charge"
          className={`pro-input lg ${
            getFieldError("delivery_charge") && " error"
          }`}
          {...formik.getFieldProps("delivery_charge")}
          // onChangedevent) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("delivery_charge")}
          errorMessage={getFieldError("delivery_charge")}
        />
      </div>

      <div className="pro-check-box pro-py-2">
        <div className="form-check form-switch ">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            checked={Boolean(formik.values?.status === 1) ?? false}
            onChange={(e) =>
              formik.setFieldValue(`status`, e?.target?.checked ? 1 : 2)
            }
          />
          <label htmlFor="default" className="pro-check-label">
            Status
          </label>
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
          // disabled={isStaffCreate || isImageUploading}
        >
          {/* {!isEdit
            ? isStaffCreate
              ? "Creating..."
              : "Create"
            : isStaffCreate
            ? "Updating..."
            : "Update"} */}
          {isEdit ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};

export default DeliveryAreasForm;
