import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetSubAdminBasicDataQuery } from "../../../../store/queries/Admin";
import { changeRole } from "../api";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../store/slices/Admin/Role/roleSlice";
import { useDispatch } from "react-redux";

const useChangeRole = (refetch, roleListData, roleId, handleDeleteAction) => {
  const { data: roleData = {} } = useGetSubAdminBasicDataQuery();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      new_role_id: "",
    },
    validationSchema: Yup.object({
      new_role_id: Yup.object().required(`Select new role`),
    }),
    onSubmit: (values) => {
      let data = {
        role_id: roleId,
        new_role_id: values?.new_role_id?._id,
      };
      changeRole(data).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success(`Role deleted successfully`);
          refetch();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          handleDeleteAction();
        } else {
          toast.error(`Unable to delete role, Please try again`);
          handleDeleteAction();
        }
      });
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  let roleValue = roleListData?.filter?.((item) => item?.id === roleId);
  let rolesList = roleData?.data?.roles?.filter?.(
    (item) => item?._id !== roleValue?.[0]?.id
  );

  return { formik, rolesList, getFieldError };
};

export default useChangeRole;
