import { useSelector } from "react-redux";
import { useGetProductImageMutation } from "../../../../../../store/queries/Product";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../../store/slices/Admin/Products/productSlice";
import { toast } from "react-toastify";

const useProductAddOns = (formik, index) => {
  const dispatch = useDispatch();
  const [selectProductImage] = useGetProductImageMutation();
  const {
    addOnImage,
    editProductData,
    optionalAddOnImage,
    isImageUploading,
    isAddonImageUploading,
    isOptionalAddonImageUploading,
  } = useSelector((state) => state.product);
  const handleImageAddOnCustomizedPreviewChange = async (
    event,
    index,
    addOnKey
  ) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      if (
        !["image/jpeg", "image/png", "image/jpg"].includes(selectedLogo.type)
      ) {
        formik.setFieldError(
          `${addOnKey}.${index}.image_url`,
          "Only image files are allowed"
        );
        return;
      }
      const formData = new FormData();
      formData.append("image", selectedLogo);
      formData.append("type", "product");
      dispatch(
        updateConfig((state) => {
          if (addOnKey === "add_on") {
            state.isAddonImageUploading[index].isImageUploading = true;
          }
          if (addOnKey === "optional_add_on") {
            state.isOptionalAddonImageUploading[index].isImageUploading = true;
          }
        })
      );
      const response = await selectProductImage(formData);
      try {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            `${addOnKey}.${index}.image_url`,
            response?.data?.data?.image_path
          );
        } else if (
          response?.error?.data?.status_code === 422 &&
          response?.error?.data?.errors
        ) {
          Object.keys(response?.error?.data?.errors).forEach((field) => {
            formik.setFieldError(
              `${addOnKey}.${index}.image_url`,
              response?.error?.data?.errors[field]
            );
          });
        } else {
          toast.error(`Unable to add image, Please try again.`);
        }
      } catch (error) {
        console.error(`Unable to add image:`, error);
      } finally {
        dispatch(
          updateConfig((state) => {
            if (addOnKey === "add_on")
              state.isAddonImageUploading[index].isImageUploading = false;
            if (addOnKey === "optional_add_on")
              state.isOptionalAddonImageUploading[
                index
              ].isImageUploading = false;
          })
        );
      }
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            if (addOnKey === "add_on") {
              state.addOnImage[index].image = reader.result;
            } else if (addOnKey === "optional_add_on") {
              state.optionalAddOnImage[index].image = reader.result;
            } else {
              state.addOnImage[index].image = "";
              state.optionalAddOnImage[index].image = "";
            }
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImageAddOnCustomizedPreviewRemove = (index, addOnKey) => {
    dispatch(
      updateConfig((state) => {
        if (addOnKey === "add_on") {
          state.addOnImage[index].image = "";
        } else if (addOnKey === "optional_add_on") {
          state.optionalAddOnImage[index].image = "";
        } else {
          state.addOnImage[index].image = "";
          state.optionalAddOnImage[index].image = "";
        }
      })
    );
    formik.setFieldValue(`${addOnKey}.${index}.image_url`, "");
    formik?.setFieldError(`${addOnKey}.${index}.image_url`, "Upload image");
    formik?.setFieldTouched(`${addOnKey}.${index}.image_url`, true);
  };

  const handleImageAddOnPreviewChange = (event, index, addOnKey) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);
      formData.append("type", "product");
      selectProductImage(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            `${addOnKey}.${index}.add_on_image`,
            response?.data?.data?.image_path
          );
        }
      });
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            if (addOnKey === "add_on") {
              state.addOnImage[index].add_on_image = reader.result;
            } else if (addOnKey === "optional_add_on") {
              state.optionalAddOnImage[index].add_on_image = reader.result;
            } else {
              state.addOnImage[index].image = "";
              state.optionalAddOnImage[index].image = "";
            }
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImageAddOnPreviewRemove = (index, addOnKey) => {
    dispatch(
      updateConfig((state) => {
        if (addOnKey === "add_on") {
          state.addOnImage[index].add_on_image = "";
        } else if (addOnKey === "optional_add_on") {
          state.optionalAddOnImage[index].add_on_image = "";
        } else {
          state.addOnImage[index].add_on_image = "";
          state.optionalAddOnImage[index].add_on_image = "";
        }
      })
    );
    formik.setFieldValue(`${addOnKey}.${index}.add_on_image`, "");
    formik?.setFieldError(`${addOnKey}.${index}.add_on_image`, "Upload image");
    formik?.setFieldTouched(`${addOnKey}.${index}.add_on_image`, true);
  };
  let mappedVariantListOptions = (editProductData?.variants_list ?? [])?.map(
    ({ name, id }) => {
      return {
        label: name,
        value: id,
      };
    }
  );

  return {
    addOnImage,
    optionalAddOnImage,
    isImageUploading,
    isAddonImageUploading,
    isOptionalAddonImageUploading,
    editProductData,
    mappedVariantListOptions,
    handleImageAddOnPreviewChange,
    handleImageAddOnPreviewRemove,
    handleImageAddOnCustomizedPreviewRemove,
    handleImageAddOnCustomizedPreviewChange,
  };
};

export default useProductAddOns;
