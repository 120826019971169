import { CreateWidget } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useVideoContent from "./useVideoContent";
import VideoUploadForm from "./VideoUploadForm";

const VideoContent = () => {
  const { showComponent, handleUploadClick, handleClose } = useVideoContent();
  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="pro-m-4">
            <CreateWidget
              description={"Upload flash screen video"}
              icon={
                <span className="material-symbols-outlined x4">
                  upload_file
                </span>
              }
              handleClick={handleUploadClick}
            />
          </div>
        </div>
        <div className="col-6">
          {showComponent && <VideoUploadForm handleClose={handleClose} />}
        </div>
      </div>
    </>
  );
};

export default VideoContent;
