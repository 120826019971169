import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useUserList from "./useUserList";
import EmptyData from "../../Global/EmptyData";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import SendExcelData from "../../Admin/OrderList/SendExcelData";
import Select from "react-select";
import Style from "./user.module.scss";

const UserList = () => {
  const {
    date,
    isLoading,
    isFetching,
    userList,
    userState,
    showEditModal,
    paginationOptions,
    isDateRangeApplied,
    isUserExcelLinkSend,
    userBasicData,
    handleChangeNationality,
    handleEditColumnsClick,
    sendUserExcelDownload,
    handleDateRangeChange,
    handleExcelDownload,
    updateUserFields,
    handleDeleteModal,
    handleCreateClick,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handleDateChange,
    handlePageSize,
    handleSearch,
    handleDelete,
    modalClose,
    handleSort,
    refetch,
    getRow,
  } = useUserList();
  return (
    <div>
      <HeadingGroup
        title={"Users"}
        className={`pro-mb-4`}
        buttonTitle={userList?.data?.create_permission && "Create"}
        handleClick={handleCreateClick}
      >
        <button
          className={`pro-btn pro-btn-outline-primary pro-items-center pro-ms-3`}
          type="submit"
          onClick={handleExcelDownload}
        >
          <span className="material-symbols-outlined x4">download</span>
          <span>{`Export Data`}</span>
        </button>
      </HeadingGroup>
      <div className="col-auto pro-pt-3 pro-pb-6">
        <div className="row">
          <div className="col-auto">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={
                userState?.search !== "" || isDateRangeApplied ? true : false
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: userState?.search }}
              handleActionClick={handleEditColumnsClick}
              dropDownFilter={
                <>
                  <div className="col-auto">
                    <CustomDateRangePicker
                      defaultDateRange={date}
                      handleDateRangeChange={handleDateRangeChange}
                    />
                  </div>
                  <div className={`col-auto ${Style.selectbox}`}>
                    <Select
                      id="nationality"
                      isClearable={true}
                      placeholder={"Select Country"}
                      options={userBasicData?.data?.nationality_list}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      className={`pro-input lg pro-min-full multi-select`}
                      value={userBasicData?.data?.nationality_list?.filter(
                        (p) =>
                          p.id?.toString() ===
                          userState?.nationalityFilter?.toString()
                      )}
                      classNamePrefix="pro-input"
                      onChange={(value) => handleChangeNationality(value?.id)}
                    ></Select>
                  </div>
                </>
              }
              onDateChange={handleDateChange}
              initialDateRange={date}
            />
          </div>
        </div>
        {userList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={userList?.data?.result || []}
            uniqueID={"id"}
            fields={userList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={userState.clearSelection}
            loading={isFetching}
            perpage={userState?.currentPageSize}
            assignable={false}
            handleEdit={handleEditAction}
            handleDelete={handleDeleteModal}
            deletable={userList?.data?.delete_permission}
            // user is not editable
            editable={userList?.data?.edit_permission ? false : false}
            showCheckBox={
              userList?.data?.delete_permission ||
              userList?.data?.edit_permission
            }
          />
        )}

        {userList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={userState?.currentPage}
            totalPageCount={Math.ceil(
              userState.totalOrderCount / userState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === userState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={userState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Sub Admin ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={userList?.data?.fields || []}
              moduleId={userList?.data?.module_id}
              updateData={updateUserFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={userState.excelSendModal}
          handleClose={handleExcelDownload}
          title={`Send Excel Download Link`}
          // backdrop="static"
        >
          <div className="pro-m-5">
            <SendExcelData
              handleCloseModal={modalClose}
              sendExcelData={sendUserExcelDownload}
              isExcelLinkSend={isUserExcelLinkSend}
              isUserList
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default UserList;
