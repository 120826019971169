import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useFaq from "./useFaq";
import AddFaq from "./AddFaq";

const Faq = () => {
  const {
    faqList,
    faqState,
    isLoading,
    isFetching,
    // tableFields,
    showEditModal,
    paginationOptions,
    handleCreateClick,
    handleEditColumnsClick,
    handlePagination,
    handleEditAction,
    handleDeleteModal,
    updateFaqFields,
    handleClearClick,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useFaq();

  return (
    <>
      <HeadingGroup
        title="Frequently Asked Questions"
        className={`pro-mb-4`}
        buttonTitle={faqList?.data?.create_permission && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={faqState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: faqState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        <Table
          multiSelect={false}
          data={faqList?.data?.result || []}
          uniqueID={"_id"}
          fields={faqList?.data?.fields || []}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          clear={faqState?.clearSelection}
          perpage={faqState?.currentPageSize}
          assignable={false}
          handleEdit={handleEditAction}
          handleDelete={handleDeleteModal}
          deletable={faqList?.data?.delete_permission}
          editable={faqList?.data?.edit_permission}
          showCheckBox={
            faqList?.data?.delete_permission || faqList?.data?.edit_permission
          }
        />
        {faqList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={faqState?.currentPage}
            totalPageCount={Math.ceil(
              faqList.data.total_count / faqState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === faqState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={faqState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Tier ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={faqList?.data?.fields || []}
              moduleId={faqList?.data?.module_id}
              updateData={updateFaqFields}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={faqState?.showCreateModal}
          handleClose={handleCreateClick}
          title={faqState?.isEdit ? "Update Faq" : "Add Faq"}
          backdrop={"static"}
        >
          <AddFaq refetch={refetch} tierState={faqState} isStickyFooter />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default Faq;
