import { useDispatch, useSelector } from "react-redux";
import {
  useGetRepeatOrderListDataQuery,
  useUpdateRepeatOrderTableHeadDataMutation,
} from "../../../store/queries/Report";
import { updateConfig } from "../../../store/slices/Report/repeatOrderSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useState } from "react";
import { toast } from "react-toastify";
import { getRepeatOrderExportedData } from "../api";
import { getFormatedDate } from "../../../utils/Functions/table";
import { Tooltip as ReactTooltip } from "react-tooltip";

const useRepeatOrder = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const [isExcelLinkSend, setIsExcelLinkSend] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const repeatOrderState = useSelector((state) => state.repeatOrder);
  const {
    data: repeatOrderList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetRepeatOrderListDataQuery({
    page_size: repeatOrderState?.currentPageSize,
    page: repeatOrderState?.currentPage,
    sort: repeatOrderState?.sortBy,
    sort_order: repeatOrderState?.sortOrder,
    search: repeatOrderState?.search,
    start_date: getFormatedDate(repeatOrderState?.startDate),
    end_date: getFormatedDate(repeatOrderState?.endDate),
  });
  const [updateRepeatOrderFields] = useUpdateRepeatOrderTableHeadDataMutation();
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e?.target?.value;
        state.currentPage = 1;
      })
    );
  };
  const [date, setDate] = useState({
    startDate: repeatOrderState.startDate,
    endDate: repeatOrderState.endDate,
    key: "selection",
  });

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (repeatOrderState?.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            repeatOrderState?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      formated_totalRewardAmount: (field, data) => {
        return (
          <>
            <p
              className="pro-mb-0"
              data-tooltip-id={`repeat-order-reward-amount-${data?.orderCount}`}
            >
              {data[field]}
            </p>
            <ReactTooltip
              id={`repeat-order-reward-amount-${data?.orderCount}`}
              place="left"
              content={`Reward Amount : ${data?.totalRewardAmount}`}
            />
          </>
        );
      },
      formated_totalAmount: (field, data) => {
        return (
          <>
            <p
              className="pro-mb-0"
              data-tooltip-id={`repeat-order-purchased-amount-${data?.orderCount}`}
            >
              {data[field]}
            </p>
            <ReactTooltip
              id={`repeat-order-purchased-amount-${data?.orderCount}`}
              place="left"
              content={`Purchased Amount : ${data?.totalAmount}`}
            />
          </>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleRepeatOrderExcelDownload = () => {
    dispatch(
      updateConfig((state) => {
        state.repeatOrderExcelSendModal = !state.repeatOrderExcelSendModal;
      })
    );
  };
  const modalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.repeatOrderExcelSendModal = false;
      })
    );
  };
  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const sendExcelData = async (value) => {
    setIsExcelLinkSend(true);
    let params = {
      email: value?.email ?? "",
      start_date: getFormatedDate(repeatOrderState.startDate ?? ""),
      end_date: getFormatedDate(repeatOrderState.endDate ?? ""),
      sort: repeatOrderState?.sortBy,
      sort_order: repeatOrderState?.sortOrder,
    };
    let response = await getRepeatOrderExportedData(params);
    try {
      if (response?.status === 200) {
        toast.success(`Excel download link send successfully`);
      } else {
        toast.error(`Failed to send download link,Please try again`);
      }
    } catch (error) {
      console.error("Failed to send link:", error);
      toast.error(`Failed to send download link`);
    } finally {
      modalClose();
      setIsExcelLinkSend(false);
    }
  };

  return {
    date,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    repeatOrderList,
    isExcelLinkSend,
    repeatOrderState,
    paginationOptions,
    handleRepeatOrderExcelDownload,
    handleEditColumnsClick,
    updateRepeatOrderFields,
    handleDateRangeChange,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handlePageSize,
    sendExcelData,
    handleSearch,
    handleSort,
    modalClose,
    refetch,
    getRow,
  };
};

export default useRepeatOrder;
