import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  walletTransactionExcelSendModal: false,
  typeId: "",
  totalCount: "",
};

const walletTransactionSlice = createSlice({
  name: "walletTransaction",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});
export const { updateConfig } = walletTransactionSlice.actions;
export default walletTransactionSlice.reducer;
