import { getAdminAxiosInstance } from "../../../api/Admin";

export const createCategory = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/category/store`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const deleteCategory = async (id) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.get(`category/destroy?category_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
