import { getAdminAxiosInstance } from "../../../../api/Admin";

export const changeProduct = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/product/change-product`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
