import React from "react";
import useAddNote from "./useAddNote";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const AddNote = ({ isStickyFooter, handleClose, refetch }) => {
  const { formik } = useAddNote(handleClose, refetch);
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Note
            </label>
            <textarea
              id="notes"
              rows={3}
              name="notes"
              className={`pro-input lg ${
                formik.errors.notes && formik.touched.notes && " error"
              }`}
              value={formik?.values?.notes}
              onBlur={formik?.handleBlur("notes")}
              onChange={(e) => formik.setFieldValue("notes", e.target.value)}
            ></textarea>
            {formik.touched.notes && formik.errors.notes && (
              <span className="error-text">{formik.errors.notes}</span>
            )}
          </div>
        </div>
        <div className="pro-check-box pro-py-2">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={Boolean(formik.values?.status === 1) ?? false}
              onChange={(e) => {
                formik.setFieldValue(`status`, e?.target?.checked ? 1 : 2);
              }}
            />
            <label className="form-check-label" for="flexSwitchCheckChecked">
              Status
            </label>
          </div>
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Update"}
        </Button>
      </div>
    </div>
  );
};

export default AddNote;
