import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/DeliveryAreas/deliveryAreasSlice";
import { uploadFile } from "./api";
import { toast } from "react-toastify";

const useDeliveryExcelForm = ({ closeModal, refetch }) => {
  const dispatch = useDispatch();
  const deliveryAreasState = useSelector((state) => state.deliveryAreas);
  const validation = Yup.object({
    file: Yup.string().required(`Upload file`),
  });
  const formik = useFormik({
    initialValues: {
      file: "",
    },
    validationSchema: validation,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      let formData = new FormData();
      formData.append("excel_file", values?.file);
      uploadFile(formData).then((res) => {
        setSubmitting(false);
        try {
          if (res?.data?.status_code === 200) {
            toast.success("File uploaded successfully");
            closeModal();
            refetch();
          } else if (
            res?.data?.status_code === 422 &&
            res?.data?.errors?.excel_file
          ) {
            formik.setFieldError("excel_file", res.data.errors["excel_file"]);
          }
        } catch (err) {
          console.error(err);
          return;
        }
      });
    },
  });

  const handleFileChange = (event) => {
    const selectedFile = event?.target?.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("upload_file", selectedFile);
      // setLastUploadedLogo(selectedFile);
      formik.setFieldValue("file", selectedFile);
      const file = selectedFile;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
            state.imageName = selectedFile?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  return {
    formik,
    deliveryAreasState,
    handleFileChange,
  };
};

export default useDeliveryExcelForm;
