import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Admin/Store/storeSlice";
import { getProfileImage } from "../api";
import { toast } from "react-toastify";

const useBasic = (formik) => {
  const dispatch = useDispatch();
  const { isEdit, imagePreview, imageName, isImageUploading } = useSelector(
    (state) => state.store
  );

  const handleImageChange = async (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);

      dispatch(
        updateConfig((state) => {
          state.isImageUploading = true;
        })
      );
      const response = await getProfileImage(formData);
      try {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            "image_url",
            response?.data?.data?.image_path ?? ""
          );
        } else if (response?.status_code === 422 && response?.errors) {
          Object.keys(response?.errors).forEach((field) => {
            formik.setFieldError("image_url", response?.errors[field]);
          });
        } else {
          toast.error(`Unable to add image, Please try again.`);
        }
      } catch (error) {
        console.error(`Unable to add image:`, error);
      } finally {
        dispatch(
          updateConfig((state) => {
            state.isImageUploading = false;
          })
        );
      }
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
            state.imageName = selectedLogo?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleNext = () => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Store Hours";
      })
    );
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };

  return {
    isEdit,
    imagePreview,
    isImageUploading,
    handleNext,
    handleCloseModal,
    handleImageChange,
    imageName,
  };
};

export default useBasic;
