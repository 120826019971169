import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/User/UserList/userSlice";
import { useSelector } from "react-redux";

const useUserDetailView = () => {
  const dispatch = useDispatch();
  const { activeId } = useSelector((state) => state.user);
  const handleClick = (e) => {
    if (e?.name === "Order Listing") {
      dispatch(updateConfig((state) => (state.activeId = 1)));
    } else if (e?.name === "Point History") {
      dispatch(updateConfig((state) => (state.activeId = 2)));
    } else if (e?.name === "Wallet Transactions") {
      dispatch(updateConfig((state) => (state.activeId = 3)));
    } else if (e?.name === "Favorite Store List") {
      dispatch(updateConfig((state) => (state.activeId = 4)));
    } else if (e?.name === "Favorite Product List") {
      dispatch(updateConfig((state) => (state.activeId = 5)));
    } else if (e?.name === "Vehicle Details") {
      dispatch(updateConfig((state) => (state.activeId = 6)));
    }
  };
  return { activeId, handleClick };
};

export default useUserDetailView;
