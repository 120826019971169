import React from "react";
import CommonLayout from "../../pages/Layout/CommonLayout";
import { Outlet } from "react-router-dom";
import useStaff from "./useStaff";

const Staff = () => {
  const drawerMenu = useStaff();

  return (
    <CommonLayout drawerMenu={drawerMenu?.drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Staff;
