import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import { ImageCard } from "../../Global/ImageCard";
import useBanner from "./useBanner";
import OrderColumn from "../../Global/OrderColumn/index";
import AddBanner from "./AddBanner";
import EmptyData from "../../Global/EmptyData";

const Banner = () => {
  const {
    isLoading,
    imageData,
    isFetching,
    bannerList,
    showEditModal,
    bannerState,
    showImageModal,
    paginationOptions,
    handleEditColumnsClick,
    updateBannerFields,
    handleDeleteModal,
    handleDelete,
    handleCreateClick,
    handlePagination,
    handleClearClick,
    handleEditAction,
    closeImageModal,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useBanner();
  return (
    <div>
      <HeadingGroup
        title={"Banners"}
        className={`pro-mb-4`}
        buttonTitle={bannerList?.data?.create_permission && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={bannerState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: bannerState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        {bannerList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={bannerList?.data?.data || []}
            uniqueID={"id"}
            fields={bannerList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={bannerState?.clearSelection}
            loading={isFetching}
            perpage={bannerState?.currentPageSize}
            assignable={false}
            handleEdit={handleEditAction}
            handleDelete={handleDeleteModal}
            deletable={bannerList?.data?.delete_permission}
            editable={bannerList?.data?.edit_permission}
            showCheckBox={
              bannerList?.data?.delete_permission ||
              bannerList?.data?.edit_permission
            }
          />
        )}

        {bannerList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={bannerState?.currentPage}
            totalPageCount={Math.ceil(
              bannerList?.data?.total_count / bannerState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === bannerState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={bannerState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure to delete the selected Banner ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={bannerList?.data?.fields || []}
              moduleId={bannerList?.data?.module_id}
              updateData={updateBannerFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={bannerState?.showCreateModal}
          handleClose={handleCreateClick}
          title={bannerState?.isEdit ? "Update Banner" : "Add Banner"}
          backdrop={"static"}
        >
          <AddBanner
            refetch={refetch}
            handleCreateClick={handleCreateClick}
            isStickyFooter
          />
        </OffCanvasLayout>

        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>
      </div>
    </div>
  );
};

export default Banner;
