import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../../api/Admin";

const initialState = {
  isLoading: false,
  isSuccess: false,
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  selectedCategory: "",
  productFetchParams: {},
  productData: [],
  editProductData: {},
  finalProductChangeData: {},
  changeAvailable: false,
  showProductEditModal: false,
  updateAllStore: false,
  isUpdatingProduct: false,
  isSavingChangedProduct: false,
  isImageUploading: false,
  isAddonImageUploading: [],
  isVariantImageUploading: [],
  isOptionalAddonImageUploading: [],

  imagePreview: "",
  imageCustomizedPreview: "",
  variantImagePreview: "",
  variantCustomizedPreview: "",
  addonImagePreview: "",
  addonCustomizedPreview: "",
  variantImage: [],
  addOnImage: [],
  optionalAddOnImage: [],
};

export const getProductChangeData = createAsyncThunk(
  "/product/edit",
  async (params, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.post(`/product/edit`, params);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductChangeData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getProductChangeData.fulfilled, (state, action) => {
        state.editProductData = action.payload?.data;
        state.isSuccess = true;
      })
      .addCase(getProductChangeData.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = productSlice.actions;
export default productSlice.reducer;
