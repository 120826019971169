import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { updateContactInfo } from "../api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Contact/contactSlice";
import { useEffect } from "react";

const useAddNote = (handleClose, refetch) => {
  const dispatch = useDispatch();
  const { isEdit, selectedId, editContactData } = useSelector(
    (state) => state.contact
  );
  useEffect(() => {
    if (isEdit && editContactData !== "") {
      formik?.setFieldValue("notes", editContactData?.notes ?? "");
      formik?.setFieldValue("status", editContactData?.status ?? "");
    }
    //eslint-disable-next-line
  }, [isEdit, editContactData]);
  const formik = useFormik({
    initialValues: {
      notes: "",
      status: 2,
    },
    validationSchema: Yup.object({
      notes: Yup.string()
        .trim()
        .required("Enter Notes")
        .min(3, " The note must be at least 3 characters"),
    }),
    onSubmit: (values) => {
      let updateData = {
        contact_id: selectedId,
        ...values,
      };
      if (isEdit) {
        updateContactInfo(updateData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Contact info updated successfully`);
            handleClose();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(`Something went wrong!`);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            handleClose();
          }
        });
      }
    },
  });

  return { formik };
};

export default useAddNote;
