import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useReport = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Best Seller",
      link: "/report/best-seller",
      icon: <span className="material-symbols-outlined x4">star</span>,
      active: checkIfActiveRoute("/report/best-seller", true),
    },
    {
      title: "Most Spent Customer",
      link: "/report/most-spent-customer",
      icon: <span className="material-symbols-outlined x4">groups_3</span>,
      active: checkIfActiveRoute("/report/most-spent-customer", true),
    },
    {
      title: "Earning And Redemption",
      link: "/report/earning-redemption",
      icon: <span className="material-symbols-outlined x4">savings</span>,
      active: checkIfActiveRoute("/report/earning-redemption", true),
    },
    {
      title: "Repeat Order",
      link: "/report/repeat-order",
      icon: <span className="material-symbols-outlined x4">orders</span>,
      active: checkIfActiveRoute("/report/repeat-order", true),
    },
    {
      title: "Repeat Customer",
      link: "/report/repeat-customer",
      icon: <span className="material-symbols-outlined x4">person</span>,
      active: checkIfActiveRoute("/report/repeat-customer", true),
    },
    {
      title: "Gift Card Purchase",
      link: "/report/gift-cards-purchase",
      icon: <span className="material-symbols-outlined x4">redeem</span>,
      active: checkIfActiveRoute("/report/gift-cards-purchase", true),
    },
    {
      title: "Wallet Transaction",
      link: "/report/wallet-transaction",
      icon: <span className="material-symbols-outlined x4">receipt_long</span>,
      active: checkIfActiveRoute("/report/wallet-transaction", true),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );
    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.title);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return { drawerMenu: drawerMenuPermission };
};

export default useReport;
