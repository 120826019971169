import { getAdminAxiosInstance } from "../../../api/Admin";

export const createAndUpdateGiftCard = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/gift-card/store`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteGiftCard = async (id) => {
  const api = await getAdminAxiosInstance();

  try {
    const response = await api.get(
      `/gift-card/destroy?gift_card_template_id=${id}`
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getGiftCardImage = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/gift-card/upload-image`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
