import { useEffect, useState } from "react";
import {
  useGetDashboardOrderCountDataQuery,
  useGetDashboardUserCountDataQuery,
} from "../../../store/queries/Dashboard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Global";
import { useGetUserDetailsDataQuery } from "../../../store/queries/Global";
import { useGetSubAdminBasicDataQuery } from "../../../store/queries/Admin";
import { getFormatedDate } from "../../../utils/Functions/table";

const useDashboardListing = () => {
  const dispatch = useDispatch();
  const [listingType, setListingType] = useState();
  const [isStoreAvailable, setIsStoreAvailable] = useState(false);
  const globalState = useSelector((state) => state.global);
  const { data: userData = {} } = useGetUserDetailsDataQuery();
  const { data: subAdminBasicData = {}, isLoading } =
    useGetSubAdminBasicDataQuery();
  const {
    data: userCountData = {},
    isFetching: userCountFetching,
    isSuccess,
  } = useGetDashboardUserCountDataQuery({
    start_date: getFormatedDate(globalState?.startDate) ?? "",
    end_date: getFormatedDate(globalState?.endDate) ?? "",
    customer_key: globalState?.customerKey ?? "",
  });
  const { data: orderCountData = {}, isFetching: orderCountFetching } =
    useGetDashboardOrderCountDataQuery({
      start_date: getFormatedDate(globalState?.startDate) ?? "",
      end_date: getFormatedDate(globalState?.endDate) ?? "",
      customer_key: globalState?.customerKey ?? "",
    });

  const statusChartOptions = [
    { label: "Orders", value: "order" },
    { label: "Sales-Basic", value: "basic_sale" },
    { label: "Sales-Advanced", value: "sale" },
    { label: "Users", value: "user" },
  ];
  const [date, setDate] = useState({
    startDate: globalState.startDate ?? "",
    endDate: globalState.endDate ?? "",
    key: "selection",
  });

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  useEffect(() => {
    if (
      userData?.data?.hasOwnProperty(`customer_key`) &&
      userData?.data?.customer_key !== "" &&
      userData?.data?.customer_key !== null &&
      !isLoading
    ) {
      dispatch(
        updateConfig((state) => {
          state.customerKey = userData?.data?.customer_key;
        })
      );
      setIsStoreAvailable(true);
    }
    //eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    let dashboardData = {
      ...orderCountData?.data,
      ...userCountData?.data,
    };
    if (!orderCountFetching) {
      dispatch(updateConfig((state) => (state.dashboardData = dashboardData)));
    }
    //eslint-disable-next-line
  }, [userCountFetching, orderCountFetching]);

  const handleClick = (label) => {
    // object[label]();
  };

  let dashboardItems = {
    totalOrders: {
      label: "totalOrders",
      title: "Total Orders",
      icon: (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.85135 0.26004C1.58891 0.26004 1.33722 0.36733 1.15164 0.558307C0.966071 0.749284 0.861816 1.0083 0.861816 1.27839C0.861816 1.54847 0.966071 1.80749 1.15164 1.99847C1.33722 2.18944 1.58891 2.29673 1.85135 2.29673H2.3382C2.55307 2.29711 2.76198 2.36946 2.93336 2.50284C3.10474 2.63622 3.22927 2.82339 3.28815 3.03605L6.42694 14.3397C6.60447 14.9776 6.97898 15.5387 7.49386 15.9381C8.00873 16.3375 8.63598 16.5536 9.28075 16.5536H18.3231C18.9165 16.5537 19.4963 16.3708 19.9877 16.0284C20.4791 15.6861 20.8596 15.2 21.0799 14.633L23.9971 7.12573C24.117 6.81682 24.1616 6.48238 24.1269 6.15164C24.0922 5.82091 23.9793 5.50395 23.7982 5.22847C23.617 4.95299 23.373 4.72737 23.0875 4.57135C22.802 4.41532 22.4837 4.33363 22.1605 4.33342H5.70656L5.19003 2.47596C5.01295 1.83801 4.63889 1.27673 4.12438 0.876947C3.60987 0.477166 2.98289 0.260619 2.3382 0.26004H1.85135Z"
            fill="#006A80"
          />
          <path
            opacity="0.6"
            d="M9.15673 24.9974C9.48189 24.9974 9.80387 24.9333 10.1043 24.8089C10.4047 24.6844 10.6776 24.5021 10.9076 24.2721C11.1375 24.0422 11.3199 23.7693 11.4443 23.4688C11.5687 23.1684 11.6328 22.8465 11.6328 22.5213C11.6328 22.1961 11.5687 21.8742 11.4443 21.5737C11.3199 21.2733 11.1375 21.0004 10.9076 20.7704C10.6776 20.5405 10.4047 20.3581 10.1043 20.2337C9.80387 20.1093 9.48189 20.0452 9.15673 20.0452C8.50004 20.0452 7.87024 20.3061 7.40589 20.7704C6.94153 21.2348 6.68066 21.8646 6.68066 22.5213C6.68066 23.178 6.94153 23.8078 7.40589 24.2721C7.87024 24.7365 8.50004 24.9974 9.15673 24.9974ZM18.0872 24.9974C18.4124 24.9974 18.7344 24.9333 19.0348 24.8089C19.3352 24.6844 19.6081 24.5021 19.8381 24.2721C20.068 24.0422 20.2504 23.7693 20.3748 23.4688C20.4992 23.1684 20.5633 22.8465 20.5633 22.5213C20.5633 22.1961 20.4992 21.8742 20.3748 21.5737C20.2504 21.2733 20.068 21.0004 19.8381 20.7704C19.6081 20.5405 19.3352 20.3581 19.0348 20.2337C18.7344 20.1093 18.4124 20.0452 18.0872 20.0452C17.4305 20.0452 16.8007 20.3061 16.3364 20.7704C15.872 21.2348 15.6112 21.8646 15.6112 22.5213C15.6112 23.178 15.872 23.8078 16.3364 24.2721C16.8007 24.7365 17.4305 24.9974 18.0872 24.9974Z"
            fill="#006A80"
          />
        </svg>
      ),
      handleClick,
      // component: <Messages dashboard={true} />,
    },
    totalSales: {
      label: "totalSales",
      title: "Today Sales",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.28809 20.7096H21.9539C22.6411 20.7096 23.1983 21.2667 23.1983 21.954C23.1983 22.6412 22.6411 23.1983 21.9539 23.1983H2.0437C1.35645 23.1983 0.799316 22.6412 0.799316 21.954V2.04376C0.799316 1.35651 1.35645 0.799377 2.0437 0.799377C2.73096 0.799377 3.28809 1.35651 3.28809 2.04376V20.7096Z"
            fill="#006A80"
          />
          <path
            opacity="0.6"
            d="M7.92907 15.3383C7.45903 15.8397 6.67154 15.8651 6.17016 15.3951C5.66878 14.925 5.64338 14.1375 6.11342 13.6362L10.7799 8.65861C11.2345 8.17372 11.9899 8.13167 12.4955 8.56311L16.1785 11.706L20.9772 5.62766C21.403 5.08824 22.1855 4.99618 22.725 5.42204C23.2644 5.84789 23.3564 6.6304 22.9306 7.16981L17.3308 14.2628C16.8935 14.8168 16.0833 14.8965 15.5464 14.4383L11.7833 11.2272L7.92907 15.3383Z"
            fill="#006A80"
          />{" "}
        </svg>
      ),
      handleClick,
      // component: <Members dashboard={true} />,
    },
    totalUsers: {
      label: "totalUsers",
      title: "Total Users",
      icon: (
        <svg
          width="26"
          height="20"
          viewBox="0 0 26 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {" "}
          <path
            opacity="0.6"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5332 4.66483C5.5332 7.02125 7.44345 8.9315 9.79987 8.9315C12.1563 8.9315 14.0665 7.02125 14.0665 4.66483C14.0665 2.30841 12.1563 0.398163 9.79987 0.398163C7.44345 0.398163 5.5332 2.30841 5.5332 4.66483ZM16.1998 8.93187C16.1998 10.6992 17.6325 12.1319 19.3998 12.1319C21.1671 12.1319 22.5998 10.6992 22.5998 8.93187C22.5998 7.16456 21.1671 5.73187 19.3998 5.73187C17.6325 5.73187 16.1998 7.16456 16.1998 8.93187Z"
            fill="#006A80"
          />{" "}
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.78194 11.066C4.74575 11.066 0.613849 13.6543 0.200402 18.7451C0.177881 19.0224 0.7082 19.5993 0.975709 19.5993H18.5962C19.3975 19.5993 19.4099 18.9545 19.3975 18.746C19.0849 13.5121 14.889 11.066 9.78194 11.066ZM25.2196 19.5965H21.1069C21.1069 17.1954 20.3135 14.9797 18.9747 13.1971C22.6082 13.2369 25.575 15.074 25.7983 18.9565C25.8073 19.1128 25.7983 19.5965 25.2196 19.5965Z"
            fill="#006A80"
          />
        </svg>
      ),
      handleClick,
      // component: <Deleted dashboard={true} />,
    },
    totalPurchase: {
      label: "totalPurchase",
      title: "Total Purchase",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.28809 20.7096H21.9539C22.6411 20.7096 23.1983 21.2667 23.1983 21.954C23.1983 22.6412 22.6411 23.1983 21.9539 23.1983H2.0437C1.35645 23.1983 0.799316 22.6412 0.799316 21.954V2.04376C0.799316 1.35651 1.35645 0.799377 2.0437 0.799377C2.73096 0.799377 3.28809 1.35651 3.28809 2.04376V20.7096Z"
            fill="#006A80"
          />
          <path
            opacity="0.6"
            d="M7.92907 15.3383C7.45903 15.8397 6.67154 15.8651 6.17016 15.3951C5.66878 14.925 5.64338 14.1375 6.11342 13.6362L10.7799 8.65861C11.2345 8.17372 11.9899 8.13167 12.4955 8.56311L16.1785 11.706L20.9772 5.62766C21.403 5.08824 22.1855 4.99618 22.725 5.42204C23.2644 5.84789 23.3564 6.6304 22.9306 7.16981L17.3308 14.2628C16.8935 14.8168 16.0833 14.8965 15.5464 14.4383L11.7833 11.2272L7.92907 15.3383Z"
            fill="#006A80"
          />{" "}
        </svg>
      ),
      handleClick,
      // component: <Deleted dashboard={true} />,
    },
  };

  const handleChart = (value) => {
    dispatch(
      updateConfig((state) => {
        state.chartFilterType = value;
      })
    );
  };
  const handleStoreChange = (value) => {
    dispatch(
      updateConfig((state) => (state.customerKey = value?.customer_key))
    );
  };
  let storeObj = subAdminBasicData?.data?.stores?.filter(
    (item) => item?.customer_key == globalState?.customerKey
  );

  return {
    date,
    storeObj,
    isSuccess,
    isLoading,
    listingType,
    dashboardItems,
    isStoreAvailable,
    userCountFetching,
    orderCountFetching,
    statusChartOptions,
    dashboardData: globalState?.dashboardData,
    storeList: subAdminBasicData?.data?.stores,
    chartFilterType: globalState?.chartFilterType,
    children: dashboardItems[listingType]?.component,
    dashboardFilterType: globalState?.dashboardFilterType,
    handleDateRangeChange,
    handleStoreChange,
    handleChart,
  };
};

export default useDashboardListing;
