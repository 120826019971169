import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetCustomNotificationBasicDataQuery,
  useSendCustomNotificationMutation,
} from "../../../../store/queries/CMS";
import { updateConfig } from "../../../../store/slices/CMS/customNotificationSlice";
import { toast } from "react-toastify";
import { getBannerImage, getExcelUrl } from "./api";
import { phoneRegExp, urlRegex } from "../../../../utils/Functions/table";

const useCreateCustomNotification = ({ handleClose, refetch }) => {
  const dispatch = useDispatch();
  const { data: NotificationBasicData } =
    useGetCustomNotificationBasicDataQuery();
  const [sendCustomNotification] = useSendCustomNotificationMutation();

  const customNotificationState = useSelector(
    (state) => state.customNotification
  );
  const [userNumber, setUserNumber] = useState("");
  const [fileLoader, setFileLoader] = useState(false);
  const [imageUrlValidation, setImageUrlValidation] = useState({});
  const [aliasImageUrlValidation, setAliasImageUrlValidation] = useState({});

  // Helper function to generate required field validation based on recepient_filters
  const isFilterIncluded = (filter) => (value) =>
    Array.isArray(value) && value.includes(filter);

  const validation = Yup.object({
    notification_type: Yup.string().required("Notification Type Required"),

    image_url: Yup.string().when("notification_type", {
      is: "in_app_notification",
      then: (schema) => schema.required("Image Required"),
    }),
    alias_image_url: Yup.string().when("notification_type", {
      is: "in_app_notification",
      then: (schema) => schema.required("Alias Image Required"),
    }),

    title: Yup.string().when("notification_type", {
      is: "push_notification",
      then: (schema) => schema.required("Title Required"),
    }),
    alias_title: Yup.string().when("notification_type", {
      is: "push_notification",
      then: (schema) => schema.required("Alias Title Required"),
    }),

    message: Yup.string().when("notification_type", {
      is: "push_notification",
      then: (schema) => schema.required("Message Required"),
    }),
    alias_message: Yup.string().when("notification_type", {
      is: "push_notification",
      then: (schema) => schema.required("Alias Message Required"),
    }),

    recepient_filters: Yup.array()
      .min(1, "At least one recipient filter must be selected")
      .required("Recipient Filters Required"),

    excel_url: Yup.string().when("recepient_filters", {
      is: isFilterIncluded("from_excel_file"),
      then: (schema) => schema.required("Excel URL Required"),
    }),

    age_from: Yup.string().when("recepient_filters", {
      is: isFilterIncluded("age"),
      then: (schema) =>
        schema.required("Age From Required").min(1, "minimum age should be 1"),
    }),
    age_to: Yup.number().when("recepient_filters", {
      is: isFilterIncluded("age"),
      then: (schema) =>
        schema
          .required("Age To Required")
          .max(100, "Maximum age should be 100")
          .test(
            "is-greater-than-age_from",
            "Age To must be greater than Age From",
            function (value) {
              const { age_from } = this.parent;
              return !age_from || !value || Number(value) > Number(age_from);
            }
          ),
    }),

    gender: Yup.number().when("recepient_filters", {
      is: isFilterIncluded("gender"),
      then: (schema) => schema.required("Gender Required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    nationality: Yup.array().when("recepient_filters", {
      is: isFilterIncluded("nationality"),
      then: (schema) =>
        schema
          .min(1, "At least one nationality must be selected")
          .required("Nationality Required"),
    }),

    day_from: Yup.string().when("recepient_filters", {
      is: isFilterIncluded("inactive_users"),
      then: (schema) => schema.required("From Day is required"),
    }),

    day_to: Yup.string().when("recepient_filters", {
      is: isFilterIncluded("inactive_users"),
      then: (schema) =>
        schema
          .required("To Day is required")
          .test(
            "is-greater-than-day_from",
            "To Day count must be greater than From Day",
            function (value) {
              const { day_from } = this.parent;
              return !day_from || !value || Number(value) > Number(day_from);
            }
          ),
    }),

    launch_url: Yup.string()
      .matches(urlRegex, "Enter correct URL!")
      .max(150, "The URL length must be within 150 characters."),

    add_user: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(8, "Number should be at least 8 characters long")
      .max(8, "Number should be  8 characters long"),

    selected_users: Yup.array().when("recepient_filters", {
      is: isFilterIncluded("selected_users"),
      then: (schema) =>
        schema.min(
          1,
          "The selected_users field must have at least one user added."
        ),
    }),
    type: Yup.string().when("notification_type", {
      is: "in_app_notification",
      then: (schema) => schema.required("Type is required"),
    }),
    schedule_date: Yup.string().when("notification_type", {
      is: "scheduled_push_notification",
      then: (schema) => schema.required("Scheduled date is required"),
    }),
    schedule_time: Yup.string().when("notification_type", {
      is: "scheduled_push_notification",
      then: (schema) => schema.required("Scheduled time is required"),
    }),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      alias_title: "",
      message: "",
      alias_message: "",
      image_url: "",
      alias_image_url: "",
      notification_type: "",
      recepient_filters: [],
      excel_url: "",
      age_from: "",
      age_to: "",
      nationality: [],
      day_from: "",
      day_to: "",
      notification_disabled_users: 2,
      selected_users: [],
      add_user: "",
      launch_url: "",
      schedule_date: "",
      schedule_time: "",
      type: "",
      selected_item: "",
      gender: "",
    },
    validationSchema: validation,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const obj = {
        title: values?.title,
        alias_title: values?.alias_title,
        message: values?.message,
        alias_message: values?.alias_message,
        notification_type: values?.notification_type,
        type: values?.type,
        image_url: values?.image_url,
        alias_image_url: values?.alias_image_url,
        selected_item: values?.selected_item,
        recepient_types: values?.recepient_filters,
        excel_url: values?.excel_url,
        age_from: values?.age_from,
        age_to: values?.age_to,
        gender: values?.gender ? values?.gender : "",
        nationality: values?.nationality,
        day_from: values?.day_from,
        day_to: values?.day_to,
        selected_users: values?.selected_users,
        launch_url: values?.launch_url,
        notification_disabled_users:
          values?.notification_disabled_users === 1 ? true : false,
        schedule_date: values?.schedule_date,
        schedule_time: values?.schedule_time,
      };

      sendCustomNotification(obj).then((res) => {
        setSubmitting(false);
        if (res?.data?.status_code === 200) {
          handleClose();
          refetch();
          toast.success("Notification sent Successfully");
        } else if (res?.error?.data?.status_code === 422) {
          let errors = res?.error?.data?.errors;
          let errorFields = Object.keys(errors);
          errorFields?.forEach((field) => {
            formik.setFieldError(field, errors[field]);
          });
        } else {
          toast.error("Failed to sent notification");
          handleClose();
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleImageChange = async (event, fieldName) => {
    const selectedLogo = event?.target?.files[0];

    // for conditional Loading of the Images
    const isMainImage = fieldName === "image";
    if (selectedLogo) {
      if (
        !["image/jpeg", "image/png", "image/jpg"].includes(selectedLogo.type)
      ) {
        formik.setFieldError(
          `${fieldName === "image" ? "image_url" : "alias_image_url"}`,
          "Only image files are allowed"
        );
        return;
      }

      const formData = new FormData();
      formData.append("image", selectedLogo);
      dispatch(
        updateConfig((state) => {
          isMainImage
            ? (state.isLoadingImage = true)
            : (state.isLoadingAliasImage = true);
        })
      );
      const response = await getBannerImage(formData);
      try {
        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              isMainImage
                ? (state.isLoadingImage = false)
                : (state.isLoadingAliasImage = false);
            })
          );
          formik.setFieldValue(
            fieldName === "image" ? "image_url" : "alias_image_url",
            response?.data?.data?.image_path
          );

          if (fieldName === "alias_image_url") {
            setAliasImageUrlValidation({});
          } else {
            setImageUrlValidation({});
          }

          const file = selectedLogo;
          const reader = new FileReader();
          reader.onloadend = () => {
            dispatch(
              updateConfig((state) => {
                state[
                  fieldName === "image" ? "imagePreview" : "aliasImagePreview"
                ] = reader.result;
                state[fieldName === "image" ? "imageName" : "aliasImageName"] =
                  selectedLogo?.name;
              })
            );
          };

          if (file) {
            reader.readAsDataURL(file);
          }
        } else if (response?.status_code === 422) {
          // Object.keys(response?.errors).forEach((field) => {
          //   formik.setFieldError(
          //     `${fieldName === "image" ? "image_url" : "alias_image_url"}`,
          //     response?.errors[field]
          //   );
          // });

          const validationErrors = {};
          Object.keys(response?.errors).forEach((field) => {
            const errorField =
              fieldName === "image" ? "image_url" : "alias_image_url";
            validationErrors[errorField] = response?.errors[field];
          });

          dispatch(
            updateConfig((state) => {
              isMainImage
                ? (state.isLoadingImage = false)
                : (state.isLoadingAliasImage = false);
            })
          );

          dispatch(
            updateConfig((state) => {
              state[
                fieldName === "image" ? "imagePreview" : "aliasImagePreview"
              ] = "";
              state[fieldName === "image" ? "imageName" : "aliasImageName"] =
                "";
            })
          );

          if (fieldName === "image") {
            setImageUrlValidation(validationErrors);
          } else {
            setAliasImageUrlValidation(validationErrors);
          }
        } else {
          toast.error(`Unable to add image, Please try again.`);
        }
      } catch (error) {
        console.error(`Unable to add image:`, error);
      } finally {
        dispatch(
          updateConfig((state) => {
            isMainImage
              ? (state.isLoadingImage = false)
              : (state.isLoadingAliasImage = false);
          })
        );
      }
    }
  };

  const handleFileChange = (e) => {
    setFileLoader(true);
    const selectedLogo = e?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("excel_file", selectedLogo);
      getExcelUrl(formData).then((res) => {
        setFileLoader(false);
        if (res?.data?.status_code === 200) {
          formik.setFieldValue("excel_url", res?.data?.data?.file_path);
          dispatch(
            updateConfig((state) => {
              state.excelFileName = selectedLogo?.name;
            })
          );
        } else if (res?.status_code === 422) {
          res?.errors &&
            formik?.setFieldError("excel_url", res?.errors["excel_file"]);
        } else {
          toast.error("Failed to upload excel file");
        }
      });
    }
  };

  const handleChangeRecepientFilters = (values) => {
    // Utility function to clear a field if the filter is not present
    const clearFieldIfFilterNotPresent = (filter, field, defaultValue) => {
      if (!formik?.values?.recepient_filters?.includes(filter)) {
        if (formik?.values?.[field] !== defaultValue) {
          formik?.setFieldValue(field, defaultValue);
        }
      }
    };

    // Determine if specific filters are present in the values
    const hasSpecificFilters = values.some(
      (item) => item.slug === "selected_users" || item.slug === "all_users"
    );

    // Update recepient_filters based on the presence of specific filters
    const updatedValues = hasSpecificFilters
      ? values.filter(
          (item) => item.slug === "selected_users" || item.slug === "all_users"
        )
      : values;

    formik.setFieldValue(
      "recepient_filters",
      updatedValues.map((item) => item.slug)
    );

    // Clear specific fields based on the presence of filters
    const fieldsToClear = [
      { filter: "from_excel_file", field: "excel_url", defaultValue: "" },
      { filter: "age", field: "age_from", defaultValue: "" },
      { filter: "age", field: "age_to", defaultValue: "" },
      { filter: "gender", field: "gender", defaultValue: "" },
      { filter: "nationality", field: "nationality", defaultValue: [] },
      { filter: "inactive_users", field: "inactive_users", defaultValue: "" },
      { filter: "selected_users", field: "selected_users", defaultValue: [] },
      { filter: "selected_users", field: "add_user", defaultValue: "" },
    ];

    fieldsToClear.forEach(({ filter, field, defaultValue }) => {
      clearFieldIfFilterNotPresent(filter, field, defaultValue);
    });

    // Additional logic for specific fields
    if (!formik?.values?.recepient_filters?.includes("from_excel_file")) {
      dispatch(
        updateConfig((state) => {
          state.excelFileName = "";
        })
      );
    }
  };

  const handleAddSelectedUser = () => {
    if (formik?.values?.add_user !== "" && !formik?.errors?.add_user) {
      if (!formik?.values?.selected_users?.includes(formik?.values?.add_user)) {
        formik.setFieldValue("selected_users", [
          ...formik.values.selected_users,
          formik?.values?.add_user,
        ]);
        formik.setFieldValue("add_user", "");
      } else {
        formik.setFieldError("add_user", "Number is already added");
      }
    }
  };

  const handleRemoveSelectedUser = (index) => {
    if (index > -1 && index < formik?.values?.selected_users.length) {
      const updatedUsers = [...formik.values.selected_users];
      updatedUsers.splice(index, 1);

      formik.setFieldValue("selected_users", updatedUsers);
    }
  };

  const handleTimeChange = (newValue) => {
    //converting the time which getting from time picker
    const formatTime = (dateString) => {
      // Parse the date string into a Date object
      const date = new Date(dateString);

      // Extract hours and minutes
      let hours = date.getHours();
      const minutes = date.getMinutes();

      // Determine AM/PM
      const ampm = hours >= 12 ? "PM" : "AM";

      // Convert to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12;

      // Add leading zero to single-digit hours
      const formattedHours = hours < 10 ? "0" + hours : hours;

      // Format minutes to always be two digits
      const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

      // Construct the final time string
      const timeString = formattedHours + ":" + formattedMinutes + " " + ampm;

      return timeString;
    };

    const receivedTime = newValue?.["$d"] ?? {};
    const convertedTime = formatTime(receivedTime);
    formik?.setFieldValue("schedule_time", convertedTime);
  };

  const handleChangeNotificationType = (value) => {
    formik.setFieldValue("notification_type", value.slug);
    setAliasImageUrlValidation({});
    setImageUrlValidation({});
    formik.errors = {};
    formik.setFieldValue("title", "");
    formik.setFieldValue("alias_title", "");
    formik.setFieldValue("message", "");
    formik.setFieldValue("alias_message", "");
    formik.setFieldValue("image_url", "");
    formik.setFieldValue("alias_image_url", "");
    dispatch(
      updateConfig((state) => {
        state.imageName = "";
        state.aliasImageName = "";
        state.imagePreview = "";
        state.aliasImagePreview = "";
      })
    );
  };

  return {
    getFieldError,
    handleImageChange,
    handleFileChange,
    setUserNumber,
    handleAddSelectedUser,
    handleChangeRecepientFilters,
    handleRemoveSelectedUser,
    handleTimeChange,
    handleChangeNotificationType,
    imageUrlValidation,
    aliasImageUrlValidation,
    fileLoader,
    userNumber,
    customNotificationState,
    NotificationBasicData,
    formik,
  };
};

export default useCreateCustomNotification;
