import { useDispatch, useSelector } from "react-redux";
import {
  useGetWalletTransactionBasicListDataQuery,
  useGetWalletTransactionListDataQuery,
  useUpdateWalletTransactionTableHeadDataMutation,
} from "../../../store/queries/Report";
import { updateConfig } from "../../../store/slices/Report/walletTransactionSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getWalletTransactionExportedData } from "../api";
import { getFormatedDate } from "../../../utils/Functions/table";

const useWalletTransaction = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const [isExcelLinkSend, setIsExcelLinkSend] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const walletTransactionState = useSelector(
    (state) => state.walletTransaction
  );
  const { data: basicData = {} } = useGetWalletTransactionBasicListDataQuery();
  const {
    data: walletTransactionList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetWalletTransactionListDataQuery({
    page_size: walletTransactionState?.currentPageSize,
    page: walletTransactionState?.currentPage,
    sort: walletTransactionState?.sortBy,
    sort_order: walletTransactionState?.sortOrder,
    search: walletTransactionState?.search,
    start_date: getFormatedDate(walletTransactionState?.startDate),
    end_date: getFormatedDate(walletTransactionState?.endDate),
    type: walletTransactionState?.typeId,
  });
  const [updateWalletTransactionFields] =
    useUpdateWalletTransactionTableHeadDataMutation();
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e?.target?.value;
        state.currentPage = 1;
      })
    );
  };
  useEffect(() => {
    if (walletTransactionList && walletTransactionList?.data?.total_count) {
      dispatch(
        updateConfig((state) => {
          state.totalCount = walletTransactionList?.data?.total_count ?? "0";
        })
      );
    }
    //eslint-disable-next-line
  }, [walletTransactionList?.data?.total_count]);
  const [date, setDate] = useState({
    startDate: walletTransactionState.startDate,
    endDate: walletTransactionState.endDate,
    key: "selection",
  });

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (walletTransactionState?.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            walletTransactionState?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleWalletTransactionExcelDownload = () => {
    dispatch(
      updateConfig((state) => {
        state.walletTransactionExcelSendModal =
          !state.walletTransactionExcelSendModal;
      })
    );
  };
  const modalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.walletTransactionExcelSendModal = false;
      })
    );
  };
  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const sendExcelData = async (value) => {
    setIsExcelLinkSend(true);
    let params = {
      email: value?.email ?? "",
      start_date: getFormatedDate(walletTransactionState.startDate ?? ""),
      end_date: getFormatedDate(walletTransactionState.endDate ?? ""),
      search: walletTransactionState?.search,
      type: walletTransactionState?.typeId,
      sort_order: walletTransactionState?.sortOrder,
      sort: walletTransactionState?.sortBy,
    };
    let response = await getWalletTransactionExportedData(params);
    try {
      if (response?.status === 200) {
        toast.success(`Excel download link send successfully`);
      } else {
        toast.error(`Failed to send download link,Please try again`);
      }
    } catch (error) {
      console.error("Failed to send link:", error);
      toast.error(`Failed to send download link`);
    } finally {
      modalClose();
      setIsExcelLinkSend(false);
    }
  };
  const handleChange = (e) => {
    dispatch(
      updateConfig((state) => {
        state.typeId = e?.label;
      })
    );
  };
  let filterList = basicData?.data?.transaction_type?.map((item) => {
    return {
      label: item?.name,
      value: item?.id,
    };
  });
  let filterObj = filterList?.filter(
    (item) => item?.label === walletTransactionState?.typeId
  );

  return {
    date,
    filterObj,
    isLoading,
    isFetching,
    filterList,
    tableFields,
    showEditModal,
    isExcelLinkSend,
    paginationOptions,
    walletTransactionList,
    walletTransactionState,
    handleWalletTransactionExcelDownload,
    updateWalletTransactionFields,
    handleEditColumnsClick,
    handleDateRangeChange,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handlePageSize,
    sendExcelData,
    handleSearch,
    handleChange,
    handleSort,
    modalClose,
    refetch,
    getRow,
  };
};

export default useWalletTransaction;
