import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useGetproductListingDataQuery,
  useUpdateProductListTableHeadDataMutation,
} from "../../../../store/queries/User";
import { updateConfig } from "../../../../store/slices/User/UserDetails/productListingSlice";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { useState } from "react";

const useProductListing = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const activeProfile = user_id ?? sessionStorage.getItem("active");
  const productListState = useSelector((state) => state.productListing);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: productList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetproductListingDataQuery({
    user_id: activeProfile,
    page_size: productListState?.currentPageSize,
    page: productListState?.currentPage,
    sort: productListState?.sortBy,
    sort_order: productListState?.sortOrder,
    search: productListState?.search,
  });
  const [updateProductListFields] = useUpdateProductListTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (productListState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            productListState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      image: (field, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image)}
            />
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };
  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  return {
    isLoading,
    imageData,
    isFetching,
    tableFields,
    productList,
    showEditModal,
    showImageModal,
    productListState,
    paginationOptions,
    updateProductListFields,
    handleEditColumnsClick,
    handlePagination,
    handleClearClick,
    closeImageModal,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useProductListing;
