import { useDispatch } from "react-redux";
import { useGetUserDetailsDataQuery } from "../../../store/queries/Global";
import { updateConfig } from "../../../store/slices/Admin/Sub-admin/subAdminSlice";
import countryData from "../../../utils/components/countryCode";
import useValidations from "../../../utils/hooks/useValidations";
import { useFormik } from "formik";
import { profileUpdate } from "./api";

import { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";
import { getProfileImage } from "../../Admin/AdminUser/AddSubAdmin/api";
import { toast } from "react-toastify";

const useProfileView = () => {
  const { emailRegExp } = useValidations();
  const { data: userData, refetch } = useGetUserDetailsDataQuery();

  const [showNewPassword, setNewShowPassword] = useState(true);
  const profileFileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(
    userData?.data !== "" ? userData?.data?.image_url : ""
  );
  const dispatch = useDispatch();
  const validation = Yup.object({
    first_name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("Enter First Name")
      .max(50, "Enter valid Name"),
    last_name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("Enter Last Name")
      .max(50, "Enter valid Name"),
    dialcode: Yup.string().required("Select Country Code"),
    personal_number: Yup.string()
      .required("Enter Personal Number")
      .min(7, "Enter Valid Number")
      .max(14, "Enter Valid Number")
      // eslint-disable-next-line no-useless-escape
      .matches(/^[0-9 \-]+$/, "Enter Valid Number"),
    contact_number: Yup.string()
      .required("Enter Contact Number")
      .min(7, "Enter Valid Number")
      .max(14, "Enter Valid Number")
      // eslint-disable-next-line no-useless-escape
      .matches(/^[0-9 \-]+$/, "Enter Valid Number")
      .notOneOf([Yup.ref("personal_number")], "Both numbers cannot be same"),
    email: Yup.string()
      .required("Enter  Email ID")
      .matches(emailRegExp, "Enter Valid Email ID"),
  });
  useEffect(() => {
    formikEditUserData?.setValues({
      first_name: userData?.data?.first_name ?? "",
      last_name: userData?.data?.last_name ?? "",
      email: userData?.data?.email ?? "",
      dialcode: userData?.data?.dialcode ?? "",
      personal_number: userData?.data?.personal_number ?? "",
      contact_number: userData?.data?.contact_number ?? "",
      image: userData?.data?.image_url ?? "",
    });
    //eslint-disable-next-line
  }, [userData?.data]);

  const formikEditUserData = useFormik({
    initialValues: {
      enableReinitialize: true,
      first_name: "",
      last_name: "",
      email: "",
      dialcode: "",
      image: "",
      personal_number: "",
      contact_number: "",
      password: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      profileUpdate(values).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success(`Profile updated successfully`);
          refetch();
          handleClose();
        } else {
          toast.error(`Something went wrong!`);
          handleClose();
        }
      });
    },
  });
  const formikEditProfileImageData = useFormik({
    initialValues: {
      enableReinitialize: true,
      first_name: "",
      last_name: "",
      email: "",
      dialcode: "",
      image: "",
      personal_number: "",
      contact_number: "",
      password: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      profileUpdate(values).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success(`Profile image successfully`);
          refetch();
        } else {
          toast.error(`Something went wrong!`);
        }
      });
    },
  });
  const countryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );
  const handleShowNewPassword = (e) => {
    e.preventDefault();

    setNewShowPassword(!showNewPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  let countryObj = countryCodeOptions?.filter(
    (item) => item?.value === formikEditUserData?.values?.dialcode
  );
  const handleImageChange = (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);
      getProfileImage(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          formikEditProfileImageData.setFieldValue(
            "image",
            response?.data?.data?.image_path
          );
          formikEditUserData.setFieldValue(
            "image",
            response?.data?.data?.image_path
          );
        }
      });
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader?.result);
      };

      if (file) {
        reader.readAsDataURL(file);
        formikEditProfileImageData.handleSubmit();
      }
    }
  };
  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = !state.isProfileViewVisible;
      })
    );
  };

  return {
    userData,
    countryObj,
    imagePreview,
    showNewPassword,
    formikEditUserData,
    countryCodeOptions,
    profileFileInputRef,
    handleShowNewPassword,
    handleImageChange,
    setImagePreview,
    handleClose,
  };
};

export default useProfileView;
