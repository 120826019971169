import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetFilterBasicDataQuery } from "../../../../store/queries/Admin";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  updateConfig,
  resetConfig,
} from "../../../../store/slices/Admin/Orders/ordersSlice";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useGetUserDetailsDataQuery } from "../../../../store/queries/Global";

const useFilter = () => {
  const dispatch = useDispatch();
  const [isStoreAvailable, setIsStoreAvailable] = useState(false);
  const { data: orderBasicData = {} } = useGetFilterBasicDataQuery();
  const { data: userData = {} } = useGetUserDetailsDataQuery();

  useEffect(() => {
    if (userData?.data?.hasOwnProperty(`customer_key`)) {
      formik?.setFieldValue("store_name", userData?.data?.customer_key);
      setIsStoreAvailable(true);
    }
    //eslint-disable-next-line
  }, []);
  const orderState = useSelector((state) => state.orders);
  const validation = Yup.object({
    store_name: Yup.array().nullable(),
    payment_mode: Yup.string().nullable(),
    status: Yup.string().nullable(),
  });
  const formik = useFormik({
    initialValues: {
      store_name: orderState?.store_name ?? [],
      payment_mode: orderState?.payment_mode ?? "",
      status: orderState?.status ?? "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const { store_name, payment_mode, status } = values;
      if (store_name === "" && payment_mode === "" && status === "") {
        toast.error(`All fields are empty`);
      } else {
        dispatch(
          updateConfig((state) => [
            (state.store_name = values?.store_name),
            (state.payment_mode = values?.payment_mode),
            (state.status = values?.status),
          ])
        );
        handleClose();
      }
    },
  });
  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.filterShow = false;
      })
    );
  };
  const handleReset = () => {
    dispatch(resetConfig());
    handleClose();
  };

  let storeList = orderBasicData?.data?.store_name?.map((item) => {
    return {
      name: item?.name,
      id: item?.customer_key,
    };
  });
  let paymentObj = orderBasicData?.data?.payment_mode?.filter((item) => {
    return item?.name == formik?.values?.payment_mode;
  });
  let statusObj = orderBasicData?.data?.status?.filter((item) => {
    return item?.name == formik?.values?.status;
  });
  return {
    formik,
    paymentObj,
    storeList,
    statusObj,
    orderBasicData,
    isStoreAvailable,
    handleReset,
  };
};

export default useFilter;
