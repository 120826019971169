import { getAdminAxiosInstance } from "../../api/Admin";

export const createAndUpdateStaff = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/staff/store`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const deleteStaff = async (id) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.get(`/staff/destroy?staff_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
