import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { createFaq } from "../api";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Faq/faqSlice";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import useValidations from "../../../../utils/hooks/useValidations";

const useAddFaq = (refetch) => {
  const dispatch = useDispatch();
  const [isFaqCreate, setIsFaqCreate] = useState(false);
  const { arabicOnlyRegex, arabicRegex, englishRegex } = useValidations();
  const { isEdit, editFaqData } = useSelector((state) => state.faq);

  useEffect(() => {
    if (isEdit && editFaqData !== "") {
      formik?.setFieldValue("question", editFaqData?.question ?? "");
      formik?.setFieldValue(
        "alias_question",
        editFaqData?.alias_question ?? ""
      );
      formik?.setFieldValue("answer", editFaqData?.answer ?? "");
      formik?.setFieldValue("alias_answer", editFaqData?.alias_answer ?? "");
      formik?.setFieldValue("status", editFaqData?.status ?? "");
    }
    //eslint-disable-next-line
  }, [isEdit, editFaqData]);

  const validation = Yup.object({
    question: Yup.string()
      .trim()
      .required("Enter category name")
      .matches(englishRegex, "Enter in english letters"),
    alias_question: Yup.string()
      .trim()
      .required("Enter category name")
      .matches(arabicRegex, "Enter in arabic letters"),
    answer: Yup.string()
      .required(`Enter your  answers`)
      .min(5, "Answer which must be at least 5 characters long")
      .matches(englishRegex, "Enter in english letters"),
    alias_answer: Yup.string()
      .required(`Enter your  answers`)
      .min(5, "Answer which must be at least 5 characters long.")
      .matches(arabicRegex, "Enter in arabic letters"),
  });
  const formik = useFormik({
    initialValues: {
      question: "",
      alias_question: "",
      answer: "",
      alias_answer: "",
      status: 2,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        setIsFaqCreate(true);
        const data = isEdit ? { faq_id: editFaqData?._id, ...values } : values;
        const response = await createFaq(data);
        if (response?.data?.status_code === 200) {
          toast.success(`Faq ${isEdit ? `Updated` : `Created`} Successfully`);
          setIsFaqCreate(false);
          refetch();
        }
      } catch (error) {
        //TODO
        toast.error(error?.message);
        setIsFaqCreate(false);
      } finally {
        closeModal();
        setIsFaqCreate(false);
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  return {
    formik,
    isEdit,
    isFaqCreate,
    getFieldError,
    closeModal,
  };
};

export default useAddFaq;
