import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Products/productSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { changeProduct } from "./api";
import { toast } from "react-toastify";

const useProductChangeForm = (refetch) => {
  const dispatch = useDispatch();
  const {
    editProductData,
    finalProductChangeData,
    changeAvailable,
    isSuccess,
    isSavingChangedProduct,
    // updateAllStore,
  } = useSelector((state) => state.product);
  const [finalData, setFinalData] = useState(false);
  const [updateAllStore, setUpdateAllStore] = useState(false);

  const newFetchedData = { ...editProductData?.new_items };
  const previousFetchedData = { ...editProductData };
  delete previousFetchedData?.new_items;
  let variantsKey = editProductData?.mismatched_keys?.variants?.length
    ? editProductData?.mismatched_keys?.variants
    : [];
  let addOnKey = editProductData?.mismatched_keys?.add_on?.length
    ? editProductData?.mismatched_keys?.add_on
    : [];

  let optionalAddOnKey = editProductData?.mismatched_keys?.optional_add_on
    ?.length
    ? editProductData?.mismatched_keys?.optional_add_on
    : [];
  const { mismatched_keys } = { ...editProductData };
  let newKeyData;
  if (mismatched_keys && typeof mismatched_keys === "object") {
    newKeyData = Object.values(mismatched_keys);
  }

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isSavingChangedProduct = false;
        // state.editProductData = {};
        // state.finalProductChangeData = {};
      })
    );
  };
  useEffect(() => {
    if (
      finalProductChangeData !== "" &&
      Object.keys(finalProductChangeData)?.length > 0 &&
      changeAvailable
    ) {
      getChangeProductData(finalProductChangeData);
    }
    //eslint-disable-next-line
  }, [changeAvailable]);

  const getChangeProductData = async (value) => {
    let data = {
      customer_key: editProductData?.customer_key,
      product_id: editProductData?.product_id,
      update_all_store: updateAllStore,
      ...value,
    };
    dispatch(
      updateConfig((state) => {
        state.isSavingChangedProduct = true;
      })
    );
    const response = await changeProduct(data);

    if (response?.data?.status_code === 200) {
      toast.success(`Product change updated successfully`);
      dispatch(
        updateConfig((state) => {
          state.isSavingChangedProduct = false;
        })
      );
      refetch();
    } else {
      toast.error(`Something went wrong!`);
      dispatch(
        updateConfig((state) => {
          state.isSavingChangedProduct = false;
        })
      );
    }
    dispatch(
      updateConfig((state) => {
        state.changeAvailable = false;
      })
    );
    handleCloseModal();
  };

  const handleUpdateAllStore = (e) => {
    // dispatch(
    //   updateConfig((state) => {
    //     state.updateAllStore = e?.target?.checked;
    //   })
    // );
    setUpdateAllStore(e?.target?.checked);
  };
  const handleSubmit = () => {
    setFinalData(true);
  };
  return {
    addOnKey,
    finalData,
    isSuccess,
    newKeyData,
    variantsKey,
    newFetchedData,
    updateAllStore,
    optionalAddOnKey,
    previousFetchedData,
    isSavingChangedProduct,
    handleUpdateAllStore,
    handleCloseModal,
    handleSubmit,
  };
};

export default useProductChangeForm;
