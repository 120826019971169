import { useDispatch, useSelector } from "react-redux";
import {
  useGetRepeatCustomerListDataQuery,
  useUpdateRepeatCustomerTableHeadDataMutation,
} from "../../../store/queries/Report";
import { updateConfig } from "../../../store/slices/Report/repeatCustomerSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useState } from "react";
import { toast } from "react-toastify";
import { getRepeatCustomerExportedData } from "../api";
import { getFormatedDate } from "../../../utils/Functions/table";
import { Tooltip as ReactTooltip } from "react-tooltip";

const useRepeatCustomer = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const [isExcelLinkSend, setIsExcelLinkSend] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const repeatCustomerState = useSelector((state) => state.repeatCustomer);
  const {
    data: repeatCustomerList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetRepeatCustomerListDataQuery({
    page_size: repeatCustomerState?.currentPageSize,
    page: repeatCustomerState?.currentPage,
    sort: repeatCustomerState?.sortBy,
    sort_order: repeatCustomerState?.sortOrder,
    search: repeatCustomerState?.search,
    start_date: getFormatedDate(repeatCustomerState?.startDate),
    end_date: getFormatedDate(repeatCustomerState?.endDate),
  });
  const [updateRepeatCustomerFields] =
    useUpdateRepeatCustomerTableHeadDataMutation();
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e?.target?.value;
        state.currentPage = 1;
      })
    );
  };
  const [date, setDate] = useState({
    startDate: repeatCustomerState.startDate,
    endDate: repeatCustomerState.endDate,
    key: "selection",
  });

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (repeatCustomerState?.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            repeatCustomerState?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      formated_amount: (field, data) => {
        return (
          <>
            <p
              className="pro-mb-0"
              data-tooltip-id={`customer-amount-${data?.name}`}
            >
              {data[field]}
            </p>
            <ReactTooltip
              id={`customer-amount-${data?.name}`}
              place="left"
              content={`Amount : ${data?.amount}`}
            />
          </>
        );
      },
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleRepeatCustomerExcelDownload = () => {
    dispatch(
      updateConfig((state) => {
        state.repeatCustomerExcelSendModal =
          !state.repeatCustomerExcelSendModal;
      })
    );
  };
  const modalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.repeatCustomerExcelSendModal = false;
      })
    );
  };
  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const sendExcelData = async (value) => {
    setIsExcelLinkSend(true);
    let params = {
      email: value?.email ?? "",
      start_date: getFormatedDate(repeatCustomerState.startDate ?? ""),
      end_date: getFormatedDate(repeatCustomerState.endDate ?? ""),
      sort: repeatCustomerState?.sortBy,
      sort_order: repeatCustomerState?.sortOrder,
    };
    let response = await getRepeatCustomerExportedData(params);
    try {
      if (response?.status === 200) {
        toast.success(`Excel download link send successfully`);
      } else {
        toast.error(`Failed to send download link,Please try again`);
      }
    } catch (error) {
      console.error("Failed to send link:", error);
      toast.error(`Failed to send download link`);
    } finally {
      modalClose();
      setIsExcelLinkSend(false);
    }
  };

  return {
    date,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    isExcelLinkSend,
    paginationOptions,
    repeatCustomerList,
    repeatCustomerState,
    handleRepeatCustomerExcelDownload,
    updateRepeatCustomerFields,
    handleEditColumnsClick,
    handleDateRangeChange,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handlePageSize,
    sendExcelData,
    handleSearch,
    handleSort,
    modalClose,
    refetch,
    getRow,
  };
};

export default useRepeatCustomer;
