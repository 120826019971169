import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddGiftCards from "./useAddGiftCards";

const AddGiftCards = ({ refetch, handleCreateClick }) => {
  const {
    formik,
    isEdit,
    typeObj,
    isLoading,
    imageName,
    typeOptions,
    imagePreview,
    isStickyFooter,
    isLoadingImage,
    arabicImageName,
    arabicImagePreview,
    isArabicImageLoading,
    handleImageChange,
    handleTypeChange,
    getFieldError,
    handleClose,
  } = useAddGiftCards({ refetch, handleCreateClick });

  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Title"}
          type="text"
          id="title"
          name="title"
          className={`pro-input lg ${getFieldError("title") && " error"}`}
          {...formik.getFieldProps("title")}
          error={getFieldError("title")}
          errorMessage={getFieldError("title")}
        />
        <div className="pro-rtl">
          <Input
            label={"(إمالة) Title"}
            type="text"
            id="alias_title"
            name="alias_title"
            className={`pro-input lg ${
              getFieldError("alias_title") && " error"
            }`}
            {...formik.getFieldProps("alias_title")}
            error={getFieldError("alias_title")}
            errorMessage={getFieldError("alias_title")}
          />
        </div>
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Description
            </label>
            <textarea
              id="description"
              rows={3}
              name="description"
              className={`pro-input lg ${
                formik.errors.description &&
                formik.touched.description &&
                " error"
              }`}
              value={formik?.values?.description ?? ""}
              onBlur={formik?.handleBlur("description")}
              onChange={(e) =>
                formik.setFieldValue("description", e.target.value)
              }
            ></textarea>
            {formik.touched.description && formik.errors.description && (
              <span className="error-text">{formik.errors.description}</span>
            )}
          </div>
        </div>
        <div className="col-12 pro-mb-4 pro-rtl">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              {`(وصف) Description`}
            </label>
            <textarea
              id="alias_description"
              rows={3}
              name="alias_description"
              className={`pro-input lg ${
                formik.errors.alias_description &&
                formik.touched.alias_description &&
                " error"
              }`}
              value={formik?.values?.alias_description ?? ""}
              onBlur={formik?.handleBlur("alias_description")}
              onChange={(e) =>
                formik.setFieldValue("alias_description", e.target.value)
              }
            ></textarea>
            {formik.touched.alias_description &&
              formik.errors.alias_description && (
                <span className="error-text">
                  {formik.errors.alias_description}
                </span>
              )}
          </div>
        </div>
        <div className="input-wrap pro-mb-4 ">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Image
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".jpeg,.jpg , .png "
              className={`pro-input ${
                formik.errors.image && formik.touched.image && " error"
              }`}
              id="image"
              onBlur={formik.handleBlur("image")}
              onChange={(e) => handleImageChange(e, "image")}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  isLoadingImage
                    ? "Uploading..."
                    : imageName !== ""
                    ? imageName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched.image && formik.errors.image && (
              <span className="error-text">{formik.errors.image}</span>
            )}
          </div>
          <div className={`col-2 pro-my-2`}>
            <div className={``}>
              <Image
                width={100}
                height={100}
                src={imagePreview}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>
        <div className="input-wrap pro-mb-4 pro-rtl">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            ( صورة ) Image
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".jpeg,.jpg , .png "
              className={`pro-input ${
                formik.errors.alias_image &&
                formik.touched.alias_image &&
                " error"
              }`}
              id="alias_image"
              onBlur={formik.handleBlur("alias_image")}
              onChange={(e) => handleImageChange(e, "alias_image")}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  isArabicImageLoading
                    ? "...Uploading"
                    : arabicImageName !== ""
                    ? arabicImageName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched.alias_image && formik.errors.alias_image && (
              <span className="error-text">{formik.errors.alias_image}</span>
            )}
          </div>
          <div className={`col-2 pro-my-2`}>
            <div className={``}>
              <Image
                width={100}
                height={100}
                src={arabicImagePreview}
                alt={`image - 01`}
              />
            </div>
          </div>
        </div>

        <Input
          label={"Priority "}
          type="text"
          id="priority"
          name="priority"
          className={`pro-input lg ${getFieldError("priority") && " error"}`}
          {...formik.getFieldProps("priority")}
          error={getFieldError("priority")}
          errorMessage={getFieldError("priority")}
        />
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Type
          </label>
          <Select
            id="type"
            name="type"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("type") && " error"}`}
            classNamePrefix="pro-input"
            options={typeOptions ?? []}
            value={typeObj}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.value}
            onBlur={formik.handleBlur("type")}
            onChange={(e) => handleTypeChange(e)}
            menuPlacement="auto"
          />
          {getFieldError("type") && (
            <span className="error-text">{getFieldError("type")}</span>
          )}
        </div>
        {formik.values.type === "LIMITED" && (
          <>
            <Input
              label={"From Date"}
              type="date"
              id="from_date"
              name="from_date"
              className={`pro-input lg ${
                getFieldError("from_date") && " error"
              }`}
              {...formik.getFieldProps("from_date")}
              min={new Date().toISOString().split("T")[0]}
              error={getFieldError("from_date")}
              errorMessage={getFieldError("from_date")}
            />
            <Input
              label={"To Date"}
              type="date"
              id="to_date"
              name="to_date"
              className={`pro-input lg ${getFieldError("to_date") && " error"}`}
              {...formik.getFieldProps("to_date")}
              min={formik?.values?.from_date}
              disabled={formik?.values?.from_date === ""}
              error={getFieldError("to_date")}
              errorMessage={getFieldError("to_date")}
            />
          </>
        )}
        <div className="pro-check-box pro-py-2">
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={Boolean(formik.values?.status === 1) ?? false}
              onChange={(e) =>
                formik.setFieldValue("status", e?.target?.checked ? 1 : 2)
              }
            />
            <label htmlFor="default" className="pro-check-label">
              Status
            </label>
          </div>
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            disabled={isLoading || isLoadingImage || isArabicImageLoading}
            onClick={formik.handleSubmit}
          >
            {!isEdit
              ? isLoading
                ? "creating..."
                : "Create"
              : isLoading
              ? "Updating..."
              : "Update"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddGiftCards;
