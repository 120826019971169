import React from "react";
import Style from "./input.module.scss";
export const ProductChangeInput = ({
  label,
  placeholder,
  onChange,
  error = false,
  errorMessage,
  missMatchedKeyData,
  handleCheckboxChange,
  checked,
  fieldName,
  data,
  value,
  defaultValue,
  ...props
}) => {
  return (
    <div className="input-wrap pro-mb-4">
      <div className="pro-d-flex pro-items-center">
        {missMatchedKeyData?.includes(fieldName) ? (
          <div
            className={`pro-check-box pro-w-auto pro-py-2 ${Style.checkbox}`}
          >
            <input
              type="checkbox"
              className="pro-check"
              id={fieldName}
              name={fieldName}
              checked={checked}
              onChange={handleCheckboxChange}
            />
          </div>
        ) : (
          ""
        )}
        <label
          className={`pro-font-sm pro-fw-medium ${
            missMatchedKeyData?.includes(fieldName) ? "pro-mb-0" : "pro-mb-1"
          }`}
        >
          {label}
        </label>
      </div>
      <input
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        name={fieldName}
        readOnly
        id={fieldName}
        value={value}
        defaultValue={defaultValue}
        className={`pro-input lg ${error ? "error" : ""}`}
        {...props}
      />
      {error && <span className="error-text">{errorMessage}</span>}
    </div>
  );
};
