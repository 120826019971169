import { useState } from "react";
import { admin } from "../../../../store/queries/Admin";
import { user } from "../../../../store/queries/User";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Sub-admin/subAdminSlice";
// import { logOut } from "./api";
// import { toast } from "react-toastify";

const useHeaderActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subAdminState = useSelector((state) => state.subAdmin);
  const [isProfileViewVisible, setProfileViewVisible] = useState(false);

  const handleViewProfile = () => {
    dispatch(
      updateConfig((state) => {
        state.isProfileViewVisible = !state.isProfileViewVisible;
      })
    );
  };

  const handleLogout = () => {
    // logOut().then((response) => {
    //   if (response?.data?.status_code === 200) toast(`Logged Out Successfully`);
    //   else toast(`Something went wrong!`);
    // });
    dispatch(admin.util.resetApiState());
    dispatch(user.util.resetApiState());
    localStorage.removeItem("USER_DETAILS");
    localStorage.removeItem("shopData");
    localStorage.removeItem("USER_ACCESS_TOKEN_CARIBOU");
    localStorage.removeItem("dashboard_permissions");

    navigate("/");
  };
  const handleSettings = () => {
    navigate(`/admin/settings`);
  };

  return {
    subAdminState,
    isProfileViewVisible,
    handleLogout,
    handleSettings,
    handleViewProfile,
    setProfileViewVisible,
  };
};

export default useHeaderActions;
