import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
// import { capitalizeOnSpace } from "../../../../utils/Functions/table";
import useAddCategory from "./useAddCategory";

const AddCategory = ({ isStickyFooter, handleCreateClick, refetch }) => {
  const {
    formik,
    isEdit,
    iconName,
    imageName,
    iconPreview,
    imagePreview,
    isCategoryCreate,
    profileIconLoading,
    profileImageLoading,
    handleImageChange,
    handleIconChange,
    getFieldError,
    closeModal,
  } = useAddCategory(handleCreateClick, refetch);
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Category Name"}
          type="text"
          id="name"
          name="name"
          className={`pro-input lg ${getFieldError("name") && " error"}`}
          {...formik.getFieldProps("name")}
          // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("name")}
          errorMessage={getFieldError("name")}
        />
        <div className="pro-rtl">
          <Input
            label={"(اسم التصنيف) Category Name"}
            type="text"
            id="alias_name"
            name="alias_name"
            className={`pro-input lg ${
              getFieldError("alias_name") && " error"
            }`}
            {...formik.getFieldProps("alias_name")}
            error={getFieldError("alias_name")}
            errorMessage={getFieldError("alias_name")}
          />
        </div>
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Description
            </label>
            <textarea
              id="description"
              rows={3}
              name="description"
              className={`pro-input lg ${
                formik.errors.description &&
                formik.touched.description &&
                " error"
              }`}
              value={formik?.values?.description ?? ""}
              onBlur={formik?.handleBlur("description")}
              onChange={(e) =>
                formik.setFieldValue("description", e.target.value)
              }
            ></textarea>
            {formik.touched.description && formik.errors.description && (
              <span className="error-text">{formik.errors.description}</span>
            )}
          </div>
        </div>
        <div className="col-12 pro-mb-4 pro-rtl">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              {`(وصف) Description`}
            </label>
            <textarea
              id="alias_description"
              rows={3}
              name="alias_description"
              className={`pro-input lg ${
                formik.errors.alias_description &&
                formik.touched.alias_description &&
                " error"
              }`}
              value={formik?.values?.alias_description ?? ""}
              onBlur={formik?.handleBlur("alias_description")}
              onChange={(e) =>
                formik.setFieldValue("alias_description", e.target.value)
              }
            ></textarea>
            {formik.touched.alias_description &&
              formik.errors.alias_description && (
                <span className="error-text">
                  {formik.errors.alias_description}
                </span>
              )}
          </div>
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Image
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".jpeg,.jpg , .png "
              className={`pro-input ${
                formik.errors.image && formik.touched.image && " error"
              }`}
              id="image"
              onBlur={formik.handleBlur("image")}
              onChange={(e) => handleImageChange(e)}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  profileImageLoading
                    ? "Uploading..."
                    : imageName !== ""
                    ? imageName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched.image && formik.errors.image && (
              <span className="error-text">{formik.errors.image}</span>
            )}
          </div>
          {imagePreview && (
            <div className={`col-2 pro-my-2`}>
              <div className={``}>
                <Image
                  width={100}
                  height={100}
                  src={imagePreview}
                  alt={`image - 01`}
                />
              </div>
            </div>
          )}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Icon
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".jpeg,.jpg , .png "
              className={`pro-input ${
                formik.errors.icon && formik.touched.icon && " error"
              }`}
              id="icon"
              onBlur={formik.handleBlur("icon")}
              onChange={(e) => handleIconChange(e)}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  profileIconLoading
                    ? "Uploading..."
                    : iconName !== ""
                    ? iconName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched.icon && formik.errors.icon && (
              <span className="error-text">{formik.errors.icon}</span>
            )}
          </div>
          {iconPreview && (
            <div className={`col-2 pro-my-2`}>
              <div className={``}>
                <Image
                  width={100}
                  height={100}
                  src={iconPreview}
                  alt={`image - 01`}
                />
              </div>
            </div>
          )}
        </div>

        <Input
          label={"Menu Priority"}
          type="text"
          id="display_priority"
          name="display_priority"
          className={`pro-input lg ${
            getFieldError("display_priority") && " error"
          }`}
          {...formik.getFieldProps("display_priority")}
          // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("display_priority")}
          errorMessage={getFieldError("display_priority")}
        />

        <Input
          label={"Home Priority"}
          type="text"
          id="home_priority"
          name="home_priority"
          className={`pro-input lg ${
            getFieldError("home_priority") && " error"
          }`}
          {...formik.getFieldProps("home_priority")}
          // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("home_priority")}
          errorMessage={getFieldError("home_priority")}
        />
        <div className="pro-check-box pro-py-2">
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={Boolean(formik.values?.is_new === 1) ?? false}
              onChange={(e) =>
                formik.setFieldValue(`is_new`, e?.target?.checked ? 1 : 2)
              }
            />
            <label htmlFor="default" className="pro-check-label">
              New Category
            </label>
          </div>
        </div>
        <div className="pro-check-box pro-py-2">
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={Boolean(formik.values?.status === 1) ?? false}
              onChange={(e) =>
                formik.setFieldValue(`status`, e?.target?.checked ? 1 : 2)
              }
            />
            <label htmlFor="default" className="pro-check-label">
              Status
            </label>
          </div>
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
          disabled={
            isCategoryCreate || profileImageLoading || profileIconLoading
          }
        >
          {!isEdit && !isCategoryCreate
            ? "Create"
            : !isEdit && isCategoryCreate
            ? "Creating..."
            : isEdit && isCategoryCreate
            ? "Updating..."
            : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default AddCategory;
