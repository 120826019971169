import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useUser = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Users",
      link: "/user/users",
      icon: (
        <span className="material-symbols-outlined x4">
          admin_panel_settings
        </span>
      ),
      active: checkIfActiveRoute("/user/users", true),
    },
    {
      title: "Banner",
      link: "/user/banner",
      icon: <span className="material-symbols-outlined x4">photo_library</span>,
      active: checkIfActiveRoute("/user/banner", true),
    },
    {
      title: "Video Content",
      link: "/user/video-content",
      icon: <span className="material-symbols-outlined x4">video_library</span>,
      active: checkIfActiveRoute("/user/video-content", true),
    },
    {
      title: "Contact Us",
      link: "/user/contact-us",
      icon: <span className="material-symbols-outlined x4">contact_page</span>,
      active: checkIfActiveRoute("/user/contact-us", true),
    },
    {
      title: "Transaction Types",
      link: "/user/transaction-type",
      icon: <span className="material-symbols-outlined x4">payments</span>,
      active: checkIfActiveRoute("/user/transaction-type", true),
    },
    {
      title: "Wallet Transactions",
      link: "/user/transactions",
      icon: <span className="material-symbols-outlined x4">receipt_long</span>,
      active: checkIfActiveRoute("/user/transactions", true),
    },
    {
      title: "Offers",
      link: "/user/offers",
      icon: <span className="material-symbols-outlined x4">redeem</span>,
      active: checkIfActiveRoute("/user/offers", true),
    },
    {
      title: "Gifts Cards",
      link: "/user/gift-cards",
      icon: (
        <span className="material-symbols-outlined x4">
          featured_seasonal_and_gifts
        </span>
      ),
      active: checkIfActiveRoute("/user/gift-cards", true),
    },
    {
      title: "Feed Back",
      link: "/user/feedback",
      icon: <span className="material-symbols-outlined x4">rate_review</span>,
      active: checkIfActiveRoute("/user/feedback", true),
    },
    {
      title: "FAQ",
      link: "/user/faq",
      icon: <span className="material-symbols-outlined x4">quiz</span>,
      active: checkIfActiveRoute("/user/faq", true),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );
    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.title);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return { drawerMenu: drawerMenuPermission };
};

export default useUser;
