import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useTermsAndConditions from "./useTermsAndConditions";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Style from "./termsConditions.module.scss";

const TermsAndConditions = ({ handleCloseModal, refetch }) => {
  const {
    formik,
    editPrivacyPolicyData,
    handleContentChange,
    handleArabicContentChange,
    getFieldError,
  } = useTermsAndConditions(refetch, handleCloseModal);
  return (
    <div className="pro-p-5">
      <div className="row d-flex">
        <div className="col-6">
          <Input
            label={" Name"}
            type="text"
            id="name"
            name="name"
            readOnly
            className={`pro-input lg ${getFieldError("name") && " error"}`}
            {...formik.getFieldProps("name")}
            error={getFieldError("name")}
            errorMessage={getFieldError("name")}
          />
          <div className={Style.editor_container}>
            <Editor
              wrapperClassName={Style.pro_editor_wrapper}
              editorClassName={Style.pro_editor_main}
              toolbarClassName={Style.pro_editor_toolbar}
              editorState={formik?.values?.description}
              readOnly={editPrivacyPolicyData === "" ? true : false}
              onEditorStateChange={handleContentChange}
              // toolbarStyle={{ paddingTop: "30px", marginTop: "30px" }}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                ],
                blockType: {
                  inDropdown: true,
                  className: Style.dropdown_wrapper,
                  dropdownClassName: Style.dropdown_menu,
                },
                fontFamily: {
                  options: ["Vinyl OT", "Neutraface Text Book"],
                  default: "sans-serif", // Set default font family
                },
              }}
              customStyleMap={{
                fontFamily: {
                  fontFamily: "Neutraface Text Book",
                },
              }}
            />
            {formik?.touched?.description && formik?.errors?.description && (
              <span className={Style.error_text}>
                {formik?.errors?.description}
              </span>
            )}{" "}
          </div>
        </div>
        <div className="col-6">
          <div className="pro-rtl">
            <Input
              label={"( اسم ) Name "}
              type="text"
              id="alias_name"
              name="alias_name"
              readOnly
              className={`pro-input lg ${
                getFieldError("alias_name") && " error"
              }`}
              {...formik.getFieldProps("alias_name")}
              error={getFieldError("alias_name")}
              errorMessage={getFieldError("alias_name")}
            />
          </div>
          <div className="">
            <div className={`${Style.editor_container} ${Style.arabic_editor}`}>
              <Editor
                wrapperClassName={Style.pro_editor_wrapper}
                editorClassName={Style.pro_editor_main}
                toolbarClassName={Style.pro_editor_toolbar}
                editorState={formik?.values?.alias_description}
                readOnly={editPrivacyPolicyData === "" ? true : false}
                onEditorStateChange={handleArabicContentChange}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "fontFamily",
                    "list",
                    "textAlign",
                  ],
                  blockType: {
                    inDropdown: true,
                    className: Style.dropdown_wrapper,
                    dropdownClassName: Style.dropdown_menu,
                  },
                  fontFamily: {
                    options: ["Vinyl OT", "Neutraface Text Book"],
                    default: "sans-serif", // Set default font family
                  },
                }}
                customStyleMap={{
                  fontFamily: {
                    fontFamily: "Neutraface Text Book",
                  },
                  history: {
                    options: ["undo", "redo"],
                  },
                }}
              />
              {formik?.touched?.alias_description &&
                formik?.errors?.alias_description && (
                  <span className={Style.error_text}>
                    {formik?.errors?.alias_description}
                  </span>
                )}{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button
          className={"pro-btn-link lg "}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default TermsAndConditions;
