import React from "react";
import CommonLayout from "../../pages/Layout/CommonLayout";
import { Outlet } from "react-router-dom";
import useAdmin from "./useAdmin";

const Admin = () => {
  const drawerMenu = useAdmin();
  return (
    <CommonLayout drawerMenu={drawerMenu?.drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Admin;
