import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useCreateOffer from "./useCreateOffer";
// import { capitalizeOnSpace } from "../../../../utils/Functions/table";

const CreateOffer = ({ isStickyFooter, closeModal, refetch }) => {
  const { formik, isEdit, isCustomOfferCreate, getFieldError } = useCreateOffer(
    refetch,
    closeModal
  );
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Offer Name"}
          type="text"
          id="offer_name"
          name="offer_name"
          className={`pro-input lg ${getFieldError("offer_name") && "error"}`}
          {...formik.getFieldProps("offer_name")}
          // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("offer_name")}
          errorMessage={getFieldError("offer_name")}
        />
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Details
            </label>
            <textarea
              id="details"
              rows={3}
              name="details"
              className={`pro-input lg ${
                formik.errors.details && formik.touched.details && " error"
              }`}
              value={formik?.values?.details ?? ""}
              onBlur={formik?.handleBlur("details")}
              onChange={(e) => formik.setFieldValue("details", e.target.value)}
            ></textarea>
            {formik.touched.details && formik.errors.details && (
              <span className="error-text">{formik.errors.details}</span>
            )}
          </div>
        </div>
        <div className="col-12 pro-mb-4">
          <div className="input-wrap pro-rtl">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              (تفاصيل) Details
            </label>
            <textarea
              id="alias_details"
              rows={3}
              name="alias_details"
              className={`pro-input lg ${
                formik.errors.alias_details &&
                formik.touched.alias_details &&
                " error"
              }`}
              value={formik?.values?.alias_details ?? ""}
              onBlur={formik?.handleBlur("alias_details")}
              onChange={(e) =>
                formik.setFieldValue("alias_details", e.target.value)
              }
            ></textarea>
            {formik.touched.alias_details && formik.errors.alias_details && (
              <span className="error-text">{formik.errors.alias_details}</span>
            )}
          </div>
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            onClick={formik.handleSubmit}
            disabled={isCustomOfferCreate}
          >
            {!isEdit
              ? isCustomOfferCreate
                ? "Creating..."
                : "Create"
              : isCustomOfferCreate
              ? "Updating..."
              : "Update"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateOffer;
