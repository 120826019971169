import React from "react";
import useVideoUploadForm from "./useVideoUploadForm";
import {
  Button,
  IconText,
  Input,
} from "@wac-ui-dashboard/wac_component_library";

const VideoUploadForm = ({ handleClose }) => {
  const {
    formik,
    videoName,
    videoPreview,
    isLoadingVideo,
    isUploading,
    getFieldError,
    handleVideoChange,
  } = useVideoUploadForm(handleClose);
  return (
    <>
      <div className="input-wrap pro-mb-4 ">
        <Input
          label={"Caption"}
          type="text"
          id="caption"
          name="caption"
          className={`pro-input lg ${getFieldError("caption") && " error"}`}
          {...formik.getFieldProps("caption")}
          error={getFieldError("caption")}
          errorMessage={getFieldError("caption")}
        />
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Flash Screen Video
        </label>
        <div className="input-drag">
          <input
            type="file"
            accept=".mp4, .mov, .avi"
            className={`pro-input ${
              formik.errors.video && formik.touched.video && " error"
            }`}
            id="video"
            onBlur={formik.handleBlur("video")}
            onChange={(e) => handleVideoChange(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                isLoadingVideo
                  ? "Uploading..."
                  : videoName !== ""
                  ? videoName
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.video && formik.errors.video && (
            <span className="error-text">{formik.errors.video}</span>
          )}
        </div>
        <div className={`col-2 pro-my-2`}>
          <div className={``}>
            {videoPreview && (
              <video width={320} height={240} controls>
                <source src={videoPreview} type="video/mp4" />
              </video>
            )}
          </div>
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {isUploading ? "Uploading..." : "Upload"}
        </Button>
      </div>
    </>
  );
};

export default VideoUploadForm;
