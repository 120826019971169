import React from "react";
import InputShimmer from "../InputShimmer";

const ProductEditShimmer = () => {
  return (
    <div className="pro-w-100">
      <div className="row">
        <div className="col-6">
          {[...Array(15)].map((_, i) => (
            <InputShimmer key={i} />
          ))}
        </div>
        <div className="col-6">
          {[...Array(15)].map((_, i) => (
            <InputShimmer key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductEditShimmer;
