import Style from "./positionCard.module.scss";

const PositionCard = ({
  isActive,
  position,
  optionBtn,
  cardIndex,
  isCheckbox,
  setCardActive,
  componentData,
  handleAddClick,
  enableMoveButton,
  handleDeleteClick,
  enableDeleteButton,
  // showToolTip,
}) => {
  return (
    // ${Style.active} active class
    <div
      className={`${Style.root} ${isActive ? Style.active : ""} pro-rounded-1`}
      onClick={() => setCardActive(cardIndex)}
    >
      <div className="pro-d-flex pro-justify-between pro-items-center pro-gap-2">
        <p className="pro-mb-0 pro-fw-medium ">{componentData?.title}</p>
        {!isCheckbox && optionBtn ? (
          <button className={`pro-btn ${Style.option_btn}`}>
            {/* <span className="material-symbols-outlined"> more_horiz </span>
            {showToolTip && (
              <span className={`${Style.tooltip} pro-d-flex pro-flex-column`}> */}
            {enableMoveButton && (
              <span
                className={`${Style.tooltip_btn} pro-d-flex pro-justify-between`}
                // onClick={() => addComponentToHomePage(componentData)}
                onClick={() => handleAddClick(componentData, cardIndex)}
              >
                {/* <span> Move </span> */}
                <span className="material-symbols-outlined">add</span>
              </span>
            )}
            {enableDeleteButton && (
              <span
                className={`${Style.tooltip_btn} pro-d-flex pro-justify-between`}
                onClick={() => handleDeleteClick(cardIndex)}
              >
                {/* <span> Delete </span> */}
                <span className="material-symbols-outlined"> delete </span>
              </span>
            )}
            {/* <span
                  className={`${Style.tooltip_btn} ${Style.disabled} pro-d-flex pro-justify-between`}
                >
                  <span> Duplicate </span>
                  <span className="material-symbols-outlined">
                    {" "}
                    tab_duplicate{" "}
                  </span>
                </span> */}
            {/* </span> */}
            {/* )} */}
          </button>
        ) : isCheckbox ? (
          <div className="form-check form-switch pro-mb-0">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={true}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      {position && (
        <p
          className={`pro-mb-0 pro-fw-medium pro-font-xs pro-mt-2 ${Style.positionText}`}
        >
          {position}
        </p>
      )}
    </div>
  );
};

export default PositionCard;
