import React from "react";
import { TabHeading } from "../../../components/Global/TabHeading";
import Style from "../../Products/Product/ProductDetailView/compareTab.module.scss";
import useActivityLog from "./useActivityLog";
import Activity from "./Activity";

const ActivityLog = () => {
  const data = [
    { name: "User", id: 1 },
    { name: "Staff", id: 2 },
  ];
  const { activeId, handleClick } = useActivityLog();

  return (
    <div className={`pro-w-100`}>
      <div className={`pro-ms-4 pro-d-flex pro-flex-column`}>
        <TabHeading
          data={data}
          uniqueId={"id"}
          activeId={activeId}
          handleClick={handleClick}
        />
        <Activity />
      </div>
    </div>
  );
};

export default ActivityLog;
