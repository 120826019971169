import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  useGetFaqListDataQuery,
  useUpdateFaqTableHeadDataMutation,
  useUpdateFaqStatusMutation,
  admin,
} from "../../../store/queries/Admin";
import {
  getFaqEditData,
  updateConfig,
} from "../../../store/slices/Admin/Faq/faqSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { deleteFaq } from "./api";
import { current } from "@reduxjs/toolkit";

const useFaq = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const faqState = useSelector((state) => state.faq);
  const { selectedId } = useSelector((state) => state.faq);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: faqList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetFaqListDataQuery({
    page_size: faqState?.currentPageSize,
    page: faqState?.currentPage,
    sort: faqState?.sortBy,
    sort_order: faqState?.sortOrder,
    search: faqState?.search,
  });
  const [updateFaqFields] = useUpdateFaqTableHeadDataMutation();
  const [updateFaqStatus] = useUpdateFaqStatusMutation();
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (faqState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = faqState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleToggle = (e, data) => {
    const newStatus = data.status === "Active" ? false : true;
    let body = {
      faq_id: data?._id,
      status: newStatus ? 1 : 2,
    };
    updateFaqStatus(body);
    dispatch(
      admin.util.updateQueryData(
        "getFaqListData",
        {
          page_size: faqState?.currentPageSize,
          page: faqState?.currentPage,
          sort: faqState?.sortBy,
          sort_order: faqState?.sortOrder,
          search: faqState?.search,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data.result?.map((res) =>
            res._id === data?._id
              ? {
                  ...res,
                  status: data.status === "Active" ? "Deactivated" : "Active",
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              result: newResult,
            },
          };
          return currentCache;
        }
      )
    );
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (field, data) => {
        const isChecked = data?.status === "Active";
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={isChecked}
              onChange={(e) => handleToggle(e, data)}
            />
          </div>
        );
      },
      alias_question: (field, data) => {
        return <p className="pro-rtl">{data?.alias_question}</p>;
      },
      alias_answer: (field, data) => (
        <p className="pro-rtl">{data?.alias_answer}</p>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getFaqEditData(e?.[0]));
  };
  const handleDelete = async () => {
    const response = await deleteFaq(selectedId);
    if (response && response.status === 200) {
      toast.success(`Faq deleted successfully.`);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };
  useEffect(() => {
    if (faqState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [faqState.clearSelection]);

  return {
    faqList,
    faqState,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    paginationOptions,
    handleEditColumnsClick,
    handleCreateClick,
    handleDeleteModal,
    handleClearClick,
    handlePagination,
    handleEditAction,
    updateFaqFields,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useFaq;
