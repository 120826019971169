import { createApi } from "@reduxjs/toolkit/query/react";
import { getAdminAxiosInstance } from "../../../api/Admin";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAdminAxiosInstance();

    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("task_type")}&${getParams(
          "user_type_sort"
        )}&${getParams("sort")}&sort=${params?.sort_order || "desc"}&per_page=${
          params?.page_size || "10"
        }&page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const dashboard = createApi({
  reducerPath: "dashboardApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Dashboard"],
  endpoints: (builder) => ({
    getDashboardUserCountData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/dashboard/total-users`,
      }),
      keepUnusedDataFor: 1,
      providesTags: ["Dashboard"],
    }),
    getDashboardOrderCountData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/dashboard/total-orders`,
      }),
      keepUnusedDataFor: 1,
      providesTags: ["Dashboard"],
    }),
    // getDashboardProductCountData: builder.query({
    //   query: (body) => ({
    //     method: "post",
    //     body,
    //     endpoint: `/dashboard/total-products`,
    //   }),
    //   keepUnusedDataFor: 1,
    //   providesTags: ["Dashboard"],
    // }),
    getStatusData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/dashboard/progress-graph`,
      }),
      keepUnusedDataFor: 1,
      providesTags: ["Dashboard"],
    }),
  }),
});

export const {
  useGetDashboardUserCountDataQuery,
  useGetDashboardOrderCountDataQuery,
  // useGetDashboardProductCountDataQuery,
  useGetStatusDataQuery,
} = dashboard;
