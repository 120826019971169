import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  shoeEditModal: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  editPrivacyPolicyData: {},
  //home page
  editHomePageData: {},
  componentIndex: "",
  contentId: "",
  characters: [],
  selectedComponent: "",
  selectedComponentIndex: "",
  selectedComponentName: "",
  selectedSlug: "",
};

export const getPrivacyPolicyEditData = createAsyncThunk(
  "/staff/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(
        `/app-settings/edit?app_settings_id=${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cmsSlice = createSlice({
  name: "cms",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPrivacyPolicyEditData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPrivacyPolicyEditData.fulfilled, (state, action) => {
        state.editPrivacyPolicyData = action.payload?.data;
      })
      .addCase(getPrivacyPolicyEditData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = cmsSlice.actions;
export default cmsSlice.reducer;
