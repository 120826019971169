import { useFormik } from "formik";
import * as Yup from "yup";
import useValidations from "../../../../utils/hooks/useValidations";
import { useSelector } from "react-redux";
import countryData from "../../../../utils/components/countryCode";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Sub-admin/subAdminSlice";
import { createSubAdmin, getProfileImage } from "./api";
import { toast } from "react-toastify";
import { useGetSubAdminBasicDataQuery } from "../../../../store/queries/Admin";
import { useGetUserDetailsDataQuery } from "../../../../store/queries/Global";

const useSubAdmin = (handleCreateClick, refetch) => {
  const { emailRegExp, validPasswords } = useValidations();
  const [showNewPassword, setNewShowPassword] = useState(true);
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isStoreAvailable, setIsStoreAvailable] = useState(false);
  // const [showReEnterPassword, setShowReEnterPassword] = useState(true);
  const dispatch = useDispatch();
  const { data: subAdminBasicData = {} } = useGetSubAdminBasicDataQuery();
  const { data: userData = {} } = useGetUserDetailsDataQuery();
  const { isEdit, imagePreview, editAdminData, imageName, isImageUploading } =
    useSelector((state) => state.subAdmin);
  useEffect(() => {
    if (
      userData?.data?.hasOwnProperty(`customer_key`) &&
      userData?.data?.customer_key !== "" &&
      userData?.data?.customer_key !== null
    ) {
      formik?.setFieldValue("customer_key", userData?.data?.customer_key);
      formik?.setFieldValue("is_customer_key", 1);
      setIsStoreAvailable(true);
    }
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (isEdit && editAdminData !== "") {
      formik?.setFieldValue("first_name", editAdminData?.first_name ?? "");
      formik?.setFieldValue("last_name", editAdminData?.last_name ?? "");
      formik?.setFieldValue(
        "personal_number",
        editAdminData?.personal_number ?? ""
      );
      formik?.setFieldValue(
        "contact_number",
        editAdminData?.contact_number ?? ""
      );
      formik?.setFieldValue("email", editAdminData?.email ?? "");
      formik?.setFieldValue("role_id", editAdminData?.role_id ?? "");
      formik?.setFieldValue("dialcode", editAdminData?.dialcode ?? "");
      formik?.setFieldValue(
        "password_change",
        editAdminData?.password_change ?? 2
      );
      formik?.setFieldValue(
        "password_confirmation",
        editAdminData?.password_confirmation ?? ""
      );
      formik?.setFieldValue("status", editAdminData?.status ?? 2);
      formik?.setFieldValue("customer_key", editAdminData?.customer_key ?? "");
      formik?.setFieldValue(
        "is_customer_key",
        editAdminData?.is_customer_key ?? 2
      );
      formik?.setFieldValue("image", editAdminData?.image ?? "");
    }
    dispatch(
      updateConfig((state) => {
        state.imagePreview = editAdminData?.image_url ?? "";
        state.imageName = editAdminData?.image?.split("/").pop() ?? "";
      })
    );
    //eslint-disable-next-line
  }, [isEdit, editAdminData]);

  const validation = Yup.object({
    first_name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("Enter First Name")
      .max(50, "Enter valid Name"),
    last_name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("Enter Last Name")
      .max(50, "Enter valid Name"),
    dialcode: Yup.string().required("Select Country Code"),
    personal_number: Yup.string()
      .required("Enter Mobile Number")
      .min(7, "Enter Valid Number")
      .max(14, "Enter Valid Number")
      // eslint-disable-next-line no-useless-escape
      .matches(/^[0-9 \-]+$/, "Enter Valid Number"),
    contact_number: Yup.string()
      .required("Enter Contact Number")
      .min(7, "Enter Valid Number")
      .max(14, "Enter Valid Number")
      // eslint-disable-next-line no-useless-escape
      .matches(/^[0-9 \-]+$/, "Enter Valid Number")
      .notOneOf([Yup.ref("personal_number")], "Both numbers cannot be same"),
    email: Yup.string()
      .required("Enter  Email ID")
      .matches(emailRegExp, "Enter Valid Email ID"),
    role_id: Yup.string().required("Select Role"),
    password:
      (!isEdit || isPasswordChange) &&
      Yup.string()
        .required("Enter Password")
        .min(
          8,
          "Password must be 8 characters or more and include uppercase, numbers, and symbols."
        )
        .test(
          "Password validation",
          "*Password must be 8 characters or more and include uppercase, numbers, and symbols.",
          function (passwordValue) {
            const validationResults = validPasswords(passwordValue);
            if (
              validationResults?.uppercasePassword &&
              validationResults?.lowercasePassword &&
              validationResults?.digitsPassword &&
              validationResults?.charsPassword
            ) {
              return true;
            }
          }
        ),
    password_confirmation:
      editAdminData?.password_change === 1 &&
      Yup.string()
        .required("Enter Password")
        .min(
          8,
          "Password must be 8 characters or more and include uppercase, numbers, and symbols."
        )
        .test(
          "Password validation",
          "*Password must be 8 characters or more and include uppercase, numbers, and symbols.",
          function (passwordValue) {
            const validationResults = validPasswords(passwordValue);
            if (
              validationResults?.uppercasePassword &&
              validationResults?.lowercasePassword &&
              validationResults?.digitsPassword &&
              validationResults?.charsPassword
            ) {
              return true;
            }
          }
        ),
    password_change: editAdminData?.password_change === 1 && Yup.string(),
    image: Yup.string().required("Upload Image"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      dialcode: "+965",
      personal_number: "",
      contact_number: "",
      email: "",
      role_id: "",
      password_change: 2,
      password: "",
      password_confirmation: "",
      status: 2,
      is_customer_key: 2,
      customer_key: "",
      image: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        first_name: values?.first_name,
        last_name: values?.last_name,
        dialcode: values?.dialcode,
        personal_number: values?.personal_number,
        contact_number: values?.contact_number,
        email: values?.email,
        role_id: values?.role_id,
        password_change: values?.password_change ?? "",
        password: values?.password,
        password_confirmation: values?.password_confirmation ?? "",
        status: values?.status,
        customer_key: values?.customer_key ?? "",
        is_customer_key: values?.is_customer_key ?? 2,
        image: values?.image,
      };
      setIsLoading(true);
      let newObj = Object.entries(data);
      let subData = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      if (!isEdit) {
        createSubAdmin(subData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Admin created successfully`);
            handleCreateClick();
            refetch();
            setIsLoading(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (response?.status_code === 422 && response?.errors) {
            Object.keys(response?.errors).forEach((field) => {
              formik.setFieldError(field, response?.errors[field]);
            });
            setIsLoading(false);
          } else {
            toast.error(`Something went wrong!`);
            setIsLoading(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            handleCreateClick();
          }
        });
      } else {
        let updateData = { user_id: editAdminData?._id, ...subData };
        createSubAdmin(updateData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Admin updated successfully`);
            handleCreateClick();
            refetch();
            setIsLoading(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else if (response?.status_code === 422 && response?.errors) {
            Object.keys(response?.errors).forEach((field) => {
              formik.setFieldError(field, response?.errors[field]);
            });
            setIsLoading(false);
          } else {
            toast.error(`Something went wrong!`);
            handleCreateClick();
            setIsLoading(false);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          }
        });
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const countryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );
  const handleImageChange = async (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      // Check if the selected file is an image
      if (
        !["image/jpeg", "image/png", "image/jpg"].includes(selectedLogo.type)
      ) {
        formik.setFieldError("image", "Only image files are allowed");
        return;
      }
      const formData = new FormData();
      formData.append("image", selectedLogo);
      dispatch(
        updateConfig((state) => {
          state.isImageUploading = true;
        })
      );
      try {
        const response = await getProfileImage(formData);
        if (response?.data?.status_code === 200) {
          formik.setFieldValue("image", response?.data?.data?.image_path);
        } else if (response?.status_code === 422 && response?.errors) {
          Object.keys(response?.errors).forEach((field) => {
            formik.setFieldError(field, response?.errors[field]);
          });
        } else {
          toast.error(`Unable to add image, Please try again.`);
        }
      } catch (error) {
        console.error(`Unable to add image:`, error);
      } finally {
        dispatch(
          updateConfig((state) => {
            state.isImageUploading = false;
          })
        );
      }
      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
            state.imageName = selectedLogo?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleShowNewPassword = (e) => {
    e.preventDefault();

    setNewShowPassword(!showNewPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };
  const handleAllocateStore = (e) => {
    formik.setFieldValue(`is_customer_key`, e?.target?.checked ? 1 : 2);
    if (!e?.target?.checked) {
      formik.setFieldValue(`customer_key`, "");
    }
  };
  let countryObj = countryCodeOptions?.filter(
    (item) => item?.value === formik?.values?.dialcode
  );
  let roleObj = subAdminBasicData?.data?.roles?.filter(
    (item) => item?._id === formik?.values?.role_id
  );
  let storeObj = subAdminBasicData?.data?.stores?.filter(
    (item) => item?.customer_key === formik?.values?.customer_key
  );

  return {
    formik,
    isEdit,
    roleObj,
    storeObj,
    isLoading,
    countryObj,
    countryCodeOptions,
    imagePreview,
    imageName,
    showNewPassword,
    isStoreAvailable,
    isImageUploading,
    basicData: subAdminBasicData?.data?.roles,
    storeList: subAdminBasicData?.data?.stores,
    closeModal,
    getFieldError,
    handleImageChange,
    handleAllocateStore,
    setIsPasswordChange,
    handleShowNewPassword,
  };
};

export default useSubAdmin;
