import { useGetStaffBasicDataQuery } from "../../../../store/queries/Staff";

const useCopyStore = ({
  setCopyConfirm,
  setCopyClicked,
  setStoreNames,
  formik,
}) => {
  const { data: storeData = {} } = useGetStaffBasicDataQuery();

  const handleClickCopy = () => {
    setCopyConfirm(true);
    setCopyClicked(false);
  };

  const handleChangeStore1 = (value) => {
    formik.setFieldValue("store_from", value?.customer_key);
    setStoreNames((prevState) => ({
      ...prevState,
      store1: value?.name,
    }));
  };

  const handleChangeStore2 = (value) => {
    formik.setFieldValue("store_to", value?.customer_key);
    setStoreNames((prevState) => ({
      ...prevState,
      store2: value?.name,
    }));
  };

  return { storeData, handleChangeStore1, handleChangeStore2, handleClickCopy };
};

export default useCopyStore;
