import {
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useTransactions from "./useTransactions";
import EmptyData from "../../../Global/EmptyData";
import OrderColumn from "../../../Global/OrderColumn";

const Transactions = () => {
  const {
    isLoading,
    isFetching,
    showEditModal,
    transactionList,
    transactionState,
    paginationOptions,
    updateTransactionsFields,
    handleEditColumnsClick,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useTransactions();

  return (
    <>
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={transactionState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: transactionState?.search }}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
        />
        {transactionList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={transactionList?.data?.result || []}
            uniqueID={"id"}
            fields={transactionList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            clear={transactionState?.clearSelection}
            perpage={transactionState?.currentPageSize}
            assignable={false}
            deletable={false}
            editable={false}
            showCheckBox={false}
          />
        )}
        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={transactionList?.data?.fields || []}
              moduleId={transactionList?.data?.module_id}
              updateData={updateTransactionsFields}
            />
          </div>
        </ModalLayout>

        {transactionList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={transactionState?.currentPage}
            totalPageCount={Math.ceil(
              transactionList.data.total_count /
                transactionState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === transactionState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>
    </>
  );
};

export default Transactions;
