import style from "./dashboardCardShimmer.module.scss";
const DashboardCardShimmer = () => {
  return (
    <div className="pro-w-100">
      <div className={`pro-p-5 pro-py-4 ${style.shimmer_container}`}>
        <div className="pro-d-flex pro-justify-between pro-items-start">

          <div className={`pro-d-flex flex-fill pro-py-1 pro-flex-column pro-items-start ${style.root_item}`}>
            <div className="pro-pb-5 pro-pt-1 pro-ps-6 pro-pe-2 shimmer"></div>
            <div className="pro-py-1 pro-pt-2 pro-px-6 shimmer pro-mt-4 pro-mb-1"></div>
            <div className="pro-mt-5 pro-py-3 pro-px-6 shimmer pro-rounded-3"></div>
          </div>

          <div className={`pro-d-flex flex-fill pro-py-1 pro-flex-column pro-items-start ${style.root_item}`}>
            <div className="pro-pb-5 pro-pt-1 pro-ps-6 pro-pe-2 shimmer"></div>
            <div className="pro-py-1 pro-pt-2 pro-px-6 shimmer pro-mt-4 pro-mb-1"></div>
            <div className="pro-mt-5 pro-py-3 pro-px-6 shimmer pro-rounded-3"></div>
          </div>

          <div className={`pro-d-flex flex-fill pro-py-1 pro-flex-column pro-items-start ${style.root_item}`}>
            <div className="pro-pb-5 pro-pt-1 pro-ps-6 pro-pe-2 shimmer"></div>
            <div className="pro-py-1 pro-pt-2 pro-px-6 shimmer pro-mt-4 pro-mb-1"></div>
            <div className="pro-mt-5 pro-py-3 pro-px-6 shimmer pro-rounded-3"></div>
          </div>

          <div className={`pro-d-flex flex-fill pro-py-1 pro-flex-column pro-items-start ${style.root_item}`}>
            <div className="pro-pb-5 pro-pt-1 pro-ps-6 pro-pe-2 shimmer"></div>
            <div className="pro-py-1 pro-pt-2 pro-px-6 shimmer pro-mt-4 pro-mb-1"></div>
            <div className="pro-mt-5 pro-py-3 pro-px-6 shimmer pro-rounded-3"></div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default DashboardCardShimmer;
