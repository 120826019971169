import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { updateConfig } from "../../../../store/slices/User/UserDetails/storeListingSlice";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import {
  useGetStoreListingDataQuery,
  useUpdateStoreListTableHeadDataMutation,
} from "../../../../store/queries/User";
import { IconText } from "@wac-ui-dashboard/wac_component_library";

const useStoreList = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const activeProfile = user_id ?? sessionStorage.getItem("active");
  const storeListingState = useSelector((state) => state.storeListing);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: storeListingList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetStoreListingDataQuery({
    user_id: activeProfile,
    page_size: storeListingState?.currentPageSize,
    page: storeListingState?.currentPage,
    sort: storeListingState?.sortBy,
    sort_order: storeListingState?.sortOrder,
    search: storeListingState?.search,
  });
  const [updateStoreListingFields] = useUpdateStoreListTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (storeListingState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            storeListingState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      image_url: (field, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image_url)}
            />
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };
  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    imageData,
    showImageModal,
    showEditModal,
    storeListingList,
    paginationOptions,
    storeListingState,
    handleEditColumnsClick,
    updateStoreListingFields,
    handlePagination,
    handleClearClick,
    handlePageSize,
    closeImageModal,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useStoreList;
