const useProductVariants = (variantsKey) => {
  const checkMissMatchKey = (fieldName, index) => {
    return Object.keys(variantsKey?.[index] ?? {})?.includes(fieldName);
  };

  return {
    checkMissMatchKey,
  };
};

export default useProductVariants;
