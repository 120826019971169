import { getAdminAxiosInstance } from "../../../../api/Admin";

export const getProfileImage = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/admin/upload-image`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const createSubAdmin = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/admin/store`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const deleteSubadmin = async (userid) => {
  const api = await getAdminAxiosInstance();

  try {
    const response = await api.get(`/admin/destroy?user_id=${userid}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};