import { getAdminAxiosInstance } from "../../../api/Admin";

export const profileUpdate = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`admin/profile-update`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
