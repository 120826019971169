import { useSelector, useDispatch } from "react-redux";
import {
  useGetOrdersListDataQuery,
  useUpdateOrdersTableHeadDataMutation,
} from "../../../store/queries/Admin";
import { updateConfig } from "../../../store/slices/Admin/Orders/ordersSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useEffect, useState } from "react";
import { subDays } from "date-fns";
import { getFormatedDate } from "../../../utils/Functions/table";
import { toast } from "react-toastify";
import { getExportedData } from "./api";

const useOrderList = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  let filterOptions = [
    { label: "Daily Orders", value: "day" },
    { label: "Weekly Orders", value: "week" },
    { label: "Monthly Orders", value: "month" },
    { label: "Yearly Orders", value: "year" },
  ];
  const dispatch = useDispatch();
  const [isDateRangeApplied, setIsDateRangeApplied] = useState(false);
  const [isExcelLinkSend, setIsExcelLinkSend] = useState(false);
  const ordersState = useSelector((state) => state.orders);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: ordersList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetOrdersListDataQuery({
    per_page: ordersState?.currentPageSize,
    page: ordersState?.currentPage,
    sort: ordersState?.sortBy,
    sort_order: ordersState?.sortOrder,
    start_date: getFormatedDate(ordersState.startDate),
    end_date: getFormatedDate(ordersState.endDate),
    search: ordersState?.search,
    store_name: ordersState?.store_name,
    payment_mode: ordersState?.payment_mode,
    status: ordersState?.status,
  });

  const [updateOrdersFields] = useUpdateOrdersTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  useEffect(() => {
    if (ordersList && ordersList?.data?.total_count) {
      dispatch(
        updateConfig((state) => {
          state.totalOrderCount = ordersList?.data?.total_count ?? "0";
          state.totalAmount = ordersList?.data?.total_amount ?? "0";
          state.totalFormattedAmount =
            ordersList?.data?.formated_total_amount ?? "0";
          state.totalFormattedRedeemedAmount =
            ordersList?.data?.formated_redeemed_amount ?? "0";
          state.totalFormattedPurchasedAmount =
            ordersList?.data?.formated_purchased_amount ?? "0";
          state.totalRedeemedAmount = ordersList?.data?.redeemed_amount ?? "0";
          state.totalPurchasedAmount =
            ordersList?.data?.purchased_amount ?? "0";
        })
      );
    }
    //eslint-disable-next-line
  }, [ordersList?.data?.total_count, ordersList?.data?.total_amount]);

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30);
        state.endDate = new Date();
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
    setIsDateRangeApplied(false);
  };

  const handleSort = (label) => {
    if (ordersState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = ordersState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      posist_status: (field, data) =>
        data?.posist_status === "Preparing" ? (
          <span className={"pro-badge badge-grey-outline"}>{"Preparing"}</span>
        ) : data?.posist_status === "Pending" ? (
          <span className={"pro-badge badge-warning-outline"}>{"Pending"}</span>
        ) : data?.posist_status === "Delivered" ? (
          <span className={"pro-badge badge-success-outline"}>
            {"Delivered"}
          </span>
        ) : data?.posist_status === "Food prepared" ? (
          <span className={"pro-badge badge-primary-outline"}>
            {"Food prepared"}
          </span>
        ) : data?.posist_status === "Failed" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Failed"}</span>
        ) : data?.posist_status === "Waiting for pickup" ? (
          <span className={"pro-badge badge-waiting-outline"}>
            {"Waiting for pickup"}
          </span>
        ) : data?.posist_status === "Processing" ? (
          <span className={"pro-badge badge-processing-outline"}>
            {"Processing"}
          </span>
        ) : (
          <span className={"pro-badge badge-grey-outline"}>
            {data?.posist_status}
          </span>
        ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const [date, setDate] = useState({
    startDate: ordersState.startDate,
    endDate: ordersState.endDate,
    key: "selection",
  });
  const handleDateChange = (range) => {
    setIsDateRangeApplied(true);
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    setIsDateRangeApplied(true);
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const handleExcelDownload = () => {
    dispatch(
      updateConfig((state) => {
        state.excelSendModal = !state.excelSendModal;
      })
    );
  };

  const modalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.excelSendModal = false;
      })
    );
  };
  const sendExcelData = async (value) => {
    setIsExcelLinkSend(true);
    let params = {
      start_date: getFormatedDate(ordersState.startDate),
      end_date: getFormatedDate(ordersState.endDate),
      sort_order: ordersState?.sortOrder,
      sort: ordersState?.sortBy,
      search: ordersState?.search,
      store_name: ordersState?.store_name ?? "",
      payment_mode: ordersState?.payment_mode ?? "",
      status: ordersState?.status ?? "",
      email: value?.email ?? "",
      type: value?.type ?? "",
    };
    let response = await getExportedData(params);
    try {
      if (response?.status === 200) {
        toast.success(`Excel download link send successfully`);
      } else {
        toast.error(`Failed to send download link,Please try again`);
      }
    } catch (error) {
      console.error("Failed to send link:", error);
      toast.error(`Failed to send download link`);
    } finally {
      modalClose();
      setIsExcelLinkSend(false);
    }
  };
  const handleFilterAction = () => {
    dispatch(
      updateConfig((state) => {
        state.filterShow = !state.filterShow;
      })
    );
  };

  const headerLabel = {
    count: "Order Count",
    amount: "Amount Received",
    redeemedAmount: "Redeemed Amount",
    purchasedAmount: "Purchased Amount",
  };

  const HeaderFormattedValue = {
    count: ordersState.totalOrderCount,
    amount: ordersState.totalFormattedAmount,
    redeemedAmount: ordersState?.totalFormattedRedeemedAmount,
    purchasedAmount: ordersState?.totalFormattedPurchasedAmount,
  };

  const HeaderValue = {
    count: ordersState.totalOrderCount,
    amount: ordersState.totalAmount,
    redeemedAmount: ordersState?.totalRedeemedAmount,
    purchasedAmount: ordersState?.totalPurchasedAmount,
  };

  const headerDetails = Object.keys(headerLabel).map((key) => {
    return {
      label: headerLabel[key],
      value:
        typeof HeaderFormattedValue[key] === "string"
          ? HeaderFormattedValue?.[key]
          : typeof HeaderFormattedValue[key] === "number"
          ? HeaderFormattedValue?.[key]
          : HeaderFormattedValue?.[key]?.name,
      totalValue:
        typeof HeaderValue[key] === "string"
          ? HeaderValue?.[key]
          : typeof HeaderValue[key] === "number"
          ? HeaderValue?.[key]
          : HeaderValue?.[key]?.name,
    };
  });

  const handleOrderCardChange = (value) => {
    dispatch(
      updateConfig((state) => {
        state.selectedOrderFilter = value?.value;
      })
    );
  };

  let cardFilterObj = filterOptions?.filter(
    (item) => item?.value == ordersState?.selectedOrderFilter
  );

  return {
    date,
    isLoading,
    isFetching,
    ordersList,
    tableFields,
    ordersState,
    cardFilterObj,
    showEditModal,
    headerDetails,
    filterOptions,
    isExcelLinkSend,
    paginationOptions,
    isDateRangeApplied,
    handleEditColumnsClick,
    handleDateRangeChange,
    handleOrderCardChange,
    handleExcelDownload,
    handleFilterAction,
    updateOrdersFields,
    handlePagination,
    handleClearClick,
    handleDateChange,
    handlePageSize,
    sendExcelData,
    handleSearch,
    handleSort,
    modalClose,
    refetch,
    getRow,
  };
};

export default useOrderList;
