import React from "react";
import CommonLayout from "../../pages/Layout/CommonLayout";
import { Outlet } from "react-router-dom";
import useCMS from "./useCMS";

const CMS = () => {
  const drawerMenu = useCMS();

  return (
    <CommonLayout drawerMenu={drawerMenu?.drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default CMS;
