import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetCustomNotificationQuery,
  useUpdateCustomNotificationHeadDataMutation,
} from "../../../store/queries/CMS";
import { updateConfig } from "../../../store/slices/CMS/customNotificationSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import {
  deleteNotification,
  resendNotification,
} from "./CreateCustomNotification/api";
import { toast } from "react-toastify";

const useCustomNotification = () => {
  const dispatch = useDispatch();
  const customNotificationState = useSelector(
    (state) => state.customNotification
  );
  const { showEditModal } = useSelector((state) => state.global);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const [udpateCustomNotificationHead] =
    useUpdateCustomNotificationHeadDataMutation();

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const {
    data: customNotificationList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetCustomNotificationQuery({
    page_size: customNotificationState?.currentPageSize,
    page: customNotificationState?.currentPage,
    sort: customNotificationState?.sortBy,
    sort_order: customNotificationState?.sortOrder,
    search: customNotificationState?.search,
  });

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleSort = (label) => {
    if (customNotificationState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            customNotificationState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      image: (field, data) => {
        return (
          data[field] !== "" && (
            <div className="no-wrap">
              <IconText
                icon={
                  <span className="material-symbols-outlined x4">
                    imagesmode
                  </span>
                }
                title={`${"View Photo"}`}
                onClick={() => handleViewImage(data?.image)}
              />
            </div>
          )
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isLoadingImage = false;
        state.isLoadingAliasImage = false;
        state.imageName = "";
        state.aliasImageName = "";
        state.imagePreview = "";
        state.aliasImagePreview = "";
      })
    );
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleResendNotification = (id) => {
    const itemId = id?.[0];
    resendNotification({ notification_id: itemId }).then((res) => {
      if (res?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        refetch();
        toast.success("Notification Resent successfully");
      } else {
        toast.error("Failed to resend the notification");
      }
    });
  };

  const handleDeleteNotification = (id) => {
    const itemId = id?.[0];
    deleteNotification({ notification_id: itemId }).then((res) => {
      if (res?.data?.status_code === 200) {
        toast.success("Notification deleted successfully");
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        refetch();
      } else {
        toast.error("Failed to delete notification");
      }
    });
  };

  return {
    customNotificationList,
    customNotificationState,
    isLoading,
    isFetching,
    showImageModal,
    imageData,
    showEditModal,
    paginationOptions,
    handleDeleteNotification,
    handlePagination,
    handlePageSize,
    udpateCustomNotificationHead,
    refetch,
    closeImageModal,
    handleCreateClick,
    getRow,
    handleResendNotification,
    handleSort,
    handleEditColumnsClick,
    handleClearClick,
    handleSearch,
  };
};

export default useCustomNotification;
