const useProductAddOns = (addOnKey, variantData) => {
  const checkMissMatchKey = (fieldName, index) => {
    return Object.keys(addOnKey?.[index] ?? {})?.includes(fieldName);
  };
  let mappedVariantListOptions = (variantData ?? [])?.map(({ name, id }) => {
    return {
      label: name,
      value: id,
    };
  });

  return {
    checkMissMatchKey,
    mappedVariantListOptions,
  };
};

export default useProductAddOns;
