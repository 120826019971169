import { Button, IconText } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useDeliveryExcelForm from "./useDeliveryExcelForm";
import { Link } from "react-router-dom";

const DeliveryExcelForm = ({ isStickyFooter, refetch, closeModal }) => {
  const { formik, deliveryAreasState, handleFileChange } = useDeliveryExcelForm(
    { refetch, closeModal }
  );
  const samplePdf =
    "https://d2we35i3jy32ke.cloudfront.net/deliveryArea/excel/sample_delivery_area.xlsx";

  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            File
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              className={`pro-input ${
                formik.errors.file && formik.touched.file && " error"
              }`}
              id="file"
              onBlur={formik.handleBlur("file")}
              onChange={(e) => handleFileChange(e)}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  deliveryAreasState?.imageName !== ""
                    ? deliveryAreasState?.imageName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik?.touched?.file && formik?.errors?.file && (
              <span className="error-text">{formik?.errors?.file}</span>
            )}
          </div>
        </div>

        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Link
            className={
              "pro-p-1 pro-gap-1 pro-btn-link pro-items-center pro-me-auto"
            }
            to={samplePdf}
          >
            <span>Sample File </span>
            <span className="material-symbols-outlined x3">download</span>
          </Link>

          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${
              formik?.isSubmitting ? "loading" : ""
            }`}
            type="submit"
            disabled={formik?.isSubmitting === true}
            onClick={formik.handleSubmit}
          >
            {"Upload"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DeliveryExcelForm;
