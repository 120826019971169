import { useFormik } from "formik";
import * as Yup from "yup";
import useValidations from "../../../../utils/hooks/useValidations";
import { useGetFilterBasicDataQuery } from "../../../../store/queries/Admin";

const useSendExcelData = ({
  sendExcelData,
  isWalletTransaction,
  isBestSeller,
  isUserList,
}) => {
  let options = [
    { name: "Users Data", value: 1 },
    { name: "Wallet Balance", value: 2 },
  ];
  const { emailRegExp } = useValidations();
  const { data: orderBasicData = {} } = useGetFilterBasicDataQuery();
  const validation = Yup.object({
    email: Yup.string()
      .trim()
      .required("Enter  Email ID")
      .matches(emailRegExp, "Enter Valid Email ID"),
    type:
      !isWalletTransaction &&
      !isBestSeller &&
      Yup.string().required("Select type"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      type: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      if (values?.email) {
        sendExcelData(values);
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  let typeList = orderBasicData?.data?.order_export?.map((item) => {
    return {
      name: item?.name,
      value: item?.value,
    };
  });
  let typeObj = orderBasicData?.data?.order_export?.filter((item) => {
    //eslint-disable-next-line
    return item?.value == formik?.values?.type;
  });
  let expObj = options?.filter((item) => item?.value === formik?.values?.type);
  return { formik, typeList, typeObj, expObj, options, getFieldError };
};

export default useSendExcelData;
