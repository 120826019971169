import { createApi } from "@reduxjs/toolkit/query/react";
import { getAdminAxiosInstance } from "../../../api/Admin";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAdminAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start_date")}${getParams(
            "end_date"
          )}filter=${params?.filter || "all"}&${getParams("sort")}sort_order=${
            params?.sort_order || "desc"
          }&per_page=${params?.page_size || "20"}&${getParams("search")}page=${
            params?.page || "1"
          }&${getParams("product_id")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}${getParams("start")}${getParams("end")}${getParams(
            "sort"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const product = createApi({
  reducerPath: "productApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "Product",
    "Product Add On",
    "Product Variant",
    "Product Details",
    "Product Basic Data",
  ],
  endpoints: (builder) => ({
    //Product section starts
    getProductListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "/product/index",
      }),
      providesTags: ["Product"],
    }),
    updateProductTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Product"],
    }),
    getProductDetailData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "/product/product-details",
      }),
      providesTags: ["Product Details"],
    }),
    getProductAddOnsListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "/product/product-addons",
      }),
      providesTags: ["Product Add On"],
    }),
    updateProductAddOnTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Product Add On"],
    }),
    getProductVariantListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "/product/product-variants",
      }),
      providesTags: ["Product Variant"],
    }),
    updateProductVariantTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-field/store`,
      }),

      invalidatesTags: ["Product Variant"],
    }),
    getProductImage: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/product/upload-image`,
      }),
    }),
    updateProductStatus: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: "/product/change-status",
      }),
    }),
    getProductBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: "/product/basic-data",
      }),
      providesTags: ["Product Basic Data"],
    }),
    //Product section ends
  }),
});

export const {
  useGetProductBasicDataQuery,
  useGetProductDetailDataQuery,
  useGetProductListDataQuery,
  useGetProductImageMutation,
  useUpdateProductStatusMutation,
  useGetProductAddOnsListDataQuery,
  useGetProductVariantListDataQuery,
  useUpdateProductTableHeadDataMutation,
  useUpdateProductAddOnTableHeadDataMutation,
  useUpdateProductVariantTableHeadDataMutation,
} = product;
