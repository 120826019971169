import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Store/storeSlice";
import { useSelector } from "react-redux";

const useProductDetailView = () => {
  const dispatch = useDispatch();
  const shopData = JSON.parse(localStorage.getItem("shopData"));
  const { activeId } = useSelector((state) => state.store);
  const handleClick = (e) => {
    if (e?.name === "Add Ons") {
      dispatch(updateConfig((state) => (state.activeId = 1)));
    } else {
      dispatch(updateConfig((state) => (state.activeId = 2)));
    }
  };
  return { activeId, customerKey: shopData?.customer_key, handleClick };
};

export default useProductDetailView;
