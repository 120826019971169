import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useDelivery from "./useDelivery";
import Style from "../addStore.module.scss";
const Delivery = ({ formik, getFieldError, checkError, isStoreCreate }) => {
  const {
    time,
    isEdit,
    handleCloseModal,
    handleTimeChange,
    daysOfWeek,
    handleToggle,
    enabledDays,
    // getMinToTime,
    // curbTimeError,
  } = useDelivery(formik);

  return (
    <>
      <div className="form-check form-switch pro-mb-4">
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          role="switch"
          id="delivery"
          name="delivery"
          checked={Boolean(formik.values?.delivery === 1) ?? false}
          onChange={(e) => {
            formik.setFieldValue("delivery", e.target.checked ? 1 : 2);
          }}
        />
        <label htmlFor="delivery" className="pro-check-label">
          Set Delivery Hours
        </label>
        {getFieldError("delivery") && (
          <span className="error">Please select at least one day's hours.</span>
        )}
      </div>

      {formik.values?.delivery === 1 && (
        <>
          {daysOfWeek.map((day) => (
            <div
              key={day}
              className={`pro-d-flex pro-flex-column pro-mb-4 ${Style.time_wrap}`}
            >
              <label className="pro-font-sm pro-mb-1 pro-fw-medium">
                {day}
              </label>
              <div
                className={`pro-d-flex pro-items-center ${Style.time_picker_wrap}`}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={time[day]?.from || ""}
                    onChange={(newValue) =>
                      handleTimeChange(day, "from", newValue)
                    }
                    className="time-picker"
                    disabled={!enabledDays[day] || formik.values.delivery === 0}
                  />

                  <span className="pro-px-3 pro-d-flex pro-items-center">
                    <span className="material-symbols-outlined x4">
                      {" "}
                      remove{" "}
                    </span>
                  </span>

                  <TimePicker
                    value={time[day]?.to || ""}
                    onChange={(newValue) =>
                      handleTimeChange(day, "to", newValue)
                    }
                    className="time-picker"
                    // minTime={getMinToTime(day, time[day]?.from)}
                    disabled={!enabledDays[day] || formik.values.delivery === 0}
                  />
                  <div className="form-check form-switch pro-mb-0 pro-ms-auto">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      id={`${day}-switch`}
                      checked={enabledDays[day] || false}
                      onChange={() => handleToggle(day)}
                    />
                  </div>
                </LocalizationProvider>
              </div>
            </div>
          ))}

          <div>
            <Input
              label={"Delivery Note"}
              type="text"
              id="delivery_note"
              name="delivery_note"
              className={`pro-input lg ${
                getFieldError("delivery_note") && " error"
              }`}
              {...formik.getFieldProps("delivery_note")}
              error={getFieldError("delivery_note")}
              errorMessage={getFieldError("delivery_note")}
            />
          </div>
          <div className="pro-rtl">
            <Input
              label={"(مذكرة التسليم)  Delivery Note"}
              type="text"
              id="alias_delivery_note"
              name="alias_delivery_note"
              className={`pro-input lg ${
                getFieldError("alias_delivery_note") && " error"
              }`}
              {...formik.getFieldProps("alias_delivery_note")}
              error={getFieldError("alias_delivery_note")}
              errorMessage={getFieldError("alias_delivery_note")}
            />
          </div>

          <div>
            <Input
              label={"Delivery Info"}
              type="text"
              id="delivery_info"
              name="delivery_info"
              className={`pro-input lg ${
                getFieldError("delivery_info") && " error"
              }`}
              {...formik.getFieldProps("delivery_info")}
              error={getFieldError("delivery_info")}
              errorMessage={getFieldError("delivery_info")}
            />
          </div>
          <div className="pro-rtl">
            <Input
              label={"(معلومات التسليم) Delivery Info"}
              type="text"
              id="alias_delivery_info"
              name="alias_delivery_info"
              className={`pro-input lg ${
                getFieldError("alias_delivery_info") && " error"
              }`}
              {...formik.getFieldProps("alias_delivery_info")}
              error={getFieldError("alias_delivery_info")}
              errorMessage={getFieldError("alias_delivery_info")}
            />
          </div>
        </>
      )}
      <div className={`col-12 pro-d-flex pro-justify-end pro-pb-5 pro-mt-auto`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={checkError}
          disabled={isStoreCreate}
        >
          {isEdit && !isStoreCreate
            ? "Update"
            : isStoreCreate && isEdit
            ? "Updating..."
            : isStoreCreate && !isEdit
            ? "Creating..."
            : "Create"}
        </Button>
      </div>
    </>
  );
};

export default Delivery;
