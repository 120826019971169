import useCommonLayout from "../../../pages/Layout/CommonLayout/useCommonLayout";
import { useParams } from "react-router-dom";
import { useGetUserDetailDataQuery } from "../../../store/queries/User";

const useUserDetailViewLayout = () => {
  const { userID } = useParams();
  const activeProfile = userID ?? sessionStorage.getItem("active");

  const { navigations, globalState } = useCommonLayout();

  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetUserDetailDataQuery({
    user_id: activeProfile,
  });

  const label = {
    mobile_number: "Mobile Number",
    email: "Email",
    DOB: "DOB",
  };

  const value = {
    mobile_number: `${profileData?.data?.dial_code} ${
      profileData?.data?.mobile ?? "Not specified"
    }`,
    email: `${profileData?.data?.email ?? "Not specified"}`,
    DOB: `${profileData?.data?.dob ?? "Not specified"}`,
  };
  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  return {
    navigations,
    basicDetails,
    globalState,
    activeProfile,
    profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  };
};

export default useUserDetailViewLayout;
