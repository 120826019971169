import React from "react";
import Select from "react-select";
import useChangeRole from "./useChangeRole";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const ChangeRole = ({ refetch, handleDeleteAction, roleListData, roleId }) => {
  const { formik, rolesList, getFieldError } = useChangeRole(
    refetch,
    roleListData,
    roleId,
    handleDeleteAction
  );

  return (
    <div className="row pro-p-4">
      <div className="input-wrap pro-mb-4 col">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Select Role
        </label>
        <Select
          id="new_role_id"
          name="new_role_id"
          placeholder={"Select"}
          className={`pro-input lg  ${
            getFieldError("new_role_id") && " error"
          }`}
          classNamePrefix="pro-input"
          options={rolesList || []}
          value={formik?.values?.new_role_id}
          getOptionLabel={(option) => option?.name}
          getOptionValue={(option) => option?._id}
          onBlur={formik.handleBlur("new_role_id")}
          onChange={(value) =>
            formik?.setFieldValue("new_role_id", value || null)
          }
          isMulti={false}
          menuPlacement="auto"
        />
        {getFieldError("new_role_id") && (
          <span className="error-text">{getFieldError("new_role_id")}</span>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleDeleteAction}
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          {"Submit"}
        </Button>
      </div>
    </div>
  );
};

export default ChangeRole;
