import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { updateConfig } from "../../../../store/slices/Admin/Products/productSlice";
import { useDispatch } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { updateProduct } from "./api";
import { toast } from "react-toastify";

const useEditProduct = (refetch) => {
  const dispatch = useDispatch();
  const [updateAllStore, setUpdateAllStore] = useState(false);
  const [initialData, setInitialData] = useState({
    name: "",
    alias_name: "",
    product_code: "",
    price: "",
    description: "",
    alias_description: "",
    category_id: "",
    variants: [
      {
        name: "",
        alias_name: "",
        price: "",
        type_id: "",
        description: "",
        alias_description: "",
        add_on_image: "",
        image: "",
        image_url: "",
      },
    ],
    image: "",
    product_image_url: "",
    product_image: "",
    isNonVeg: 0,
    status: 2,
    best_seller: 2,
    new_product: 2,
    product_suggestion: 2,
    priority: "",
    add_on: [
      {
        name: "",
        alias_name: "",
        description: "",
        parent_id: "",
        alias_description: "",
        price: "",
        add_on_image: "",
        image: "",
        image_url: "",
      },
    ],
    optional_add_on: [
      {
        name: "",
        alias_name: "",
        description: "",
        parent_id: "",
        alias_description: "",
        price: "",
        add_on_image: "",
        image: "",
        image_url: "",
      },
    ],
  });
  const { editProductData, isEdit, selectedId, isSuccess } = useSelector(
    (state) => state.product
  );

  useEffect(() => {
    if (editProductData !== "" && isEdit && selectedId) {
      setInitialData({
        name: editProductData?.name,
        alias_name: editProductData?.alias_name,
        product_code: editProductData?.product_code,
        price: editProductData?.price,
        description: editProductData?.description,
        alias_description: editProductData?.alias_description,
        category_id: editProductData?.category_id,
        variants: editProductData?.variants,
        image: editProductData?.image,
        product_image_url: editProductData?.product_image_url,
        product_image: editProductData?.product_image,
        isNonVeg: editProductData?.isNonVeg,
        status: editProductData?.status,
        best_seller: editProductData?.best_seller,
        product_suggestion: editProductData?.product_suggestion,
        priority: editProductData?.priority,
        new_product: editProductData?.new_product,
        add_on: editProductData?.add_on,
        optional_add_on: editProductData?.optional_add_on,
      });
      let variantImage = (editProductData?.variants || [])?.map((item) => {
        return {
          image: item?.image ?? "",
          add_on_image: item?.add_on_image ?? "",
        };
      });
      let addOnImage = (editProductData?.add_on || [])?.map((item) => {
        return {
          image: item?.image ?? "",
          add_on_image: item?.add_on_image ?? "",
        };
      });
      let optionalAddOnImage = (editProductData?.optional_add_on || [])?.map(
        (item) => {
          return {
            image: item?.image ?? "",
            add_on_image: item?.add_on_image ?? "",
          };
        }
      );
      dispatch(
        updateConfig((state) => {
          state.imagePreview = editProductData?.image;
          state.imageCustomizedPreview = editProductData?.product_image;
          state.variantImage = variantImage ?? [];
          state.addOnImage = addOnImage ?? [];
          state.optionalAddOnImage = optionalAddOnImage ?? [];
          state.isAddonImageUploading = editProductData?.add_on?.map((_) => {
            return { isImageUploading: false };
          });
          state.isVariantImageUploading = editProductData?.variants?.map(
            (_) => {
              return { isImageUploading: false };
            }
          );
          state.isOptionalAddonImageUploading =
            editProductData?.optional_add_on?.map((_) => {
              return { isImageUploading: false };
            });
        })
      );
    }

    // eslint-disable-next-line
  }, [editProductData, isEdit, selectedId]);

  const validation = Yup.object({
    name: Yup.string().required("Enter Name"),
    // alias_name: Yup.string().required("Enter Arabic Name"),
    // description: Yup.string().required("Enter Description"),
    // alias_description: Yup.string().required("Enter arabic description"),
    // product_code: Yup.string().required("Enter Product code"),
    price: Yup.string().required("Enter Price"),
    category_id: Yup.string().required("Enter Category"),
    variants: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Enter variant name"),
        // alias_name: Yup.string().required("Enter variant arabic name"),
        // description: Yup.string().required("Enter description"),
        // alias_description: Yup.string().required(
        //   "Enter arabic variant description"
        // ),
        // type_id:Yup.string().required('Select type'),
        price: Yup.string().required("Enter Price"),
        // add_on_image: Yup.mixed().required("Choose Caribou Image"),
        // image: Yup.mixed().required("Choose Customized Image"),
      })
    ),
    // image: Yup.mixed().required("Select Caribou Image"),
    // product_image: Yup.mixed().required("Select Customized Image"),
    isNonVeg: Yup.string(),
    status: Yup.string(),
    best_seller: Yup.string(),
    product_suggestion: Yup.string(),
    priority: Yup.string(),
    new_product: Yup.string(),
    add_on: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required("Enter add on name"),
        // alias_name: Yup.string().required("Enter arabic name"),
        // description: Yup.string().required("Enter description"),
        // alias_description: Yup.string().required("Enter arabic description"),
        // price: Yup.string().required("Enter Price"),
        // add_on_image: Yup.mixed().required("Choose Caribou Image"),
        // image: Yup.mixed().required("Choose Customized Image"),
      })
    ),
    optional_add_on: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(`Enter optional add on name`),
      })
    ),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validation,

    onSubmit: async (values) => {
      let data = {
        customer_key: editProductData?.customer_key,
        product_id: editProductData?.product_id,
        update_all_store: updateAllStore,
        ...values,
      };
      dispatch(
        updateConfig((state) => {
          state.isUpdatingProduct = true;
        })
      );

      let response = await updateProduct(data);
      if (response?.data?.status_code === 200) {
        toast.success(`Product updated successfully`);
        refetch();
        dispatch(
          updateConfig((state) => {
            state.isUpdatingProduct = false;
          })
        );
      } else {
        toast.error(`Something went wrong!`);
        dispatch(
          updateConfig((state) => {
            state.isUpdatingProduct = false;
          })
        );
      }
      handleCloseModal();
      dispatch(
        updateConfig((state) => {
          state.clearSelection = true;
          state.isUpdatingProduct = false;
        })
      );
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      let element = document.getElementById(fieldName);
      element.focus();
      element.scrollIntoView({ behavior: "smooth" });
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showProductEditModal = !state.showProductEditModal;
        state.isEdit = false;
        state.isUpdatingProduct = false;
      })
    );
  };
  useEffect(() => {
    if (initialData?.add_on?.length === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.offCanvasEditWidth = true;
        })
      );
    } else {
      dispatch(
        globalUpdateConfig((state) => {
          state.offCanvasEditWidth = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [initialData !== "", initialData?.add_on?.length]);

  return {
    formik,
    isSuccess,
    getFieldError,
    handleCloseModal,
    setUpdateAllStore,
  };
};

export default useEditProduct;
