import { getAdminAxiosInstance } from "../../../api/Admin";

export const getVideoBanner = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/banner/video-upload`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const createVideoBanner = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/banner/video-store`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteVideoBanner = async (id) => {
  const api = await getAdminAxiosInstance();

  try {
    const response = await api.get(`banner/video-delete?banner_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
