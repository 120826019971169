import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useBasic from "./useBasic";

const Basic = ({
  formik,
  getFieldError,
  countryCodeOptions,
  countrySec_Code,
  countryCode,
}) => {
  const {
    isEdit,
    imagePreview,
    imageName,
    isImageUploading,
    handleNext,
    handleCloseModal,
    handleImageChange,
  } = useBasic(formik);
  return (
    <div className="row">
      <Input
        label={"Name"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
      />
      <div className="pro-rtl">
        <Input
          label={"( اسم ) Name "}
          type="text"
          id="alias_name"
          name="alias_name"
          className={`pro-input lg ${getFieldError("alias_name") && " error"}`}
          {...formik.getFieldProps("alias_name")}
          error={getFieldError("alias_name")}
          errorMessage={getFieldError("alias_name")}
        />
      </div>
      <Input
        label={"Address "}
        type="text"
        id="address_type"
        name="address_type"
        className={`pro-input lg ${getFieldError("address_type") && " error"}`}
        {...formik.getFieldProps("address_type")}
        error={getFieldError("address_type")}
        errorMessage={getFieldError("address_type")}
      />
      <Input
        label={"Building Number "}
        type="text"
        id="building_no"
        name="building_no"
        className={`pro-input lg ${getFieldError("building_no") && " error"}`}
        {...formik.getFieldProps("building_no")}
        error={getFieldError("building_no")}
        errorMessage={getFieldError("building_no")}
      />
      <Input
        label={"Addressline 1"}
        type="text"
        id="address_line1"
        name="address_line1"
        className={`pro-input lg ${getFieldError("address_line1") && " error"}`}
        {...formik.getFieldProps("address_line1")}
        error={getFieldError("address_line1")}
        errorMessage={getFieldError("address_line1")}
      />
      <Input
        label={"Addressline 2"}
        type="text"
        id="address_line2"
        name="address_line2"
        className={`pro-input lg ${getFieldError("address_line2") && " error"}`}
        {...formik.getFieldProps("address_line2")}
        error={getFieldError("address_line2")}
        errorMessage={getFieldError("address_line2")}
      />
      <Input
        label={"Addressline 3"}
        type="text"
        id="address_line3"
        name="address_line3"
        className={`pro-input lg ${getFieldError("address_line3") && " error"}`}
        {...formik.getFieldProps("address_line3")}
        error={getFieldError("address_line3")}
        errorMessage={getFieldError("address_line3")}
      />
      <Input
        label={"Pincode"}
        type="text"
        id="pincode"
        name="pincode"
        className={`pro-input lg ${getFieldError("pincode") && " error"}`}
        {...formik.getFieldProps("pincode")}
        error={getFieldError("pincode")}
        errorMessage={getFieldError("pincode")}
      />
      <div className="pro-rtl">
        <Input
          label={"( اسم ) Address  "}
          type="text"
          id="alias_address_type"
          name="alias_address_type"
          className={`pro-input lg ${
            getFieldError("alias_address_type") && " error"
          }`}
          {...formik.getFieldProps("alias_address_type")}
          error={getFieldError("alias_address_type")}
          errorMessage={getFieldError("alias_address_type")}
        />

        <Input
          label={"( لا للبناء )Building no  "}
          type="text"
          id="alias_building_no"
          name="alias_building_no"
          className={`pro-input lg ${
            getFieldError("alias_building_no") && " error"
          }`}
          {...formik.getFieldProps("alias_building_no")}
          error={getFieldError("alias_building_no")}
          errorMessage={getFieldError("alias_building_no")}
        />
        <Input
          label={"( العنوان سطر 1 ) Addressline 1  "}
          type="text"
          id="alias_address_line1"
          name="alias_address_line1"
          className={`pro-input lg ${
            getFieldError("alias_address_line1") && " error"
          }`}
          {...formik.getFieldProps("alias_address_line1")}
          error={getFieldError("alias_address_line1")}
          errorMessage={getFieldError("alias_address_line1")}
        />
        <Input
          label={"( سطر العنوان 2 )Addressline 2  "}
          type="text"
          id="alias_address_line2"
          name="alias_address_line2"
          className={`pro-input lg ${
            getFieldError("alias_address_line2") && " error"
          }`}
          {...formik.getFieldProps("alias_address_line2")}
          error={getFieldError("alias_address_line2")}
          errorMessage={getFieldError("alias_address_line2")}
        />
        <Input
          label={"( سطر العنوان 3 )Addressline 3 "}
          type="text"
          id="alias_address_line3"
          name="alias_address_line3"
          className={`pro-input lg ${
            getFieldError("alias_address_line3") && " error"
          }`}
          {...formik.getFieldProps("alias_address_line3")}
          error={getFieldError("alias_address_line3")}
          errorMessage={getFieldError("alias_address_line3")}
        />
        <Input
          label={" ( الرقم السري ) Pincode"}
          type="text"
          id="alias_pincode"
          name="alias_pincode"
          className={`pro-input lg ${
            getFieldError("alias_pincode") && " error"
          }`}
          {...formik.getFieldProps("alias_pincode")}
          error={getFieldError("alias_pincode")}
          errorMessage={getFieldError("alias_pincode")}
        />
      </div>
      <Input
        label={"About us"}
        type="text"
        id="about_us"
        name="about_us"
        className={`pro-input lg ${getFieldError("about_us") && " error"}`}
        {...formik.getFieldProps("about_us")}
        error={getFieldError("about_us")}
        errorMessage={getFieldError("about_us")}
      />
      <div className="pro-rtl">
        <Input
          label={"( اسم ) About us "}
          type="text"
          id="alias_about_us"
          name="alias_about_us"
          className={`pro-input lg ${
            getFieldError("alias_about_us") && " error"
          }`}
          {...formik.getFieldProps("alias_about_us")}
          error={getFieldError("alias_about_us")}
          errorMessage={getFieldError("alias_about_us")}
        />
      </div>
      <Input
        label={"Store Code"}
        type="text"
        id="store_code"
        name="store_code"
        className={`pro-input lg ${getFieldError("store_code") && " error"}`}
        {...formik.getFieldProps("store_code")}
        error={getFieldError("store_code")}
        errorMessage={getFieldError("store_code")}
      />
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Mobile Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="dial_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={countryCode || ""}
                classNamePrefix="pro-input"
                className="country-select"
                onBlur={formik.handleBlur("dial_code")}
                menuPlacement="auto"
                onChange={(value) =>
                  formik.setFieldValue("dial_code", value?.value ?? "")
                }
              ></Select>
            </div>
            <input
              type="text"
              id="contact_number"
              name="contact_number"
              className={`pro-input lg ${
                formik.errors.contact_number &&
                formik.touched.contact_number &&
                " error"
              }`}
              {...formik.getFieldProps("contact_number")}
            />
          </div>
          {formik.errors.contact_number && formik.touched.contact_number && (
            <>
              <span className="error-text">{formik.errors.contact_number}</span>
            </>
          )}
        </div>
      </div>
      <div className="input-wrap pro-mb-4 ">
        <div className="input-country-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Secondary Mobile Number
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="sec_dial_code"
                options={countryCodeOptions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                value={countrySec_Code || ""}
                classNamePrefix="pro-input"
                className="country-select"
                onBlur={formik.handleBlur("sec_dial_code")}
                menuPlacement="auto"
                onChange={(value) =>
                  formik.setFieldValue("sec_dial_code", value?.value ?? "")
                }
              ></Select>
            </div>
            <input
              type="text"
              id="sec_contact_number"
              name="sec_contact_number"
              className={`pro-input lg ${
                formik.errors.sec_contact_number &&
                formik.touched.sec_contact_number &&
                " error"
              }`}
              {...formik.getFieldProps("sec_contact_number")}
            />
          </div>
          {formik.errors.sec_contact_number &&
            formik.touched.sec_contact_number && (
              <>
                <span className="error-text">
                  {formik.errors.sec_contact_number}
                </span>
              </>
            )}
        </div>
      </div>

      <div className="input-wrap pro-mb-4 ">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Image
        </label>
        <div className="input-drag">
          <input
            type="file"
            accept=".jpeg,.jpg , .png "
            className={`pro-input ${
              formik.errors.image_url && formik.touched.image_url && " error"
            }`}
            id="image_url"
            onBlur={formik.handleBlur("image_url")}
            onChange={(e) => handleImageChange(e, "image_url")}
          />
          <span className="input-drag-box">
            <IconText
              title={
                isImageUploading
                  ? "Uploading..."
                  : imageName !== ""
                  ? imageName
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image_url && formik.errors.image_url && (
            <span className="error-text">{formik.errors.image_url}</span>
          )}
        </div>
        <div className={`col-2 pro-my-2`}>
          <div className={``}>
            <Image
              width={100}
              height={100}
              src={imagePreview}
              alt={`image - 01`}
            />
          </div>
        </div>
      </div>
      <Input
        label={"Latitude"}
        type="text"
        id="location_latitude"
        name="location_latitude"
        className={`pro-input lg ${
          getFieldError("location_latitude") && " error"
        }`}
        {...formik.getFieldProps("location_latitude")}
        error={getFieldError("location_latitude")}
        errorMessage={getFieldError("location_latitude")}
      />
      <Input
        label={"Longitude"}
        type="text"
        id="location_longitude"
        name="location_longitude"
        className={`pro-input lg ${
          getFieldError("location_longitude") && " error"
        }`}
        {...formik.getFieldProps("location_longitude")}
        error={getFieldError("location_longitude")}
        errorMessage={getFieldError("location_longitude")}
      />
      <Input
        label={"Location "}
        type="text"
        id="location_name"
        name="location_name"
        className={`pro-input lg ${getFieldError("location_name") && " error"}`}
        {...formik.getFieldProps("location_name")}
        error={getFieldError("location_name")}
        errorMessage={getFieldError("location_name")}
      />
      <div className="pro-rtl">
        <Input
          label={"( موقع ) Location "}
          type="text"
          id="alias_location_name"
          name="alias_location_name"
          className={`pro-input lg ${
            getFieldError("alias_location_name") && " error"
          }`}
          {...formik.getFieldProps("alias_location_name")}
          error={getFieldError("alias_location_name")}
          errorMessage={getFieldError("alias_location_name")}
        />
      </div>
      <Input
        label={"Customer key "}
        type="text"
        id="customer_key"
        name="customer_key"
        className={`pro-input   lg ${
          getFieldError("customer_key") && " error"
        }`}
        {...formik.getFieldProps("customer_key")}
        error={getFieldError("customer_key")}
        errorMessage={getFieldError("customer_key")}
        disabled={isEdit}
      />
      <Input
        label={"Qubriux Customer Key "}
        type="text"
        id="qubriux_customer_key"
        name="qubriux_customer_key"
        className={`pro-input   lg ${
          getFieldError("qubriux_customer_key") && " error"
        }`}
        {...formik.getFieldProps("qubriux_customer_key")}
        error={getFieldError("qubriux_customer_key")}
        errorMessage={getFieldError("qubriux_customer_key")}
        // disabled={isEdit}
      />
      <Input
        label={"Qubriux Key "}
        type="text"
        id="qubriux_api_key"
        name="qubriux_api_key"
        className={`pro-input   lg ${
          getFieldError("qubriux_api_key") && " error"
        }`}
        {...formik.getFieldProps("qubriux_api_key")}
        error={getFieldError("qubriux_api_key")}
        errorMessage={getFieldError("qubriux_api_key")}
      />

      <div className="pro-check-box pro-py-2">
        <div className="form-check form-switch ">
          <input
            className="form-check-input cursor-pointer"
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            checked={Boolean(formik.values?.status === 1) ?? false}
            onChange={(e) =>
              formik.setFieldValue(`status`, e?.target?.checked ? 1 : 2)
            }
          />
          <label htmlFor="default" className="pro-check-label">
            Status
          </label>
        </div>
      </div>
      <div className={`col-12 pro-d-flex pro-justify-end pro-mt-auto pro-pb-5`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          disabled={isImageUploading}
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default Basic;
