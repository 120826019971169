import React from "react";
import "../../../assets/scss/date-rang-picker.scss";
import { DateRangePicker } from "rsuite";
import { predefinedRanges, predefinedDashboardRanges } from "./predefinedDates";

const CustomDateRangePicker = ({
  defaultDateRange,
  handleDateRangeChange,
  isDashboard,
}) => {
  const customLocale = {
    sunday: "Su",
    monday: "Mo",
    tuesday: "Tu",
    wednesday: "We",
    thursday: "Th",
    friday: "Fr",
    saturday: "Sa",
    ok: "Apply",
    today: "Today",
    yesterday: "Yesterday",
    last7Days: "Last 7 Days",
    thisMonth: "This Month",
    lastMonth: "Last Month",
  };

  return (
    <DateRangePicker
      format="dd-MM-yyyy"
      ranges={isDashboard ? predefinedDashboardRanges : predefinedRanges}
      character=" - "
      style={{ width: "100%" }}
      locale={customLocale}
      placement={`${isDashboard ? "bottomEnd" : "bottomStart"}`}
      value={[defaultDateRange?.startDate, defaultDateRange?.endDate]}
      onChange={handleDateRangeChange}
    />
  );
};

export default CustomDateRangePicker;
