import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoadingImage: false, // Loading state for the main image
  isArabicImageLoading: false, // Loading state for the Arabic image
  showPassword: false,
  bannerData: {},
  imagePreview: "",
  imageName: "",
  arabicImagePreview: "",
  arabicImageName: "",
  editBannerData: {},
  error: "",
  statusData: {},
  isLoading: false,
};

export const getBannerEditData = createAsyncThunk(
  "/admin/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/banner/show?banner_id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBannerEditData.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(getBannerEditData.fulfilled, (state, action) => {
        state.editBannerData = action.payload?.data;
        // state.isLoading = false;
      })
      .addCase(getBannerEditData.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = bannerSlice.actions;
export default bannerSlice.reducer;
