import useCommonLayout from "../../../../pages/Layout/CommonLayout/useCommonLayout";
import { useParams } from "react-router-dom";
import { useGetProductDetailDataQuery } from "../../../../store/queries/Product";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Store/storeSlice";
import { useSelector } from "react-redux";

const useProductDetailLayout = () => {
  const { productID } = useParams();
  const activeProfile = productID ?? sessionStorage.getItem("active");
  const dispatch = useDispatch();
  const storeState = useSelector((state) => state.store);
  const { navigations, globalState } = useCommonLayout();

  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetProductDetailDataQuery({
    customer_key: JSON.parse(localStorage.getItem("shopData"))?.customer_key,
    product_id: activeProfile,
  });
  const hasUpdatePermission = useMemo(() => {
    let permission = profileData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [profileData]);

  const label = {
    offer: "Offer",
    category: "Category",
    mobile: "Digital Punch",
  };
  const value = {
    offer: `${profileData?.data?.data?.name ?? "Not specified"}`,
    category: `${profileData?.data?.data?.category ?? "Not specified"}`,
    mobile: `${profileData?.data?.data?.price ?? "Not specified"}`,
  };
  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const handleEditModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showProductEditModal = !state.showProductEditModal;
        state.is_edit = true;
      })
    );
  };

  return {
    navigations,
    basicDetails,
    globalState,
    activeProfile,
    profileData,
    isFetching,
    isSuccess,
    isError,
    storeState,
    hasUpdatePermission,
    handleEditModal,
    refetch,
  };
};

export default useProductDetailLayout;
