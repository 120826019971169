import { useFormik } from "formik";
import * as Yup from "yup";
import useValidations from "../../../../utils/hooks/useValidations";
import { toast } from "react-toastify";
import { addCustomOffers, updateCustomOffer } from "../api";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/User/CustomOffers/customOfferSlice";
import { useEffect, useState } from "react";

const useCreateOffer = (refetch, closeModal) => {
  const dispatch = useDispatch();
  const [isCustomOfferCreate, setIsCustomOfferCreate] = useState(false);
  const { englishRegex, arabicRegex } = useValidations();
  const { isEdit, isLoading, selectedId, customOfferListEditData } =
    useSelector((state) => state.customOffers);
  useEffect(() => {
    if (customOfferListEditData !== "" && isEdit) {
      formik.setValues({
        offer_name: customOfferListEditData?.offer_name ?? "",
        details: customOfferListEditData?.details ?? "",
        alias_details: customOfferListEditData?.alias_details ?? "",
      });
    }
    //eslint-disable-next-line
  }, [customOfferListEditData?.offer_name, isEdit]);
  const validation = Yup.object({
    offer_name: Yup.string()
      .trim()
      .matches(englishRegex, `Enter valid offer name`)
      .required(`Enter offer name`),
    details: Yup.string()
      .trim()
      .matches(englishRegex, `Enter details in english`)
      .min(5, `Please enter minimum 5 characters`)
      .required(`Enter details`),
    alias_details: Yup.string()
      .trim()
      .matches(arabicRegex, `Enter details in arabic`)
      .min(5, `Please enter minimum 5 characters`)
      .required(`Enter details`),
  });
  const formik = useFormik({
    initialValues: {
      offer_name: "",
      details: "",
      alias_details: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      setIsCustomOfferCreate(true);
      if (!isEdit) {
        addCustomOffers(values).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Custom offer added successfully`);
            setIsCustomOfferCreate(false);
            refetch?.();
            closeModal();
          } else if (response?.data?.status_code === 422 && response?.errors) {
            Object.keys(response?.errors).forEach((field) => {
              formik.setFieldError(field, response?.errors[field]);
            });
            setIsCustomOfferCreate(false);
          } else {
            toast.error(`Something went wrong!`);
            setIsCustomOfferCreate(false);
            closeModal();
          }
        });
      } else {
        let params = {
          offer_id: selectedId,
          ...values,
        };
        updateCustomOffer(params).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Offer updated successfully`);
            setIsCustomOfferCreate(false);
            refetch?.();
            closeModal();
            clearSelectionOfItem();
          } else if (response?.data?.status_code === 422 && response?.errors) {
            Object.keys(response?.errors).forEach((field) => {
              formik.setFieldError(field, response?.errors[field]);
            });
            setIsCustomOfferCreate(false);
            dispatch(
              updateConfig((state) => {
                state.isLoading = false;
              })
            );
          } else {
            toast.error(`Something went wrong!`);
            setIsCustomOfferCreate(false);
            closeModal();
            clearSelectionOfItem();
          }
        });
      }
    },
  });
  const clearSelectionOfItem = () => {
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
        state.isLoading = false;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    } else {
      return "";
    }
  };
  return { formik, isLoading, isEdit, isCustomOfferCreate, getFieldError };
};

export default useCreateOffer;
