import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Banner/bannerSlice";
import { toast } from "react-toastify";
import { getBannerImage, createBanner } from "../api";
import { useGetBannerBasicDataQuery } from "../../../../store/queries/Admin";

const useAddBanner = (handleCreateClick, refetch) => {
  const dispatch = useDispatch();
  const [typeSlug, setTypeSlug] = useState("");
  const { data: basicData = {}, isLoading: basicLoading } =
    useGetBannerBasicDataQuery();
  const {
    isEdit,
    imagePreview,
    imageName,
    arabicImagePreview,
    arabicImageName,
    editBannerData,
  } = useSelector((state) => state.banner);

  const { isLoadingImage, isLoading, isArabicImageLoading } = useSelector(
    (state) => state.banner
  );
  const validation = Yup.object({
    title: Yup.string()
      .trim()
      .required("Enter Banner Title")
      .max(50, "Enter valid First Name"),
    image_url: Yup.string().required("Please upload an image"),
    alias_image_url: Yup.string().required("Please upload an image"),
    type: Yup.object().required("Select type"),
    selected_item:
      basicData?.data?.dropdown_list?.hasOwnProperty(typeSlug) &&
      Yup.object().required("Select item"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      image_url: "",
      alias_image_url: "",
      status: 2,
      type: "",
      selected_item: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        title: values?.title ?? "",
        image_url: values?.image_url ?? "",
        alias_image_url: values?.alias_image_url ?? "",
        status: values?.status ?? 2,
        type: values?.type?.slug ?? "",
        selected_item: values?.selected_item?._id ?? "",
      };
      let newObj = Object.entries(data);
      let subData = newObj
        .filter(
          (item) => item[1] !== undefined && item[1] !== "" && item[1] !== null
        )
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      dispatch(
        updateConfig((state) => {
          state.isLoading = true;
        })
      );

      if (!isEdit) {
        createBanner(subData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Banner created successfully`);
            refetch();
            handleCreateClick();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.isLoading = false;
              })
            );
          } else if (response?.status_code === 422 && response?.errors) {
            Object.keys(response?.errors).forEach((field) => {
              formik.setFieldError(field, response?.errors[field]);
            });
            dispatch(
              updateConfig((state) => {
                state.isLoading = false;
              })
            );
          } else {
            toast.error(`Something went wrong!`);
            handleCreateClick();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.isLoading = false;
              })
            );
          }
        });
      } else {
        let updateBannerData = { banner_id: editBannerData?._id, ...subData };

        createBanner(updateBannerData).then((response) => {
          if (response?.data?.status_code === 200) {
            toast.success(`Banner updated successfully`);
            refetch();
            handleCreateClick();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.isLoading = false;
                state.editBannerData = {};
              })
            );
          } else if (response?.status_code === 422 && response?.errors) {
            Object.keys(response?.errors).forEach((field) => {
              formik.setFieldError(field, response?.errors[field]);
            });
            dispatch(
              updateConfig((state) => {
                state.isLoading = false;
              })
            );
          } else {
            toast.error(`Something went wrong!`);
            handleCreateClick();
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
                state.isLoading = false;
              })
            );
          }
        });
      }
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
        state.editBannerData = {};
      })
    );
  };
  const handleImageChange = async (event, fieldName) => {
    const selectedLogo = event?.target?.files[0];
    // for conditional Loading of the Images
    const isMainImage = fieldName === "image";
    if (selectedLogo) {
      if (
        !["image/jpeg", "image/png", "image/jpg"].includes(selectedLogo.type)
      ) {
        formik.setFieldError(
          `${fieldName === "image" ? "image_url" : "alias_image_url"}`,
          "Only image files are allowed"
        );
        return;
      }
      const formData = new FormData();
      formData.append("image", selectedLogo);
      dispatch(
        updateConfig((state) => {
          isMainImage
            ? (state.isLoadingImage = true)
            : (state.isArabicImageLoading = true);
        })
      );
      const response = await getBannerImage(formData);
      try {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            fieldName === "image" ? "image_url" : "alias_image_url",
            response?.data?.data?.image_path
          );
        } else if (response?.status_code === 422 && response?.errors) {
          Object.keys(response?.errors).forEach((field) => {
            formik.setFieldError(
              `${fieldName === "image" ? "image_url" : "alias_image_url"}`,
              response?.errors[field]
            );
          });
        } else {
          toast.error(`Unable to add image, Please try again.`);
        }
      } catch (error) {
        console.error(`Unable to add image:`, error);
      } finally {
        dispatch(
          updateConfig((state) => {
            isMainImage
              ? (state.isLoadingImage = false)
              : (state.isArabicImageLoading = false);
          })
        );
      }

      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state[
              fieldName === "image" ? "imagePreview" : "arabicImagePreview"
            ] = reader.result;
            state[fieldName === "image" ? "imageName" : "arabicImageName"] =
              selectedLogo?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  useEffect(() => {
    let selectVal = basicData?.data?.types?.find(
      (item) => item?.slug === editBannerData?.type
    );
    let typeVal = basicData?.data?.dropdown_list[editBannerData?.type]?.find(
      (item) => item?._id === editBannerData?.selected_item
    );
    setTypeSlug(editBannerData?.type);
    if (isEdit && editBannerData) {
      formik.setValues({
        title: editBannerData?.title || "",
        status: editBannerData?.status || 2,
        type: selectVal,
        image_url: editBannerData?.image_url,
        alias_image_url: editBannerData?.alias_image_url,
        selected_item: typeVal,
      });
      dispatch(
        updateConfig((state) => {
          state.imagePreview = editBannerData?.image_full_url;
          state.imageName = editBannerData?.image_url?.split("/").pop();
          state.arabicImagePreview = editBannerData?.alias_image_full_url;
          state.arabicImageName = editBannerData?.alias_image_url
            ?.split("/")
            .pop();
        })
      );
    }
    // eslint-disable-next-line
  }, [isEdit, editBannerData, basicLoading]);

  const handleTypeChange = (e) => {
    formik?.setFieldValue("type", e);
    formik?.setFieldValue("selected_item", "");
    setTypeSlug(e?.slug);
  };

  return {
    isEdit,
    formik,
    typeSlug,
    isLoading,
    imageName,
    basicData,
    imagePreview,
    editBannerData,
    isLoadingImage,
    arabicImageName,
    arabicImagePreview,
    isArabicImageLoading,
    handleImageChange,
    handleTypeChange,
    getFieldError,
    closeModal,
  };
};

export default useAddBanner;
