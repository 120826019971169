import React from "react";
import Style from "../editProfile.module.scss";

import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const EditProfile = ({
  handleClose,
  formikEditUserData,
  countryCodeOptions,
  countryObj,
  showNewPassword,
  handleShowNewPassword,
}) => {
  return (
    <>
      <div className={Style.item}>
        <h5>Contact info</h5>
        <div className="row">
          <div className="col-12 ">
            <div className="input-wrap pro-mb-4">
              <label
                htmlFor="first_name"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                First Name
              </label>
              <input
                type="first_name"
                className={`pro-input lg ${
                  formikEditUserData.errors.first_name &&
                  formikEditUserData.touched.first_name &&
                  "error"
                }`}
                id="first_name"
                name="first_name"
                {...formikEditUserData.getFieldProps("first_name")}
              />
              {formikEditUserData.touched.first_name &&
                formikEditUserData.errors.first_name && (
                  <span className="error-text">
                    {formikEditUserData.errors.first_name}
                  </span>
                )}
            </div>
            <div className="input-wrap pro-mb-4">
              <label
                htmlFor="last_name"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Last Name
              </label>
              <input
                type="last_name"
                className={`pro-input lg ${
                  formikEditUserData.errors.last_name &&
                  formikEditUserData.touched.last_name &&
                  "error"
                }`}
                id="last_name"
                name="last_name"
                {...formikEditUserData.getFieldProps("last_name")}
              />
              {formikEditUserData.touched.last_name &&
                formikEditUserData.errors.last_name && (
                  <span className="error-text">
                    {formikEditUserData.errors.last_name}
                  </span>
                )}
            </div>
            <div className="input-wrap pro-mb-4 ">
              <div className="input-country-wrap ">
                <label
                  htmlFor=""
                  className="pro-font-sm pro-mb-1 pro-fw-medium"
                >
                  Mobile Number
                </label>
                <div className="input-main-wrap verify-btn-wrap">
                  <div className="code-wrap pro-mb-4 ">
                    <Select
                      id="dialcode"
                      options={countryCodeOptions}
                      getOptionLabel={(option) => `${option.label}`}
                      getOptionValue={(option) => `${option.value}`}
                      value={countryObj}
                      classNamePrefix="pro-input"
                      className="country-select"
                      onBlur={formikEditUserData.handleBlur("dialcode")}
                      menuPlacement="auto"
                      onChange={(value) =>
                        formikEditUserData.setFieldValue(
                          "dialcode",
                          value?.value
                        )
                      }
                    ></Select>
                  </div>
                  <input
                    type="text"
                    id="personal_number"
                    name="personal_number"
                    className={`pro-input lg ${
                      formikEditUserData.errors.personal_number &&
                      formikEditUserData.touched.personal_number &&
                      " error"
                    }`}
                    {...formikEditUserData.getFieldProps("personal_number")}
                  />
                </div>
                {formikEditUserData.errors.personal_number &&
                  formikEditUserData.touched.personal_number && (
                    <>
                      <span className="error-text">
                        {formikEditUserData.errors.personal_number}
                      </span>
                    </>
                  )}
              </div>
            </div>
            <div className="input-wrap pro-mb-4 ">
              <input
                type="text"
                id="contact_number"
                name="contact_number"
                className={`pro-input lg ${
                  formikEditUserData.errors.contact_number &&
                  formikEditUserData.touched.contact_number &&
                  " error"
                }`}
                {...formikEditUserData.getFieldProps("contact_number")}
              />
              {formikEditUserData.errors.contact_number &&
                formikEditUserData.touched.contact_number && (
                  <>
                    <span className="error-text">
                      {formikEditUserData.errors.contact_number}
                    </span>
                  </>
                )}
            </div>
            <div className="input-wrap pro-mb-4">
              <label
                htmlFor="email"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Email
              </label>
              <input
                type="email"
                className={`pro-input lg ${
                  formikEditUserData.errors.email &&
                  formikEditUserData.touched.email &&
                  "error"
                }`}
                id="email"
                name="email"
                {...formikEditUserData.getFieldProps("email")}
              />
              {formikEditUserData.touched.email &&
                formikEditUserData.errors.email && (
                  <span className="error-text">
                    {formikEditUserData.errors.email}
                  </span>
                )}
            </div>
            <div className="input-wrap pro-mb-5 pro-pr-3">
              <label
                htmlFor=""
                className={`pro-font-sm pro-mb-1 pro-fw-medium`}
              >
                Password
              </label>
              <input
                name="password"
                id="password"
                value={formikEditUserData.values.password}
                onChange={formikEditUserData.handleChange}
                onBlur={formikEditUserData.handleBlur}
                type="password"
                className={`pro-input lg hide-icon-input ${
                  formikEditUserData.errors.password &&
                  (formikEditUserData.touched.password ||
                    formikEditUserData.values.password) &&
                  "error"
                }`}
                autoComplete="new-password"
              />
              {showNewPassword ? (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowNewPassword(e)}
                  type="button"
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility_off{" "}
                  </span>
                </button>
              ) : (
                <button
                  className={`hide-icon`}
                  onClick={(e) => handleShowNewPassword(e)}
                  type="button"
                >
                  <span className="material-symbols-outlined">
                    {" "}
                    visibility{" "}
                  </span>
                </button>
              )}
              {formikEditUserData.errors.password &&
                (formikEditUserData.touched.password ||
                  formikEditUserData.values.password) && (
                  <span className="error-text">
                    {formikEditUserData?.errors?.password}
                  </span>
                )}
            </div>
          </div>
        </div>

        <div className="row">
          <div className={`col-12 pro-mt-4 pro-d-flex pro-justify-end`}>
            <Button
              className={"pro-btn-link lg pro-px-5"}
              onClick={handleClose}
            >
              Cancel
            </Button>

            <Button
              className={"pro-btn-primary lg pro-ms-3"}
              onClick={formikEditUserData.handleSubmit}
              // type="submit"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
