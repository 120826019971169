import React from "react";
import { TabHeading } from "../../../Global/TabHeading";
import AddOns from "./AddOns";
import Variants from "./Variants";
import useProductDetailView from "./useProductDetailView";

const ProductDetailView = () => {
  const data = [
    { name: "Add Ons", id: 1 },
    { name: "Variant", id: 2 },
  ];
  const { activeId, customerKey, handleClick } = useProductDetailView();

  return (
    <div className={`pro-w-100 pro-pt-5`}>
      <div className={`pro-ms-4 pro-d-flex pro-flex-column `}>
        <TabHeading
          data={data}
          uniqueId={"id"}
          activeId={activeId}
          handleClick={handleClick}
        />
        {activeId === 1 ? (
          <AddOns customerKey={customerKey} />
        ) : activeId === 2 ? (
          <Variants customerKey={customerKey} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProductDetailView;
