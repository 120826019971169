import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
};

const transactiontypeSlice = createSlice({
  name: "transactiontype",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});
export const { updateConfig } = transactiontypeSlice.actions;
export default transactiontypeSlice.reducer;
