import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../../store/slices/Admin/Products/productSlice";
import { useSelector } from "react-redux";
import {
  useGetProductImageMutation,
  useGetProductBasicDataQuery,
} from "../../../../../store/queries/Product";
import Assets from "../../../../../assets/Assets";
import { toast } from "react-toastify";

const useProductDetails = ({ formik, setUpdateAllStore }) => {
  const dispatch = useDispatch();
  const [selectProductImage] = useGetProductImageMutation();
  const { data: productBasicData } = useGetProductBasicDataQuery();
  const {
    imagePreview,
    imageCustomizedPreview,
    isUpdatingProduct,
    isImageUploading,
  } = useSelector((state) => state.product);

  const handleImagePreviewChange = (event) => {
    //TODO need to add validation
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      const formData = new FormData();
      formData.append("image", selectedLogo);
      formData.append("type", "product");
      selectProductImage(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(`image`, response?.data?.data?.image_path);
        }
      });

      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImagePreviewRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.imagePreview = "";
      })
    );
    formik.setFieldValue("image", "");
    formik?.setFieldError("image", "Upload image");
    formik?.setFieldTouched("image", true);
  };
  const handleImageCustomizedPreviewChange = async (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      if (
        !["image/jpeg", "image/png", "image/jpg"].includes(selectedLogo.type)
      ) {
        formik.setFieldError(
          "product_image_url",
          "Only image files are allowed"
        );
        return;
      }
      const formData = new FormData();
      formData.append("image", selectedLogo);
      formData.append("type", "product");
      dispatch(
        updateConfig((state) => {
          state.isImageUploading = true;
        })
      );
      const response = await selectProductImage(formData);
      try {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            `product_image_url`,
            response?.data?.data?.image_path
          );
        } else if (
          response?.error?.data?.status_code === 422 &&
          response?.error?.data?.errors
        ) {
          Object.keys(response?.error?.data?.errors).forEach((field) => {
            formik.setFieldError(
              "product_image_url",
              response?.error?.data?.errors[field]
            );
          });
        } else {
          toast.error(`Unable to add image, Please try again.`);
        }
      } catch (error) {
        console.error(`Unable to add image:`, error);
      } finally {
        dispatch(
          updateConfig((state) => {
            state.isImageUploading = false;
          })
        );
      }

      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imageCustomizedPreview = reader.result;
          })
        );
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleImageCustomizedPreviewRemove = () => {
    dispatch(
      updateConfig((state) => {
        state.imageCustomizedPreview = "";
      })
    );
    formik.setFieldValue("product_image_url", "");
    formik?.setFieldError("product_image_url", "Upload image");
    formik?.setFieldTouched("product_image_url", true);
  };

  let categoryObj = productBasicData?.data?.category?.filter(
    (item) => item?._id === formik?.values?.category_id
  );
  const handleUpdateAllStore = (e) => {
    setUpdateAllStore(e?.target?.checked);
  };

  return {
    Assets,
    categoryObj,
    imagePreview,
    isImageUploading,
    productBasicData,
    isUpdatingProduct,
    imageCustomizedPreview,
    handleUpdateAllStore,
    handleImagePreviewChange,
    handleImagePreviewRemove,
    handleImageCustomizedPreviewRemove,
    handleImageCustomizedPreviewChange,
  };
};

export default useProductDetails;
