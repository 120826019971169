import React from "react";
import useFilter from "./useFilter";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const Filter = () => {
  const {
    formik,
    storeList,
    statusObj,
    paymentObj,
    orderBasicData,
    isStoreAvailable,
    handleReset,
  } = useFilter();

  return (
    <div>
      {/* Render form fields for the "Basic" tab */}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Store
        </label>
        <Select
          id="store_name"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={true}
          name="store_name"
          options={storeList}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={storeList?.filter((m) =>
            formik.values.store_name?.includes?.(m?.id)
          )}
          onChange={(value) =>
            formik.setFieldValue(
              "store_name",
              value.map((v) => v?.id)
            )
          }
          menuPlacement="auto"
          isDisabled={isStoreAvailable}
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Payment Mode
        </label>
        <Select
          id="payment_mode"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={false}
          name="payment_mode"
          options={orderBasicData?.data?.payment_mode}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={paymentObj}
          onChange={(value) =>
            formik.setFieldValue("payment_mode", value?.name)
          }
          menuPlacement="auto"
          isClearable
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Status
        </label>
        <Select
          id="status"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={false}
          name="status"
          options={orderBasicData?.data?.status}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={statusObj}
          onChange={(value) => formik.setFieldValue("status", value?.name)}
          menuPlacement="auto"
          isClearable
        />
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleReset}>
          Reset
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik?.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default Filter;
