import {
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useProductListing from "./useProductListing";
import EmptyData from "../../../Global/EmptyData";
import { ImageCard } from "../../../Global/ImageCard";
import OrderColumn from "../../../Global/OrderColumn";

const ProductList = () => {
  const {
    isLoading,
    isFetching,
    imageData,
    showEditModal,
    showImageModal,
    productList,
    paginationOptions,
    productListState,
    handleEditColumnsClick,
    updateProductListFields,
    handlePagination,
    handleClearClick,
    closeImageModal,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useProductListing();
  return (
    <>
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={productListState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: productListState?.search }}
          showActions={true}
          handleActionClick={handleEditColumnsClick}
        />
        {productList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={productList?.data?.result || []}
            uniqueID={"id"}
            fields={productList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            clear={productListState?.clearSelection}
            perpage={productListState?.currentPageSize}
            assignable={false}
            deletable={false}
            editable={false}
            showCheckBox={false}
          />
        )}
        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={productList?.data?.fields || []}
              moduleId={productList?.data?.module_id}
              updateData={updateProductListFields}
            />
          </div>
        </ModalLayout>

        {productList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={productListState?.currentPage}
            totalPageCount={Math.ceil(
              productList.data.total_count / productListState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === productListState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>
      </div>
    </>
  );
};

export default ProductList;
