import LOGO from "./images/header/logo-header.svg";
import LOGODARK from "./images/header/logo_header_dark.svg";
import USER from "./images/header/avatar.png";
import HEADERLOGO from "./images/header/logo.svg";
import HEADERLOGODARK from "./images/header/logo_dark.svg";
import IMAGE_APPEARANCE from "./images/image-appearance.png";
import THEME_BLACK from "./images/black.png";
import THEME_LIGHT from "./images/light.png";
import THEME_CLOSED from "./images/closed.png";
import THEME_COMPACT from "./images/compact.png";
import THEME_DARK from "./images/dark.png";
import THEME_DETAILED from "./images/detailed.png";
import THEME_EXPANDED from "./images/expanded.png";
import NO_DATA from "./images/no_data.svg";
import EMPTY_DATA from "./images/empty_data.png";
import EMPTY_DATA_DARK from "./images/noDataDark.svg";
import ACCOUNT_CIRCLE from "./images/acount_circle.png";
import FEMALEPROFILE from "./images/female.png";
import MALEPROFILE from "./images/male.png";
import GENDER_IMAGE from "./images/gender_image.png";
import IMAGE_ABSENCE from "./images/caribou.png";

const Assets = {
  LOGO,
  LOGODARK,
  USER,
  HEADERLOGO,
  HEADERLOGODARK,
  IMAGE_APPEARANCE,
  THEME_BLACK,
  THEME_LIGHT,
  THEME_CLOSED,
  THEME_COMPACT,
  THEME_DARK,
  THEME_DETAILED,
  THEME_EXPANDED,
  NO_DATA,
  EMPTY_DATA,
  FEMALEPROFILE,
  MALEPROFILE,
  ACCOUNT_CIRCLE,
  GENDER_IMAGE,
  EMPTY_DATA_DARK,
  IMAGE_ABSENCE,
};

export default Assets;
