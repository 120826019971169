import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  activeId: 1,
  activeType: "user",
  activityData: {},
};

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = activitySlice.actions;
export default activitySlice.reducer;
