import React, { useEffect, useState } from "react";
import {
  getSubAdminEditData,
  updateConfig,
} from "../../../store/slices/Admin/Sub-admin/subAdminSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  admin,
  useGetSubAdminListDataQuery,
  useUpdateSubAdminStatusMutation,
  useUpdateSubAdminTableHeadDataMutation,
} from "../../../store/queries/Admin";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { deleteSubadmin } from "./AddSubAdmin/api";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";

const useAdminUser = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const dispatch = useDispatch();
  const subAdminState = useSelector((state) => state.subAdmin);
  const { selectedId } = useSelector((state) => state.subAdmin);
  const { showEditModal } = useSelector((state) => state.global);
  const {
    data: subAdminList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetSubAdminListDataQuery({
    page_size: subAdminState?.currentPageSize,
    page: subAdminState?.currentPage,
    sort: subAdminState?.sortBy,
    sort_order: subAdminState?.sortOrder,
    search: subAdminState?.search,
  });

  const [updateSubAdminFields] = useUpdateSubAdminTableHeadDataMutation();
  const [updateSubAdminStatus] = useUpdateSubAdminStatusMutation();
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.imageName = "";
        state.imagePreview = "";
        state.isEdit = false;
        state.editAdminData = {};
      })
    );
  };
  useEffect(() => {
    if (subAdminState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [subAdminState.clearSelection]);
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handleToggle = (id, data) => {
    const newStatus = data.status === "Active" ? false : true;
    let params = {
      user_id: data?.id,
      status: newStatus ? 1 : 2,
    };
    updateSubAdminStatus(params);
    dispatch(
      admin.util.updateQueryData(
        "getSubAdminListData",
        {
          page_size: subAdminState?.currentPageSize,
          page: subAdminState?.currentPage,
          sort: subAdminState?.sortBy,
          sort_order: subAdminState?.sortOrder,
          search: subAdminState?.search,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data.result?.map((res) =>
            res.id === id
              ? {
                  ...res,
                  status: data.status === "Active" ? "Deactivated" : "Active",
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              result: newResult,
            },
          };
          return currentCache;
        }
      )
    );
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      image: (field, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image)}
            />
          </div>
        );
      },
      status: (field, data) => {
        const isChecked = data?.status === "Active";
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={isChecked}
              onChange={(e) => handleToggle(data?.id, data)}
            />
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleSort = (label) => {
    if (subAdminState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = subAdminState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getSubAdminEditData(e?.[0]));
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };
  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };
  const handleDelete = async () => {
    const response = await deleteSubadmin(selectedId);
    if (response && response.status === 200) {
      toast.success(`Admin deleted successfully.`);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };

  return {
    isLoading,
    imageData,
    isFetching,
    subAdminList,
    showEditModal,
    subAdminState,
    showImageModal,
    paginationOptions,
    handleEditColumnsClick,
    updateSubAdminFields,
    handleDeleteModal,
    handleCreateClick,
    handlePagination,
    handleClearClick,
    handleEditAction,
    closeImageModal,
    handlePageSize,
    handleSearch,
    handleDelete,
    handleSort,
    refetch,
    getRow,
  };
};

export default useAdminUser;
