import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetRoleListDataQuery } from "../../../../store/queries/Admin";
import { updateConfig } from "../../../../store/slices/Admin/Role/roleSlice";

const useCreateRoleModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);

  const { data: roleListData } = useGetRoleListDataQuery({});

  const handleRoleClick = (item) => {
    if (item) {
      navigate({
        pathname: "/admin/roles/role-update",
        search: `role=${item?.id}`,
      });
      handleCreateModal();
    } else {
      navigate("/admin/roles/role-update");
      handleCreateModal();
    }
  };
  const handleCreateModal = () => {
    dispatch(
      updateConfig((state) => {
        state.isEdit = false;
        state.showCreateModal = false;
      })
    );
  };

  return {
    roleState,
    roleListData,
    handleRoleClick,
  };
};

export default useCreateRoleModal;
