import { useSelector, useDispatch } from "react-redux";
import {
  useGetOrderListingDataQuery,
  useUpdateOrderListingTableHeadDataMutation,
} from "../../../../store/queries/User";
import { updateConfig } from "../../../../store/slices/User/UserDetails/orderListingSlice";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { useParams } from "react-router-dom";

const useOrderListing = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const activeProfile = user_id ?? sessionStorage.getItem("active");
  const orderListingState = useSelector((state) => state.orderListing);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: orderListingList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetOrderListingDataQuery({
    user_id: activeProfile,
    page_size: orderListingState?.currentPageSize,
    page: orderListingState?.currentPage,
    sort: orderListingState?.sortBy,
    sort_order: orderListingState?.sortOrder,
    search: orderListingState?.search,
  });
  const [updateOrderListingFields] =
    useUpdateOrderListingTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (orderListingState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            orderListingState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      posist_status: (field, data) =>
        data?.posist_status === "Preparing" ? (
          <span className={"pro-badge badge-grey-outline"}>{"Preparing"}</span>
        ) : data?.posist_status === "Pending" ? (
          <span className={"pro-badge badge-warning-outline"}>{"Pending"}</span>
        ) : data?.posist_status === "Delivered" ? (
          <span className={"pro-badge badge-success-outline"}>
            {"Delivered"}
          </span>
        ) : data?.posist_status === "Food prepared" ? (
          <span className={"pro-badge badge-primary-outline"}>
            {"Food prepared"}
          </span>
        ) : data?.posist_status === "Failed" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Failed"}</span>
        ) : data?.posist_status === "Waiting for pickup" ? (
          <span className={"pro-badge badge-waiting-outline"}>
            {"Waiting for pickup"}
          </span>
        ) : data?.posist_status === "Processing" ? (
          <span className={"pro-badge badge-processing-outline"}>
            {"Processing"}
          </span>
        ) : (
          <span className={"pro-badge badge-grey-outline"}>
            {data?.posist_status}
          </span>
        ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    orderListingList,
    paginationOptions,
    orderListingState,
    handleEditColumnsClick,
    updateOrderListingFields,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useOrderListing;
