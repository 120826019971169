import { Image } from "@wac-ui-dashboard/wac_component_library";
import style from "./emptyData.module.scss";
import Assets from "../../../assets/Assets";
import { useSelector } from "react-redux";

export const EmptyTable = ()=>{
  const globalState = useSelector((state) => state.global);
  return(
    <div className={`pro-text-center pro-py-6 pro-d-flex pro-justify-center pro-items-center ${style.empty_root}`}>
      <div className="">
        <div className={style.image_inner}>
          <Image
            src={
              globalState.currentTheme === "light" ||
              globalState.currentTheme === null
                ? Assets.NO_DATA
                : Assets.EMPTY_DATA_DARK
            }
            width={65}
            height={65}
          />
        </div>
        <h5 className={`pro-ttl h3 ${style.title}`}>No Data Found</h5>
        <p className={`pro-mb-0 ${style.subtitle}`}>
          Sorry we haven’t found any data for you.
        </p>
      </div>
    </div>
    
  )
}

const EmptyData = () => {
  return (
    <div className={`${style.table_root} pro-mt-3`}>
      <table>
        <thead>
          <tr>
            {
              [...Array(5)].map((_, i) =>(
                <th key={i}></th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5}>
              <EmptyTable />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EmptyData;
