import { useDispatch, useSelector } from "react-redux";
import {
  useGetFeedbackListDataQuery,
  useUpdateFeedbackTableHeadDataMutation,
} from "../../../store/queries/User";
import { updateConfig } from "../../../store/slices/User/Feedback/feedbackSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useState } from "react";
import { getFormatedDate } from "../../../utils/Functions/table";
import { FaStar } from "react-icons/fa";
import Style from "./feedback.module.scss";

const useFeedback = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const feedbackState = useSelector((state) => state.feedback);
  const {
    data: feedbackList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetFeedbackListDataQuery({
    page_size: feedbackState?.currentPageSize,
    page: feedbackState?.currentPage,
    sort: feedbackState?.sortBy,
    sort_order: feedbackState?.sortOrder,
    search: feedbackState?.search,
    start_date: getFormatedDate(feedbackState?.startDate),
    end_date: getFormatedDate(feedbackState?.endDate),
  });
  const [updateFeedbackFields] = useUpdateFeedbackTableHeadDataMutation();
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e?.target?.value;
        state.currentPage = 1;
      })
    );
  };
  const [date, setDate] = useState({
    startDate: feedbackState.startDate,
    endDate: feedbackState.endDate,
    key: "selection",
  });

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (feedbackState?.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = feedbackState?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      feed_back_rate: (field, data) => {
        return (
          <div className={Style.container}>
            {[...Array(5)].map((item, index) => {
              let givenRating = index + 1;
              let rate = data?.feed_back_rate;
              return (
                <div>
                  <FaStar
                    color={
                      givenRating < rate || givenRating === rate
                        ? "#ffa534"
                        : "rgb(192,192,192)"
                    }
                  />
                </div>
              );
            })}
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };

  return {
    date,
    isLoading,
    isFetching,
    tableFields,
    feedbackList,
    feedbackState,
    showEditModal,
    paginationOptions,
    handleEditColumnsClick,
    handleDateRangeChange,
    updateFeedbackFields,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useFeedback;
