import React from "react";
import CommonLayout from "../../pages/Layout/CommonLayout";
import { Outlet } from "react-router-dom";
import useProducts from "./useProducts";

const Products = () => {
  const drawerMenu = useProducts();
  return (
    <CommonLayout drawerMenu={drawerMenu?.drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Products;
