import React, { useMemo } from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";
import { useSelector } from "react-redux";

const useStaff = () => {
  const { checkIfActiveRoute } = useRouteUtils();
  const globalState = useSelector((state) => state.global);
  const drawerMenu = [
    {
      title: "Staffs",
      link: "/staff/list",
      icon: <span className="material-symbols-outlined x4">person</span>,
      active: checkIfActiveRoute("/staff/list", true),
    },
  ];
  const drawerMenuPermission = useMemo(() => {
    let menus = Object.values(
      globalState.dashboard_permissions?.menu_permissions ?? []
    )?.flatMap?.((menu) =>
      menu?.sub_menu_permissions?.map((side_menu) => side_menu?.sub_menu_name)
    );
    return drawerMenu
      ?.filter((menu) => {
        return menus?.includes?.(menu?.title);
      })
      ?.map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);
  return { drawerMenu: drawerMenuPermission };
};

export default useStaff;
