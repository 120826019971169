import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import { getAdminAxiosInstance } from "../../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  giftCardEditData: [],
  isLoadingImage: false, // Loading state for the main image
  isArabicImageLoading: false, // Loading state for the Arabic image
  imagePreview: "",
  imageName: "",
  arabicImagePreview: "",
  arabicImageName: "",
};

export const getGiftCardEditData = createAsyncThunk(
  "gift-card/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(
        `/gift-card/edit?gift_card_template_id=${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const giftCardSlice = createSlice({
  name: "giftCard",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGiftCardEditData.pending, (state) => {
        state.error = null;
      })
      .addCase(getGiftCardEditData.fulfilled, (state, action) => {
        state.giftCardEditData = action?.payload?.data;
      })
      .addCase(getGiftCardEditData.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = giftCardSlice.actions;
export default giftCardSlice.reducer;
