import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useValidations from "../../../utils/hooks/useValidations";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/Global";
import { forgotPassword, verifyOTP } from "../api";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const useForgotPassword = ({ emailRef }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { otpReceived } = useSelector((state) => state.global);
  const { validateEmail } = useValidations();
  const [emailInfo, setEmailInfo] = useState({});
  const [emailStatus, setEmailStatus] = useState("idle");

  useEffect(() => {
    if (emailStatus === "failed") {
      let errorFields = Object.keys(emailInfo);
      errorFields.forEach((field) => {
        formik.setFieldError(field, emailInfo[field]);
      });
    }
    // eslint-disable-next-line
  }, [emailStatus]);

  const formik = useFormik({
    initialValues: {
      email: "",
      otp: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.email) {
        errors.email = "*Email is Required";
      }
      if (!values.otp && otpReceived) {
        errors.otp = "* Enter received OTP";
      }
      if (!validateEmail(values.email)) {
        errors.email = "*Enter a valid email";
      }
      return errors;
    },
    onSubmit: (values) => {
      emailRef.current = values?.email;
      if (!otpReceived) {
        setEmailStatus("pending");
        forgotPassword({ email: values?.email }).then((response) => {
          if (response?.data?.status_code === 200) {
            setEmailStatus("success");
            toast.success("OTP send successfully");
            dispatch(
              updateConfig((state) => {
                state.otpReceived = true;
              })
            );
          } else if (response?.data?.status_code === 422) {
            setEmailStatus("failed");
            setEmailInfo({ email: response?.message });
          } else {
            setEmailStatus("failed");
            setEmailInfo({ email: response?.message });
          }
        });
      } else {
        setEmailStatus("pending");
        verifyOTP(values).then((response) => {
          if (response?.data?.status_code === 200) {
            setEmailStatus("success");
            navigate("/login/change-password");
            dispatch(
              updateConfig((state) => {
                state.otpReceived = false;
              })
            );
          } else if (response?.data?.status_code === 422 && response?.errors) {
            setEmailStatus("failed");
            // Object.keys(response?.errors).forEach((field) => {
            //   formik.setFieldError(field, response?.errors[field]);
            // });
            formik?.setFieldError("otp", `Invalid OTP`);
            // setEmailInfo(response?.errors);
          } else {
            setEmailStatus("failed");
            formik?.setFieldError("otp", `Invalid OTP`);
            // setEmailInfo(response?.errors);
          }
        });
      }
    },
  });
  const handleLoginClick = () => {
    navigate("/login");
    dispatch(
      updateConfig((state) => {
        state.otpReceived = false;
      })
    );
  };

  return { formik, emailStatus, otpReceived, handleLoginClick };
};

export default useForgotPassword;
