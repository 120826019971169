import React from "react";
import AdminProfileCard from "../AdminProfileCard";
import useProfileView from "./useProfileView";
import EditProfile from "./EditProfile";
import Style from "./editProfile.module.scss";

const ProfileDetails = () => {
  const {
    userData,
    countryObj,
    imagePreview,
    showNewPassword,
    countryCodeOptions,
    formikEditUserData,
    profileFileInputRef,
    handleShowNewPassword,
    handleImageChange,
    setImagePreview,
    handleClose,
  } = useProfileView();
  return (
    <>
      <div className={Style.root}>
        <div className={Style.item}>
          <AdminProfileCard
            profileFileInputRef={profileFileInputRef}
            userData={userData?.data}
            imagePreview={imagePreview}
            handleImageChange={handleImageChange}
          />
        </div>
        <EditProfile
          handleClose={handleClose}
          setImagePreview={setImagePreview}
          userData={userData?.data}
          formikEditUserData={formikEditUserData}
          countryCodeOptions={countryCodeOptions}
          countryObj={countryObj}
          showNewPassword={showNewPassword}
          handleShowNewPassword={handleShowNewPassword}
        />
      </div>
    </>
  );
};

export default ProfileDetails;
