import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useAdminUser from "./useAdminUser";
import AddSubAdmin from "./AddSubAdmin";
import { ImageCard } from "../../Global/ImageCard";
import EmptyData from "../../Global/EmptyData";

const AdminUser = () => {
  const {
    isLoading,
    isFetching,
    imageData,
    subAdminList,
    showEditModal,
    subAdminState,
    showImageModal,
    paginationOptions,
    handleEditColumnsClick,
    updateSubAdminFields,
    handleDeleteModal,
    handleCreateClick,
    handleEditAction,
    handleClearClick,
    handlePagination,
    closeImageModal,
    handlePageSize,
    handleSearch,
    handleDelete,
    handleSort,
    refetch,
    getRow,
  } = useAdminUser();
  return (
    <div>
      <HeadingGroup
        title={"Admin User"}
        className={`pro-mb-4`}
        buttonTitle={subAdminList?.data?.create_permission && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={subAdminState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: subAdminState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        {subAdminList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={subAdminList?.data?.result || []}
            uniqueID={"id"}
            fields={subAdminList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={subAdminState.clearSelection}
            loading={isFetching}
            perpage={subAdminState?.currentPageSize}
            assignable={false}
            handleEdit={handleEditAction}
            handleDelete={handleDeleteModal}
            deletable={subAdminList?.data?.delete_permission}
            editable={subAdminList?.data?.edit_permission}
            showCheckBox={
              subAdminList?.data?.delete_permission ||
              subAdminList?.data?.edit_permission
            }
          />
        )}

        {subAdminList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={subAdminState?.currentPage}
            totalPageCount={Math.ceil(
              subAdminList?.data?.total_count / subAdminState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === subAdminState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={subAdminState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure to delete the selected Admin ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={subAdminList?.data?.fields || []}
              moduleId={subAdminList?.data?.module_id}
              updateData={updateSubAdminFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={subAdminState?.showCreateModal}
          handleClose={handleCreateClick}
          title={subAdminState?.isEdit ? "Update Admin" : "Add Admin"}
          backdrop={"static"}
        >
          <AddSubAdmin
            refetch={refetch}
            handleCreateClick={handleCreateClick}
            isStickyFooter
            subAdminState={subAdminState}
          />
        </OffCanvasLayout>
        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>
      </div>
    </div>
  );
};

export default AdminUser;
