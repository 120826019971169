import { getAdminAxiosInstance } from "../../../api/Admin";

export const deleteUser = async (id) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.get(`/user/destroy?user_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getUserExportedData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/user/excel-export`, params, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getWalletBalanceReportData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/user/wallet-balance-report`, params, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
