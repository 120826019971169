import React from "react";
import { TabHeading } from "../../Global/TabHeading";
import useUserDetailView from "./useUserDetailView";
import OrderListing from "./OrderListing";
import PointHistory from "./PointHistory";
import Transactions from "./Transactions";
import StoreList from "./StoreListing";
import ProductList from "./ProductListing";
import VehicleList from "./VehicleListing";

const UserDetailView = () => {
  const data = [
    { name: "Order Listing", id: 1 },
    { name: "Point History", id: 2 },
    { name: "Wallet Transactions", id: 3 },
    { name: "Favorite Store List", id: 4 },
    { name: "Favorite Product List", id: 5 },
    { name: "Vehicle Details", id: 6 },
  ];
  const { activeId, handleClick } = useUserDetailView();

  return (
    <div className={`pro-w-100 pro-pt-5`}>
      <div className={`pro-ms-4 pro-d-flex pro-flex-column`}>
        <TabHeading
          data={data}
          uniqueId={"id"}
          activeId={activeId}
          handleClick={handleClick}
        />
        {activeId === 1 ? (
          <OrderListing />
        ) : activeId === 2 ? (
          <PointHistory />
        ) : activeId === 3 ? (
          <Transactions />
        ) : activeId === 4 ? (
          <StoreList />
        ) : activeId === 5 ? (
          <ProductList />
        ) : (
          <VehicleList />
        )}
      </div>
    </div>
  );
};

export default UserDetailView;
