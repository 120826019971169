import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { createCategory } from "../api";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Category/categorySlice";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import {
  useGetProfileImageMutation,
  useGetIconImageMutation,
} from "../../../../store/queries/Admin";
import useValidations from "../../../../utils/hooks/useValidations";

const useAddCategory = (handleCreateClick, refetch) => {
  const dispatch = useDispatch();
  const [isCategoryCreate, setIsCategoryCreate] = useState(false);
  const { englishRegex, arabicRegex } = useValidations();
  const {
    isEdit,
    imagePreview,
    editCategoryData,
    imageName,
    iconName,
    iconPreview,
  } = useSelector((state) => state.category);
  const [selectProfileImage, { isLoading: profileImageLoading }] =
    useGetProfileImageMutation();
  const [selectIconImage, { isLoading: profileIconLoading }] =
    useGetIconImageMutation();
  useEffect(() => {
    if (isEdit && editCategoryData !== "") {
      formik?.setFieldValue("name", editCategoryData?.name ?? "");
      formik?.setFieldValue("alias_name", editCategoryData?.alias_name ?? "");
      formik?.setFieldValue("description", editCategoryData?.description ?? "");
      formik?.setFieldValue(
        "alias_description",
        editCategoryData?.alias_description ?? ""
      );
      formik?.setFieldValue("image", editCategoryData?.image ?? "");
      formik?.setFieldValue("icon", editCategoryData?.icon ?? "");
      formik?.setFieldValue("is_new", editCategoryData?.is_new ?? 2);
      formik?.setFieldValue("status", editCategoryData?.status ?? 2);
      formik?.setFieldValue(
        "display_priority",
        editCategoryData?.display_priority ?? ""
      );

      formik?.setFieldValue(
        "home_priority",
        editCategoryData?.home_priority ?? ""
      );

      dispatch(
        updateConfig((state) => {
          state.imagePreview = editCategoryData?.image_url;
          state.iconPreview = editCategoryData?.icon_url;
          state.imageName = editCategoryData?.image_url?.split("/").pop();
          state.iconName = editCategoryData?.icon_url?.split("/").pop();
        })
      );
    }
    //eslint-disable-next-line
  }, [isEdit, editCategoryData]);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .matches(englishRegex, "Invalid name Format")
      .required("Enter category name")
      .max(50, "Maximum 50 letters are allowed"),
    alias_name: Yup.string()
      .trim()
      .matches(arabicRegex, "Enter in arabic letters")
      .required("Enter category name")
      .max(50, "Maximum 50 letters are allowed"),
    description: Yup.string()
      .matches(englishRegex, "Enter in english letters")
      .required(`Enter description`)
      .min(5, " The description must be at least 5 characters"),
    alias_description: Yup.string()
      .matches(arabicRegex, "Enter in arabic letters")
      .required(`Enter description`)
      .min(5, " The description must be at least 5 characters"),
    // status: Yup.boolean(),
    image: Yup.string().required("Please upload an image."),
    icon: Yup.string().required("Please upload an Icon."),
    display_priority: Yup.number()
      .required("Enter Menu Prirority")
      .typeError("Enter a valid number"),
    home_priority: Yup.number()
      .required("Enter Home Prirority")
      .typeError("Enter a valid number"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      alias_name: "",
      alias_description: "",
      status: 2,
      image: "",
      icon: "",
      is_new: 2,
      display_priority: "",
      home_priority: "",
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      setIsCategoryCreate(true);
      try {
        const data = isEdit
          ? { category_id: editCategoryData?._id, ...values }
          : values;
        const response = await createCategory(data);
        if (response?.data?.status_code === 200) {
          toast.success(
            `Category ${isEdit ? `Updated` : `Created`} Successfully`
          );
          setIsCategoryCreate(false);
          refetch();
        }
      } catch (error) {
        //TODO
        toast.error(error?.message);
        handleCreateClick();
      } finally {
        handleCreateClick();
        setIsCategoryCreate(false);
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleImageChange = async (event) => {
    const selectedLogo = event?.target?.files[0];
    if (selectedLogo) {
      if (
        !["image/jpeg", "image/png", "image/jpg"].includes(selectedLogo.type)
      ) {
        formik.setFieldError("image", "Only image files are allowed");
        return;
      }
      const formData = new FormData();
      formData.append("image", selectedLogo);
      formData.append("type", "category");
      dispatch(
        updateConfig((state) => {
          state.isImageUploading = true;
        })
      );
      const response = await selectProfileImage(formData);
      try {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue("image", response?.data?.data?.image_path ?? "");
        } else if (
          response?.error?.data?.status_code === 422 &&
          response?.error?.data?.errors
        ) {
          Object.keys(response?.error?.data?.errors).forEach((field) => {
            formik.setFieldError(field, response?.error?.data?.errors[field]);
          });
        } else {
          toast.error(`Unable to add image, Please try again.`);
        }
      } catch (error) {
        console.error(`Unable to upload image:`, error);
      } finally {
        dispatch(
          updateConfig((state) => {
            state.isImageUploading = false;
          })
        );
      }

      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.imagePreview = reader.result;
            state.imageName = selectedLogo?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  const handleIconChange = async (event) => {
    const selectedIcon = event?.target?.files[0];
    if (selectedIcon) {
      if (
        !["image/jpeg", "image/png", "image/jpg"].includes(selectedIcon.type)
      ) {
        formik.setFieldError("icon", "Only image files are allowed");
        return;
      }
      const formData = new FormData();
      formData.append("image", selectedIcon);
      formData.append("type", "icon");
      // setLastUploadedLogo(selectedIcon);
      dispatch(
        updateConfig((state) => {
          state.isImageUploading = true;
        })
      );
      const response = await selectIconImage(formData);
      try {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue("icon", response?.data?.data?.image_path ?? "");
        } else if (
          response?.error?.data?.status_code === 422 &&
          response?.error?.data?.errors
        ) {
          Object.keys(response?.error?.data?.errors).forEach((field) => {
            formik.setFieldError("icon", response?.error?.data?.errors[field]);
          });
        } else {
          toast.error(`Unable to add image, Please try again.`);
        }
      } catch (error) {
        console.error(`Unable to upload image:`, error);
      } finally {
        dispatch(
          updateConfig((state) => {
            state.isImageUploading = false;
          })
        );
      }
      const file = selectedIcon;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state.iconPreview = reader.result;
            state.iconName = selectedIcon?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
      })
    );
  };

  return {
    formik,
    isEdit,
    iconName,
    imageName,
    iconPreview,
    imagePreview,
    editCategoryData,
    isCategoryCreate,
    profileIconLoading,
    profileImageLoading,
    handleImageChange,
    handleIconChange,
    getFieldError,
    closeModal,
  };
};

export default useAddCategory;
