import { getAuthAxiosInstance } from "../../api/Authentication";

export const login = async (params) => {
  const api = await getAuthAxiosInstance();
  try {
    const response = await api.post(`/admin/login`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const forgotPassword = async (params) => {
  const api = await getAuthAxiosInstance();
  try {
    const response = await api.post(`/admin/send-otp`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const verifyOTP = async (params) => {
  const api = await getAuthAxiosInstance();
  try {
    const response = await api.post(`/admin/verify-otp`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const changePassword = async (params) => {
  const api = await getAuthAxiosInstance();
  try {
    const response = await api.post(`/admin/reset-password`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
