import { getAdminAxiosInstance } from "../../../../api/Admin";

export const getBannerImage = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/notification/upload-image`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getExcelUrl = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/notification/upload-excel`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const resendNotification = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/notification/resend-notification`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteNotification = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/notification/delete-notification`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
