import "./assets/scss/app.scss";
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./components/App";

import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./store";
import { admin } from "./store/queries/Admin";
import { user } from "./store/queries/User";
import { staff } from "./store/queries/Staff";
import { product } from "./store/queries/Product";
import { cms } from "./store/queries/CMS";
import { dashboard } from "./store/queries/Dashboard";
import { global } from "./store/queries/Global";
import { report } from "./store/queries/Report";

const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      admin.middleware,
      user.middleware,
      staff.middleware,
      product.middleware,
      cms.middleware,
      dashboard.middleware,
      global.middleware,
      report.middleware
    ),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // {/* </React.StrictMode> */}
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
