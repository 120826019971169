import { useSelector, useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Admin/Activity/activitySlice";
import {
  useGetActivityLogListDataQuery,
  useUpdateActivityTableHeadDataMutation,
} from "../../../../store/queries/Admin";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";

const useActivity = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const activityState = useSelector((state) => state.activity);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: activityData = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetActivityLogListDataQuery({
    type: activityState?.activeType,
    page_size: activityState?.currentPageSize,
    page: activityState?.currentPage,
    sort: activityState?.sortBy,
    sort_order: activityState?.sortOrder,
    search: activityState?.search,
  });
  const [updateActivityTableHead] = useUpdateActivityTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e?.target?.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (activityState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = activityState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    activityData,
    paginationOptions,
    activityState,
    handleEditColumnsClick,
    updateActivityTableHead,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useActivity;
