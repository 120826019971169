import { useDispatch, useSelector } from "react-redux";
import {
  useGetMostSpentCustomerListDataQuery,
  useUpdateMostSpentCustomerTableHeadDataMutation,
} from "../../../store/queries/Report";
import { updateConfig } from "../../../store/slices/Report/mostSpentCustomerSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useState } from "react";
import { toast } from "react-toastify";
import { getMostSpentCustomerExportedData } from "../api";
import { getFormatedDate } from "../../../utils/Functions/table";
import { Tooltip as ReactTooltip } from "react-tooltip";

const useMostSpentCustomer = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const [isExcelLinkSend, setIsExcelLinkSend] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const mostSpentCustomerState = useSelector(
    (state) => state.mostSpentCustomer
  );
  const {
    data: mostSpentCustomerList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetMostSpentCustomerListDataQuery({
    page_size: mostSpentCustomerState?.currentPageSize,
    page: mostSpentCustomerState?.currentPage,
    sort: mostSpentCustomerState?.sortBy,
    sort_order: mostSpentCustomerState?.sortOrder,
    search: mostSpentCustomerState?.search,
    start_date: getFormatedDate(mostSpentCustomerState?.startDate),
    end_date: getFormatedDate(mostSpentCustomerState?.endDate),
  });
  const [updateMostSpentCustomerFields] =
    useUpdateMostSpentCustomerTableHeadDataMutation();
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e?.target?.value;
        state.currentPage = 1;
      })
    );
  };
  const [date, setDate] = useState({
    startDate: mostSpentCustomerState.startDate,
    endDate: mostSpentCustomerState.endDate,
    key: "selection",
  });

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (mostSpentCustomerState?.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            mostSpentCustomerState?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      formated_amount: (field, data) => {
        return (
          <>
            <p className="pro-mb-0" data-tooltip-id={`amount-${data?.name}`}>
              {data[field]}
            </p>
            <ReactTooltip
              id={`amount-${data?.name}`}
              place="left"
              content={`Amount : ${data?.amount}`}
            />
          </>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleMostSpentCustomerExcelDownload = () => {
    dispatch(
      updateConfig((state) => {
        state.mostSpentCustomerExcelSendModal =
          !state.mostSpentCustomerExcelSendModal;
      })
    );
  };
  const modalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.mostSpentCustomerExcelSendModal = false;
      })
    );
  };
  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const sendExcelData = async (value) => {
    setIsExcelLinkSend(true);
    let params = {
      email: value?.email ?? "",
      start_date: getFormatedDate(mostSpentCustomerState.startDate ?? ""),
      end_date: getFormatedDate(mostSpentCustomerState.endDate ?? ""),
      sort: mostSpentCustomerState?.sortBy,
      sort_order: mostSpentCustomerState?.sortOrder,
    };
    let response = await getMostSpentCustomerExportedData(params);
    try {
      if (response?.status === 200) {
        toast.success(`Excel download link send successfully`);
      } else {
        toast.error(`Failed to send download link,Please try again`);
      }
    } catch (error) {
      console.error("Failed to send link:", error);
      toast.error(`Failed to send download link`);
    } finally {
      modalClose();
      setIsExcelLinkSend(false);
    }
  };

  return {
    date,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    isExcelLinkSend,
    paginationOptions,
    mostSpentCustomerList,
    mostSpentCustomerState,
    handleMostSpentCustomerExcelDownload,
    updateMostSpentCustomerFields,
    handleEditColumnsClick,
    handleDateRangeChange,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handlePageSize,
    sendExcelData,
    handleSearch,
    modalClose,
    handleSort,
    refetch,
    getRow,
  };
};

export default useMostSpentCustomer;
