import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useAddFaq from "./useAddFaq";

const AddFaq = ({ isStickyFooter, refetch }) => {
  const { formik, isEdit, isFaqCreate, getFieldError, closeModal } =
    useAddFaq(refetch);
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Question"}
          type="text"
          id="question"
          name="question"
          className={`pro-input lg ${getFieldError("question") && " error"}`}
          {...formik.getFieldProps("question")}
          error={getFieldError("question")}
          errorMessage={getFieldError("question")}
        />
        <div className="pro-rtl">
          <Input
            label={"(سؤال )Question"}
            type="text"
            id="alias_question"
            name="alias_question"
            className={`pro-input lg ${
              getFieldError("alias_question") && " error"
            }`}
            {...formik.getFieldProps("alias_question")}
            error={getFieldError("alias_question")}
            errorMessage={getFieldError("alias_question")}
          />
        </div>
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Answer
            </label>
            <textarea
              id="answer"
              rows={8}
              name="answer"
              className={`pro-input lg ${
                formik.errors.answer && formik.touched.answer && " error"
              }`}
              value={formik?.values?.answer}
              onBlur={formik?.handleBlur("answer")}
              onChange={(e) => formik.setFieldValue("answer", e.target.value)}
            ></textarea>
            {formik.touched.answer && formik.errors.answer && (
              <span className="error-text">{formik.errors.answer}</span>
            )}
          </div>
        </div>
        <div className="col-12 pro-mb-4 pro-rtl">
          <div className="input-wrap">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              {`(إجابة) Answer`}
            </label>
            <textarea
              id="alias_answer"
              rows={8}
              name="alias_answer"
              className={`pro-input lg ${
                formik.errors.alias_answer &&
                formik.touched.alias_answer &&
                " error"
              }`}
              value={formik?.values?.alias_answer}
              onBlur={formik?.handleBlur("alias_answer")}
              onChange={(e) =>
                formik.setFieldValue("alias_answer", e.target.value)
              }
            ></textarea>
            {formik.touched.alias_answer && formik.errors.alias_answer && (
              <span className="error-text">{formik.errors.alias_answer}</span>
            )}
          </div>
        </div>
        <div className="pro-check-box pro-py-2">
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={Boolean(formik.values?.status === 1) ?? false}
              onChange={(e) =>
                formik.setFieldValue(`status`, e?.target?.checked ? 1 : 2)
              }
            />
            <label htmlFor="default" className="pro-check-label">
              Status
            </label>
          </div>
        </div>
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
          disabled={isFaqCreate}
        >
          {!isEdit
            ? isFaqCreate
              ? "Creating..."
              : "Create"
            : isFaqCreate
            ? "Updating..."
            : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default AddFaq;
