import React from "react";
import Style from "./simlpleReport.module.scss";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const SimpleReport = ({
  extraClassNames,
  data,
  showButton,
  buttonText,
  handleButtonClick,
}) => {
  return (
    <div className={`${Style.root} ${extraClassNames}`}>
      <div className={Style.textWrapper}>
        <div className={`${Style.textInner} col-12`}>
          <div className="row">
            {data?.map((item, index) => (
              <div className={`${Style.item} col`} key={index}>
                <p className="pro-fw-medium pro-mb-1">{item?.label}</p>
                <h5
                  data-tooltip-id={`order-${index}`}
                  className="pro-ttl h5 pro-mb-0"
                >
                  {item?.value}
                </h5>
                <ReactTooltip
                  id={`order-${index}`}
                  place="bottom"
                  content={`${item?.label} : ${
                    index === 0 ? item?.value : item?.totalValue
                  }`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {showButton && (
        <div className={Style.actionWrapper}>
          <button
            onClick={handleButtonClick}
            className="pro-btn pro-btn-outline"
          >
            <span>{buttonText}</span>
          </button>
        </div>
      )}
    </div>
  );
};
