import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useStore from "./useStore";
import AddStore from "./AddStore";
import EmptyData from "../../Global/EmptyData";
import CopyStore from "./CopyStore";

const Store = () => {
  const {
    isLoading,
    isFetching,
    storeList,
    showEditModal,
    menuFetching,
    storeState,
    paginationOptions,
    copyClicked,
    formik,
    copyConfirm,
    storeNames,
    setStoreNames,
    setCopyClicked,
    handleCloseConfirmation,
    setCopyConfirm,
    handleCloseCopyModal,
    handleCopyStoreMenu,
    handleManualMenuFetching,
    handleEditColumnsClick,
    handleMenuFetching,
    updateStoreFields,
    handleDeleteModal,
    handleCreateClick,
    handleEditAction,
    handleClearClick,
    handlePagination,
    handlePageSize,
    handleSearch,
    handleDelete,
    handleSort,
    refetch,
    getRow,
  } = useStore();

  return (
    <div>
      <HeadingGroup
        title={"Store"}
        className={`pro-mb-4`}
        buttonTitle={storeList?.create_permission && "Create"}
        handleClick={handleCreateClick}
      >
        <div className="row gx-0">
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline-primary pro-items-center pro-ms-3`}
              type="submit"
              onClick={handleCopyStoreMenu}
            >
              <span className="material-symbols-outlined x4">content_copy</span>
              <span>{`Copy Store Menu`}</span>
            </button>
          </div>
          <div className="col-auto">
            <button
              className={`pro-btn pro-btn-outline-primary pro-items-center pro-ms-3`}
              type="submit"
              onClick={handleManualMenuFetching}
            >
              <span className="material-symbols-outlined x4">
                system_update_alt
              </span>
              <span>{`Menu Fetch`}</span>
            </button>
          </div>
        </div>
      </HeadingGroup>
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={storeState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: storeState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        {storeList?.result?.length === 0 ? (
          <EmptyData />
        ) : menuFetching ? (
          <div className="app-loading fill"></div>
        ) : (
          <Table
            multiSelect={false}
            data={storeList?.result || []}
            uniqueID={"id"}
            fields={storeList?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            assignIcon={
              <span className="material-symbols-outlined">summarize</span>
            }
            assignText={`Fetch Posist Menu`}
            handleSort={handleSort}
            getRow={getRow}
            clear={storeState?.clearSelection}
            loading={isFetching}
            perpage={storeState?.currentPageSize}
            assignable={true}
            handleEdit={handleEditAction}
            handleDelete={handleDeleteModal}
            handleAssign={handleMenuFetching}
            deletable={storeList?.delete_permission}
            editable={storeList?.edit_permission}
            showCheckBox={
              storeList?.delete_permission || storeList?.edit_permission
            }
          />
        )}

        {storeList?.total_count > 0 && (
          <Pagination
            currentPage={storeState?.currentPage}
            totalPageCount={Math.ceil(
              storeList?.total_count / storeState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === storeState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={storeState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Store ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={storeList?.fields || []}
              moduleId={storeList?.module_id}
              updateData={updateStoreFields}
            />
          </div>
        </ModalLayout>

        <OffCanvasLayout
          show={storeState?.showCreateModal}
          handleClose={handleCreateClick}
          title={storeState?.isEdit ? "Update Store" : "Store"}
          backdrop={"static"}
        >
          <AddStore
            refetch={refetch}
            handleCreateClick={handleCreateClick}
            isStickyFooter
          />
        </OffCanvasLayout>

        <ModalLayout
          show={copyClicked}
          handleClose={handleCloseCopyModal}
          backdrop="static"
          title={"Copy Store Menu"}
        >
          <div className="pro-m-5">
            <CopyStore
              formik={formik}
              closeModal={handleCloseCopyModal}
              setCopyConfirm={setCopyConfirm}
              setCopyClicked={setCopyClicked}
              setStoreNames={setStoreNames}
            />
          </div>
        </ModalLayout>

        <ModalLayout show={copyConfirm} handleClose={handleCloseConfirmation}>
          <div className="pro-m-5">
            <ConfirmationBox
              title={
                "Are you sure you want to copy the menu to the selected store?"
              }
              subTitle={`${storeNames.store1} to ${storeNames.store2}`}
              cancelText={"No"}
              cancelAction={handleCloseConfirmation}
              submitText={"Yes"}
              submitAction={formik.handleSubmit}
              isRight={true}
            />
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default Store;
