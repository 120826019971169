import { getAdminAxiosInstance } from "../../../../api/Admin";

export const getProfileImage = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/store/upload-image`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createStore = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/store/store`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteStore = async (storeId) => {
  const api = await getAdminAxiosInstance();

  try {
    const response = await api.get(`/store/destroy?store_id=${storeId}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};