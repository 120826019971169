import { getAdminAxiosInstance } from "../../../api/Admin";

export const deleteProduct = async (id) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.get(`/product/destroy?product_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
