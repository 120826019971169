import { FormTabs } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Style from "./addStore.module.scss";
import useAddStore from "./useAddStore";

const  AddStore=({refetch,handleCreateClick})=> {
  const { tabs, activeTab, formik, renderTabContent, handleTabClick,handleMoveToErrorTab } =
    useAddStore(refetch,handleCreateClick);
  return (
    <div className="pro-h-100 pro-d-flex pro-flex-column">
      <FormTabs
        propStyle={{ 
          root: Style.formTab_root,
          root_items : Style.formTab_root_items
        }}
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
      />
    <form className="flex-fill pro-d-flex pro-flex-column" onSubmit={(e) => {handleMoveToErrorTab();formik.handleSubmit(e); }}>{renderTabContent()}</form>
    </div>
  );
}

export default AddStore;
