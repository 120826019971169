import style from "./dashboardCard.module.scss";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import OrderListCard from "./orderListCard";

const DashboardCard = ({
  title,
  data,
  index,
  icon,
  active,
  transactions,
  isDashboard,
}) => {
  let titleName = isDashboard ? title : data?.order_name;
  return (
    <>
      {!isDashboard ? (
        <OrderListCard
          data={data}
          titleName={titleName}
          index={index}
          active={active}
        />
      ) : (
        <>
          <div className={`${style.root} ${active ? `active` : ""}`}>
            <div className={`${style.inner}`}>
              {isDashboard && <div className={`${style.icon}`}>{icon}</div>}
              <div className={`${style.body}`}>
                <h4
                  data-tooltip-id={`total-order-${index}`}
                  className="pro-ttl h4 pro-mb-2 pro-fw-medium"
                >
                  {data?.formated_current_total !== null &&
                  data?.formated_current_total !== undefined
                    ? data?.formated_current_total
                    : null}
                </h4>

                <div className="pro-d-flex pro-items-center pro-gap-2">
                  <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                    {isDashboard ? title : ""}
                  </h6>

                  {data?.percentage_difference !== "" &&
                    data?.comparison_status && (
                      <span
                        className={`${
                          data?.comparison_status === "greater"
                            ? style.increment
                            : data?.comparison_status === "lesser"
                            ? style.decrement
                            : ""
                        } ${
                          style.growth_percentage
                        } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
                      >
                        <span
                          className={`material-symbols-outlined ${style.arrow}`}
                        >
                          arrow_outward
                        </span>
                        <span>{data?.percentage_difference}%</span>
                      </span>
                    )}
                </div>
                <div
                  className={`pro-d-flex pro-items-center pro-pt-2 pro-mt-auto pro-gap-3`}
                >
                  {data?.formated_previous_total !== "" &&
                    Object.keys(data)?.includes("formated_previous_total") && (
                      <span
                        data-tooltip-id={`previous-order-${index}`}
                        className={`pro-badge  ${style.last_month}`}
                      >
                        {data?.display_text ?? "Previous"}
                        &nbsp;:&nbsp;{data?.formated_previous_total ?? ""}
                      </span>
                    )}
                </div>
              </div>
            </div>
            <ReactTooltip
              id={`total-order-${index}`}
              place="bottom"
              content={`${titleName} : ${data?.current_total}`}
              className={"react-tooltip"}
            />
            <ReactTooltip
              id={`previous-order-${index}`}
              place="bottom"
              content={`${titleName} : ${data?.previous_total}`}
              className={"react-tooltip"}
            />
          </div>
        </>
      )}
    </>
  );
};
export default DashboardCard;
