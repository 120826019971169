import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { uploadFlashVideo } from "../api";

const useVideoUploadForm = (handleClose) => {
  const [videoName, setVideoName] = useState("");
  const [videoPreview, setVideoPreview] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);
  const formik = useFormik({
    initialValues: {
      caption: "",
      video: "",
    },
    validationSchema: Yup.object({
      caption: Yup.string(),
      video: Yup.string().required(`Upload video file`),
    }),
    onSubmit: (values) => {
      setIsUploading(true);
      const formData = new FormData();
      formData.append("caption", values?.caption);
      formData.append("video", values?.video);
      uploadFlashVideo(formData).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success(`Update flash video successfully`);
          setIsUploading(false);
          handleClose();
        } else {
          toast.error(`Something went wrong!`);
          handleClose();
        }
      });
    },
  });

  const handleVideoChange = (e, fieldName) => {
    const file = e.target.files[0];

    if (file) {
      setVideoName(file.name);

      const fileSize = file.size / 1024 / 1024; // in MB
      if (fileSize > 5) {
        formik.setFieldError("video", "File size exceeds 5MB limit.");
        return;
      }
      setIsLoadingVideo(true);
      const reader = new FileReader();
      formik.setFieldValue("video", file);
      reader.onloadend = () => {
        setVideoPreview(reader.result);
        setIsLoadingVideo(false);
      };
      reader.readAsDataURL(file);
    }
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  return {
    formik,
    videoName,
    videoPreview,
    isLoadingVideo,
    isUploading,
    getFieldError,
    handleVideoChange,
  };
};

export default useVideoUploadForm;
