import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useCreateCustomNotification from "./useCreateCustomNotification";
import Select from "react-select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Style from "../customNotification.module.scss";

const CreateCustomNotification = ({ isStickyFooter, handleClose, refetch }) => {
  const {
    getFieldError,
    handleImageChange,
    handleAddSelectedUser,
    handleRemoveSelectedUser,
    handleChangeRecepientFilters,
    handleFileChange,
    handleTimeChange,
    handleChangeNotificationType,
    imageUrlValidation,
    aliasImageUrlValidation,
    fileLoader,
    customNotificationState,
    NotificationBasicData,
    formik,
  } = useCreateCustomNotification({ handleClose, refetch });
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Notification Type
          </label>
          <Select
            id="notification_type"
            name="notification_type"
            placeholder={"Select"}
            className={`pro-input lg  ${
              getFieldError("notification_type") && " error"
            }`}
            classNamePrefix="pro-input"
            options={NotificationBasicData?.data?.notification_types ?? []}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.slug}
            value={NotificationBasicData?.data?.notification_types?.filter(
              (item) => item.slug === formik.values?.notification_type
            )}
            onBlur={formik.handleBlur("notification_type")}
            onChange={(value) => {
              handleChangeNotificationType(value);
            }}
            menuPlacement="auto"
          />
          {getFieldError("notification_type") && (
            <span className="error-text">
              {getFieldError("notification_type")}
            </span>
          )}
        </div>
        {(formik?.values?.notification_type === "push_notification" ||
          formik?.values?.notification_type ===
            "scheduled_push_notification") && (
          <>
            <Input
              label={"Title"}
              type="text"
              id="title"
              name="title"
              className={`pro-input lg ${getFieldError("title") && " error"}`}
              {...formik.getFieldProps("title")}
              error={getFieldError("title")}
              errorMessage={getFieldError("title")}
            />

            <Input
              label={"Alias Title"}
              type="text"
              id="alias_title"
              name="alias_title"
              className={`pro-input lg ${
                getFieldError("alias_title") && " error"
              }`}
              {...formik.getFieldProps("alias_title")}
              error={getFieldError("alias_title")}
              errorMessage={getFieldError("alias_title")}
            />
            <Input
              label={"Message"}
              type="text"
              id="message"
              name="message"
              className={`pro-input lg ${getFieldError("message") && " error"}`}
              {...formik.getFieldProps("message")}
              error={getFieldError("message")}
              errorMessage={getFieldError("message")}
            />

            <Input
              label={"Alias Message"}
              type="text"
              id="alias_message"
              name="alias_message"
              className={`pro-input lg ${
                getFieldError("alias_message") && " error"
              }`}
              {...formik.getFieldProps("alias_message")}
              error={getFieldError("alias_message")}
              errorMessage={getFieldError("alias_message")}
            />
          </>
        )}
        <>
          <div className="input-wrap pro-mb-4 ">
            <label
              htmlFor="image"
              className={`pro-font-sm pro-mb-1 pro-fw-medium`}
            >
              Image (.jpeg, .jpg , .png)
            </label>
            <div className="input-drag">
              <input
                type="file"
                accept=".jpeg,.jpg , .png "
                className={`pro-input ${
                  imageUrlValidation["image_url"]?.length > 0
                    ? imageUrlValidation["image_url"] && "error"
                    : formik.errors?.image_url &&
                      formik.touched?.image_url &&
                      " error"
                }`}
                id="image"
                onBlur={formik.handleBlur("image_url")}
                onChange={(e) => handleImageChange(e, "image")}
              />
              <span className="input-drag-box">
                <IconText
                  title={
                    customNotificationState?.isLoadingImage
                      ? "Uploading..."
                      : customNotificationState?.imageName !== ""
                      ? customNotificationState?.imageName
                      : `Drop files to attach or browse`
                  }
                />
              </span>

              {imageUrlValidation["image_url"]?.length > 0 ? (
                <span className="error-text">
                  {imageUrlValidation["image_url"]}
                </span>
              ) : (
                formik?.touched?.image_url &&
                formik?.errors?.image_url && (
                  <span className="error-text">{formik.errors.image_url}</span>
                )
              )}
            </div>
            <div className={`col-2 pro-my-2`}>
              <div className={``}>
                <Image
                  width={100}
                  height={100}
                  src={customNotificationState?.imagePreview}
                  alt={`image - 01`}
                />
              </div>
            </div>
          </div>

          <div className="input-wrap pro-mb-4 ">
            <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
              Alias Image (.jpeg, .jpg ,.png)
            </label>
            <div className="input-drag">
              <input
                type="file"
                accept=".jpeg,.jpg , .png "
                className={`pro-input ${
                  aliasImageUrlValidation["alias_image_url"]?.length > 0
                    ? aliasImageUrlValidation["alias_image_url"] && "error"
                    : formik?.errors?.alias_image_url &&
                      formik?.touched?.alias_image_url &&
                      " error"
                }`}
                id="image"
                onBlur={formik.handleBlur("alias_image_url")}
                onChange={(e) => handleImageChange(e, "alias_image_url")}
              />
              <span className="input-drag-box">
                <IconText
                  title={
                    customNotificationState?.isLoadingAliasImage
                      ? "...Uploading"
                      : customNotificationState?.aliasImageName !== ""
                      ? customNotificationState?.aliasImageName
                      : `Drop files to attach or browse`
                  }
                />
              </span>
              {aliasImageUrlValidation["alias_image_url"]?.length > 0 ? (
                <span className="error-text">
                  {aliasImageUrlValidation["alias_image_url"]}
                </span>
              ) : (
                formik?.touched?.alias_image_url &&
                formik?.errors?.alias_image_url && (
                  <span className="error-text">
                    {formik.errors.alias_image_url}
                  </span>
                )
              )}
            </div>
            <div className={`col-2 pro-my-2`}>
              <div className={``}>
                <Image
                  width={100}
                  height={100}
                  src={customNotificationState?.aliasImagePreview}
                  alt={`image - 01`}
                />
              </div>
            </div>
          </div>
        </>

        {formik?.values?.notification_type !== "in_app_notification" && (
          <Input
            label={"Launch URL"}
            type="text"
            id="launch_url"
            name="launch_url"
            className={`pro-input lg ${
              getFieldError("launch_url") && " error"
            }`}
            {...formik.getFieldProps("launch_url")}
            error={getFieldError("launch_url")}
            errorMessage={getFieldError("launch_url")}
          />
        )}

        {formik?.values?.notification_type ===
          "scheduled_push_notification" && (
          <>
            <Input
              label={"Select Date"}
              type="date"
              id="schedule_date"
              name="schedule_date"
              className={`pro-input lg ${
                getFieldError("schedule_date") && " error"
              }`}
              {...formik.getFieldProps("schedule_date")}
              onChange={(event) =>
                formik?.setFieldValue("schedule_date", event?.target?.value)
              }
              error={getFieldError("schedule_date")}
              errorMessage={getFieldError("schedule_date")}
            />
            <div className={`${Style.time_wrap} input-wrap pro-mb-4`}>
              <label
                htmlFor=""
                className={`pro-font-sm pro-mb-1 pro-fw-medium`}
              >
                Select Time
              </label>
              <div className={Style.time_picker_wrap}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={formik?.values?.schedule_time || ""}
                    onChange={(newValue) => handleTimeChange(newValue)}
                    className="time-picker"
                  />
                </LocalizationProvider>
              </div>
              {getFieldError("schedule_time") && (
                <span className="error-text error error-img">
                  {getFieldError("schedule_time")}
                </span>
              )}
            </div>
          </>
        )}

        {formik?.values?.notification_type === "in_app_notification" && (
          <>
            <div className="input-wrap pro-mb-4">
              <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
                Type
              </label>
              <Select
                id="type"
                name="type"
                placeholder={"Select"}
                className={`pro-input lg  ${getFieldError("type") && " error"}`}
                classNamePrefix="pro-input"
                options={NotificationBasicData?.data?.types ?? []}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.slug}
                value={NotificationBasicData?.data?.types?.filter(
                  (item) => item.slug === formik.values?.type
                )}
                onBlur={formik.handleBlur("type")}
                onChange={(value) => formik.setFieldValue("type", value.slug)}
                menuPlacement="auto"
              />
              {getFieldError("type") && (
                <span className="error-text">{getFieldError("type")}</span>
              )}
            </div>
            {formik?.values?.type &&
              NotificationBasicData?.data?.dropdown_list?.[
                formik?.values?.type
              ] && (
                <div className="input-wrap pro-mb-4">
                  <label
                    htmlFor=""
                    className="pro-font-sm pro-mb-1 pro-fw-medium"
                  >
                    Selected Item
                  </label>
                  <Select
                    id="selected_item"
                    name="selected_item"
                    placeholder={"Select"}
                    className={`pro-input lg  ${
                      getFieldError("selected_item") && " error"
                    }`}
                    classNamePrefix="pro-input"
                    options={
                      NotificationBasicData?.data?.dropdown_list?.[
                        formik?.values?.type
                      ] ?? []
                    }
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?._id}
                    value={NotificationBasicData?.data?.dropdown_list?.[
                      formik?.values?.type
                    ]?.filter(
                      (item) => item._id === formik.values?.selected_item
                    )}
                    onBlur={formik.handleBlur("selected_item")}
                    onChange={(value) =>
                      formik.setFieldValue("selected_item", value._id)
                    }
                    menuPlacement="auto"
                    isDisabled={!formik?.values?.type}
                  />
                  {getFieldError("selected_item") && (
                    <span className="error-text">
                      {getFieldError("selected_item")}
                    </span>
                  )}
                </div>
              )}
          </>
        )}
        {
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Recipient Filters
            </label>
            <Select
              isMulti
              id="recepient_filters"
              name="recepient_filters"
              placeholder={"Select"}
              isClearable={true}
              className={`pro-input lg multi-select ${
                getFieldError("recepient_filters") &&
                formik?.values?.recepient_filters?.length < 1 &&
                " error"
              }`}
              classNamePrefix="pro-input"
              options={
                formik?.values?.recepient_filters?.includes("selected_users") ||
                formik?.values?.recepient_filters?.includes("all_users")
                  ? []
                  : NotificationBasicData?.data?.recepient_types ?? []
              }
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.slug}
              value={NotificationBasicData?.data?.recepient_types?.filter(
                (item) => formik?.values?.recepient_filters?.includes(item.slug)
              )}
              onBlur={formik.handleBlur("recepient_filters")}
              onChange={(value) => handleChangeRecepientFilters(value)}
              menuPlacement="auto"
            />
            {getFieldError("recepient_filters") &&
              formik?.values?.recepient_filters?.length < 1 && (
                <span className="error-text">
                  {getFieldError("recepient_filters")}
                </span>
              )}
          </div>
        }

        {formik?.values.recepient_filters.includes("from_excel_file") &&
          !formik?.values?.recepient_filters?.includes("all_users") &&
          !formik?.values?.recepient_filters?.includes("selected_users") && (
            <div className="input-wrap pro-mb-4">
              <label
                htmlFor="excel_url"
                className={`pro-font-sm pro-mb-1 pro-fw-medium`}
              >
                Excel File Upload
              </label>
              <div className="input-drag">
                <input
                  type="file"
                  accept=".xlsx,.csv"
                  className={`pro-input ${
                    formik.errors.excel_url &&
                    formik?.touched?.excel_url &&
                    " error"
                  }`}
                  id="excel_url"
                  onBlur={formik.handleBlur("excel_url")}
                  onChange={(e) => handleFileChange(e)}
                />
                <span className="input-drag-box">
                  <IconText
                    title={
                      fileLoader
                        ? "Uploading..."
                        : customNotificationState?.excelFileName !== ""
                        ? customNotificationState?.excelFileName
                        : `Drop files to attach or browse`
                    }
                  />
                </span>
                {formik.errors.excel_url && formik?.touched?.excel_url && (
                  <span className="error-text">{formik.errors.excel_url}</span>
                )}
              </div>
            </div>
          )}
        {formik?.values.recepient_filters.includes("age") &&
          !formik?.values?.recepient_filters?.includes("all_users") &&
          !formik?.values?.recepient_filters?.includes("selected_users") && (
            <>
              <Input
                label={"Age From"}
                type="text"
                id="age_from"
                name="age_from"
                className={`pro-input lg ${
                  getFieldError("age_from") && " error"
                }`}
                {...formik.getFieldProps("age_from")}
                error={getFieldError("age_from")}
                errorMessage={getFieldError("age_from")}
              />

              <Input
                label={"Age To"}
                type="text"
                id="age_to"
                name="age_to"
                className={`pro-input lg ${
                  getFieldError("age_to") && " error"
                }`}
                {...formik.getFieldProps("age_to")}
                error={getFieldError("age_to")}
                errorMessage={getFieldError("age_to")}
              />
            </>
          )}

        {formik?.values?.recepient_filters?.includes("gender") &&
          !formik?.values?.recepient_filters?.includes("all_users") &&
          !formik?.values?.recepient_filters?.includes("selected_users") && (
            <div className="input-wrap pro-mb-4">
              <label
                htmlFor="gender"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Gender
              </label>
              <Select
                id="gender"
                name="gender"
                placeholder={"Select"}
                isClearable={true}
                className={`pro-input lg  ${
                  getFieldError("gender") && " error"
                }`}
                classNamePrefix="pro-input"
                options={NotificationBasicData?.data?.gender ?? []}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
                value={NotificationBasicData?.data?.gender?.filter(
                  (item) => item?.id === formik?.values?.gender
                )}
                onBlur={formik.handleBlur("gender")}
                onChange={(value) => formik?.setFieldValue("gender", value?.id)}
                menuPlacement="auto"
              />
              {getFieldError("gender") && (
                <span className="error-text">{getFieldError("gender")}</span>
              )}
            </div>
          )}

        {formik?.values?.recepient_filters?.includes("nationality") &&
          !formik?.values?.recepient_filters?.includes("all_users") &&
          !formik?.values?.recepient_filters?.includes("selected_users") && (
            <div className="input-wrap pro-mb-4">
              <label
                htmlFor="nationality"
                className="pro-font-sm pro-mb-1 pro-fw-medium"
              >
                Nationality
              </label>
              <Select
                isMulti
                id="nationality"
                name="nationality"
                placeholder={"Select"}
                isClearable={true}
                className={`pro-input lg multi-select ${
                  getFieldError("nationality") && " error"
                }`}
                classNamePrefix="pro-input"
                options={NotificationBasicData?.data?.nationality ?? []}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.id}
                value={NotificationBasicData?.data?.nationality?.filter(
                  (item) => formik?.values?.nationality?.includes(item.id)
                )}
                onBlur={formik.handleBlur("nationality")}
                onChange={(value) =>
                  formik.setFieldValue(
                    "nationality",
                    value?.map((item) => item?.id)
                  )
                }
                menuPlacement="auto"
              />
              {getFieldError("nationality") && (
                <span className="error-text">
                  {getFieldError("nationality")}
                </span>
              )}
            </div>
          )}

        {formik?.values.recepient_filters.includes("inactive_users") &&
          !formik?.values?.recepient_filters?.includes("all_users") &&
          !formik?.values?.recepient_filters?.includes("selected_users") && (
            <>
              <Input
                label={"Inactive Users (Day count from)"}
                type="text"
                id="day_from"
                name="day_from"
                className={`pro-input lg ${
                  getFieldError("day_from") && " error"
                }`}
                {...formik.getFieldProps("day_from")}
                q
                error={getFieldError("day_from")}
                errorMessage={getFieldError("day_from")}
              />

              <Input
                label={"Inactive Users (Day count to)"}
                type="text"
                id="day_to"
                name="day_to"
                className={`pro-input lg ${
                  getFieldError("day_to") && " error"
                }`}
                {...formik.getFieldProps("day_to")}
                error={getFieldError("day_to")}
                errorMessage={getFieldError("day_to")}
              />
            </>
          )}
        {formik?.values.recepient_filters.includes("selected_users") &&
          !formik?.values?.recepient_filters?.includes("all_users") && (
            <>
              <div className={Style.add_wrap}>
                <Input
                  label={"Add Users"}
                  type="text"
                  id="add_user"
                  name="add_user"
                  className={`pro-input lg ${
                    getFieldError("add_user") && "error"
                  }`}
                  {...formik.getFieldProps("add_user")}
                  onChange={(e) =>
                    formik.setFieldValue("add_user", e.target.value)
                  }
                  error={getFieldError("add_user")}
                  errorMessage={getFieldError("add_user")}
                />
                <button
                  onClick={handleAddSelectedUser}
                  className={`${Style.add_btn}`}
                >
                  <span className="material-symbols-outlined x4"> add </span>
                </button>
              </div>
              <div className={`${Style.number_badge_wrap} pro-mb-4`}>
                {formik?.values?.selected_users?.map((item, index) => (
                  <div key={index} className={Style.badge}>
                    {item}
                    <span
                      onClick={() => handleRemoveSelectedUser(index)}
                      className="material-symbols-outlined"
                    >
                      close
                    </span>
                  </div>
                ))}
              </div>
              {formik?.errors?.selected_users &&
                formik?.values?.selected_users?.length <= 0 && (
                  <span className="error-text error error-img">
                    {getFieldError("selected_users")}
                  </span>
                )}
            </>
          )}

        <div className="pro-check-box pro-py-2">
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={
                Boolean(formik.values?.notification_disabled_users === 1) ??
                false
              }
              onChange={(e) =>
                formik.setFieldValue(
                  `notification_disabled_users`,
                  e?.target?.checked ? 1 : 2
                )
              }
            />
            <label htmlFor="default" className="pro-check-label">
              Include Notification Disabled Users
            </label>
          </div>
        </div>

        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            disabled={
              (aliasImageUrlValidation["alias_image_url"]?.length > 0 &&
                formik?.values?.notification_type === "in_app_notification") ||
              (imageUrlValidation["image_url"]?.length > 0 &&
                formik?.values?.notification_type === "in_app_notification") ||
              customNotificationState?.isLoadingImage ||
              customNotificationState?.isLoadingAliasImage ||
              formik?.isSubmitting
            }
            onClick={formik.handleSubmit}
          >
            {formik.isSubmitting ? "Sending..." : "Send"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomNotification;
