import { useDispatch, useSelector } from "react-redux";
import {
  useGetTransactionsTypeDataQuery,
  useUpdateTransactionTypeTableHeadDataMutation,
} from "../../../store/queries/User";
import { updateConfig } from "../../../store/slices/User/Transactiontype/transactionTypeSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";

const useTransactiontype = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const transactionTypeState = useSelector((state) => state.transactiontype);
  const {
    data: transactionTypeList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetTransactionsTypeDataQuery({
    page_size: transactionTypeState?.currentPageSize,
    page: transactionTypeState?.currentPage,
    sort: transactionTypeState?.sortBy,
    sort_order: transactionTypeState?.sortOrder,
    search: transactionTypeState?.search,
  });
  const [updateTransactionTypeFields] =
    useUpdateTransactionTypeTableHeadDataMutation();
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e?.target?.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (transactionTypeState?.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            transactionTypeState?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    transactionTypeList,
    tableFields,
    transactionTypeState,
    showEditModal,
    paginationOptions,
    handleEditColumnsClick,
    updateTransactionTypeFields,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useTransactiontype;
