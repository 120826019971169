import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getMenuPermissions,
  updateConfig as updateGlobalConfig,
} from "../../../../store/slices/Global";
import {
  getAllPermissions,
  getPermission,
  updateConfig,
} from "../../../../store/slices/Admin/Role/roleSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AddUpdateRole } from "../api";
import { admin } from "../../../../store/queries/Admin";

const useAddRole = () => {
  const statusOptions = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 2 },
  ];
  const [params] = useSearchParams();
  const role = params.get("role");
  const update = params.get("update");

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const state = useSelector((state) => state.role);
  const [permissionModules, setPermissionModules] = useState({});
  const [singlePermissionModules, setSinglePermissionModules] = useState([]);
  useEffect(() => {
    dispatch(
      updateGlobalConfig((state) => {
        state.menuDrawer = {
          show: true,
          backTo: "/admin/roles",
          text: "Back to Roles",
        };
      })
    );
    if (state.allPermissionsStatus === "idle") {
      dispatch(getAllPermissions());
    }
    if (role) {
      dispatch(getPermission({ id: role }))
        .unwrap()
        .then((response) => {
          if (update === "true") {
            formik?.setFieldValue("name", response?.data?.data?.name);
            formik?.setFieldValue("level", response?.data?.data?.level);
            const statusValue = statusOptions?.filter(
              (item) => item?.value === response?.data?.data?.status
            );
            formik?.setFieldValue("status", statusValue?.[0]);
          }
          // Extracting menu and sub-menu IDs
          const menuPermissions = response?.data?.data?.menu_permissions;

          const permissionModules = {};
          const singlePermissionModules = [];
          menuPermissions?.forEach((permission) => {
            if (permission?.sub_menu_permissions) {
              // If there are sub-menu permissions
              permissionModules[permission?.menu_id] =
                permission?.sub_menu_permissions.map((subMenu) => {
                  return { sub_menu_id: subMenu.sub_menu_id };
                });
            } else {
              // If there are no sub-menu permissions
              singlePermissionModules.push(permission?.menu_id);
            }
          });

          // Set the state
          setPermissionModules(permissionModules);
          setSinglePermissionModules(singlePermissionModules);
        });
    }
    return () => {
      dispatch(
        updateGlobalConfig((state) => {
          state.menuDrawer = {};
        })
      );
    };
    // eslint-disable-next-line
  }, [state?.allPermissions, role, update, state.isEdit]);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("Enter Name")
      .max(80, "Please enter a valid name"),
    level: Yup.string().required(`Enter level`),
    status: Yup.object().required("Select Status"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      level: "",
      status: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      const commonModules = Object.keys(permissionModules).map((mId) => {
        return { menu_id: mId, sub_menu_permissions: permissionModules[mId] };
      });
      const filteredData = commonModules.filter(
        (obj) => obj?.sub_menu_permissions?.length > 0
      );
      const singleFinal = singlePermissionModules?.map((value) => {
        return {
          menu_id: value,
        };
      });

      if (!update) {
        let data = {
          name: values?.name,
          level: values?.level,
          status: values?.status?.value,
          menu_permissions: [...filteredData, ...singleFinal],
        };
        AddUpdateRole(data)
          .then((response) => {
            if (response.status === 200) {
              navigate("/admin/roles");
              toast.success("Role created successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              dispatch(admin?.util?.invalidateTags(["Roles"]));
            } else if (response?.status_code === 422 && response?.errors) {
              Object.keys(response.errors).forEach((field) => {
                formik.setFieldError(field, response.errors[field]);
              });
            } else {
              toast.error("Failed to Create Role");
            }
          })
          .catch(() => {
            toast.error("Failed to Create Role");
          });
      } else {
        let editData = {
          role_id: role,
          name: values?.name,
          status: values?.status?.value,
          level: values?.level,
          menu_permissions: [...filteredData, ...singleFinal],
        };
        AddUpdateRole(editData).then((response) => {
          if (response.status === 200) {
            dispatch(
              updateConfig((state) => {
                state.allPermissionsStatus = "idle";
                state.allPermissions = [];
                state.clearSelection = true;
              })
            );
            dispatch(admin?.util?.invalidateTags(["Roles"]));
            toast.success("Role updated successfully", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(
              getMenuPermissions({
                token: localStorage.getItem("USER_ACCESS_TOKEN_CARIBOU"),
              })
            );
            navigate("/admin/roles");
          } else if (response?.status_code === 422 && response?.errors) {
            Object.keys(response.errors).forEach((field) => {
              formik.setFieldError(field, response.errors[field]);
            });
          } else {
            toast.error("Failed to Update Role");
          }
        });
      }
      // }
    },
  });

  const handlePermissionChange = (item, moduleId) => {
    if (item.e.target.checked) {
      setPermissionModules((prev) => {
        return {
          ...prev,
          [moduleId]: prev?.[moduleId]
            ? [...prev[moduleId], { sub_menu_id: item._id }]
            : [{ sub_menu_id: item._id }],
        };
      });
    } else {
      setPermissionModules((prev) => {
        return {
          ...prev,
          [moduleId]: prev[moduleId]?.filter(
            (permissions) => permissions.sub_menu_id !== item._id
          ),
        };
      });
    }
  };
  const handleSingleMenuChange = (item, moduleId) => {
    if (item.e.target.checked) {
      setSinglePermissionModules([...singlePermissionModules, moduleId]);
    } else {
      setSinglePermissionModules(
        singlePermissionModules?.filter((itemId) => itemId !== moduleId)
      );
    }
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    state,
    role,
    update,
    statusOptions,
    getFieldError,
    handlePermissionChange,
    handleSingleMenuChange,
  };
};

export default useAddRole;
