import { Auth } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { useOutletContext } from "react-router-dom";
import useForgotPassword from "./useForgotPassword";

const ForgotPassword = () => {
  const emailRef = useOutletContext();

  const { formik, emailStatus, otpReceived, handleLoginClick } =
    useForgotPassword(emailRef);

  return (
    <Auth
      title={"Forgot Password"}
      buttonText={`Submit`}
      handleClick={formik.handleSubmit}
      actionText={`Back to login`}
      handleActionClick={handleLoginClick}
      loading={emailStatus === "pending"}
    >
      <div className="input-wrap pro-mb-5">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Email
        </label>
        <input
          name="email"
          value={formik?.values?.email}
          onChange={formik?.handleChange}
          onBlur={formik.handleBlur}
          disabled={otpReceived}
          type="text"
          className={`pro-input lg ${
            formik.errors.email &&
            (formik.touched.email || formik.values.email) &&
            "error"
          }`}
        />
        {formik.errors.email &&
          (formik.touched.email || formik.values.email) && (
            <span className="error-text">{formik?.errors?.email}</span>
          )}
      </div>
      {otpReceived ? (
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Enter OTP sent to your email address
          </label>
          <input
            name="otp"
            value={formik?.values?.otp}
            onChange={formik?.handleChange}
            onBlur={formik.handleBlur}
            type="text"
            className={`pro-input lg ${
              formik.errors.otp &&
              (formik.touched.otp || formik.values.otp) &&
              "error"
            }`}
          />
          {formik.errors.otp && (formik.touched.otp || formik.values.otp) && (
            <span className="error-text">{formik?.errors?.otp}</span>
          )}
        </div>
      ) : (
        ""
      )}
    </Auth>
  );
};

export default ForgotPassword;
