import React from "react";
import ReactPlayer from "react-player";
import Style from "./videoCard.module.scss";
import useVideoCard from "./useVideoCard";

const VideoCard = ({ handleClose, isShortVideo, data }) => {
  const {
    volume,
    duration,
    isPlaying,
    playerRef,
    currentTime,
    handleSeekMouseDown,
    handleSeekMouseUp,
    handleSeekChange,
    handlePlayPause,
    handleDuration,
    handleProgress,
    formatTime,
  } = useVideoCard();

  return (
    <div className={`${Style.root} pro-p-2`}>
      <div
        className={`${Style.player_control_wrap} ${Style.video_control} ${
          isShortVideo ? Style.short_video : ""
        }`}
      >
        <div style={{ touchAction: "none", width: "100%", height: "0px" }}>
          <ReactPlayer
            ref={playerRef}
            url={data}
            // controls
            width="100%"
            height="auto"
            playing={isPlaying}
            playsinline
            volume={volume}
            onProgress={handleProgress}
            onDuration={handleDuration}
          />
        </div>
        <span className={Style.video_btn}>
          <button
            onClick={handlePlayPause}
            className={`pro-btn ${Style.btn_play}`}
          >
            {isPlaying && !(parseInt(duration) === parseInt(currentTime)) ? (
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={Style.play_fill}
                  d="M0 18C0 8.05887 8.05887 0 18 0V0C27.9411 0 36
8.05887 36 18V18C36 27.9411 27.9411 36 18 36V36C8.05887 36 0 27.9411 0
18V18Z"
                />
                <rect
                  x="11.4981"
                  y="10.155"
                  width="4.50379"
                  height="15.7668"
                  fill="white"
                />
                <rect
                  x="19.9981"
                  y="10.0781"
                  width="4.50379"
                  height="15.7668"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={Style.play_fill}
                  d="M0 18C0 8.05887 8.05887 0 18 0V0C27.9411 0 36
8.05887 36 18V18C36 27.9411 27.9411 36 18 36V36C8.05887 36 0 27.9411 0
18V18Z"
                />
                <path
                  d="M25.4806 17.1318C26.1524 17.5157 26.1524 18.4843
25.4806 18.8682L14.4961 25.1451C13.8295 25.526 13 25.0446 13
24.2768L13 11.7232C13 10.9554 13.8295 10.474 14.4961 10.8549L25.4806
17.1318Z"
                  fill="white"
                />
              </svg>
            )}
          </button>
        </span>
        <div
          className={`${Style.input_container} pro-d-flex
pro-items-center pro-gap-4`}
        >
          <div className={Style.slider_wrapper}>
            <input
              type="range"
              min={0}
              max={duration}
              step={0.1}
              value={currentTime}
              onMouseDown={handleSeekMouseDown}
              onChange={handleSeekChange}
              onMouseUp={handleSeekMouseUp}
              className={Style.pro_audio_input}
            />

            <div className={Style.tracker_wrpper}>
              <div
                className={Style.tracker_bar}
                style={{ width: `${(currentTime / duration) * 100}%` }}
              ></div>
            </div>
          </div>
          <div className={`pro-d-flex pro-gap-1 ${Style.time_wraper}`}>
            <span className={`${Style.time_start} ${Style.time} pro-fw-medium`}>
              {/* {played} */}
              {formatTime(currentTime)}
            </span>
            /
            <span className={`${Style.time_end} ${Style.time} pro-fw-medium`}>
              {/* {minutes}:{remainingSeconds.toFixed(2)} */}
              {formatTime(duration)}
            </span>
          </div>
        </div>
      </div>
      <div
        className={`${Style.buttons} pro-d-flex pro-mt-2 pro-m-1 pro-justify-end `}
      >
        <button className={`pro-btn pro-btn-primary`} onClick={handleClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default VideoCard;
