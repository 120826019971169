import { useState } from "react";

const useVideoContent = () => {
  const [showComponent, setShowComponent] = useState(false);

  const handleUploadClick = () => {
    setShowComponent(true);
  };
  const handleClose = () => {
    setShowComponent(false);
  };
  return { showComponent, handleUploadClick, handleClose };
};

export default useVideoContent;
