import { HeadingGroup, Input } from "@wac-ui-dashboard/wac_component_library";
import useAddRole from "./useAddRole";
import Style from "./addRole.module.scss";
import Select from "react-select";
import { Permissions } from "../../../Global/Permissions";
// import { capitalizeOnSpace } from "../../../../utils/Functions/table";
const AddRole = () => {
  const {
    state,
    role,
    formik,
    statusOptions,
    getFieldError,
    handlePermissionChange,
    handleSingleMenuChange,
  } = useAddRole();
  return (
    <>
      <HeadingGroup
        title={"Role Management"}
        buttonTitle={"Save"}
        handleClick={formik?.handleSubmit}
        extraClassName={`pro-justify-between pro-pb-4`}
        buttonProps={{ type: "submit" }}
      />
      <div className={`row`}>
        <div className="col">
          <Input
            label={"Name"}
            type="text"
            id="name"
            name="name"
            className={`pro-input lg ${getFieldError("name") && " error"}`}
            {...formik.getFieldProps("name")}
            // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
            error={getFieldError("name")}
            errorMessage={getFieldError("name")}
          />
        </div>
        <div className="col">
          <Input
            label={"Level"}
            type="text"
            id="level"
            name="level"
            className={`pro-input lg ${getFieldError("level") && " error"}`}
            {...formik.getFieldProps("level")}
            error={getFieldError("level")}
            errorMessage={getFieldError("level")}
          />
        </div>
        <div className="input-wrap pro-mb-4 col">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Status
          </label>
          <Select
            id="status"
            name="status"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("status") && " error"}`}
            classNamePrefix="pro-input"
            options={statusOptions}
            value={formik?.values?.status}
            getOptionLabel={(option) => option?.label}
            getOptionValue={(option) => option?.value}
            onBlur={formik.handleBlur("status")}
            onChange={(value) => formik?.setFieldValue("status", value || null)}
            isMulti={false}
            menuPlacement="auto"
          />
          {getFieldError("status") && (
            <span className="error-text">{getFieldError("status")}</span>
          )}
        </div>
      </div>
      <div className="pro-pb-6">
        {(role ? state.rolePermission?.[role] : true) &&
          state?.allPermissions?.map?.((permission, index) => (
            <div className={Style.root} key={index}>
              <Permissions
                title={permission?.menu_name}
                permissions={permission?.sub_menu_permissions}
                defaultCheckedList={
                  state.rolePermission?.[role]?.menu_permissions
                }
                menu_id={permission?._id}
                key={index}
                uniqueID={"_id"}
                singleID={permission?._id}
                handleChange={(item) =>
                  handlePermissionChange(item, permission?._id)
                }
                handleSingleChange={(item) =>
                  handleSingleMenuChange(item, permission?._id)
                }
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default AddRole;
