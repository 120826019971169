import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  editDeliveryDetailsData: {},
  showDeleteModal: false,
  showCreateModal: false,
  showEditModal: false,
  selectedId: "",
  isEdit: false,
  deleteConfirmationModal: false,
  exportModal: false,
  imagePreview: "",
  imageName: "",
};

export const getDeliveryAreasEditData = createAsyncThunk(
  "/delivery-areas/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(
        `/delivery/edit-delivery-area?area_id=${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const deliveryAreasSlice = createSlice({
  name: "deliveryAreas",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDeliveryAreasEditData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getDeliveryAreasEditData.fulfilled, (state, action) => {
        state.editDeliveryDetailsData = action.payload?.data;
      })
      .addCase(getDeliveryAreasEditData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = deliveryAreasSlice.actions;
export default deliveryAreasSlice.reducer;
