import { useFormik } from "formik";
import { useState } from "react";
import { changePassword } from "../api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const useChangePassword = (emailRef) => {
  const navigate = useNavigate();
  const [showNewPassword, setNewShowPassword] = useState(true);
  const [showReEnterPassword, setShowReEnterPassword] = useState(true);
  const [status, setStatus] = useState("idle");
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.password) {
        errors.password = "*Password Required";
      }
      if (!values.confirm_password) {
        errors.confirm_password = "*Confirm Password Required";
      }
      if (values.confirm_password !== values.password) {
        errors.confirm_password = "Password doesn't match with new password";
      }
      return errors;
    },
    onSubmit: (values) => {
      setStatus("pending");
      const params = {
        email: emailRef.current,
        password: values?.password,
        confirm_password: values?.confirm_password,
      };
      changePassword(params).then((response) => {
        if (response?.data?.status_code === 200) {
          setStatus("success");
          toast.success(`Password changed successfully`);
          navigate("/login");
        } else if (response?.data?.status_code === 422) {
          setStatus("failed");
          toast.error(`New password and Confirm password doesn't match`);
        } else {
          toast.error(`Something went wrong!`);
        }
      });
    },
  });
  const handleShowNewPassword = (e) => {
    e.preventDefault();

    setNewShowPassword(!showNewPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleShowReEnteredPassword = (e) => {
    e.preventDefault();

    setShowReEnterPassword(!showReEnterPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  return {
    formik,
    status,
    showNewPassword,
    showReEnterPassword,
    handleShowNewPassword,
    handleShowReEnteredPassword,
  };
};

export default useChangePassword;
