import React, { useEffect, useState } from "react";
import {
  getProductChangeData,
  updateConfig,
} from "../../../store/slices/Admin/Products/productSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  product,
  useGetProductListDataQuery,
  useUpdateProductTableHeadDataMutation,
} from "../../../store/queries/Product";

import {
  useUpdateProductStatusMutation,
  useGetProductBasicDataQuery,
} from "../../../store/queries/Product";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconText, Image } from "@wac-ui-dashboard/wac_component_library";
import Style from "./product.module.scss";
import Assets from "../../../assets/Assets";
import { deleteProduct } from "./api";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";

const useProduct = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const shopData = JSON.parse(localStorage.getItem("shopData"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productState = useSelector((state) => state.product);
  const { showEditModal, offCanvasWidth, offCanvasEditWidth } = useSelector(
    (state) => state.global
  );
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  let path = window.location.pathname.split("/").pop();
  const { data: productBasicData } = useGetProductBasicDataQuery();

  useEffect(() => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.search = "";
        state.currentPageSize = 10;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: productList = {},
    isFetching,
    refetch,
  } = useGetProductListDataQuery({
    customer_key: shopData?.customer_key,
    category_id: productState?.selectedCategory,
    status: "",
    per_page: productState?.currentPageSize,
    page: productState?.currentPage,
    sort: productState?.sortBy,
    sort_order: productState?.sortOrder,
    search: productState?.search,
  });
  const [updateProductFields] = useUpdateProductTableHeadDataMutation();
  const [updateProductStatus] = useUpdateProductStatusMutation();

  const categoryList = productBasicData?.data?.category?.map((item) => {
    return {
      label: item?.name,
      value: item?._id,
    };
  });

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };
  useEffect(() => {
    if (productState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [productState.clearSelection]);
  useEffect(() => {
    if (path === "product-list") {
      dispatch(
        globalUpdateConfig((state) => {
          state.menuDrawer = {
            show: true,
            backTo: "/admin/store",
            text: "Back to Store",
          };
        })
      );
    }
    return () => {
      dispatch(
        globalUpdateConfig((state) => {
          state.menuDrawer = {};
        })
      );
    };
    //eslint-disable-next-line
  }, []);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.search = "";
        state.selectedCategory = "";
      })
    );
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => (
        <p
          className={`pro-mb-0 ${
            data?.isChange === 1 ? Style.product_active : ""
          }`}
        >
          {data[field]}
        </p>
      ),
      name: (field, data) => {
        return (
          <div
            className={`${data?.isChange === 1 ? Style.product_active : ""}`}
          >
            <div
              className={`pro-d-flex avatar-container ${Style.avatar_container}`}
              onClick={() => handleViewUser(data)}
            >
              <div
                className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
              >
                <Image
                  src={
                    data?.image == "" || data?.image == null
                      ? Assets.IMAGE_ABSENCE
                      : data?.image
                  }
                  width={16}
                  height={16}
                  alt={data?.name}
                />
              </div>
              <span
                className={`pro-ms-2 pro-align-self-center pro-text-primary pro-fw-medium`}
              >
                {data?.name}
              </span>
            </div>
          </div>
        );
      },
      isChange: (field, data) => (
        <p
          className={` pro-mb-0 ${data?.isChange === 1 ? "pro-pnt" : ""} ${
            data?.isChange === 1 ? Style.product_active : ""
          }`}
          onClick={() =>
            data?.isChange === 1 ? handleChangeProduct(data) : ""
          }
        >
          {`${data?.isChange === 1 ? "Change" : "No Change"}`}
        </p>
      ),
      image: (field, data) => {
        return (
          <div
            className={`no-wrap ${
              data?.isChange === 1 ? Style.product_active : ""
            }`}
          >
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image)}
            />
          </div>
        );
      },
      in_stock: (field, data) => {
        return (
          <div
            className={`no-wrap ${
              data?.isChange === 1 ? Style.product_active : ""
            }`}
          >
            {data?.in_stock == "In Stock" ? (
              <span className={"pro-badge badge-success-outline"}>
                {"In Stock"}
              </span>
            ) : (
              <span className={"pro-badge badge-danger-outline"}>
                {"Out Of Stock"}
              </span>
            )}
          </div>
        );
      },
      status: (field, data) => {
        const isChecked = data?.status === "Active";
        return (
          <div
            className={`form-check form-switch ${
              data?.isChange === 1 ? Style.product_active : ""
            }`}
          >
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={isChecked}
              onChange={(e) => handleToggle(e, data)}
            />
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };
  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };
  const handleToggle = (e, data) => {
    const newStatus = data.status === "Active" ? false : true;
    let params = {
      customer_key: shopData?.customer_key,
      product_id: data?._id,
      status: newStatus ? 1 : 2,
    };
    updateProductStatus(params);
    dispatch(
      product.util.updateQueryData(
        "getProductListData",
        {
          customer_key: shopData?.customer_key,
          category_id: productState?.selectedCategory,
          status: "",
          per_page: productState?.currentPageSize,
          page: productState?.currentPage,
          sort: productState?.sortBy,
          sort_order: productState?.sortOrder,
          search: productState?.search,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data?.result?.map((res) =>
            res._id === data?._id
              ? {
                  ...res,
                  status: data.status === "Active" ? "Deactivated" : "Active",
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              result: newResult,
            },
          };
          return currentCache;
        }
      )
    );
  };
  const handleChangeProduct = (data) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = true;
      })
    );
    let params = {
      customer_key: shopData?.customer_key,
      product_id: data?._id,
    };
    dispatch(getProductChangeData(params));
  };
  const handleViewUser = (data) => {
    localStorage.setItem("productDetailsData", JSON.stringify(data));
    navigate(`/product-detail/${data?._id}`);
    sessionStorage.setItem("active", `${data?._id}`);
  };
  const handleSort = (label) => {
    if (productState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = productState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showProductEditModal = !state.showProductEditModal;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    let data = {
      customer_key: shopData?.customer_key,
      product_id: e?.[0],
    };
    dispatch(getProductChangeData(data));
  };
  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showProductEditModal = !state.showProductEditModal;
        state.isEdit = false;
        state.isUpdatingProduct = false;
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleProductChangeClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
        state.isSavingChangedProduct = false;
        // state.editProductData = {};
        // state.finalProductChangeData = {};
      })
    );
  };

  const handleDelete = async () => {
    const response = await deleteProduct(productState?.selectedId);
    try {
      if (response?.data?.status_code === 200) {
        toast.success(`Product deleted successfully`);
        refetch();
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      handleDeleteModal();
      dispatch(
        updateConfig((state) => {
          state.clearSelection = true;
        })
      );
    }
  };
  const handleChange = (selectedOption) => {
    dispatch(
      updateConfig((state) => {
        state.selectedCategory = selectedOption.value;
      })
    );
  };
  let categoryObj = categoryList?.filter(
    (item) => item?.value === productState?.selectedCategory
  );

  return {
    shopData,
    imageData,
    isFetching,
    productList,
    categoryObj,
    categoryList,
    showEditModal,
    productState,
    showImageModal,
    offCanvasWidth,
    paginationOptions,
    offCanvasEditWidth,
    handleProductChangeClose,
    handleEditColumnsClick,
    updateProductFields,
    handleDeleteModal,
    handleCreateClick,
    handlePagination,
    handleClearClick,
    handleEditAction,
    closeImageModal,
    handlePageSize,
    handleSearch,
    handleDelete,
    handleClose,
    handleChange,
    handleSort,
    refetch,
    getRow,
  };
};

export default useProduct;
