import { useDispatch, useSelector } from "react-redux";
import {
  useGetBestSellerListDataQuery,
  useUpdateBestSellerTableHeadDataMutation,
} from "../../../store/queries/Report";
import { updateConfig } from "../../../store/slices/Report/bestSellerSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useState } from "react";
import { toast } from "react-toastify";
import { getBestSellerExportedData } from "../api";
import { getFormatedDate } from "../../../utils/Functions/table";

const useBestSeller = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const [isExcelLinkSend, setIsExcelLinkSend] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const bestSellerState = useSelector((state) => state.bestseller);
  const {
    data: bestSellerList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetBestSellerListDataQuery({
    page_size: bestSellerState?.currentPageSize,
    page: bestSellerState?.currentPage,
    sort: bestSellerState?.sortBy,
    sort_order: bestSellerState?.sortOrder,
    search: bestSellerState?.search,
    start_date: getFormatedDate(bestSellerState?.startDate),
    end_date: getFormatedDate(bestSellerState?.endDate),
  });
  const [updateBestSellerFields] = useUpdateBestSellerTableHeadDataMutation();
  const [date, setDate] = useState({
    startDate: bestSellerState.startDate,
    endDate: bestSellerState.endDate,
    key: "selection",
  });
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e?.target?.value;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (bestSellerState?.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            bestSellerState?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) =>
        data?.type === "" ? (
          <b>
            <p className="pro-mb-0">{data[feild]}</p>
          </b>
        ) : (
          <p className="pro-mb-0">{data[feild]}</p>
        ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleBestSellerExcelDownload = () => {
    dispatch(
      updateConfig((state) => {
        state.bestSellerExcelSendModal = !state.bestSellerExcelSendModal;
      })
    );
  };
  const modalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.bestSellerExcelSendModal = false;
      })
    );
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const sendExcelData = async (value) => {
    setIsExcelLinkSend(true);
    let params = {
      email: value?.email ?? "",
      start_date: getFormatedDate(bestSellerState.startDate ?? ""),
      end_date: getFormatedDate(bestSellerState.endDate ?? ""),
      sort: bestSellerState?.sortBy,
      sort_order: bestSellerState?.sortOrder,
    };
    let response = await getBestSellerExportedData(params);
    try {
      if (response?.status === 200) {
        toast.success(`Excel download link send successfully`);
      } else {
        toast.error(`Failed to send download link,Please try again`);
      }
    } catch (error) {
      console.error("Failed to send link:", error);
      toast.error(`Failed to send download link`);
    } finally {
      modalClose();
      setIsExcelLinkSend(false);
    }
  };

  return {
    date,
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    bestSellerList,
    bestSellerState,
    isExcelLinkSend,
    paginationOptions,
    handleBestSellerExcelDownload,
    handleEditColumnsClick,
    updateBestSellerFields,
    handleDateRangeChange,
    handleDateChange,
    handlePagination,
    handlePageSize,
    sendExcelData,
    handleSearch,
    modalClose,
    handleSort,
    refetch,
    getRow,
  };
};

export default useBestSeller;
