import { getAdminAxiosInstance } from "../../../api/Admin";
export const getExportedData = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`admin/excel-export`, params, {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
