import React from "react";
import useCopyStore from "./useCopyStore";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const CopyStore = ({
  formik,
  isStickyFooter,
  closeModal,
  setCopyConfirm,
  setStoreNames,
  setCopyClicked,
}) => {
  const { storeData, handleChangeStore1, handleChangeStore2, handleClickCopy } =
    useCopyStore({
      setCopyConfirm,
      setCopyClicked,
      setStoreNames,
      formik,
    });
  return (
    <>
      <div className="col-auto pro-mt-3 ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Store From
        </label>
        <Select
          id="store_from"
          isClearable={true}
          // isDisabled={reportState?.currentBranchFilter ? false : true}
          options={storeData?.data?.stores}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => `${option["customer_key"]}`}
          className={`pro-input lg multi-select`}
          placeholder={"Store From"}
          value={storeData?.data?.stores?.filter(
            (p) =>
              p.customer_key?.toString() ===
              formik?.values?.store_from?.toString()
          )}
          classNamePrefix="pro-input"
          onChange={(value) =>
            // formik.setFieldValue("store_from", value?.customer_key)
            handleChangeStore1(value)
          }
        ></Select>
      </div>

      <div className="col-auto pro-mt-3">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Store To
        </label>
        <Select
          id="store_to"
          isClearable={true}
          isDisabled={!formik?.values?.store_from}
          options={storeData?.data?.stores?.filter(
            (item) => item?.customer_key !== formik?.values?.store_from
          )}
          getOptionLabel={(option) => `${option.name}`}
          getOptionValue={(option) => `${option["customer_key"]}`}
          className={`pro-input lg multi-select`}
          placeholder={"Store To"}
          value={storeData?.data?.stores?.filter(
            (p) =>
              p.customer_key?.toString() ===
              formik?.values?.store_to?.toString()
          )}
          classNamePrefix="pro-input"
          onChange={(value) =>
            // formik.setFieldValue("store_to", value?.customer_key)
            handleChangeStore2(value)
          }
        ></Select>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end pro-mt-3 ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          disabled={!formik?.values?.store_from || !formik?.values?.store_to}
          onClick={() => handleClickCopy()}
        >
          Copy
        </Button>
      </div>
    </>
  );
};

export default CopyStore;
