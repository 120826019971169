import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetStaffBasicDataQuery } from "../../../../store/queries/Staff";
import { useSelector, useDispatch } from "react-redux";
import { useCreateUpdateDeliveryAreaMutation } from "../../../../store/queries/Admin";
import { toast } from "react-toastify";
import { updateConfig } from "../../../../store/slices/Admin/DeliveryAreas/deliveryAreasSlice";
import { useEffect } from "react";

const useDeliveryAreasForm = ({ refetch, handleClose }) => {
  const dispatch = useDispatch();
  const { isEdit, selectedId, editDeliveryDetailsData } = useSelector(
    (state) => state.deliveryAreas
  );
  const { data: staffBasicData = {} } = useGetStaffBasicDataQuery();
  const [createUpdateDeliveryArea] = useCreateUpdateDeliveryAreaMutation();

  useEffect(() => {
    if (isEdit && editDeliveryDetailsData !== "") {
      formik?.setValues({
        name: editDeliveryDetailsData?.name ?? "",
        store: editDeliveryDetailsData?.customer_key ?? "",
        area: editDeliveryDetailsData?.area ?? "",
        alias_area: editDeliveryDetailsData?.alias_area ?? "",
        block: editDeliveryDetailsData?.block ?? "",
        alias_block: editDeliveryDetailsData?.alias_block ?? "",
        delivery_charge: editDeliveryDetailsData?.delivery_charge ?? "",
        status: editDeliveryDetailsData?.status ?? 2,
      });
    }
    //eslint-disable-next-line
  }, [isEdit, editDeliveryDetailsData]);

  const validation = Yup.object({
    name: Yup.string()
      .trim()
      .required("Enter Delivery Area name")
      .max(50, "Maximum 50 letters are allowed"),
    store: Yup.array()
      .min(1, "At least one store must be selected")
      .required("Select store"),
    area: Yup.string().required(`Enter area`),
    alias_area: Yup.string().required(`Enter Alias Area`),
    block: Yup.string().required("Enter Block"),
    alias_block: Yup.string().required("Enter Alias Block"),
    delivery_charge: Yup.string().required("Enter Delivery Charge"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      store: [],
      area: "",
      alias_area: "",
      block: "",
      alias_block: "",
      delivery_charge: "",
      status: 2,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      const obj = {
        area_id: isEdit ? selectedId : "",
        name: values?.name,
        customer_key: values?.store,
        area: values?.area,
        alias_area: values?.alias_area,
        block: values?.block,
        alias_block: values?.alias_block,
        delivery_charge: values?.delivery_charge,
        status: values?.status,
      };

      createUpdateDeliveryArea(obj).then((res) => {
        if (res?.data?.status_code === 200) {
          toast.success(
            isEdit
              ? "Delivery Area Created Successfully"
              : "Delivery Area Updated Successfully"
          );
          handleClose();
          refetch();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
              state.isEdit = false;
            })
          );
        } else if (res?.data?.status_code === 422 && res?.data?.errors) {
          Object.keys(res.data.errors).forEach((field) => {
            formik.setFieldError(field, res.data.errors[field]);
          });
        } else {
          toast.error(`Something went wrong!`);
          handleClose();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        }
      });
    },
  });

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return { getFieldError, isEdit, staffBasicData, formik };
};

export default useDeliveryAreasForm;
