import { useFormik } from "formik";
import * as Yup from "yup";
import useValidations from "../../../../utils/hooks/useValidations";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../../store/slices/User/GiftCards/giftCardSlice";
import { useDispatch } from "react-redux";
import { createAndUpdateGiftCard, getGiftCardImage } from "../api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const useAddGiftCards = ({ refetch, handleCreateClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isType, setType] = useState(null);
  const typeOptions = [
    { name: "LIMITED", value: 1 },
    { name: "UNLIMITED", value: 2 },
  ];
  const giftCardState = useSelector((state) => state.giftCard);
  const dispatch = useDispatch();
  const {
    isEdit,
    imageName,
    imagePreview,
    isLoadingImage,
    arabicImageName,
    giftCardEditData,
    arabicImagePreview,
    isArabicImageLoading,
  } = useSelector((state) => state.giftCard);
  const { englishRegex, arabicRegex } = useValidations();
  const validation = Yup.object({
    title: Yup.string()
      .trim()
      .matches(englishRegex, "Invalid title Format")
      .required("Enter Title")
      .max(50, "Enter valid title"),
    alias_title: Yup.string()
      .trim()
      .matches(arabicRegex, "Enter in arabic letters")
      .required("Enter Title")
      .max(50, "Enter valid title"),
    description: Yup.string()
      .matches(englishRegex, "Enter in english letters")
      .required(`Enter description`)
      .min(5, " The description must be at least 5 characters"),
    alias_description: Yup.string()
      .matches(arabicRegex, "Enter in arabic letters")
      .required(`Enter description`)
      .min(5, " The description must be at least 5 characters"),
    image: Yup.string().required("Please upload an image"),
    alias_image: Yup.string().required("Please upload an image"),
    from_date: isType === 1 && Yup.string().required("Please enter from date"),
    to_date: isType === 1 && Yup.string().required("Please enter to date"),
    type: Yup.string().required("Select type"),
    priority: Yup.number()
      .required("Enter priority")
      .typeError("Enter a valid number"),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      alias_title: "",
      description: "",
      alias_description: "",
      image: "",
      alias_image: "",
      from_date: "",
      to_date: "",
      type: "",
      priority: "",
      status: "" ?? 2,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      setIsLoading(true);
      let newData = {};
      if (values?.type === "UNLIMITED") {
        newData = { ...values, from_date: "", to_date: "" };
      }

      let newObj = Object.entries(
        values?.type === "UNLIMITED" ? newData : values
      );

      newObj = newObj
        .filter((item) => item[1] !== undefined && item[1] !== null)
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      let createData = {
        ...newObj,
        status: !values?.status ? 2 : values?.status,
      };

      let updateData = {
        gift_card_template_id: giftCardState?.selectedId,
        template_id: giftCardState?.giftCardEditData?.template_id,
        ...newObj,
      };
      const response = await createAndUpdateGiftCard(
        isEdit ? updateData : createData
      );
      try {
        if (response?.data?.status_code === 200) {
          toast.success(response?.data?.message);
          refetch();
          handleCreateClick();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else if (
          response?.data?.status_code === 422 &&
          response?.data?.errors
        ) {
          Object.keys(response?.data?.errors).forEach((field) => {
            formik.setFieldError(field, response?.data?.errors[field]);
          });
        } else {
          toast.error(`${response?.data?.message ?? "Something went wrong!"}`);
          console.error("Error:", response?.data?.errors);
        }
      } catch (error) {
        console.error("Error", error);
      } finally {
        setIsLoading(false);
      }
    },
  });
  useEffect(() => {
    if (isEdit) {
      formik.setValues({
        title: giftCardEditData?.title ?? "",
        alias_title: giftCardEditData?.alias_title ?? "",
        description: giftCardEditData?.description ?? "",
        alias_description: giftCardEditData?.alias_description ?? "",
        image: giftCardEditData?.image,
        alias_image: giftCardEditData?.alias_image,
        from_date: giftCardEditData?.from_date ?? "",
        to_date: giftCardEditData?.to_date ?? "",
        type: giftCardEditData?.type ?? "",
        priority: giftCardEditData?.priority ?? "",
        status: giftCardEditData?.status ?? 2,
      });
      dispatch(
        updateConfig((state) => {
          state.imagePreview = giftCardEditData?.image_url;
          state.imageName = giftCardEditData?.image?.split("/").pop();
          state.arabicImagePreview = giftCardEditData?.alias_image_url;
          state.arabicImageName = giftCardEditData?.alias_image
            ?.split("/")
            .pop();
        })
      );
    }
    // eslint-disable-next-line
  }, [isEdit, giftCardEditData]);

  const handleImageChange = async (event, fieldName) => {
    const selectedLogo = event?.target?.files[0];
    // for conditional Loading of the Images
    const isMainImage = fieldName === "image";
    if (selectedLogo) {
      if (
        !["image/jpeg", "image/png", "image/jpg"].includes(selectedLogo.type)
      ) {
        formik.setFieldError(
          `${fieldName === "image" ? "image" : "alias_image"}`,
          "Only image files are allowed"
        );
        return;
      }
      const formData = new FormData();
      formData.append("image", selectedLogo);
      dispatch(
        updateConfig((state) => {
          isMainImage
            ? (state.isLoadingImage = true)
            : (state.isArabicImageLoading = true);
        })
      );
      const response = await getGiftCardImage(formData);
      try {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            fieldName === "image" ? "image" : "alias_image",
            response?.data?.data?.image_path
          );
        } else if (response?.status_code === 422 && response?.errors) {
          Object.keys(response?.errors).forEach((field) => {
            formik.setFieldError(
              `${fieldName === "image" ? "image" : "alias_image"}`,
              response?.errors[field]
            );
          });
        } else {
          toast.error(`Unable to add image, Please try again.`);
        }
      } catch (error) {
        console.error(`Unable to add image:`, error);
      } finally {
        dispatch(
          updateConfig((state) => {
            isMainImage
              ? (state.isLoadingImage = false)
              : (state.isArabicImageLoading = false);
          })
        );
      }

      const file = selectedLogo;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state[
              fieldName === "image" ? "imagePreview" : "arabicImagePreview"
            ] = reader.result;
            state[fieldName === "image" ? "imageName" : "arabicImageName"] =
              selectedLogo?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
        state.giftCardEditData = {};
      })
    );
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleTypeChange = (e) => {
    formik?.setFieldValue("type", e?.name || null);
    setType(e?.value);
  };
  let typeObj = typeOptions?.filter(
    //eslint-disable-next-line
    (item) => item?.name == formik?.values?.type
  );
  return {
    formik,
    isEdit,
    typeObj,
    isLoading,
    imageName,
    typeOptions,
    imagePreview,
    isLoadingImage,
    arabicImageName,
    arabicImagePreview,
    isArabicImageLoading,
    handleImageChange,
    handleTypeChange,
    getFieldError,
    handleClose,
  };
};

export default useAddGiftCards;
