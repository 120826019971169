import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import EmptyData from "../../Global/EmptyData";
import SendExcelData from "../../Admin/OrderList/SendExcelData";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import Select from "react-select";
import useWalletTransaction from "./useWalletTransaction";

const WalletTransaction = () => {
  const {
    date,
    filterObj,
    isLoading,
    filterList,
    isFetching,
    showEditModal,
    isExcelLinkSend,
    paginationOptions,
    walletTransactionList,
    walletTransactionState,
    handleWalletTransactionExcelDownload,
    updateWalletTransactionFields,
    handleEditColumnsClick,
    handleDateRangeChange,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    sendExcelData,
    handleChange,
    handleSort,
    modalClose,
    refetch,
    getRow,
  } = useWalletTransaction();
  return (
    <>
      <HeadingGroup title="Wallet Transaction" className={`pro-mb-4`}>
        <div className="col dropdown-filter pro-d-flex">
          <button
            className={`pro-btn pro-btn-outline-primary no-wrap pro-items-center pro-ms-3`}
            type="submit"
            onClick={handleWalletTransactionExcelDownload}
          >
            <span className="material-symbols-outlined x4">download</span>
            <span>{`Export Data`}</span>
          </button>
        </div>
      </HeadingGroup>
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          //   showClearFilters={walletTransactionState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: walletTransactionState?.search }}
          handleActionClick={handleEditColumnsClick}
          searchable={true}
          dropDownFilter={
            <>
              <div className="col-auto">
                <CustomDateRangePicker
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>
              <div className="col-auto dropdown-filter">
                <Select
                  value={filterObj}
                  onChange={handleChange}
                  options={filterList}
                  placeholder="Select Category"
                  classNamePrefix={`pro-input`}
                  className={`pro-input lg search-filter-select`}
                />
              </div>
            </>
          }
          onDateChange={handleDateChange}
          initialDateRange={date}
        />
        {walletTransactionList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={walletTransactionList?.data?.result || []}
            uniqueID={"id"}
            fields={walletTransactionList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            clear={walletTransactionState?.clearSelection}
            perpage={walletTransactionState?.currentPageSize}
            assignable={false}
            deletable={false}
            editable={false}
            showCheckBox={false}
          />
        )}

        {walletTransactionList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={walletTransactionState?.currentPage}
            totalPageCount={Math.ceil(
              walletTransactionState?.totalCount /
                walletTransactionState?.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === walletTransactionState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={walletTransactionList?.data?.fields || []}
              moduleId={walletTransactionList?.data?.module_id}
              updateData={updateWalletTransactionFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={walletTransactionState.walletTransactionExcelSendModal}
          handleClose={handleWalletTransactionExcelDownload}
          title={`Send Excel Download Link`}
          backdrop="static"
        >
          <div className="pro-m-5">
            <SendExcelData
              isBestSeller
              handleCloseModal={modalClose}
              sendExcelData={sendExcelData}
              isExcelLinkSend={isExcelLinkSend}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default WalletTransaction;
