import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useGetComponentBasicDataQuery,
  useGetComponentEditDataQuery,
} from "../../../store/queries/CMS";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { updateHomePage } from "./api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/CMS/cmsSlice";
import { useState } from "react";

const useHomePage = () => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const { editHomePageData, componentIndex, characters } = useSelector(
    (state) => state.cms
  );
  const { data: componentBasicData } = useGetComponentBasicDataQuery();
  const { data: componentEditData } = useGetComponentEditDataQuery();

  useEffect(() => {
    if (componentEditData && componentEditData?.data?.data?.length > 0) {
      dispatch(
        updateConfig((state) => {
          state.characters = componentEditData?.data?.data;
          state.contentId = componentEditData?.data?._id;
        })
      );
    }
    //eslint-disable-next-line
  }, [componentEditData]);
  const formik = useFormik({
    initialValues: {
      title: "",
      alias_title: "",
      selected_items: editHomePageData?.multi_select ? [] : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Enter title"),
      alias_title: Yup.string().required("Enter title"),
      selected_items: editHomePageData?.multi_select
        ? Yup.array().min(1, "Select one item").required(`Select one item`)
        : Yup.string().required(`Select item`),
    }),
    onSubmit: (values) => {
      setIsUpdating(true);
      let data = {
        type: editHomePageData?.type,
        dropdown_key: editHomePageData?.dropdown_key,
        multi_select: editHomePageData?.multi_select,
        ...values,
      };
      let updatedData = [...characters];
      updatedData[componentIndex] = data;
      dispatch(
        updateConfig((state) => {
          state.characters = updatedData;
        })
      );
      handleUpdateHomePage(updatedData);
    },
  });
  const handleUpdateHomePage = (homePageData) => {
    let data = {
      content_id: componentEditData?.data?._id,
      contents: [...homePageData],
    };
    updateHomePage(data).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success(`Updated successfully`);
        setIsUpdating(false);
      } else {
        toast.error(`Something went wrong`);
        setIsUpdating(false);
      }
    });
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    characters,
    isUpdating,
    componentBasicData,
    handleUpdateHomePage,
    getFieldError,
  };
};

export default useHomePage;
