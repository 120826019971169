import { getAdminAxiosInstance } from "../../../../api/Admin";

export const updateProduct = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/product/update-product`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
