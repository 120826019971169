import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetCustomOffersListDataQuery,
  useUpdateCustomOffersTableHeadDataMutation,
} from "../../../store/queries/User";
import {
  getCustomOfferEditData,
  updateConfig,
} from "../../../store/slices/User/CustomOffers/customOfferSlice";
import { toast } from "react-toastify";
import { deleteCustomOffer } from "./api";

const useCustomOffers = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const offerState = useSelector((state) => state.customOffers);
  const { showEditModal } = useSelector((state) => state.global);
  const {
    data: customOffersList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetCustomOffersListDataQuery({
    page_size: offerState?.currentPageSize,
    page: offerState?.currentPage,
    sort: offerState?.sortBy,
    sort_order: offerState?.sortOrder,
    search: offerState?.search,
  });
  const [updateCustomOfferFields] =
    useUpdateCustomOffersTableHeadDataMutation();
  //   const [updateBannerStatus] = useUpdateBannerStatusMutation();
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
      })
    );
  };
  useEffect(() => {
    if (offerState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [offerState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSort = (label) => {
    if (offerState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = offerState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };

  const handleDelete = async () => {
    const response = await deleteCustomOffer(offerState?.selectedId);
    if (response && response?.data?.status_code === 200) {
      toast.success(`Custom offer deleted successfully.`);
      refetch?.();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getCustomOfferEditData(e?.[0]));
  };

  return {
    isLoading,
    isFetching,
    offerState,
    showEditModal,
    customOffersList,
    paginationOptions,
    handleEditColumnsClick,
    updateCustomOfferFields,
    handleDeleteModal,
    handleCreateClick,
    handlePagination,
    handleClearClick,
    handleEditAction,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useCustomOffers;
