
import { getAdminAxiosInstance } from '../../../api/Admin';

export const getBannerImage = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/banner/upload-image`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const createBanner = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/banner/store`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteBanner = async (banner_id) => {
  const api = await getAdminAxiosInstance();

  try {
    const response = await api.get(`/banner/destroy?banner_id=${banner_id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};