import { useSelector, useDispatch } from "react-redux";
import {
  useGetPointHistoryDataQuery,
  useUpdatePointHistoryTableHeadDataMutation,
} from "../../../../store/queries/User";
import { updateConfig } from "../../../../store/slices/User/UserDetails/pointHistorySlice";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import { useParams } from "react-router-dom";

const usePointHistory = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const activeProfile = user_id ?? sessionStorage.getItem("active");
  const pointHistoryState = useSelector((state) => state.pointHistory);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: pointHistoryList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetPointHistoryDataQuery({
    user_id: activeProfile,
    page_size: pointHistoryState?.currentPageSize,
    page: pointHistoryState?.currentPage,
    sort: pointHistoryState?.sortBy,
    sort_order: pointHistoryState?.sortOrder,
    search: pointHistoryState?.search,
  });
  const [updatePointHistoryFields] =
    useUpdatePointHistoryTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (pointHistoryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            pointHistoryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    tableFields,
    showEditModal,
    pointHistoryList,
    paginationOptions,
    pointHistoryState,
    updatePointHistoryFields,
    handleEditColumnsClick,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default usePointHistory;
