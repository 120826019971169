import {
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useCustomNotification from "./useCustomNotification";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import { ImageCard } from "../../Global/ImageCard";
import OrderColumn from "../../Global/OrderColumn";
import CreateCustomNotification from "./CreateCustomNotification";

const CustomNotification = () => {
  const {
    customNotificationList,
    customNotificationState,
    isLoading,
    isFetching,
    showImageModal,
    imageData,
    showEditModal,
    paginationOptions,
    handleDeleteNotification,
    handleResendNotification,
    handlePagination,
    handlePageSize,
    udpateCustomNotificationHead,
    refetch,
    closeImageModal,
    handleCreateClick,
    getRow,
    handleSort,
    handleEditColumnsClick,
    handleClearClick,
    handleSearch,
  } = useCustomNotification();

  return (
    <>
      <HeadingGroup
        title="Custom Notification"
        className={`pro-mb-4`}
        buttonTitle={
          customNotificationList?.data?.create_permission && "Create"
        }
        handleClick={handleCreateClick}
      />

      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={
            customNotificationState?.search === "" ? false : true
          }
          handleClear={handleClearClick}
          searchInputProps={{ value: customNotificationState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        {customNotificationList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={customNotificationList?.data?.result || []}
            uniqueID={"id"}
            fields={customNotificationList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">send</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            handleEdit={handleResendNotification}
            handleDelete={handleDeleteNotification}
            getRow={getRow}
            loading={isFetching}
            clear={customNotificationState?.clearSelection}
            perpage={customNotificationState?.currentPageSize}
            assignable={false}
            deletable={true}
            editable={true}
            editText={"Resend"}
            showCheckBox={true}
          />
        )}

        {customNotificationList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={customNotificationState?.currentPage}
            totalPageCount={Math.ceil(
              customNotificationList?.data?.total_count /
                customNotificationState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === customNotificationState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>
        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={customNotificationList?.data?.fields || []}
              moduleId={customNotificationList?.data?.module_id}
              updateData={udpateCustomNotificationHead}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={customNotificationState?.showCreateModal}
          handleClose={handleCreateClick}
          title={"Send Notification"}
          backdrop={"static"}
        >
          <CreateCustomNotification
            refetch={refetch}
            isStickyFooter
            handleClose={handleCreateClick}
          />
        </OffCanvasLayout>
      </div>
    </>
  );
};

export default CustomNotification;
