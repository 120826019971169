import { Auth } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useChangePassword from "./useChangePassword";
import { useOutletContext } from "react-router-dom";

const ChangePassword = () => {
  const { emailRef } = useOutletContext();
  const {
    formik,
    showNewPassword,
    showReEnterPassword,
    status,
    handleShowReEnteredPassword,
    handleShowNewPassword,
    // handleForgotPassword,
  } = useChangePassword(emailRef);
  return (
    <Auth
      title={"Reset Password"}
      buttonText={"Submit"}
      handleClick={formik.handleSubmit}
      actionText=""
      //   handleActionClick={handleForgotPassword}
      loading={status === "pending"}
      //   actionLoading={forgotPasswordStatus === "pending"}
    >
      <div className="input-wrap pro-mb-5">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Enter new password
        </label>
        <input
          name="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="password"
          className={`pro-input lg hide-icon-input
          ${
            formik.errors.password &&
            (formik.touched.password || formik.values.password) &&
            "error"
          }`}
        />
        {showNewPassword ? (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowNewPassword(e)}
            type="button"
          >
            <span className="material-symbols-outlined"> visibility_off </span>
          </button>
        ) : (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowNewPassword(e)}
            type="button"
          >
            <span className="material-symbols-outlined"> visibility </span>
          </button>
        )}
        {formik.errors.password &&
          (formik.touched.password || formik.values.password) && (
            <span className="error-text">{formik?.errors?.password}</span>
          )}
      </div>

      <div className="input-wrap pro-mb-5 p-re">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Re-enter password
        </label>
        <input
          name="confirm_password"
          id="confirm_password"
          value={formik.values.confirm_password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="password"
          className={`pro-input lg hide-icon-input ${
            formik.errors.confirm_password &&
            formik.touched.confirm_password &&
            "error"
          }`}
        />

        {showReEnterPassword ? (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowReEnteredPassword(e)}
            type="button"
          >
            <span className="material-symbols-outlined"> visibility_off </span>
          </button>
        ) : (
          <button
            className={`hide-icon`}
            onClick={(e) => handleShowReEnteredPassword(e)}
            type="button"
          >
            <span className="material-symbols-outlined"> visibility </span>
          </button>
        )}
        {formik.errors.confirm_password && formik.touched.confirm_password && (
          <span className="error-text">{formik?.errors?.confirm_password}</span>
        )}
      </div>
    </Auth>
  );
};

export default ChangePassword;
