import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn/index";
import EmptyData from "../../Global/EmptyData";
import useVideoBanner from "./useVideoBanner";
import AddVideoBanner from "./AddVideoBanner";
import VideoCard from "../../Global/VideoCard";

const VideoBanner = () => {
  const {
    isLoading,
    videoData,
    isFetching,
    showEditModal,
    showVideoModal,
    videoBannerList,
    videoBannerState,
    paginationOptions,
    handleEditColumnsClick,
    updateVideoBannerFields,
    handleDeleteModal,
    handleCreateClick,
    handlePagination,
    handleClearClick,
    handleEditAction,
    closeImageModal,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useVideoBanner();
  return (
    <div>
      <HeadingGroup
        title={"Video Content"}
        className={`pro-mb-4`}
        buttonTitle={videoBannerList?.data?.create_permission && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={videoBannerState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: videoBannerState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        {videoBannerList?.data?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={videoBannerList?.data?.data || []}
            uniqueID={"id"}
            fields={videoBannerList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={videoBannerState?.clearSelection}
            loading={isFetching}
            perpage={videoBannerState?.currentPageSize}
            assignable={false}
            handleEdit={handleEditAction}
            handleDelete={handleDeleteModal}
            deletable={videoBannerList?.data?.delete_permission}
            editable={videoBannerList?.data?.edit_permission}
            showCheckBox={
              videoBannerList?.data?.delete_permission ||
              videoBannerList?.data?.edit_permission
            }
          />
        )}

        {videoBannerList?.data?.data?.length > 0 && (
          <Pagination
            currentPage={videoBannerState?.currentPage}
            totalPageCount={Math.ceil(
              videoBannerList?.data?.total_count /
                videoBannerState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === videoBannerState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={videoBannerState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure to delete the selected Video Banner ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={videoBannerList?.data?.fields || []}
              moduleId={videoBannerList?.data?.module_id}
              updateData={updateVideoBannerFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={videoBannerState?.showCreateModal}
          handleClose={handleCreateClick}
          title={
            videoBannerState?.isEdit
              ? "Update Video Banner"
              : "Add Video Banner"
          }
          backdrop={"static"}
        >
          <AddVideoBanner
            refetch={refetch}
            handleCreateClick={handleCreateClick}
            isStickyFooter
          />
        </OffCanvasLayout>

        <ModalLayout
          show={showVideoModal}
          handleClose={closeImageModal}
          backdrop={"static"}
        >
          <VideoCard handleClose={closeImageModal} data={videoData} />
        </ModalLayout>
      </div>
    </div>
  );
};

export default VideoBanner;
