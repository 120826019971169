import DashboardHero from "../DashboardHero";
import useDashboardListing from "./useDashboardListing";
import Style from "./dashboardListing.module.scss";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import Select from "react-select";
import Charts from "../../Global/Charts";
import { useUtils } from "@mui/x-date-pickers/internals";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import useChartData from "./ChartData/chartData";

const DashboardListing = () => {
  const {
    date,
    storeObj,
    storeList,
    listingType,
    dashboardData,
    dashboardItems,
    chartFilterType,
    isStoreAvailable,
    userCountFetching,
    orderCountFetching,
    statusChartOptions,
    handleDateRangeChange,
    handleStoreChange,
    handleChart,
  } = useDashboardListing();
  const {
    // doughnutGraph,
    // doughnutData,
    chartDataMap,
    statusDataFetching,
    // doughnut,
  } = useChartData();

  return (
    <>
      <div className={`${Style.dashboard_wrap}`}>
        <div className="pro-d-flex pro-items-center pro-justify-between">
          <h4 className="pro-ttl h4 pro-mb-0">Dashboard</h4>
          <div className="row gx-5 pro-items-center">
            <div className="col-auto">
              <div className="row pro-items-center gx-3">
                <div className="col-auto">
                  <CustomDateRangePicker
                    isDashboard
                    defaultDateRange={date}
                    handleDateRangeChange={handleDateRangeChange}
                  />
                </div>
                <div className="col-auto">
                  <Select
                    id="customer_key"
                    name="customer_key"
                    placeholder={"All Stores"}
                    className={`pro-input lg ${Style.header_select}`}
                    classNamePrefix="pro-input"
                    options={storeList ?? []}
                    getOptionLabel={(option) => option?.name}
                    getOptionValue={(option) => option?.customer_key}
                    value={storeObj}
                    // onBlur={formik.handleBlur("customer_key")}
                    onChange={(value) => handleStoreChange(value)}
                    menuPlacement="auto"
                    isClearable="true"
                    isDisabled={isStoreAvailable}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pro-w-100 pro-my-4 pro-px-5">
        <DashboardHero
          isDashboard={true}
          data={dashboardData}
          obj={dashboardItems}
          loading={userCountFetching || orderCountFetching}
          listingType={listingType}
          shimmerCount={4}
        />
      </div>
      {true && (
        <div className="pro-w-100 pro-my-4 pro-px-5">
          <div className="row g-5">
            {/* orderGraph */}
            <div className="col-12">
              <div className={Style.data_container}>
                <div className={Style.header}>
                  <h6 className="pro-ttl h6 pro-mb-0">Status</h6>
                  <ul
                    className={`${Style.nav} pro-d-flex pro-items-center pro-gap-4`}
                  >
                    {statusChartOptions?.map((item, index) => {
                      return (
                        <li key={item?.value}>
                          <button
                            className={`pro-btn pro-p-1 ${
                              item?.value === chartFilterType
                                ? Style.active
                                : ""
                            } ${Style.nav_link}`}
                            onClick={() => {
                              handleChart(item?.value);
                            }}
                          >
                            {item?.label}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {!statusDataFetching ? (
                  <div className={Style.body}>
                    {chartDataMap[chartFilterType] && (
                      <Charts
                        type={chartFilterType === "order" ? "line" : "bar"}
                        labels={chartDataMap[chartFilterType].labels}
                        datasets={chartDataMap[chartFilterType].datasets}
                        options={chartDataMap[chartFilterType].options}
                      />
                    )}
                  </div>
                ) : (
                  <div className="app-loading fill"></div>
                )}
              </div>
            </div>

            {/* doughnutGraph */}
            {/* <div className="col-3">
              <div className={Style.data_container}>
                <div className={Style.header}>
                  <h6 className="pro-ttl h6 pro-mb-0">Sales Status</h6>
                </div>
                <div className={Style.body}>
                  <div className={`${Style.doughnut_graph}`}>
                    <Charts
                      type="doughnut"
                      labels={doughnutData?.labels}
                      datasets={doughnutData?.datasets}
                      options={doughnutGraph}
                    />
                  </div>
                  <div className={Style.doughnut_list}>
                    <ul className="pro-d-flex pro-flex-wrap">
                      {doughnut?.map((item, index) => (
                        <li className="pro-d-flex pro-gap-2 pro-items-center">
                          <span
                            className={Style.color}
                            style={{
                              backgroundColor:
                                doughnutData.datasets[0].backgroundColor[index],
                            }}
                          ></span>
                          <p
                            className={`pro-d-flex pro-fw-medium pro-font-sm pro-items-center pro-mb-0 ${Style.title}`}
                          >
                            <span>{item?.label}</span>
                            <span
                              className={`${Style.percent}`}
                            >{`${item?.percent}%`}</span>
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}

            {/* Best Sellers */}
            {/* <div className="col-3">
              <div className={Style.data_container}>
                <div className={Style.header}>
                  <h6 className="pro-ttl h6 pro-mb-0">Best Sellers</h6>
                </div>
                <div className={Style.body}>
                  <div
                    className={`${Style.card_wrap} pro-d-flex pro-flex-column`}
                  >
                    {[...Array(8)].map((_, i) => (
                      <div
                        className={`${Style.card_root} pro-d-flex pro-items-center`}
                        key={i}
                      >
                        <div
                          className={`${Style.img} pro-d-flex pro-items-center pro-justify-center`}
                        >
                          <Image
                            src={`https://img.freepik.com/free-psd/fresh-beef-burger-isolated-transparent-background_191095-9021.jpg?size=338&ext=jpg&ga=GA1.1.632798143.1705536000&semt=ais`}
                            width={100}
                            height={100}
                          />
                        </div>
                        <div className="pro-d-flex pro-items-center pro-px-3 flex-fill">
                          <div className="">
                            <h6 className="pro-ttl h6 pro-font-sm pro-mb-1">
                              Cheese Burger
                            </h6>
                            <p className={`pro-font-sm pro-mb-0 ${Style.size}`}>
                              Large
                            </p>
                          </div>
                          <span className={`pro-fw-bold pro-mb-0 ${Style.amt}`}>
                            KD 2.25
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      )}
    </>
  );
};
export default DashboardListing;
