import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  user,
  useGetVideoBannerListDataQuery,
  useUpdateVideoBannerTableHeadDataMutation,
  useUpdateVideoBannerStatusMutation,
} from "../../../store/queries/User";
import {
  getVideoBannerEditData,
  updateConfig,
} from "../../../store/slices/User/videoBannerSlice";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";
import { deleteVideoBanner } from "./api";

const useVideoBanner = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoData, setVideoData] = useState("");
  const dispatch = useDispatch();
  const videoBannerState = useSelector((state) => state.videoBanner);
  const { selectedId } = useSelector((state) => state.videoBanner);
  const { showEditModal } = useSelector((state) => state.global);
  const {
    data: videoBannerList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetVideoBannerListDataQuery({
    page_size: videoBannerState?.currentPageSize,
    page: videoBannerState?.currentPage,
    sort: videoBannerState?.sortBy,
    sort_order: videoBannerState?.sortOrder,
    search: videoBannerState?.search,
  });
  const [updateVideoBannerFields] = useUpdateVideoBannerTableHeadDataMutation();
  const [updateVideoBannerStatus] = useUpdateVideoBannerStatusMutation();
  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.videoPreview = "";
        state.videoName = "";
        state.arabicVideoPreview = "";
        state.arabicVideoName = "";
        state.isEdit = false;
        state.editVideoBannerData = {};
        state.isLoading = false;
      })
    );
  };
  useEffect(() => {
    if (videoBannerState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [videoBannerState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handleViewVideo = (data) => {
    setShowVideoModal(() => true);
    setVideoData(data);
  };

  const handleToggle = (e, data) => {
    const newStatus = data.status === "Active" ? false : true;
    let body = {
      banner_id: data?.id,
      status: newStatus ? 1 : 2,
    };
    updateVideoBannerStatus(body);
    dispatch(
      user.util.updateQueryData(
        "getVideoBannerListData",
        {
          page_size: videoBannerState?.currentPageSize,
          page: videoBannerState?.currentPage,
          sort: videoBannerState?.sortBy,
          sort_order: videoBannerState?.sortOrder,
          search: videoBannerState?.search,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.data.data?.map((res) =>
            res.id === data?.id
              ? {
                  ...res,
                  status: data.status === "Active" ? "Deactivated" : "Active",
                }
              : res
          );
          currentCache = {
            ...currentCache,
            data: {
              ...currentCache.data,
              data: newResult,
            },
          };
          return currentCache;
        }
      )
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      alias_title: (field, data) => (
        <p className="pro-mb-0 pro-rtl">{data?.alias_title}</p>
      ),
      video: (field, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">
                  play_circle
                </span>
              }
              title={`${"View Video"}`}
              onClick={() => handleViewVideo(data?.video)}
            />
          </div>
        );
      },
      alias_video: (field, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">
                  play_circle
                </span>
              }
              title={`${"View Video"}`}
              onClick={() => handleViewVideo(data?.alias_video)}
            />
          </div>
        );
      },
      status: (field, data) => {
        const isChecked = data?.status === "Active";
        return (
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={isChecked}
              onChange={(e) => handleToggle(e, data)}
            />
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleSort = (label) => {
    if (videoBannerState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            videoBannerState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };

  const handleDelete = async () => {
    const response = await deleteVideoBanner(selectedId);
    if (response && response.status === 200) {
      toast.success(response?.data?.message ?? `Banner deleted successfully`);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeImageModal = () => {
    setShowVideoModal(() => false);
    setVideoData("");
  };

  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getVideoBannerEditData(e?.[0]));
  };

  return {
    isLoading,
    videoData,
    isFetching,
    showEditModal,
    showVideoModal,
    videoBannerList,
    videoBannerState,
    paginationOptions,
    handleEditColumnsClick,
    updateVideoBannerFields,
    handleDeleteModal,
    handleCreateClick,
    handlePagination,
    handleClearClick,
    handleEditAction,
    closeImageModal,
    handlePageSize,
    handleDelete,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useVideoBanner;
