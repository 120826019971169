import {
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useAddOns from "./useAddOns";
import EmptyData from "../../../../Global/EmptyData";
import { ImageCard } from "../../../../Global/ImageCard";
import OrderColumn from "../../../../Global/OrderColumn";

const AddOns = ({ customerKey }) => {
  const {
    isLoading,
    imageData,
    isFetching,
    showEditModal,
    showImageModal,
    productAddOnsList,
    paginationOptions,
    productAddOnsState,
    handleEditColumnsClick,
    updateAddOnFields,
    handlePagination,
    handleClearClick,
    closeImageModal,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useAddOns(customerKey);

  return (
    <>
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={productAddOnsState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: productAddOnsState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        {productAddOnsList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={productAddOnsList?.data?.result || []}
            uniqueID={"id"}
            fields={productAddOnsList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            clear={productAddOnsState?.clearSelection}
            perpage={productAddOnsState?.currentPageSize}
            assignable={false}
            deletable={false}
            editable={false}
            showCheckBox={false}
          />
        )}

        {productAddOnsList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={productAddOnsState?.currentPage}
            totalPageCount={Math.ceil(
              productAddOnsList?.data?.result?.length /
                productAddOnsState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === productAddOnsState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={productAddOnsList?.data?.fields || []}
              moduleId={productAddOnsList?.data?.module_id}
              updateData={updateAddOnFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>
      </div>
    </>
  );
};

export default AddOns;
