import { getAdminAxiosInstance } from "../../../api/Admin";

export const addCustomOffers = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/custom-offer/store`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateCustomOffer = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/custom-offer/store`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteCustomOffer = async (id) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.get(`custom-offer/destroy?offer_id=${id}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
