import {
  Button,
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useDeliveryAreas from "./useDeliveryAreas";
import EmptyData from "../../Global/EmptyData";
import { FaSort } from "react-icons/fa";
import DeliveryAreasForm from "./DeliveryAreasForm";
import DeliveryExcelForm from "./DeliveryExcelForm";
import OrderColumn from "../../Global/OrderColumn";

const DeliveryAreas = () => {
  const {
    isLoading,
    isFetching,
    deliveryAreasList,
    paginationOptions,
    deliveryAreasState,
    showEditModal,
    updateDeliveryAreasTableHeadData,
    refetch,
    getRow,
    handleSort,
    handleSearch,
    handlePageSize,
    handleCloseForm,
    handleEditAction,
    handlePagination,
    handleClearClick,
    handleCreateClick,
    handleDeleteAction,
    handleSubmitDelete,
    handleCloseDeleteModal,
    handleEditColumnsClick,
    handleCloseExportModal,
    handleClickExportButton,
  } = useDeliveryAreas();
  return (
    <>
      <HeadingGroup
        title={"Delivery Areas"}
        className={`pro-mb-4`}
        buttonTitle={deliveryAreasList?.data?.create_permission && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={deliveryAreasState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: deliveryAreasState?.search }}
          handleActionClick={handleEditColumnsClick}
          filterChildren={
            <div className="col-auto pro-ms-auto">
              <Button
                className="pro-btn-primary lg"
                onClick={handleClickExportButton}
              >
                Import
              </Button>
            </div>
          }
        />

        <div className="pro-mb-2">
          {deliveryAreasList?.data?.result?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              multiSelect={false}
              data={deliveryAreasList?.data?.result || []}
              uniqueID={"_id"}
              fields={deliveryAreasList?.data?.fields || []}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              clear={deliveryAreasState.clearSelection}
              loading={isFetching}
              perpage={deliveryAreasState?.currentPageSize}
              assignable={false}
              handleEdit={handleEditAction}
              handleDelete={handleDeleteAction}
              deletable={deliveryAreasList?.data?.delete_permission}
              editable={deliveryAreasList?.data?.edit_permission}
              showCheckBox={
                deliveryAreasList?.data?.delete_permission ||
                deliveryAreasList?.data?.edit_permission
              }
            />
          )}
        </div>

        <div className="pro-mt-0"></div>
        {deliveryAreasList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={deliveryAreasState?.currentPage}
            totalPageCount={Math.ceil(
              deliveryAreasList?.data?.total_count /
                deliveryAreasState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === deliveryAreasState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          show={deliveryAreasState?.showCreateModal}
          handleClose={handleCloseForm}
          title={
            deliveryAreasState?.isEdit
              ? "Update Delivery Area"
              : "Add Delivery Area"
          }
          backdrop={"static"}
        >
          <DeliveryAreasForm refetch={refetch} handleClose={handleCloseForm} />
        </OffCanvasLayout>

        <ModalLayout
          show={deliveryAreasState?.deleteConfirmationModal}
          centered={"true"}
          handleClose={handleCloseDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure want to delete this delivery area ?`}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleCloseDeleteModal}
              submitText={"Yes"}
              submitAction={handleSubmitDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={deliveryAreasState?.exportModal}
          centered={"true"}
          title={"Upload file"}
          handleClose={handleCloseExportModal}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <div className="pro-m-5">
            <DeliveryExcelForm
              refetch={refetch}
              closeModal={handleCloseExportModal}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={deliveryAreasList?.data?.fields || []}
              moduleId={deliveryAreasList?.data?.module_id}
              updateData={updateDeliveryAreasTableHeadData}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default DeliveryAreas;
