import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { updateConfig } from "../../../../store/slices/CMS/cmsSlice";
import { updatePrivacySettings } from "../api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const useTermsAndConditions = (refetch, handleCloseModal) => {
  const { editPrivacyPolicyData, isEdit, selectedId } = useSelector(
    (state) => state.cms
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEdit && editPrivacyPolicyData !== "") {
      formik.setValues({
        name: editPrivacyPolicyData?.name ?? "",
        alias_name: editPrivacyPolicyData?.alias_name ?? "",
      });
    }
    //eslint-disable-next-line
  }, [isEdit, editPrivacyPolicyData]);

  const validation = Yup.object({
    name: Yup.string().trim().required("Enter Name"),
    alias_name: Yup.string().trim().required("Enter Name"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      alias_name: "",
    },
    validationSchema: validation,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};

      // Convert editor content to plain text
      const plainText = values?.description?.getCurrentContent().getPlainText();
      const plainArabicText = values?.alias_description
        ?.getCurrentContent()
        .getPlainText();

      // Check if the content is empty or only contains whitespace
      if (!plainText?.trim()) {
        errors.description = "*Description cannot be empty";
      }
      if (!plainArabicText?.trim()) {
        errors.alias_description = "Description cannot be empty*";
      }

      return errors;
    },
    onSubmit: (values) => {
      const descriptionContentState = values?.description?.getCurrentContent();
      const descriptionContentRaw = convertToRaw(descriptionContentState ?? "");
      const descriptionPlainText = draftToHtml(descriptionContentRaw ?? "");
      const AliasDescContentState =
        values?.alias_description?.getCurrentContent();
      const AliasDescContentRaw = convertToRaw(AliasDescContentState ?? "");
      const AliasDescPlainText = draftToHtml(AliasDescContentRaw ?? "");

      let obj = {
        name: values?.name,
        alias_name: values?.alias_name,
        description: descriptionPlainText,
        alias_description: AliasDescPlainText,
      };
      if (isEdit) {
        updatePrivacySettings({ app_settings_id: selectedId, ...obj }).then(
          (response) => {
            if (response?.data?.status_code === 200) {
              toast.success(`Policies and terms updated successfully`);
              refetch();
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              handleCloseModal();
            } else {
              toast.error(`Something went wrong!`);
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              handleCloseModal();
            }
          }
        );
      }
    },
  });
  //English content
  const defaultHtmlContent = editPrivacyPolicyData?.description;

  const sanitizedHtml = DOMPurify.sanitize(defaultHtmlContent);

  const contentState = ContentState?.createFromBlockArray(
    convertFromHTML(sanitizedHtml)
  );

  const editorStates = EditorState?.createWithContent(contentState);

  //Arabic content
  const defaultHtmlArabicContent = editPrivacyPolicyData?.alias_description;

  const sanitizedArabicHtml = DOMPurify.sanitize(defaultHtmlArabicContent);
  const arabicContentState = ContentState?.createFromBlockArray(
    convertFromHTML(sanitizedArabicHtml)
  );
  const editorArabicStates = EditorState?.createWithContent(arabicContentState);

  useEffect(() => {
    if (!formik.values.description && !formik?.values?.alias_description) {
      formik.setFieldValue("description", editorStates ?? "");
      formik.setFieldValue("alias_description", editorArabicStates ?? "");
    }
    //eslint-disable-next-line
  }, [editorStates, editorArabicStates]);

  const handleContentChange = (editorsState) => {
    formik.setFieldValue("description", editorsState);
  };
  const handleArabicContentChange = (editorsState) => {
    formik.setFieldValue("alias_description", editorsState);
  };
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    editPrivacyPolicyData,
    handleContentChange,
    handleArabicContentChange,
    getFieldError,
  };
};

export default useTermsAndConditions;
