import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateConfig } from "../../../../store/slices/User/UserDetails/vehicleListingSlice";
import { updateConfig as globalUpdateConfig } from "../../../../store/slices/Global";
import {
  useGetVehicleListingDataQuery,
  useUpdateVehicleListTableHeadDataMutation,
} from "../../../../store/queries/User";

const useVehicleList = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const { user_id } = useParams();
  const activeProfile = user_id ?? sessionStorage.getItem("active");
  const vehicleListState = useSelector((state) => state.vehicleListing);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: vehicleList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetVehicleListingDataQuery({
    user_id: activeProfile,
    page_size: vehicleListState?.currentPageSize,
    page: vehicleListState?.currentPage,
    sort: vehicleListState?.sortBy,
    sort_order: vehicleListState?.sortOrder,
    search: vehicleListState?.search,
  });
  const [updateVehicleListingFields] =
    useUpdateVehicleListTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (vehicleListState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder =
            vehicleListState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  return {
    isLoading,
    isFetching,
    tableFields,
    vehicleList,
    showEditModal,
    vehicleListState,
    paginationOptions,
    updateVehicleListingFields,
    handleEditColumnsClick,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useVehicleList;
