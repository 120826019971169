import { useDispatch, useSelector } from "react-redux";
import {
  useGetMyCardsBasicListDataQuery,
  useGetMyCardsListDataQuery,
  useUpdateMyCardsTableHeadDataMutation,
} from "../../../store/queries/Report";
import { updateConfig } from "../../../store/slices/Report/myCardSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useState } from "react";
import { toast } from "react-toastify";
import { getMyCardsExportedData } from "../api";
import { getFormatedDate } from "../../../utils/Functions/table";

const useMyCards = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const [isExcelLinkSend, setIsExcelLinkSend] = useState(false);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const myCardsState = useSelector((state) => state.myCards);
  const { data: basicData = {} } = useGetMyCardsBasicListDataQuery();
  const {
    data: myCardsList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetMyCardsListDataQuery({
    page_size: myCardsState?.currentPageSize,
    page: myCardsState?.currentPage,
    sort: myCardsState?.sortBy,
    sort_order: myCardsState?.sortOrder,
    search: myCardsState?.search,
    start_date: getFormatedDate(myCardsState?.startDate),
    end_date: getFormatedDate(myCardsState?.endDate),
    template_id: myCardsState?.templateId,
  });
  const [updateMyCardsFields] = useUpdateMyCardsTableHeadDataMutation();
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e?.target?.value;
        state.currentPage = 1;
      })
    );
  };
  const [date, setDate] = useState({
    startDate: myCardsState.startDate,
    endDate: myCardsState.endDate,
    key: "selection",
  });

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (myCardsState?.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = myCardsState?.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleMyCardsExcelDownload = () => {
    dispatch(
      updateConfig((state) => {
        state.myCardsExcelSendModal = !state.myCardsExcelSendModal;
      })
    );
  };
  const modalClose = () => {
    dispatch(
      updateConfig((state) => {
        state.myCardsExcelSendModal = false;
      })
    );
  };
  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };
  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const sendExcelData = async (value) => {
    setIsExcelLinkSend(true);
    let params = {
      email: value?.email ?? "",
      start_date: getFormatedDate(myCardsState.startDate ?? ""),
      end_date: getFormatedDate(myCardsState.endDate ?? ""),
      search: myCardsState?.search,
      template_id: myCardsState?.templateId,
      sort_order: myCardsState?.sortOrder,
      sort: myCardsState?.sortBy,
    };
    let response = await getMyCardsExportedData(params);
    try {
      if (response?.status === 200) {
        toast.success(`Excel download link send successfully`);
      } else {
        toast.error(`Failed to send download link,Please try again`);
      }
    } catch (error) {
      console.error("Failed to send link:", error);
      toast.error(`Failed to send download link`);
    } finally {
      modalClose();
      setIsExcelLinkSend(false);
    }
  };
  const handleChange = (e) => {
    dispatch(
      updateConfig((state) => {
        state.templateId = e?.value;
      })
    );
  };
  let filterList = basicData?.data?.map((item) => {
    return {
      label: item?.title,
      value: item?.template_id,
    };
  });
  filterList?.unshift({ label: "All", value: "" });
  let filterObj = filterList?.filter(
    (item) => item?.value === myCardsState?.templateId
  );

  return {
    date,
    filterObj,
    isLoading,
    isFetching,
    filterList,
    tableFields,
    myCardsList,
    myCardsState,
    showEditModal,
    isExcelLinkSend,
    paginationOptions,
    handleMyCardsExcelDownload,
    handleEditColumnsClick,
    handleDateRangeChange,
    updateMyCardsFields,
    handleDateChange,
    handlePagination,
    handleClearClick,
    handlePageSize,
    sendExcelData,
    handleSearch,
    handleChange,
    handleSort,
    modalClose,
    refetch,
    getRow,
  };
};

export default useMyCards;
