import {
  Header,
  // MultiColumnTable,
  ProfileSideBar,
  SimpleTextDetails,
  Image,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";

import Assets from "../../../../assets/Assets";
import ProtectRoute from "../../../../utils/components/ProtectRoute/protectRoute";
import { Link, Outlet } from "react-router-dom";
import HeaderActions from "../../../../pages/Layout/CommonLayout/HeaderActions";
import Style from "../../../../pages/Layout/CommonLayout/commonLayout.module.scss";
import profileStyle from "./productDetailLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../../Global/Shimmers/ProfileLayoutSidebarShimmer";
import useProductDetailLayout from "./useProductDetailLayout";

const ProductDetailLayout = ({ navigation }) => {
  const {
    navigations,
    // basicDetails,
    activeProfile,
    profileData,
    isFetching,
    storeState,
    globalState,
    // hasUpdatePermission,
    handleEditModal,
  } = useProductDetailLayout();

  return (
    <ProtectRoute>
      <Header
        navigations={navigations}
        Link={Link}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.LOGO
            : Assets.LOGO
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header, headerInner: Style.headerInner }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`col-3 ${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(profileData?.data?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-5 pro-pt-5`}>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      <div className={profileStyle.profile}>
                        <Image
                          src={`${
                            profileData?.data?.data?.image_url == "" ||
                            profileData?.data?.data?.image_url == null
                              ? Assets.IMAGE_ABSENCE
                              : profileData?.data?.data?.image_url
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />

                        {profileData?.data?.data?.is_non_veg ? (
                          <span
                            className={`pro-badge badge-danger ${profileStyle.img_badge}`}
                          >
                            Non Veg
                          </span>
                        ) : (
                          <span
                            className={`pro-badge badge-primary ${profileStyle.img_badge}`}
                          >
                            Veg
                          </span>
                        )}
                      </div>

                      <SimpleTextDetails
                        title={`${profileData?.data?.data?.name ?? ""}`}
                        designation={`${
                          profileData?.data?.data?.category ?? ""
                        }`}
                        uId={profileData?.data?.rows?.registration_no ?? ""}
                        extraClassNames={`pro-mt-4 pro-mb-2 ${profileStyle.profile_name_wrap}`}
                      />

                      {profileData?.data?.data?.description && (
                        <p
                          className={`${profileStyle.product_description} pro-pt-4 pro-mt-4 pro-font-sm pro-mb-0`}
                        >
                          {profileData?.data?.data?.description}
                        </p>
                      )}

                      <div
                        className={`pro-p-4 pro-d-flex pro-justify-between pro-mt-4 pro-mb-5 pro-items-center pro-gap-1 pro-rounded-3 ${profileStyle.balance_wrap}`}
                      >
                        <div
                          className={`pro-d-flex pro-items-center pro-gap-2`}
                        >
                          <p className="pro-mb-0 pro-fw-medium">{`Price`}</p>
                        </div>
                        <h6 className="pro-mb-0 pro-fw-bold">
                          {profileData?.data?.data?.price}
                        </h6>
                      </div>
                    </div>
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap}`}
                    ></div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          <OffCanvasLayout
            show={storeState.showProductEditModal}
            handleClose={handleEditModal}
            title={`${"Update Product"}`}
            closeIcon={
              <span className="material-symbols-outlined"> close </span>
            }
            backdrop="static"
          >
            Edit Product
          </OffCanvasLayout>
          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <Outlet context={{ activeProfile }} />
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default ProductDetailLayout;
