import { getAdminAxiosInstance } from "../../../api/Admin";

export const createFaq = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/faq/store`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteFaq = async (tierId) => {
  const api = await getAdminAxiosInstance();

  try {
    const response = await api.get(`/faq/destroy?faq_id=${tierId}`);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
