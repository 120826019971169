import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoadingVideo: false, // Loading state for the main image
  isArabicVideoLoading: false, // Loading state for the Arabic image
  showPassword: false,
  videoBannerData: {},
  videoPreview: "",
  videoName: "",
  arabicVideoPreview: "",
  arabicVideoName: "",
  editVideoBannerData: {},
  error: "",
  statusData: {},
  isLoading: false,
  editDataLoading: false,
};

export const getVideoBannerEditData = createAsyncThunk(
  "/banner/video-edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/banner/video-edit?banner_id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const videoBannerSlice = createSlice({
  name: "videoBanner",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVideoBannerEditData.pending, (state, action) => {
        state.editDataLoading = true;
      })
      .addCase(getVideoBannerEditData.fulfilled, (state, action) => {
        state.editVideoBannerData = action.payload?.data;
        state.editDataLoading = false;
      })
      .addCase(getVideoBannerEditData.rejected, (state, action) => {
        state.editDataLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = videoBannerSlice.actions;
export default videoBannerSlice.reducer;
