import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Admin/Activity/activitySlice";
import { useSelector } from "react-redux";

const useActivityLog = () => {
  const dispatch = useDispatch();
  const { activeId } = useSelector((state) => state.activity);

  const handleClick = (e) => {
    if (e?.name === "User") {
      dispatch(
        updateConfig((state) => {
          state.activeId = 1;
          state.activeType = "user";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.activeId = 2;
          state.activeType = "staff";
        })
      );
    }
  };

  return {
    activeId,
    handleClick,
  };
};

export default useActivityLog;
