import React, { useEffect, useState } from "react";
import {
  getCategoryEditData,
  updateConfig,
} from "../../../store/slices/Admin/Category/categorySlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  useGetCategoryListDataQuery,
  useUpdateCategoryTableHeadDataMutation,
} from "../../../store/queries/Admin";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { deleteCategory } from "./api";
import { toast } from "react-toastify";

const useCategory = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageData, setImageData] = useState("");
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category);
  const { showEditModal } = useSelector((state) => state.global);
  const {
    data: categoryList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetCategoryListDataQuery({
    page_size: categoryState?.currentPageSize,
    page: categoryState?.currentPage,
    sort: categoryState?.sortBy,
    sort_order: categoryState?.sortOrder,
    search: categoryState?.search,
  });
  const [updateCategoryTableFields] = useUpdateCategoryTableHeadDataMutation();

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.imageName = "";
        state.imagePreview = "";
        state.iconName = "";
        state.iconPreview = "";
        state.isEdit = false;
      })
    );
  };
  useEffect(() => {
    if (categoryState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [categoryState.clearSelection]);
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      image: (field, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image_url)}
            />
          </div>
        );
      },
      name: (field, data) => {
        return(
          <p className="pro-mb-0 pro-fw-medium">{data?.name}</p>
        )
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handleSort = (label) => {
    if (categoryState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = categoryState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getCategoryEditData(e?.[0]));
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };
  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };
  const handleDelete = async () => {
    const response = await deleteCategory(categoryState?.selectedId);
    if (response && response.status === 200) {
      toast.success(`Category deleted successfully.`);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };

  return {
    isLoading,
    imageData,
    isFetching,
    categoryList,
    showEditModal,
    categoryState,
    showImageModal,
    paginationOptions,
    handleEditColumnsClick,
    updateCategoryTableFields,
    handleDeleteModal,
    handleCreateClick,
    handlePagination,
    handleClearClick,
    handleEditAction,
    closeImageModal,
    handlePageSize,
    handleSearch,
    handleDelete,
    handleSort,
    refetch,
    getRow,
  };
};

export default useCategory;
