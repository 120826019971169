import Style from "./fileUpload.module.scss";
import { Image } from "@wac-ui-dashboard/wac_component_library";
const FileUpload = ({
  formik,
  label,
  isDeletable,
  errorStyle,
  logoPreview,
  getFieldError,
  handleLogoChange,
  handleLogoRemove,
  isImageUploading,
}) => {
  return (
    <div className={`${Style.root} pro-d-flex pro-flex-column`}>
      <label className="pro-font-sm pro-mb-1 pro-fw-medium">{label}</label>
      <div className={`${Style.box_wrap}`}>
        <input
          type="file"
          accept=".jpeg,.jpg , .png , .svg "
          className={errorStyle ? Style.error : ""}
          id="logo"
          onBlur={formik.handleBlur("logo")}
          onChange={(e) => handleLogoChange(e)}
        />
        <span
          className={`${Style.input_box} pro-d-flex pro-flex-column pro-justify-center pro-items-center pro-text-center`}
        >
          {!logoPreview && !isImageUploading ? (
            <span
              className={`pro-d-flex pro-items-center pro-font-sm pro-fw-medium ${Style.text}`}
            >
              <span className="material-symbols-outlined pro-me-1">
                {" "}
                cloud_upload{" "}
              </span>
              Drop file
            </span>
          ) : (
            ""
          )}
          {isImageUploading ? (
            <span
              className={`pro-d-flex pro-items-center pro-font-sm pro-fw-medium ${Style.text}`}
            >
              Uploading...
            </span>
          ) : (
            ""
          )}
          {/* <span className="pro-font-xs">jpg or png - Max file size 2MB</span> */}
        </span>
        {getFieldError("logo") && (
          <span className="error-text">{getFieldError("logo")}</span>
        )}
        {logoPreview && (
          <span className={`${Style.img_wrap}`}>
            <Image
              src={logoPreview}
              width={48}
              height={48}
              alt={`name`}
              propStyle={{ root: Style.img }}
            />
            {isDeletable ? (
              <span
                onClick={handleLogoRemove}
                className={`${Style.delete_btn} pro-rounded-circle pro-d-flex pro-justify-center pro-items-center `}
              >
                <span className="material-symbols-outlined"> delete </span>
              </span>
            ) : (
              ""
            )}
          </span>
        )}
        {/* <span className="error-text">{formik.errors.logo}</span> */}
      </div>
    </div>
  );
};

export default FileUpload;
