import React from "react";
import ProductDetails from "../../../../ProductDetails";

const FetchedDetails = ({
  data,
  prevData,
  finalData,
  optionalAddOnKey,
  missMatchedKeyData,
  variantsKey,
  addOnKey,
}) => {
  return (
    <>
      <ProductDetails
        data={data}
        prevData={prevData}
        headName={`Fetch Product Details`}
        missMatchedKeyData={missMatchedKeyData}
        variantsKey={variantsKey}
        addOnKey={addOnKey}
        optionalAddOnKey={optionalAddOnKey}
        finalData={finalData}
      />
    </>
  );
};

export default FetchedDetails;
