import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  staffData: [],
  phoneVerified: {
    verified: false,
    status: false,
  },
  imagePreview: "",
  imageName: "",
  editStaffData: {},
  statusData: {},
  isImageUploading: false,
};

export const getStaffEditData = createAsyncThunk(
  "/staff/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/staff/edit?staff_id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffEditData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getStaffEditData.fulfilled, (state, action) => {
        state.editStaffData = action.payload?.data;
      })
      .addCase(getStaffEditData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = staffSlice.actions;
export default staffSlice.reducer;
