import { useFormik } from "formik";
import * as Yup from "yup";
import useValidations from "../../../../utils/hooks/useValidations";
import { useGetVideoBannerBasicDataQuery } from "../../../../store/queries/Admin";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/User/videoBannerSlice";
import { toast } from "react-toastify";
import { getVideoBanner, createVideoBanner } from "../api";
import { useEffect } from "react";

const useAddVideoBanner = ({ handleCreateClick, refetch }) => {
  const { englishRegex, arabicRegex } = useValidations();
  const {
    isEdit,
    videoName,
    isLoading,
    selectedId,
    videoPreview,
    isLoadingVideo,
    arabicVideoName,
    editDataLoading,
    arabicVideoPreview,
    editVideoBannerData,
    isArabicVideoLoading,
  } = useSelector((state) => state.videoBanner);
  const dispatch = useDispatch();
  const { data: basicData = {} } = useGetVideoBannerBasicDataQuery();
  const validation = Yup.object({
    title: Yup.string()
      .trim()
      .matches(englishRegex, "Enter valid title")
      .required(`Enter video banner title`),
    alias_title: Yup.string()
      .trim()
      .matches(arabicRegex, "Enter valid title")
      .required("Enter video banner title"),
    video: Yup.string().required("Upload video file"),
    alias_video: Yup.string().required("Upload video file"),
    type: Yup.object().required("Select type"),
  });
  const formik = useFormik({
    initialValues: {
      title: "",
      alias_title: "",
      video: "",
      alias_video: "",
      type: "",
      status: 2,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      let data = {
        title: values?.title ?? "",
        alias_title: values?.alias_title ?? "",
        video: values?.video ?? "",
        alias_video: values?.alias_video ?? "",
        type: values?.type?.slug ?? "",
        status: values?.status ?? 2,
      };
      const updateData = { banner_id: selectedId, ...data };
      const response = await createVideoBanner(isEdit ? updateData : data);
      try {
        if (response?.data?.status_code === 200) {
          toast.success(
            response?.data?.message ??
              `Video banner ${isEdit ? "updated" : "created"} successfully`
          );
          handleCreateClick();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          refetch();
        } else if (response?.errors && response?.status_code === 422) {
          Object.keys(response?.errors).forEach((field) => {
            formik.setFieldError(field, response?.errors[field]);
          });
          dispatch(updateConfig((state) => (state.clearSelection = false)));
        } else {
          toast.error(`Something went wrong! Please try again`);
          console.error("Error:", response?.errors);
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        dispatch(updateConfig((state) => (state.isLoading = false)));
      }
    },
  });
  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.isEdit = false;
        state.editVideoBannerData = {};
      })
    );
  };
  useEffect(() => {
    if (isEdit && editVideoBannerData) {
      let selectedType = basicData?.data?.types?.find(
        (item) => item?.slug === editVideoBannerData?.type
      );
      formik?.setFieldValue("title", editVideoBannerData?.title);
      formik?.setFieldValue("alias_title", editVideoBannerData?.alias_title);
      formik?.setFieldValue("video", editVideoBannerData?.video);
      formik?.setFieldValue("alias_video", editVideoBannerData?.alias_video);
      formik?.setFieldValue("type", selectedType ?? "");
      formik?.setFieldValue("status", editVideoBannerData?.status);
      dispatch(
        updateConfig((state) => {
          state.videoName = editVideoBannerData?.video?.split("/").pop();
          state.arabicVideoName = editVideoBannerData?.alias_video
            ?.split("/")
            .pop();
        })
      );
    }
    //eslint-disable-next-line
  }, [isEdit, editVideoBannerData, editDataLoading]);

  const handleVideoChange = async (event, fieldName) => {
    const selectedFile = event?.target?.files[0];
    // for conditional Loading of the Video
    const allowedFormats = [
      "video/mp4",
      "video/mpeg",
      "video/quicktime",
      "video/3gpp",
      "video/avi",
      "video/x-msvideo",
      "video/x-flv",
      "video/x-ms-wmv",
      "video/x-matroska",
    ]; // Add more video formats as needed
    const maxSizeInBytes = 10 * 1024 * 1024; //
    const isMainVideo = fieldName === "video";
    if (selectedFile) {
      let validFiles = [];
      if (!allowedFormats.includes(selectedFile.type)) {
        formik.setFieldError(
          `${fieldName === "video" ? "video" : "alias_video"}`,
          `File ${selectedFile.name} is not a valid video format`
        );
        return;
      } else if (selectedFile?.size > maxSizeInBytes) {
        formik.setFieldError(
          `${fieldName === "video" ? "video" : "alias_video"}`,
          `File ${selectedFile.name} exceeds the 10 MB limit`
        );
        return;
      } else {
        validFiles.push(selectedFile);
      }
      const formData = new FormData();
      formData.append("video", selectedFile);
      dispatch(
        updateConfig((state) => {
          isMainVideo
            ? (state.isLoadingVideo = true)
            : (state.isArabicVideoLoading = true);
        })
      );
      const response = await getVideoBanner(formData);
      try {
        if (response?.data?.status_code === 200) {
          formik.setFieldValue(
            fieldName === "video" ? "video" : "alias_video",
            response?.data?.data?.video
          );
        } else if (response?.status_code === 422 && response?.errors) {
          Object.keys(response?.errors).forEach((field) => {
            formik.setFieldError(
              `${fieldName === "video" ? "video" : "alias_video"}`,
              response?.errors[field]
            );
          });
        } else {
          toast.error(`Unable to add video, Please try again.`);
        }
      } catch (error) {
        console.error(`Unable to add video:`, error);
      } finally {
        dispatch(
          updateConfig((state) => {
            isMainVideo
              ? (state.isLoadingVideo = false)
              : (state.isArabicVideoLoading = false);
          })
        );
      }

      const file = selectedFile;
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(
          updateConfig((state) => {
            state[
              fieldName === "video" ? "videoPreview" : "arabicVideoPreview"
            ] = reader.result;
            state[fieldName === "video" ? "videoName" : "arabicVideoName"] =
              selectedFile?.name;
          })
        );
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };
  const handleTypeChange = (e) => {
    formik.setFieldValue("type", e);
  };
  return {
    formik,
    isEdit,
    basicData,
    isLoading,
    videoName,
    videoPreview,
    isLoadingVideo,
    arabicVideoName,
    arabicVideoPreview,
    isArabicVideoLoading,
    handleVideoChange,
    handleTypeChange,
    getFieldError,
    closeModal,
  };
};

export default useAddVideoBanner;
