import { useEffect, useState } from "react";
import { updateConfig } from "../../store/slices/Admin/Products/productSlice";
import { updateConfig as globalUpdateConfig } from "../../store/slices/Global";
import { useDispatch } from "react-redux";

const useProductDetails = ({ data, prevData, finalData }) => {
  const dispatch = useDispatch();

  const [addOnChecked, setAddOnChecked] = useState([]);
  const [variantChecked, setVariantChecked] = useState([]);
  const [optionalAddOnChecked, setOptionalAddOnChecked] = useState([]);

  //setting values to state
  const [initialData, setInitialData] = useState({});
  const [checked, setChecked] = useState({
    name: 2,
    alias_name: 2,
    product_code: 2,
    price: 2,
    description: 2,
    alias_description: 2,
    offer: 2,
    category: 2,
    variants: [],
    image_caribou: 2,
    product_image: 2,
    isNonVeg: 0,
    status: 2,
    best_seller: 2,
    add_on: [],
    optional_add_on: [],
    new_product: 2,
  });

  useEffect(() => {
    setInitialData({
      name: prevData?.name ?? "",
      alias_name: prevData?.alias_name ?? "",
      product_code: prevData?.product_code ?? "",
      price: prevData?.price ?? "",
      description: prevData?.description ?? "",
      alias_description: prevData?.alias_description ?? "",
      offer: prevData?.offer ?? "",
      category: prevData?.category ?? "",
      image_caribou: prevData?.image ?? "",
      product_image: prevData?.product_image ?? "",
      isNonVeg: prevData?.isNonVeg ?? 0,
      status: prevData?.status ?? 2,
      best_seller: prevData?.best_seller ?? 2,
      new_product: prevData?.new_product ?? 2,
      add_on: (prevData?.add_on || [])?.map((item) => item),
      variants: (prevData?.variants || [])?.map((item) => item),
      optional_add_on: (prevData?.optional_add_on || [])?.map((item) => item),
    });
    //eslint-disable-next-line
  }, [prevData?.name]);

  useEffect(() => {
    setChecked({
      ...checked,
      add_on: (data?.add_on || [])?.map(() => {
        return {
          name: 2,
          alias_name: 2,
          description: 2,
          alias_description: 2,
          price: 2,
          parent_id: 2,
          add_on_image: 2,
          image: 2,
        };
      }),
      variants: (data?.variants || [])?.map(() => {
        return {
          name: 2,
          alias_name: 2,
          price: 2,
          type: 2,
          description: 2,
          alias_description: 2,
          add_on_image: 2,
          image: 2,
        };
      }),
      optional_add_on: (data?.optional_add_on || [])?.map(() => {
        return {
          name: 2,
          alias_name: 2,
          description: 2,
          alias_description: 2,
          price: 2,
          parent_id: 2,
          add_on_image: 2,
          image: 2,
        };
      }),
    });
    setAddOnChecked(
      (data?.add_on || [])?.map(() => {
        return {
          addon_new_checked: 2,
        };
      })
    );
    setVariantChecked(
      (data?.variants || [])?.map(() => {
        return {
          variant_new_checked: 2,
        };
      })
    );
    setOptionalAddOnChecked(
      (data?.optional_add_on || [])?.map(() => {
        return {
          addon_new_checked: 2,
        };
      })
    );
    //eslint-disable-next-line
  }, [data?.name]);

  useEffect(() => {
    if (finalData) {
      dispatch(
        updateConfig((state) => {
          state.finalProductChangeData = initialData;
          state.changeAvailable = true;
        })
      );
    }
    //eslint-disable-next-line
  }, [finalData]);
  useEffect(() => {
    if (data?.add_on?.length === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.offCanvasWidth = true;
        })
      );
    } else {
      dispatch(
        globalUpdateConfig((state) => {
          state.offCanvasWidth = false;
        })
      );
    }
    //eslint-disable-next-line
  }, [data !== "", data?.add_on?.length]);

  const handleNameChange = (e, fieldName, value, prevValue) => {
    setChecked({ ...checked, [fieldName]: e?.target?.checked ? 1 : 2 });
    if (fieldName === "isNonVeg") {
      setInitialData({
        ...initialData,
        [fieldName]: e?.target?.checked ? value : prevValue,
      });
    } else if (fieldName === "status") {
      setInitialData({
        ...initialData,
        [fieldName]: e?.target?.checked ? value : prevValue,
      });
    } else if (fieldName === "best_seller") {
      setInitialData({
        ...initialData,
        [fieldName]: e?.target?.checked ? value : prevValue,
      });
    } else if (fieldName === "new_product") {
      setInitialData({
        ...initialData,
        [fieldName]: e?.target?.checked ? value : prevValue,
      });
    } else {
      setInitialData({
        ...initialData,
        [fieldName]: e?.target?.checked ? value : prevValue,
      });
    }
  };

  const handleVariantsAndAddons = (e, fieldName, value, index) => {
    handleAddOnChecked(e, fieldName, index);
    let key1 = fieldName?.split(".")[0];
    let key2 = fieldName?.split(".")[1];
    let updatedInitialData = { ...initialData };
    let updateVariantOrAddOn = updatedInitialData?.[key1]?.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [key2]: e?.target?.checked ? value : prevData[key1][index][key2],
        };
      }
      return item;
    });
    updatedInitialData[key1] = updateVariantOrAddOn;

    setInitialData(updatedInitialData);
  };
  //adding new data to product
  const handleNewData = (e, fieldName, value, index) => {
    let updatedNewData = { ...initialData };
    if (fieldName === "add_on") {
      let updatedAddOnChecked = { ...addOnChecked };
      updatedAddOnChecked[index].addon_new_checked = e?.target?.checked ? 1 : 2;
      setAddOnChecked(updatedAddOnChecked);

      updatedNewData[fieldName][index] = value;
      //  {
      //   add_on_image: e?.target?.checked ? value?.add_on_image : "",
      //   image: e?.target?.checked ? value?.image : "",
      //   alias_description: e?.target?.checked ? value?.alias_description : "",
      //   alias_name: e?.target?.checked ? value?.alias_name : "",
      //   description: e?.target?.checked ? value?.description : "",
      //   name: e?.target?.checked ? value?.name : "",
      //   parent_id: e?.target?.checked ? value?.parent_id : "",
      //   price: e?.target?.checked ? value?.price : "",
      // };
    } else if (fieldName === "optional_add_on") {
      let updatedOptionalAddOnChecked = { ...optionalAddOnChecked };
      updatedOptionalAddOnChecked[index].addon_new_checked = e?.target?.checked
        ? 1
        : 2;
      setOptionalAddOnChecked(updatedOptionalAddOnChecked);

      updatedNewData[fieldName][index] = value;
      // {
      //   add_on_image: e?.target?.checked ? value?.add_on_image : "",
      //   image: e?.target?.checked ? value?.image : "",
      //   alias_description: e?.target?.checked ? value?.alias_description : "",
      //   alias_name: e?.target?.checked ? value?.alias_name : "",
      //   description: e?.target?.checked ? value?.description : "",
      //   name: e?.target?.checked ? value?.name : "",
      //   parent_id: e?.target?.checked ? value?.parent_id : "",
      //   price: e?.target?.checked ? value?.price : "",
      // };
    } else {
      let updatedVariantChecked = { ...variantChecked };
      updatedVariantChecked[index].variant_new_checked = e?.target?.checked
        ? 1
        : 2;
      setVariantChecked(updatedVariantChecked);

      updatedNewData[fieldName][index] = value;
      //  {
      //   name: e?.target?.checked ? value?.name : "",
      //   price: e?.target?.checked ? value?.price : "",
      //   type: e?.target?.checked ? value?.type : "",
      //   description: e?.target?.checked ? value?.description : "",
      //   alias_description: e?.target?.checked ? value?.alias_description : "",
      //   add_on_image: e?.target?.checked ? value?.add_on_image : "",
      // };
    }
    setInitialData(updatedNewData);
  };

  const handleAddOnChecked = (e, fieldName, index) => {
    let key1 = fieldName.split(".")[0];
    let key2 = fieldName.split(".")[1];
    let updatedChecked = { ...checked };
    updatedChecked[key1][index][key2] = e?.target?.checked ? 1 : 2;
    setChecked(updatedChecked);
  };

  return {
    checked,
    addOnChecked,
    variantChecked,
    optionalAddOnChecked,
    handleVariantsAndAddons,
    handleAddOnChecked,
    handleNameChange,
    handleNewData,
  };
};

export default useProductDetails;
