import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import { getAdminAxiosInstance } from "../../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  customOfferListEditData: [],
};

export const getCustomOfferEditData = createAsyncThunk(
  "custom-offer/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`custom-offer/edit?offer_id=${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const customOfferSlice = createSlice({
  name: "customOffers",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomOfferEditData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCustomOfferEditData.fulfilled, (state, action) => {
        state.customOfferListEditData = action?.payload?.data;
      })
      .addCase(getCustomOfferEditData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = customOfferSlice.actions;
export default customOfferSlice.reducer;
