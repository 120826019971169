import React from "react";
import Style from "./ProfileLayoutSidebarShimmer.module.scss";
const ProfileLayoutSidebarShimmer = () => {
  return (
    <div className={`pro-px-5 pro-pt-5 ${Style.shimmer_container}`}>
      <div className="pro-d-flex pro-flex-column pro-w-100">
        <div
          className="blink img"
          style={{ "--blink-duration": "1s", "--blink-delay": "0s" }}
        ></div>
      </div>
      <div className="pro-my-5 pro-pt-2 pro-d-flex pro-flex-column pro-justify-start pro-items-start pro-gap-2">
        <span className="shimmer pro-d-inline-flex">
          <span className="pro-pt-5 pro-px-6"></span>
          <span className="pro-pt-5 pro-px-6"></span>
        </span>
        <span className="shimmer pro-pt-2 pro-px-6"></span>
        <span className="shimmer pro-mt-3 pro-d-flex">
          <span className="pro-pt-2 pro-px-6"></span>
          <span className="pro-pt-2 pro-px-3"></span>
        </span>
      </div>

    <div className="pro-pt-4 pro-pb-2 pro-border-top ">
      <span className="shimmer letter para"></span>
      <span className="shimmer letter para"></span>
      <span className="shimmer pro-d-inline-flex pro-mt-2">
        <span className="pro-pt-1 pro-px-6"></span>
        <span className="pro-pt-1 pro-px-6"></span>
      </span>
    </div>

      <div className="pro-py-5 pro-rounded-3 shimmer pro-mt-5 pro-w-100">
        <div className="pro-pt-4"></div>
      </div>

      <div className="pro-pt-5">
        <div className="pro-pt-4 pro-px-6 shimmer"></div>
        <div className="pro-pt-4">
          <div className="pro-d-flex pro-gap-6  pro-mb-3">
            <span className="pro-pt-1 flex-fill pro-px-6 shimmer"></span>
            <span className="pro-pt-1 flex-fill pro-px-6 shimmer"></span>
          </div>
          <div className="pro-d-flex pro-gap-6  pro-mb-3">
            <span className="pro-pt-1 flex-fill pro-px-6 shimmer"></span>
            <span className="pro-pt-1 flex-fill pro-px-6 shimmer"></span>
          </div>
          <div className="pro-d-flex pro-gap-6  pro-mb-3">
            <span className="pro-pt-1 flex-fill pro-px-6 shimmer"></span>
            <span className="pro-pt-1 flex-fill pro-px-6 shimmer"></span>
          </div>
          <div className="pro-d-flex pro-gap-6 ">
            <span className="pro-pt-1 flex-fill pro-px-6 shimmer"></span>
            <span className="pro-pt-1 flex-fill pro-px-6 shimmer"></span>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ProfileLayoutSidebarShimmer;
