import { getAdminAxiosInstance } from "../../../api/Admin";

export const uploadFlashVideo = async (params) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/upload-video`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
