import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useCurbSide from "./useCurbSide";
import Style from "../addStore.module.scss";
const CurbSide = ({ formik, getFieldError, checkError, isStoreCreate }) => {
  const {
    time,
    isEdit,
    handleCloseModal,
    handleTimeChange,
    isImageUploading,
    daysOfWeek,
    handleToggle,
    handleNext,
    enabledDays,
    // getMinToTime,
    // curbTimeError,
  } = useCurbSide(formik);

  return (
    <>
      <div className="form-check form-switch pro-mb-4">
        <input
          className="form-check-input cursor-pointer"
          type="checkbox"
          role="switch"
          id="curb_side"
          name="curb_side"
          checked={Boolean(formik.values?.curb_side === 1) ?? false}
          onChange={(e) => {
            formik.setFieldValue("curb_side", e.target.checked ? 1 : 2);
          }}
        />
        <label htmlFor="curb_side" className="pro-check-label">
          Set Curb Hours
        </label>
        {getFieldError("curb_side") && (
          <span className="error">Please select at least one day's hours.</span>
        )}
      </div>

      {formik.values?.curb_side === 1 && (
        <>
          {daysOfWeek.map((day) => (
            <div
              key={day}
              className={`pro-d-flex pro-flex-column pro-mb-4 ${Style.time_wrap}`}
            >
              <label className="pro-font-sm pro-mb-1 pro-fw-medium">
                {day}
              </label>
              <div
                className={`pro-d-flex pro-items-center ${Style.time_picker_wrap}`}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={time[day]?.from || ""}
                    onChange={(newValue) =>
                      handleTimeChange(day, "from", newValue)
                    }
                    className="time-picker"
                    disabled={
                      !enabledDays[day] || formik.values.curb_side === 0
                    }
                  />

                  <span className="pro-px-3 pro-d-flex pro-items-center">
                    <span className="material-symbols-outlined x4">
                      {" "}
                      remove{" "}
                    </span>
                  </span>

                  <TimePicker
                    value={time[day]?.to || ""}
                    onChange={(newValue) =>
                      handleTimeChange(day, "to", newValue)
                    }
                    className="time-picker"
                    // minTime={getMinToTime(day, time[day]?.from)}
                    disabled={
                      !enabledDays[day] || formik.values.curb_side === 0
                    }
                  />
                  <div className="form-check form-switch pro-mb-0 pro-ms-auto">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      role="switch"
                      id={`${day}-switch`}
                      checked={enabledDays[day] || false}
                      onChange={() => handleToggle(day)}
                    />
                  </div>
                </LocalizationProvider>
              </div>
              {/* {curbTimeError[day] !== null && (
                <span className="error pro-mt-2 pro-font-xs pro-lh-1">
                  {curbTimeError[day]}
                </span>
              )} */}
            </div>
          ))}

          <div>
            <Input
              label={"Curbside Note"}
              type="text"
              id="curbside_note"
              name="curbside_note"
              className={`pro-input lg ${
                getFieldError("curbside_note") && " error"
              }`}
              {...formik.getFieldProps("curbside_note")}
              error={getFieldError("curbside_note")}
              errorMessage={getFieldError("curbside_note")}
            />
          </div>
          <div className="pro-rtl">
            <Input
              label={"(ملاحظة الرصيف)  Curbside Note"}
              type="text"
              id="alias_curbside_note"
              name="alias_curbside_note"
              className={`pro-input lg ${
                getFieldError("alias_curbside_note") && " error"
              }`}
              {...formik.getFieldProps("alias_curbside_note")}
              error={getFieldError("alias_curbside_note")}
              errorMessage={getFieldError("alias_curbside_note")}
            />
          </div>

          <div>
            <Input
              label={"Curbside Info"}
              type="text"
              id="curbside_info"
              name="curbside_info"
              className={`pro-input lg ${
                getFieldError("curbside_info") && " error"
              }`}
              {...formik.getFieldProps("curbside_info")}
              error={getFieldError("curbside_info")}
              errorMessage={getFieldError("curbside_info")}
            />
          </div>
          <div className="pro-rtl">
            <Input
              label={"(معلومات الرصيف)  Curbside Info"}
              type="text"
              id="alias_curbside_info"
              name="alias_curbside_info"
              className={`pro-input lg ${
                getFieldError("alias_curbside_info") && " error"
              }`}
              {...formik.getFieldProps("alias_curbside_info")}
              error={getFieldError("alias_curbside_info")}
              errorMessage={getFieldError("alias_curbside_info")}
            />
          </div>
        </>
      )}
      <div className={`col-12 pro-d-flex pro-justify-end pro-pb-5 pro-mt-auto`}>
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
          type="button"
        >
          Cancel
        </Button>

        {/* <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={checkError}
          disabled={isStoreCreate}
        >
          {isEdit && !isStoreCreate
            ? "Update"
            : isStoreCreate && isEdit
            ? "Updating..."
            : isStoreCreate && !isEdit
            ? "Creating..."
            : "Create"}
        </Button> */}
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={handleNext}
          disabled={isImageUploading}
        >
          {"Next"}
        </Button>
      </div>
    </>
  );
};

export default CurbSide;
