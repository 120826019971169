import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useSendExcelData from "./useSendExcelData";
import Select from "react-select";

const SendExcelData = ({
  isWalletTransaction,
  isBestSeller,
  sendExcelData,
  isExcelLinkSend,
  handleCloseModal,
  isUserList,
}) => {
  const { formik, typeList, typeObj, expObj, options, getFieldError } =
    useSendExcelData({
      sendExcelData,
      isWalletTransaction,
      isBestSeller,
      isUserList,
    });
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Email"}
          type="text"
          id="email"
          name="email"
          className={`pro-input lg ${getFieldError("email") && " error"}`}
          {...formik.getFieldProps("email")}
          error={getFieldError("email")}
          errorMessage={getFieldError("email")}
        />
        {isWalletTransaction || isBestSeller ? (
          <></>
        ) : (
          <div className="input-wrap pro-mb-4">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Type
            </label>
            <Select
              id="type"
              name="type"
              placeholder={"Select"}
              className={`pro-input lg  ${getFieldError("type") && " error"}`}
              classNamePrefix="pro-input"
              options={isUserList ? options : typeList}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.value}
              value={isUserList ? expObj : typeObj}
              onBlur={formik.handleBlur("type")}
              onChange={(value) =>
                formik?.setFieldValue("type", value?.value || null)
              }
              menuPlacement="auto"
            />
            {getFieldError("type") && (
              <span className="error-text">{getFieldError("type")}</span>
            )}
          </div>
        )}
      </div>
      <div
        className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn `}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
          disabled={isExcelLinkSend}
        >
          {`${isExcelLinkSend ? "Sending..." : "Send"}`}
        </Button>
      </div>
    </div>
  );
};

export default SendExcelData;
