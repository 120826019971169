import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  useGetRoleListDataQuery,
  useUpdateRoleTableHeadDataMutation,
} from "../../../store/queries/Admin";
import { updateConfig } from "../../../store/slices/Admin/Role/roleSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const useRole = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleState = useSelector((state) => state.role);
  const { tableFields, showEditModal, showDeleteModal, selectedId } =
    useSelector((state) => state.global);
  const {
    data: roleList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetRoleListDataQuery({
    page_size: roleState?.currentPageSize,
    page: roleState?.currentPage,
    sort: roleState?.sortBy,
    sort_order: roleState?.sortOrder,
    start: roleState?.startDate,
    end: roleState?.endDate,
    search: roleState?.search,
  });
  const [updateRoleFields] = useUpdateRoleTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handleSort = (label) => {
    if (roleState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = roleState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  useEffect(() => {
    if (roleState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [roleState.clearSelection]);
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      status: (field, data) =>
        data?.status === "Active" ? (
          <span className={"pro-badge badge-primary-outline"}>{"Active"}</span>
        ) : data?.status === "Deactivated" ? (
          <span className={"pro-badge badge-danger-outline"}>{"Inactive"}</span>
        ) : (
          data?.status
        ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => (state.showCreateModal = !state.showCreateModal))
    );
  };

  const handleEditAction = (e) => {
    navigate({
      pathname: "/admin/roles/role-update",
      search: `role=${e?.[0]}&update=true`,
    });
    dispatch(
      updateConfig((state) => {
        state.isEdit = true;
      })
    );
  };
  const handleDeleteAction = (e) => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };

  return {
    roleList,
    roleState,
    isLoading,
    selectedId,
    isFetching,
    tableFields,
    showEditModal,
    showDeleteModal,
    paginationOptions,
    handleEditColumnsClick,
    handleDeleteAction,
    handleCreateClick,
    updateRoleFields,
    handlePagination,
    handleClearClick,
    handleEditAction,
    handlePageSize,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  };
};

export default useRole;
