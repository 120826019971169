import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  startDate: new Date(),
  endDate: new Date(),
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  store_name: [],
  payment_mode: "",
  status: "",
  filterShow: false,
  excelSendModal: false,
  bestSellerExcelSendModal: false,
  totalOrderCount: "",
  totalAmount: "",
  totalFormattedAmount: "",
  totalFormattedRedeemedAmount: "",
  totalFormattedPurchasedAmount: "",
  totalRedeemedAmount: "",
  totalPurchasedAmount: "",
  selectedOrderFilter: "",
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    resetConfig: (state) => {
      state.store_name = [];
      state.payment_mode = "";
      state.status = "";
    },
  },
});

export const { updateConfig, resetConfig } = ordersSlice.actions;
export default ordersSlice.reducer;
