import {
  HeadingGroup,
  ModalLayout,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useContentList from "./useContentList";
import TermsAndConditions from "./TermsAndConditions";
import Style from "./contentList.module.scss";
import EmptyData from "../../Global/EmptyData";

const ContentList = () => {
  const {
    cmsState,
    isLoading,
    isFetching,
    showEditModal,
    privacyPolicyList,
    handleEditColumnsClick,
    handleCreateClick,
    handleCloseModal,
    handleEditAction,
    updateOrdersFields,
    handleClearClick,
    handleSearch,
    handleSort,
    refetch,
    getRow,
  } = useContentList();

  return (
    <>
      <HeadingGroup
        title="Policies and Terms"
        className={`pro-mb-4`}
        buttonTitle={privacyPolicyList?.create_permission && "Create"}
        handleClick={handleCreateClick}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={cmsState?.search === "" ? false : true}
          handleClear={handleClearClick}
          searchInputProps={{ value: cmsState?.search }}
          handleActionClick={handleEditColumnsClick}
        />
        {privacyPolicyList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={privacyPolicyList?.data?.result || []}
            uniqueID={"_id"}
            fields={privacyPolicyList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            handleSort={handleSort}
            handleEdit={handleEditAction}
            getRow={getRow}
            loading={isFetching}
            clear={cmsState?.clearSelection}
            perpage={cmsState?.currentPageSize}
            assignable={false}
            deletable={false}
            editable={privacyPolicyList?.data?.edit_permission}
            showCheckBox={privacyPolicyList?.data?.edit_permission}
            propStyle={{ proTableWrp: Style.pro_table_wrap }}
          />
        )}
        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={privacyPolicyList?.data?.fields || []}
              moduleId={privacyPolicyList?.data?.module_id}
              updateData={updateOrdersFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={cmsState.showCreateModal}
          handleClose={handleCloseModal}
          title={`Policies and Terms`}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop="static"
          propStyle={{ root: Style.modal_root }}
        >
          <TermsAndConditions
            handleCloseModal={handleCloseModal}
            refetch={refetch}
          />
        </ModalLayout>
      </div>
    </>
  );
};

export default ContentList;
