import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  allRoles: [],
  allRolesStatus: "idle",
  allPermissions: [],
  allPermissionsStatus: "idle",
  rolePermission: {},
  rolePermissionStatus: "idle",
  showCreateModal: false,
  name: "",
  clearSelection: false,
  PermissionsModules: {},
  userDetails: "",
  search: "",
  isEdit: false,
};

export const getAllPermissions = createAsyncThunk(
  "roles/getAllPermissions",
  async (_, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/role/basic-data`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPermission = createAsyncThunk(
  "roles/getPermission",
  async (params, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/role/edit?role_id=${params.id}`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPermissions.pending, (state) => {
      state.allPermissionsStatus = "pending";
    });
    builder.addCase(getAllPermissions.fulfilled, (state, action) => {
      state.allPermissionsStatus = "success";
      state.allPermissions = action.payload?.data?.data;
    });
    builder.addCase(getAllPermissions.rejected, (state) => {
      state.allPermissionsStatus = "failed";
    });
    builder.addCase(getPermission.pending, (state) => {
      state.rolePermissionStatus = "pending";
    });
    builder.addCase(getPermission.fulfilled, (state, action) => {
      state.rolePermission[action.meta.arg.id] = action.payload?.data?.data;
      state.userDetails = action.payload?.data?.data;
      state.rolePermissionStatus = "success";
    });
    builder.addCase(getPermission.rejected, (state) => {
      state.rolePermissionStatus = "failed";
    });
  },
});

export const { updateConfig } = roleSlice.actions;

export default roleSlice.reducer;
