import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const useVideoCard = () => {
  const [muted, setMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [seeking, setSeeking] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [duration, setDuration] = useState(0);
  const playerRef = React.createRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [hours, setHours] = useState(0);

  useEffect(() => {
    if (playerRef) {
      playerRef.current.volume = volume / 100;
      playerRef.current.muted = muted;
    }
  }, [volume, playerRef, muted]);

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e) => {
    const time = parseFloat(e.target.value);
    setCurrentTime(time);
    if (playerRef.current) {
      playerRef.current.seekTo(time);
    }
  };

  const handleSeekMouseUp = () => {
    setSeeking(false);
    playerRef.current.seekTo(currentTime);
  };

  const handleProgress = (state) => {
    setCurrentTime(state.playedSeconds);
    parseInt(duration) === parseInt(currentTime) && setIsPlaying(false);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    if (minutes > 59) {
      const hour = Math.floor(timeInSeconds / 3600);
      setHours(hour);
    }
    const seconds = Math.floor(timeInSeconds % 60);
    return `${hours}:${String(minutes).padStart(2, 0)}:${String(
      seconds
    ).padStart(2, 0)}`;
  };

  const handlePlayPause = () => {
    if (parseInt(duration) === parseInt(currentTime)) {
      setCurrentTime(0);
      setIsPlaying(true);
    } else {
      setIsPlaying(!isPlaying);
    }
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };
  return {
    volume,
    duration,
    isPlaying,
    playerRef,
    currentTime,
    handleSeekMouseDown,
    handleSeekMouseUp,
    handleSeekChange,
    handlePlayPause,
    handleDuration,
    handleProgress,
    formatTime,
  };
};

export default useVideoCard;
