import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdminAxiosInstance } from "../../../../api/Admin";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  faqData: [],
  editFaqData: {},
  error: {},
};

export const getFaqEditData = createAsyncThunk(
  "/faq/edit",
  async (id, { rejectWithValue }) => {
    const api = await getAdminAxiosInstance();
    try {
      const response = await api.get(`/faq/edit?faq_id=${id}`);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFaqEditData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFaqEditData.fulfilled, (state, action) => {
        state.editFaqData = action.payload?.data;
      })
      .addCase(getFaqEditData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload?.data;
      });
  },
});

export const { updateConfig } = faqSlice.actions;
export default faqSlice.reducer;
