import { useSelector, useDispatch } from "react-redux";
import {
  useGetContactListDataQuery,
  useUpdateContactTableHeadDataMutation,
} from "../../../store/queries/Admin";
import {
  getContactEditData,
  updateConfig,
} from "../../../store/slices/Admin/Contact/contactSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { deleteContactInfo } from "./api";
import { toast } from "react-toastify";

const useContact = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const contactState = useSelector((state) => state.contact);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    data: contactList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetContactListDataQuery({
    page_size: contactState?.currentPageSize,
    page: contactState?.currentPage,
    sort: contactState?.sortBy,
    sort_order: contactState?.sortOrder,
    search: contactState?.search,
  });
  const [updateContactFields] = useUpdateContactTableHeadDataMutation();

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };

  const handleSort = (label) => {
    if (contactState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = contactState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };
  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = !state.showEditModal;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getContactEditData(e?.[0]));
  };
  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = !state.showEditModal;
        state.isEdit = false;
      })
    );
  };
  const handleDelete = async () => {
    const response = await deleteContactInfo(contactState?.selectedId);
    if (response && response.status === 200) {
      toast.success(`Banner deleted successfully.`);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };

  return {
    isLoading,
    isFetching,
    contactList,
    tableFields,
    contactState,
    showEditModal,
    paginationOptions,
    handleEditColumnsClick,
    updateContactFields,
    handleEditAction,
    handleDeleteModal,
    handleCloseModal,
    handlePagination,
    handleClearClick,
    handlePageSize,
    handleSearch,
    handleDelete,
    handleSort,
    refetch,
    getRow,
  };
};

export default useContact;
