import {
  Header,
  MultiColumnTable,
  ProfileSideBar,
  SimpleTextDetails,
  Image,
} from "@wac-ui-dashboard/wac_component_library";

import Assets from "../../../assets/Assets";
import ProtectRoute from "../../../utils/components/ProtectRoute/protectRoute";
import { Link, Outlet } from "react-router-dom";
import useUserDetailViewLayout from "./useUserDetailViewLayout";
import HeaderActions from "../../../pages/Layout/CommonLayout/HeaderActions";
import Style from "../../../pages/Layout/CommonLayout/commonLayout.module.scss";
import profileStyle from "./userDetailLayout.module.scss";
import ProfileLayoutSidebarShimmer from "../../Global/Shimmers/ProfileLayoutSidebarShimmer";

const UserDetailLayout = ({ navigation }) => {
  const {
    navigations,
    basicDetails,
    activeProfile,
    globalState,
    profileData,
    isFetching,
  } = useUserDetailViewLayout();

  return (
    <ProtectRoute>
      <Header
        navigations={navigations}
        Link={Link}
        logo={
          globalState.currentTheme === "light" ||
          globalState.currentTheme === null
            ? Assets.LOGO
            : Assets.LOGO
        }
        children={<HeaderActions />}
        propStyle={{ header: Style.header, headerInner: Style.headerInner }}
      />

      <div
        className={`pro-w-100 container-fluid pt-custom ${profileStyle.main_wrapper}`}
      >
        <div className={`row gx-0`}>
          {/* side bar left */}
          <div className={`col-3 ${profileStyle.left_sidebar}`}>
            {isFetching &&
            Object.keys(profileData?.data ?? {})?.length === 0 ? (
              <>
                <ProfileLayoutSidebarShimmer />
              </>
            ) : (
              <>
                {/**#FIXME handle deleted accounts */}
                <ProfileSideBar
                  extraClassName={profileStyle.left_sidebar_sticky}
                >
                  <div className={`pro-px-5 pro-pt-5`}>
                    <div className={`${profileStyle.left_sidebar_sticky_top}`}>
                      <div className={profileStyle.profile}>
                        <Image
                          src={`${
                            profileData?.data?.image_url == "" ||
                            profileData?.data?.image_url == null
                              ? Assets.GENDER_IMAGE
                              : profileData?.data?.image_url
                          }`}
                          width={100 || ""}
                          height={100 || ""}
                        />
                      </div>

                      <SimpleTextDetails
                        title={`${profileData?.data?.full_name ?? ""}`}
                        designation={`${profileData?.data?.gender ?? ""}`}
                        uId={profileData?.data?.rows?.registration_no ?? ""}
                        extraClassNames={`pro-mt-4 pro-mb-4 ${profileStyle.profile_name_wrap}`}
                      />
                      <div
                        className={`pro-d-flex pro-justify-between pro-items-center pro-py-4 ${profileStyle.points_wrap}`}
                      >
                        <h5 className="pro-mb-0 pro-ttl">Reward Points</h5>
                        <h5 className="pro-mb-0 pro-ttl">
                          {profileData?.data?.pointsBalance ?? "0"} points
                        </h5>
                      </div>

                      <div
                        className={`pro-p-4 pro-d-flex pro-justify-between pro-mt-4 pro-mb-5 pro-items-center pro-gap-1 pro-rounded-3 ${profileStyle.balance_wrap}`}
                      >
                        <div
                          className={`pro-d-flex pro-items-center pro-gap-2`}
                        >
                          <span className="material-symbols-outlined x4">
                            wallet
                          </span>
                          <p className="pro-mb-0 pro-font-sm pro-fw-medium">{`Available Balance`}</p>
                        </div>
                        <h6 className="pro-mb-0">{`KD ${
                          profileData?.data?.creditBalance ?? "0"
                        }`}</h6>
                      </div>
                    </div>
                    <div
                      className={`${profileStyle.left_sidebar_sticky_scrollWrap} pro-pt-1`}
                    >
                      <MultiColumnTable
                        title={"Basic Details"}
                        data={basicDetails}
                        extraClassNames={`pro-mb-5`}
                      />
                    </div>
                  </div>
                </ProfileSideBar>
              </>
            )}
          </div>
          <div className={`${profileStyle.main_container}`}>
            <div className={`${profileStyle.main_container_inner}`}>
              <Outlet context={{ activeProfile }} />
            </div>
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default UserDetailLayout;
