/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useProduct from "./useProduct";
import Style from "./product.module.scss";
import ProductChangeForm from "./ProductChangeForm";
import EmptyData from "../../Global/EmptyData";
import Select from "react-select";
import EditProduct from "./EditProduct";
import { ImageCard } from "../../Global/ImageCard";

const Product = () => {
  const {
    shopData,
    isLoading,
    imageData,
    isFetching,
    categoryObj,
    productList,
    showEditModal,
    offCanvasWidth,
    productState,
    categoryList,
    showImageModal,
    paginationOptions,
    offCanvasEditWidth,
    handleEditColumnsClick,
    updateProductFields,
    handleDeleteModal,
    handleProductChangeClose,
    handleCreateClick,
    handleEditAction,
    handleClearClick,
    handlePagination,
    closeImageModal,
    handlePageSize,
    handleSearch,
    handleDelete,
    handleClose,
    handleChange,
    handleSort,
    refetch,
    getRow,
  } = useProduct();

  return (
    <div>
      <HeadingGroup
        title={`Product (${shopData?.name})`}
        className={`pro-mb-4`}
      />
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          showClearFilters={
            productState?.search !== "" || productState.selectedCategory
              ? true
              : false
          }
          handleClear={handleClearClick}
          searchInputProps={{ value: productState?.search }}
          handleActionClick={handleEditColumnsClick}
          dropDownFilter={
            <>
              <div className="col-auto dropdown-filter">
                <Select
                  value={categoryObj}
                  onChange={handleChange}
                  options={categoryList}
                  placeholder="Select Category"
                  classNamePrefix={`pro-input`}
                  className={`pro-input lg search-filter-select`}
                />
              </div>
            </>
          }
        />
        {productList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={productList?.data?.result || []}
            uniqueID={"_id"}
            fields={productList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            handleSort={handleSort}
            getRow={getRow}
            clear={productState?.clearSelection}
            loading={isFetching}
            perpage={productState?.currentPageSize}
            assignable={false}
            handleEdit={handleEditAction}
            handleDelete={handleDeleteModal}
            deletable={true}
            // deletable={subAdminList?.data?.delete_permission}
            editable={true}
            // editable={subAdminList?.data?.edit_permission}
            showCheckBox={true}
          />
        )}

        {productList?.data?.total_count > 10 && (
          <Pagination
            currentPage={productState?.currentPage}
            totalPageCount={Math.ceil(
              productList?.data?.total_count / productState.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === productState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={productState?.showDeleteModal}
          handleClose={handleDeleteModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={"Are you sure delete the selected Sub Admin ?"}
              subTitle={"This action can't be undo "}
              cancelText={"No"}
              cancelAction={handleDeleteModal}
              submitText={"Yes"}
              submitAction={handleDelete}
              isRight={true}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={productList?.data?.fields || []}
              moduleId={productList?.data?.module_id}
              updateData={updateProductFields}
            />
          </div>
        </ModalLayout>
        <OffCanvasLayout
          show={productState?.showCreateModal}
          handleClose={handleProductChangeClose}
          title={"Product Change"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
          className={`${Style.offcanvas} ${offCanvasWidth ? Style.small : ""}`}
        >
          <ProductChangeForm isStickyFooter={true} refetch={refetch} />
        </OffCanvasLayout>
        <OffCanvasLayout
          show={productState?.showProductEditModal}
          handleClose={handleClose}
          title={"Product Update"}
          closeIcon={<span className="material-symbols-outlined"> close </span>}
          backdrop={"static"}
          className={`${Style.edit_offcanvas} ${
            offCanvasEditWidth ? Style.small : ""
          }`}
        >
          <EditProduct refetch={refetch} />
        </OffCanvasLayout>
        <ModalLayout
          show={showImageModal}
          handleClose={closeImageModal}
          backdrop="static"
        >
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>
      </div>
    </div>
  );
};

export default Product;
