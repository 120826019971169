import {
  Button,
  IconText,
  // Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAddVideoBanner from "./useAddVideoBanner";

const AddVideoBanner = ({ handleCreateClick, refetch, isStickyFooter }) => {
  const {
    isEdit,
    formik,
    basicData,
    videoName,
    isLoading,
    // videoPreview,
    arabicVideoName,
    // arabicVideoPreview,
    isLoadingVideo,
    isArabicVideoLoading,
    handleVideoChange,
    handleTypeChange,
    getFieldError,
    closeModal,
  } = useAddVideoBanner({ handleCreateClick, refetch });
  return (
    <div className="row">
      <div className="input-wrap pro-mb-4">
        <Input
          label={"Title"}
          type="text"
          id="title"
          name="title"
          className={`pro-input lg ${getFieldError("title") && " error"}`}
          {...formik.getFieldProps("title")}
          // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          error={getFieldError("title")}
          errorMessage={getFieldError("title")}
        />
        <div className="pro-rtl">
          <Input
            label={"(عنوان)Title"}
            type="text"
            id="alias_title"
            name="alias_title"
            className={`pro-input lg ${
              getFieldError("alias_title") && " error"
            }`}
            {...formik.getFieldProps("alias_title")}
            // onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
            error={getFieldError("alias_title")}
            errorMessage={getFieldError("alias_title")}
          />
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Type
          </label>
          <Select
            id="type"
            name="type"
            placeholder={"Select"}
            className={`pro-input lg  ${getFieldError("type") && " error"}`}
            classNamePrefix="pro-input"
            options={basicData?.data?.types ?? []}
            value={formik?.values?.type ?? {}}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            onBlur={formik.handleBlur("type")}
            onChange={(e) => handleTypeChange(e)}
            menuPlacement="auto"
          />
          {getFieldError("type") && (
            <span className="error-text">{getFieldError("type")}</span>
          )}
        </div>

        <div className="input-wrap pro-mb-4 ">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            Video
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".mp4,.mov , .avi,.mpeg "
              className={`pro-input ${
                formik.errors.video && formik.touched.video && " error"
              }`}
              id="image"
              onBlur={formik.handleBlur("video")}
              onChange={(e) => handleVideoChange(e, "video")}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  isLoadingVideo
                    ? "Uploading..."
                    : videoName !== ""
                    ? videoName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched.video && formik.errors.video && (
              <span className="error-text">{formik.errors.video}</span>
            )}
          </div>
          {/* <div className={`col-2 pro-my-2`}>
            <div className={``}>
              <Image
                width={100}
                height={100}
                src={videoPreview}
                alt={`image - 01`}
              />
            </div>
          </div> */}
        </div>
        <div className="input-wrap pro-mb-4 pro-rtl">
          <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
            ( فيديو ) Video
          </label>
          <div className="input-drag">
            <input
              type="file"
              accept=".mp4,.mov,.avi,.mpeg "
              className={`pro-input ${
                formik.errors.alias_video &&
                formik.touched.alias_video &&
                " error"
              }`}
              id="image"
              onBlur={formik.handleBlur("alias_video")}
              onChange={(e) => handleVideoChange(e, "alias_video")}
            />
            <span className="input-drag-box">
              <IconText
                title={
                  isArabicVideoLoading
                    ? "...Uploading"
                    : arabicVideoName !== ""
                    ? arabicVideoName
                    : `Drop files to attach or browse`
                }
              />
            </span>
            {formik.touched.alias_video && formik.errors.alias_video && (
              <span className="error-text">{formik.errors.alias_video}</span>
            )}
          </div>
          {/* <div className={`col-2 pro-my-2`}>
            <div className={``}>
              <Image
                width={100}
                height={100}
                src={arabicVideoPreview}
                alt={`image - 01`}
              />
            </div>
          </div> */}
        </div>

        <div className="pro-check-box pro-py-2">
          <div className="form-check form-switch ">
            <input
              className="form-check-input cursor-pointer"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              checked={Boolean(formik.values?.status === 1) ?? false}
              onChange={(e) =>
                formik.setFieldValue(`status`, e?.target?.checked ? 1 : 2)
              }
            />
            <label htmlFor="default" className="pro-check-label">
              Status
            </label>
          </div>
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end ${
            isStickyFooter && "offcanvas-footer-sticky-btns"
          }`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={() => closeModal()}
          >
            Cancel
          </Button>

          <Button
            className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
            type="submit"
            disabled={isLoading || isLoadingVideo || isArabicVideoLoading}
            onClick={formik.handleSubmit}
          >
            {!isEdit
              ? isLoading
                ? "Creating..."
                : "Create"
              : isLoading
              ? "Updating..."
              : "Update"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddVideoBanner;
