/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { copyStoreMenu } from "./api";

import React, { useEffect, useState } from "react";
import {
  getStoreEditData,
  updateConfig,
} from "../../../store/slices/Admin/Store/storeSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import {
  admin,
  useGetStoreListDataQuery,
  useUpdateStoreStatusMutation,
  useUpdateStoreTableHeadDataMutation,
} from "../../../store/queries/Admin";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteStore } from "./AddStore/api";
import Style from "../../Products/Product/product.module.scss";
import {
  ManualMenuFetching,
  fetchMenuData,
  posistMenuFetchingData,
} from "./api";
import { current } from "@reduxjs/toolkit";

const useStore = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuFetching, setMenuFetching] = useState(false);
  const [copyClicked, setCopyClicked] = useState(false);
  const [copyConfirm, setCopyConfirm] = useState(false);
  const [storeNames, setStoreNames] = useState({
    store1: "",
    store2: "",
  });
  const storeState = useSelector((state) => state.store);
  const { selectedId } = useSelector((state) => state.store);
  const { showEditModal } = useSelector((state) => state.global);
  const {
    data: storeList = {},
    isLoading,
    isFetching,
    refetch,
  } = useGetStoreListDataQuery({
    page_size: storeState?.currentPageSize,
    page: storeState?.currentPage,
    sort: storeState?.sortBy,
    sort_order: storeState?.sortOrder,
    search: storeState?.search,
  });
  const [updateStoreFields] = useUpdateStoreTableHeadDataMutation();
  const [updateStoreStatus] = useUpdateStoreStatusMutation();

  //defining formik here for the copy store form
  const validation = Yup.object({
    store_from: Yup.string().required("Enter store from"),
    store_to: Yup.string().required("Enter store to"),
  });

  const formik = useFormik({
    initialValues: {
      store_from: "",
      store_to: ",",
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const obj = {
        from_customer_key: values?.store_from,
        to_customer_key: values?.store_to,
      };
      copyStoreMenu(obj).then((res) => {
        if (res.status === 200) {
          toast.success("Store copied successfully");
          refetch();
          handleCloseCopyModal();
          setCopyConfirm(false);
          setStoreNames({
            store1: "",
            store2: "",
          });
        } else {
          toast.error("Failed to copy store");
          handleCloseCopyModal();
          setCopyConfirm(false);
          setStoreNames({
            store1: "",
            store2: "",
          });
        }
      });
    },
  });

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isEdit = false;
        state.activeTab = "Basic";
        state.imagePreview = "";
        state.imageName = "";
        state.curbTimeError = {};
        state.pickupTimeError = {};
      })
    );
  };
  useEffect(() => {
    if (storeState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [storeState.clearSelection]);

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
  };
  const handleEditColumnsClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  const handleToggle = (e, data) => {
    const newStatus = data.status === "Active" ? false : true;
    let body = {
      store_id: data?.id,
      status: newStatus ? 1 : 2,
    };
    updateStoreStatus(body);
    dispatch(
      admin.util.updateQueryData(
        "getStoreListData",
        {
          page_size: storeState?.currentPageSize,
          page: storeState?.currentPage,
          sort: storeState?.sortBy,
          sort_order: storeState?.sortOrder,
          search: storeState?.search,
        },
        (cacheEntry) => {
          let currentCache = { ...current(cacheEntry) };
          const newResult = currentCache.result?.map((res) =>
            res.id === data?.id
              ? {
                  ...res,
                  status: data.status === "Active" ? "Deactivated" : "Active",
                }
              : res
          );
          currentCache = {
            ...currentCache,
            result: newResult,
          };
          return currentCache;
        }
      )
    );
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => (
        <p
          className={`pro-mb-0 ${
            data?.menu_updation === 1 ? Style.product_active : ""
          }`}
        >
          {data[field]}
        </p>
      ),
      menu_updation: (field, data) => (
        <div
          className={`${data?.menu_updation === 1 ? Style.product_active : ""}`}
        >
          <a
            className={`pro-pnt ${
              !storeList?.product_edit_permission && Style.disable_fetch
            }`}
            onClick={() => {
              data?.menu_updation === 1
                ? handleFetchMenu(data)
                : handleFetchData(data);
            }}
          >
            {`${
              data?.menu_updation === 1 && !menuFetching
                ? "Fetch Menu"
                : data?.menu_updation === 1 && menuFetching
                ? "Menu Fetching..."
                : "Fetch Product"
            }`}
          </a>
        </div>
      ),
      status: (field, data) => {
        const isChecked = data?.status === "Active";
        return (
          <div
            className={`${
              data?.menu_updation === 1 ? Style.product_active : ""
            }`}
          >
            <div className="form-check form-switch ">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={isChecked}
                onChange={(e) => handleToggle(e, data)}
                disabled={!storeList?.status_change_permission}
              />
            </div>
          </div>
        );
      },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleFetchData = (data) => {
    localStorage.setItem("shopData", JSON.stringify(data));
    navigate(`/admin/store/product-list`);
  };
  const handleFetchMenu = (data) => {
    setMenuFetching(true);
    let params = {
      customer_key: data?.customer_key,
    };
    fetchMenuData(params).then((response) => {
      if (response?.data?.status_code === 200) {
        setMenuFetching(false);
        refetch();
        toast.success(`Menu fetched successfully`);
      } else {
        toast.error("Something went wrong");
        setMenuFetching(false);
      }
    });
  };
  const handleSort = (label) => {
    if (storeState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = storeState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };
  const handleEditAction = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.selectedId = e?.[0];
        state.isEdit = true;
      })
    );
    dispatch(getStoreEditData(e?.[0]));
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedId = e?.[0];
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };
  const handleDelete = async () => {
    const response = await deleteStore(selectedId);
    if (response && response.status === 200) {
      toast.success(`Store deleted successfully.`);
      refetch();
    } else {
      toast.error(`Something went wrong !`);
    }
    handleDeleteModal();
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };
  const handleMenuFetching = async (e) => {
    setMenuFetching(true);
    const response = await posistMenuFetchingData(e?.[0]);
    try {
      if (response?.data?.status_code === 200) {
        toast.success(`Posist menu fetched successfully`);
        refetch?.();
        setMenuFetching(false);
      } else {
        toast.error(`Failed to fetch menu`);
        setMenuFetching(false);
      }
    } catch (error) {
      console.error("Error fetch menu:", error);
      toast.error(error?.message);
      setMenuFetching(false);
    }
  };

  const handleManualMenuFetching = async () => {
    setMenuFetching(true);
    const response = await ManualMenuFetching();
    try {
      if (response?.data?.status_code === 200) {
        toast.success(`All menu fetched successfully`);
        refetch?.();
        setMenuFetching(false);
      } else {
        toast.error(`Failed to fetch menu`);
        setMenuFetching(false);
      }
    } catch (error) {
      console.error("Error fetch menu:", error);
      toast.error(error?.message);
      setMenuFetching(false);
    }
  };

  const handleCopyStoreMenu = () => {
    setCopyClicked(true);
  };

  const handleCloseCopyModal = () => {
    formik.setFieldValue("store_from", "");
    formik.setFieldValue("store_to", "");

    setCopyClicked(false);
    setStoreNames({
      store1: "",
      store2: "",
    });
  };

  const handleCloseConfirmation = () => {
    setCopyConfirm(false);
    setCopyClicked(true);
  };

  return {
    isLoading,
    isFetching,
    storeList,
    showEditModal,
    storeState,
    menuFetching,
    paginationOptions,
    copyClicked,
    formik,
    copyConfirm,
    storeNames,
    setStoreNames,
    setCopyClicked,
    handleCloseConfirmation,
    setCopyConfirm,
    refetch,
    handleCloseCopyModal,
    handleCopyStoreMenu,
    handleManualMenuFetching,
    handleEditColumnsClick,
    handleMenuFetching,
    updateStoreFields,
    handleDeleteModal,
    handleCreateClick,
    handlePagination,
    handleClearClick,
    handleEditAction,
    handlePageSize,
    handleSearch,
    handleDelete,
    handleSort,
    getRow,
  };
};

export default useStore;
