import { getAdminAxiosInstance } from "../../../api/Admin";

export const AddUpdateRole = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/role/store`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const changeRole = async (data) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.get(
      `/role/destroy?role_id=${data?.role_id}&new_role_id=${data?.new_role_id}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
