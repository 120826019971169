import { getAdminAxiosInstance } from "../../../api/Admin";

export const updateHomePage = async (body) => {
  const api = await getAdminAxiosInstance();
  try {
    const response = await api.post(`/settings/update-home-page`, body);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
