import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentPageSize: 10,
  currentFilter: "all",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  isEdit: false,
  showCreateModal: false,
  showDeleteModal: false,
  clearSelection: false,
  shoeEditModal: false,
  selectedId: "",
  isLoading: false,
  showPassword: false,
  editPrivacyPolicyData: {},
  //image uploads
  isLoadingImage: false,
  isLoadingAliasImage: false,
  imageName: "",
  aliasImageName: "",
  imagePreview: "",
  aliasImagePreview: "",
  excelFileName: "",
};

const customNotificationSlice = createSlice({
  name: "customNotification",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: () => {},
});

export const { updateConfig } = customNotificationSlice.actions;
export default customNotificationSlice.reducer;
