import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import EmptyData from "../../Global/EmptyData";
import useBestSeller from "./useBestSeller";
import SendExcelData from "../../Admin/OrderList/SendExcelData";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";

const BestSeller = () => {
  const {
    date,
    isLoading,
    isFetching,
    showEditModal,
    bestSellerList,
    isExcelLinkSend,
    bestSellerState,
    paginationOptions,
    handleBestSellerExcelDownload,
    handleEditColumnsClick,
    updateBestSellerFields,
    handleDateRangeChange,
    handlePagination,
    handleDateChange,
    handlePageSize,
    sendExcelData,
    handleSearch,
    modalClose,
    handleSort,
    refetch,
    getRow,
  } = useBestSeller();
  return (
    <>
      <HeadingGroup title="Best Seller" className={`pro-mb-4`}>
        <div className="col dropdown-filter pro-d-flex">
          <button
            className={`pro-btn pro-btn-outline-primary no-wrap pro-items-center pro-ms-3`}
            type="submit"
            onClick={handleBestSellerExcelDownload}
          >
            <span className="material-symbols-outlined x4">download</span>
            <span>{`Best Seller Data`}</span>
          </button>
        </div>
      </HeadingGroup>
      <div className="col-auto pro-pt-3 pro-pb-6">
        <SearchFilters
          onSearchInput={handleSearch}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          searchable={false}
          showClearFilters={false}
          searchInputProps={{ value: bestSellerState?.search }}
          handleActionClick={handleEditColumnsClick}
          dropDownFilter={
            <>
              <div className="col-auto">
                <CustomDateRangePicker
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>
            </>
          }
          onDateChange={handleDateChange}
          initialDateRange={date}
        />
        {bestSellerList?.data?.result?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            multiSelect={false}
            data={bestSellerList?.data?.result || []}
            uniqueID={"id"}
            fields={bestSellerList?.data?.fields || []}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            clear={bestSellerState?.clearSelection}
            perpage={bestSellerState?.currentPageSize}
            assignable={false}
            deletable={false}
            editable={false}
            showCheckBox={false}
          />
        )}

        {bestSellerList?.data?.result?.length > 0 && (
          <Pagination
            currentPage={bestSellerState?.currentPage}
            totalPageCount={Math.ceil(
              bestSellerList?.data.total_count /
                bestSellerState?.currentPageSize
            )}
            defaultValue={paginationOptions?.filter(
              (item) => item?.value === bestSellerState?.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          show={showEditModal}
          handleClose={handleEditColumnsClick}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetchData={refetch}
              tableFields={bestSellerList?.data?.fields || []}
              moduleId={bestSellerList?.data?.module_id}
              updateData={updateBestSellerFields}
            />
          </div>
        </ModalLayout>
        <ModalLayout
          show={bestSellerState.bestSellerExcelSendModal}
          handleClose={handleBestSellerExcelDownload}
          title={`Send Excel Download Link`}
          backdrop="static"
        >
          <div className="pro-m-5">
            <SendExcelData
              isBestSeller
              handleCloseModal={modalClose}
              sendExcelData={sendExcelData}
              isExcelLinkSend={isExcelLinkSend}
            />
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default BestSeller;
